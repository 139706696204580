import React, { useState } from "react";
import Icon from "components/Icon";
import { useStyles } from "./styles";
import { useTranslation } from "react-i18next";

export function CustomPrayCardStyled(props) {
  const [isHover, setHover] = useState(false);
  const { t, i18n } = useTranslation();
  const {
    icon,
    title,
    typeMode,
    position,
    textHover,
    id,
    onClick,
    isSmall,
    doubleCard,
    isFirstRow,
    isLastRow,
    isFirstInRow,
    isLastInRow,
    roundedCorners,
    borderHover
  } = props;

  const classes = useStyles({
    mode: typeMode,
    position,
    mediaQuerySm: isSmall,
    doubleCard,
    isFirstRow,
    isLastRow,
    isFirstInRow,
    isLastInRow,
    roundedCorners,
    borderHover
  });

  const synodTitle = i18n.exists(title)
    ? t(title.replace(/–/g, "\n"))
    : title.replace(/–/g, "\n");
  const fullSynodTitle = `${synodTitle}`;

  const card = () => {
    return (
      <div
        data-testid={`crd_pray_content_${id}`}
        className={`${classes.list} ${classes.card} ${
          doubleCard ? classes.doubleCard : ""
        } ${roundedCorners ? classes.rounded : ""}`}
        onClick={onClick}
        id={`crd_pray_content_${id}`}
        onMouseOver={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <div className={classes.contentText} id={`content_text_${id}`}>
          {icon && (
            <Icon id={`ico_${id}`} icon={icon} alt={title} css={classes.icon} />
          )}
          {title && (
            <p data-testid={`txt_pray_title_${id}`}>{fullSynodTitle}</p>
          )}
        </div>
        {isHover && textHover && (
          <div className={classes.textHover}>
            <div id={`content_hover_${id}`}>
              <p data-testid={`txt_pray_textHover_${id}`}>
                {i18n.exists(textHover) ? t(textHover) : textHover}
              </p>
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      {doubleCard ? (
        <div className={classes.contentDoubleCard}>
          <div className={classes.doubleCard}> </div>
          {card()}
        </div>
      ) : (
        card()
      )}
    </>
  );
}

export default CustomPrayCardStyled;
