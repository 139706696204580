import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles({
  root: {
    width: "100%",
    paddingTop: "1em",
    paddingBottom: "2em",
    overflowX: "hidden",
    overflowY: "hidden"
  },
  contentText: {
    height: "100%",
    width: "150px",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    "& p": {
      margin: "10px 0 8px 0",
      fontFamily: "Nunito Sans",
      color: "#444C67",
      fontWeight: 600,
      fontSize: "0.9em",
      overflow: "visible",
      lineHeight: "1.2em",
      maxHeight: "4.8em",
      display: "-webkit-box",
      "-webkit-line-clamp": 4,
      "-webkit-box-orient": "vertical"
    },
    "&>div:last-child": {
      background: "#E7ECF0 !important",
      borderRadius: "100px !important",
      padding: "4px 16px",
      "&>p": {
        margin: 0,
        color: "#444C67",
        fontSize: "14px"
      }
    }
  },
  typePray: {
    fontFamily: "Nunito Sans !important",
    margin: "0px 0px 8px 0px !important",
    fontWeight: "normal !important",
    color: "#5D688E !important",
    fontSize: "0.875em !important",
    lineHeight: "24px !important"
  },
  doubleCard: {
    display: "flex",
    zIndex: 1,
    margin: "-16px 0px 16px 16px",
    width: "184px",
    height: "208px",
    position: "absolute",
    background: "#DAE3EC",
    boxShadow:
      "-4px -4px 10px rgba(0, 0, 0, 0.05), 4px 4px 10px rgba(0, 0, 0, 0.05)",
    borderRadius: "12px"
  },
  textHover: (props) => ({
    width: "100%",
    display: "flex",
    alignItems: "flex-end",
    height: "32px",
    border: props.borderHover ? "1px solid #ECA25E !important" : "none",
    borderBottomLeftRadius: props.roundedCorners ? "12px" : "0",
    borderBottomRightRadius: props.roundedCorners ? "12px" : "0",
    overflow: "hidden",
    backgroundColor: "#ECA25E",
    "&>div": {
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderBottomLeftRadius: props.roundedCorners ? "12px" : "0",
      borderBottomRightRadius: props.roundedCorners ? "12px" : "0",
      backgroundColor: "inherit",
      "& p:nth-child(1)": {
        fontFamily: "Nunito Sans",
        margin: 0,
        color: "#FFFFFF",
        fontSize: "0.75em"
      }
    }
  }),
  contentDoubleCard: () => ({
    display: "flex",
    alignItems: "flex-end",
    height: "224px",
    width: "200px",
    margin: "0px"
  }),
  list: (props) => ({
    cursor: "pointer",
    margin: "0",
    boxShadow: "0 0.25em 0.625em rgba(0, 0, 0, 0.15)",
    padding: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    border: "none",
    height: "100%",
    width: "100%",
    ...(props.doubleCard && { margin: 0 }),
    ...(props.roundedCorners && { borderRadius: "12px" }),

    [`@media (min-width: 1024px)`]: {
      height: "216px",
      width: "192px"
    },

    [`@media (min-width: 768px) and (max-width: 1023px)`]: {
      height: "216px",
      width: "192px"
    },

    [`@media (max-width: 767px)`]: {
      height: "216px",
      width: "192px"
    }
  }),
  dobleCard: () => ({
    borderRadius: "12px !important",
    width: "192px",
    height: "216px",
    zIndex: "1",
    position: "relative"
  }),

  card: (props) => {
    let style = {
      padding: "0",
      background: "#FFFFFF",
      boxShadow:
        "-4px -4px 10px rgba(0, 0, 0, 0.05), 4px 4px 10px rgba(0, 0, 0, 0.05)",
      "& >div:last-child": {
        "&>div": {
          background: "#ECA25E"
        }
      },
      "& >div:first-child": {}
    };

    if (props.isFirstRow && props.isFirstInRow) {
      style.borderTopLeftRadius = "0.75em";
    }
    if (props.isFirstRow && props.isLastInRow) {
      style.borderTopRightRadius = "0.75em";
    }
    if (props.isLastRow && props.isFirstInRow) {
      style.borderBottomLeftRadius = "0.75em";
    }
    if (props.isLastRow && props.isLastInRow) {
      style.borderBottomRightRadius = "0.75em";
    }

    switch (props.mode) {
      case "pope":
        style = {
          ...style,
          marginLeft: 0,
          background:
            "linear-gradient(282.7deg, #FFE48F 0.06%, #E7C26D 99.94%)",
          "& >div:last-child": {
            "&>div": {
              background: "#E7C26D"
            }
          }
        };
        break;
      case "afternoon":
        style["& >div:last-child"]["&>div"].background = "#71AF7B";
        break;
      case "evening":
        style["& >div:last-child"]["&>div"].background = "#A15F99";
        break;
    }

    if (props.doubleCard) {
      style["& >div:last-child"]["&>div"].borderBottomLeftRadius = "0.75em";
      style["& >div:last-child"]["&>div"].borderBottomRightRadius = "0.75em";
    }

    if (props.mediaQuerySm) {
      style["& >div:first-child"].padding = "0 1.625em";
    }

    if (props.roundedCorners) {
      style.borderRadius = "12px";
    }

    return style;
  },
  roundedTopLeft: {
    borderTopLeftRadius: "0.75em"
  },
  roundedTopRight: {
    borderTopRightRadius: "0.75em"
  },
  roundedBottomLeft: {
    borderBottomLeftRadius: "0.75em"
  },
  roundedBottomRight: {
    borderBottomRightRadius: "0.75em"
  },
  rounded: {
    borderRadius: "12px"
  },
  gridContainer: ({ columnsLg, columnsMd, columnsSm }) => ({
    display: "grid",
    gap: "24px",
    justifyContent: "center",
    [`@media (min-width: 1000px)`]: {
      gridTemplateColumns: `repeat(${columnsLg}, 192px)`
    },
    [`@media (min-width: 720px) and (max-width: 999px)`]: {
      gridTemplateColumns: `repeat(${columnsMd}, 192px)`
    },
    [`@media (max-width: 719px)`]: {
      gridTemplateColumns: `repeat(${columnsSm}, 192px)`,
      gap: "16px",
      justifyContent: "center",
      maxWidth: `${columnsSm * 192 + (columnsSm - 1) * 16}px`, // Ancho máximo basado en el número de columnas
      margin: "0 auto" // Centrar el contenedor
    }
  }),
  gridItem: {
    display: "flex",
    justifyContent: "center",
    width: "192px",
    height: "216px",
    [`@media (max-width: 719px)`]: {
      width: "192px",
      height: "216px",
      margin: "0 auto" // Centrar el elemento en móvil
    }
  },

  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: "2em"
  },
  noMargin: {
    margin: 0
  }
});
