import React from "react";
import { useStyles } from "./styles";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Icon from "components/Icon";
import startIcon from "assets/icons/start.svg";
import Button from "components/Button";
import { DONATE } from "navigation/CONSTANTS";
import { scrollTopWindow } from "helpers";

export function DonateSectionStyled(props) {
  const { isMd } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles({ mediaQueryMd: isMd });
  return (
    <section
      id="txt_content_donate"
      data-testid="donate_view"
      className={classes.donateContent}
    >
      <Icon
        data-testid="ico_donate"
        id="ico_start"
        icon={startIcon}
        alt="start"
      />
      <h1 data-testid="txt_donate_title">
        {t(`pages.monthly.Your help is a Blessing`)}
      </h1>
      <p data-testid="txt_donate_description">
        {t(
          `pages.monthly.With your donation, you collaborate with the Pope’s Worldwide Prayer Network, Vatican Foundation and its projects`
        )}
      </p>
      <Button
        id="btn_donate"
        text="components.button.Donate"
        type="secondary"
        onClick={() => {
          history.push(DONATE);
          scrollTopWindow();
        }}
      />
    </section>
  );
}
