import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles({
  temporalContent: (props) => {
    let style = {
      height: "100vh",
      overflowX: "hidden",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      background: "rgb(244, 246, 248)",
      flexDirection: "column",
      "&>img": {
        width: "20%",
        height: "20%",
      },
      "& >h1": {
        fontFamily: "Libre Baskerville",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "44px",
        color: " #444C67",
        margin: 0,
      },
    };
    if (props.mediaQueryMd) style["& >h1"].fontSize = "28px";

    return style;
  },
});
