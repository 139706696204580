import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createStore, combineReducers } from "redux";
import { authReducer } from "reducers/authReducer";
import { modalReducer } from "reducers/modalReducer";
import { Provider } from "react-redux";
import "./overrides";
import "./i18n";

const reducer = combineReducers({
  auth: authReducer,
  modal: modalReducer,
});
const store = createStore(reducer);
ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);
reportWebVitals();
