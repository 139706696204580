import React from "react";
import Grid from "@material-ui/core/Grid";
import { useStyles } from "./styles";
import { useTranslation } from "react-i18next";

export function MapStyled(props) {
  const { countrys, isSm, isLg } = props;
  const classes = useStyles({
    mediaQuerySm: isSm,
    mediaQueryLg: isLg,
  });
  const { t } = useTranslation();
  return (
    <section className={classes.sectionWorldMap} data-testid="map_view">
      <Grid className={classes.containerGrid} container>
        <Grid
          className={classes.contentTitleMap}
          item
          xs={12}
          sm={12}
          md={12}
          lg={3}
          xl={3}
        >
          <h1>{t("components.map.Countries that pray with Click To Pray")}</h1>
        </Grid>
        <Grid
          className={classes.contentMap}
          item
          xs={12}
          sm={12}
          md={12}
          lg={9}
          xl={9}
        >
          <div>
            <svg className={classes.map} xmlns="http://www.w3.org/2000/svg">
              <g id="countries">
                {countrys?.map(({ d, dataId, dataName, id, fill }, index) => {
                  return (
                    <path
                      key={index}
                      d={d}
                      data-id={dataId}
                      data-name={dataName}
                      id={id}
                      fill={fill}
                    />
                  );
                })}
              </g>
            </svg>
          </div>
        </Grid>
      </Grid>
    </section>
  );
}
