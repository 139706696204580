import React from "react";
import { useTranslation } from "react-i18next";
import { useStyles } from "./styles";
import Icon from "components/Icon";
import { getLogo } from "helpers";
const TemporalView = (props) => {
  const { isMd } = props;
  const { t } = useTranslation();
  const classes = useStyles({
    mediaQueryMd: isMd,
  });
  return (
    <div className={classes.temporalContent}>
      <Icon icon={getLogo("light")} alt="Logo" />
      <h1 data-testid={"txt_redirecting"}>{t(`pages.temporal.Redirecting`)}</h1>
    </div>
  );
};

export default TemporalView;
