import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles({
  dailyContent: {
    overflowX: "hidden",
  },
  sectionCard: (props) => {
    let style = {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      backgroundSize: "cover",
      height: "100%",
      width: "100%",
      backgroundPosition: "0",
    };
    if (props?.backgroundImage?.color)
      style.backgroundColor = props?.backgroundImage?.color;
    else style.backgroundImage = `url(${props?.backgroundImage?.url})`;
    return style;
  },
  wrapperIntention: (props) => {
    let style = {
      margin: "0 159px 0px 224px",
    };

    if (props.mediaQueryMd) {
      style.width = "100%";
      style.maxWidth = "62em";
      style.margin = 0;
    }

    return style;
  },
  sectionTitle: (props) => {
    let style = {
      margin: "198.87px 0px 64px 0px",
      "&>h1": {
        fontFamily: "Libre Baskerville",
        fontSize: "44px",
        color: "#FFFFFF",
        margin: "0px 0px 16px 0px",
        fontWeight: "normal",
      },
      "&>h6": {
        fontFamily: "Nunito Sans",
        fontSize: "14px",
        letterSpacing: "0.1em",
        textTransform: "uppercase",
        color: "#FFFFFF",
        margin: 0,
      },
    };
    if (props.mediaQueryMd) {
      style = {
        ...style,
        margin: "88px 0px 33px 0px",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
        "&>h1": {
          ...style["&>h1"],
          margin: 0,
          fontSize: "28px",
        },
      };
    }
    return style;
  },
  dateContent: (props) => {
    let style = {
      display: "flex",
      flexDirection: "column",
      borderRight: "1px solid rgba(255, 255, 255, 0.3)",
      textAlign: "center",
      paddingRight: "32px",
      "& p": {
        fontFamily: "Nunito Sans",
        color: " #FFFFFF",
        margin: 0,
      },
      "& p:first-child": {
        fontSize: "0.875em",
        textTransform: "capitalize",
      },
      "& p:nth-child(2)": {
        fontSize: "5.5em",
        fontFamily: "Libre Baskerville",
      },
      "& p:last-child": {
        fontSize: "0.875em",
      },
    };
    if (props.mediaQueryMd) {
      style = {
        ...style,
        width: "100%",
        paddingRight: "0px",
        flexDirection: "row",
        borderRight: "none",
        justifyContent: "center",
        "& p": {
          fontFamily: "Nunito Sans",
          fontSize: "0.875em",
          margin: "0 0 0 0.625em",
          color: " #FFFFFF",
          textTransform: "uppercase",
          "&:first-child": {
            margin: 0,
          },
        },
        "& p:first-child": {
          textTransform: "uppercase",
        },
        "& p:nth-child(2)": {
          fontSize: "0.875em",
          fontFamily: "Libre Baskerville",
          color: " #FFFFFF",
        },
      };
    }

    return style;
  },
  buttonsNavigationsPrev: (props) => {
    let style = {
      padding: 0,
      height: "0px",
      "&>span": {
        justifyContent: "flex-start",
      },
    };
    if (props.mediaQueryMd)
      style = {
        ...style,
        "&>span": {
          justifyContent: "flex-end",
        },
      };
    return style;
  },
  buttonsNavigationsNext: () => {
    let style = {
      padding: 0,
      height: "0px",
      "&>span": {
        justifyContent: "flex-start",
      },
    };

    return style;
  },
  contentSinglePlayer: (props) => {
    let sytle = {
      margin: "0px 30px 0px 32px",
    };
    if (props.mediaQueryMd) sytle.margin = "25px 20px 0px 20px";
    return sytle;
  },
  contentPrayers: (props) => {
    let style = {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "center",
      margin: "0px 0px 133px 0px",
    };
    if (props.mediaQueryMd) {
      style = {
        ...style,
        flexDirection: "column",
        alignItems: "inherit",
      };
    }
    return style;
  },

  contentButtonNavigation: (props) => {
    let style = {
      display: "flex",
      alignItems: "center",
      height: "164px",
    };
    if (props.mediaQueryMd) {
      style = {
        ...style,
        height: "auto",
      };
    }
    return style;
  },
  buttons: (props) => {
    let style = { width: "64px" };
    if (props.mediaQueryMd) style.width = "64px";
    return style;
  },

  subtitle:()=>{
    let style = {fontWeight: "400"}
    return style;

  },
  sectionTitleWeek: (props) => {
    let style = {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      flexDirection: "column",
      padding: "120px 0px 63px 0px",
      "& h1": {
        fontFamily: "Libre Baskerville",
        fontWeight: "normal",
        fontSize: "44px",
        color: "#444C67",
        margin: "0px 0px 0px",
      },
      "& p": {
        fontFamily: "Nunito Sans",
        fontWeight: "normal",
        fontSize: "14px",
        color: "#444C67",
        textTransform: "uppercase",
        margin: 0,
      },
    };
    if (props.mediaQueryMd) {
      style = {
        ...style,
        padding: "64px 0px 33px 0px",
        "& h1": {
          ...style["& h1"],
          fontSize: "28px",
        },
      };
    }
    return style;
  },
  sectionPrayerWeek: () => {
    return {
      overflowX: "auto",
      scrollbarWidth: "none !important",
      scrollBehavior: "smooth",
      display: "flex",
      alignItems: "center",
      justifyContent: "left",
      "&::-webkit-scrollbar": {
        display: "none",
      },
    };
  },
  prayWeek: (props) => {
    let style = {
      margin: "0px 32px 0px 0px",
      "&:last-child": {
        margin: 0,
      },
    };
    if (props.mediaQueryMd) {
      style = {
        margin: "25px 0px 0px 0px",
        "&:last-child": {
          overflowX: "auto",
          margin: "25px 20px 0px 20px",
        },
      };
    }
    return style;
  },
  buttonsWeek: () => {
    let style = {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    };
    return style;
  },
  buttonsBackWeek: () => {
    let style = {
      width: "24px",
      margin: "0px 35px 0px 0px",
    };
    return style;
  },
  buttonsNextWeek: () => {
    let style = {
      margin: "0px 0px 0px 35px",
    };
    return style;
  },
  sectionWeek: (props) => {
    let style = {
      display: "flex",
      flexDirection: "row",
      padding: "0px 67px 95px 67px",
    };
    if (props.mediaQueryMd) {
      style = {
        flexDirection: "column",
        padding: "0px 0px 64px 0px",
      };
    }
    return style;
  },
  dateContentWeek: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "24px",
    "& p": {
      fontFamily: "Nunito Sans",
      color: "#444C67",
      margin: "0 0 0 0.625em",
      fontSize: "14px",
      textTransform: "uppercase",
      "&:first-child": {
        margin: 0,
      },
    },
  },
  button: {
    cursor: "pointer",
  },
  backgroundWeek: {
    background: "rgb(244, 246, 248)",
  },
});
