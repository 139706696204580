import React from "react";

import Community from 'components/Community';

const CommunityView = (props) => {
  const {
    isMd,
    posts,
    handleMorePost,
    languages,
    isSuccessPost,
    handlePostCommunity,
    handleMoreComments,
    handleEditComments,
    handleReportComment,
    handleReportPost,
    handlePostComments,
    onDeletePrayer,
    handleFilter,
    handleFavorite,
    handleDislike,
  } = props;
  return (
    <Community
      isSuccessPost={isSuccessPost}
      languages={languages}
      isMd={isMd}
      isTabCommunity
      posts={posts}
      handlePostComments={handlePostComments}
      handleMorePost={handleMorePost}
      handlePostCommunity={handlePostCommunity}
      handleMoreComments={handleMoreComments}
      handleEditComments={handleEditComments}
      handleReportComment={handleReportComment}
      handleReportPost={handleReportPost}
      onDeletePrayer={onDeletePrayer}
      handleFilter={handleFilter}
      handleFavorite={handleFavorite}
      handleDislike={handleDislike}
    />
  );
};

export default CommunityView;
