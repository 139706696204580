import React from "react";
import { useStyles } from "./styles";
import { Modal } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";

export function VideoPlayerStyled(props) {
  const { open, handleClose, src, isSmall } = props;
  const classes = useStyles({
    mediaQuerySm: isSmall,
  });
  return (
    <Modal
      open={open}
      closeAfterTransition
      onClose={() => handleClose()}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      className={classes.videoPlayer}
      data-testid="video_player"
      id="video_player"
    >
      <Fade in={open}>
        <div className={classes.contentVideoPlayer}>
          <iframe
            width="100%"
            height="100%"
            src={src}
            title="YouTube video player"
            allowFullScreen
          ></iframe>
        </div>
      </Fade>
    </Modal>
  );
}
