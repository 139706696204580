import React from "react";
import AddressCard from "components/Cards/AddressCard";
import Grid from '@material-ui/core/Grid';

export function AddressListCardStyled(props) {
  const { offices } = props;
  return (
    <Grid container spacing={2}>
      {offices?.length > 0
        ? offices.map((data) => (
            <>
              <Grid item xs>
                <AddressCard {...data} />
              </Grid>
            </>
          ))
        : null}
    </Grid>
  );
}
