import React from "react";
import { useTranslation } from "react-i18next";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Container, Grid } from "@material-ui/core";
import { useStyles } from "./styles";
import Button from "components/Button";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { useHistory } from "react-router";

const NotFoundView = (props) => {
  const { t } = useTranslation();
  const { code, title = "", message = "", buttonBack } = props;

  const history = useHistory();
  const mediaQuerySm = useMediaQuery("(max-width:600px");
  const classes = useStyles({ mediaQuerySm });

  return (
    <div data-testid="not_found_container" className={classes.root}>
      <Container maxWidth="md">
        <Grid
          container
          spacing={2}
          direction="column"
          className={classes.container}
        >
          {code && (
            <Grid item>
              <p className={classes.code} data-testid="txt_error_code">
                {code}
              </p>
            </Grid>
          )}
          <Grid item>
            <p className={classes.title} data-testid="txt_error_title">
              {t(title)}
            </p>
          </Grid>
          <Grid item>
            <p className={classes.message} data-testid="txt_error_message">
              {t(message)}
            </p>
          </Grid>

          {buttonBack && (
            <Grid item className={classes.buttonBack}>
              <Button
                iconLeft={<ArrowBackIosIcon />}
                type="cuaternary"
                text="pages.notFound.buttonBack"
                onClick={() => history.goBack()}
                id="btn_error_back"
              />
            </Grid>
          )}
        </Grid>
      </Container>
    </div>
  );
};

export default NotFoundView;
