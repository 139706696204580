import React, { useEffect } from 'react';
import { useStyles } from './styles';
import Button from 'components/Button';
import AccordionRosary from 'components/AccordionRosary';
import VideoPlayer from 'components/VideoPlayer';
import Icon from 'components/Icon';
import ArrowBackIosIcon from 'assets/icons/Carrusel_Arrow_left.svg';

const RosaryView = (props) => {
  const {
    isSm,
    isLg,
    handlerBackToSchoolPrayer,
    handlevideo,
    video,
    handleCloseVideo,
    rosaries,
    groupMysteries,
    handleExpand,
    toggleAudio,
    handleFavorite,
    handleDislike,
    expandedMystery
  } = props;
  const classes = useStyles({
    mediaQuerySm: isSm,
  });

  useEffect(() => {
    const expandedIndex = Number(expandedMystery);
    if(expandedIndex >= 0 && rosaries?.id) {
      const mystery = groupMysteries.find((m) => m.dayNumber === expandedIndex);
      if(mystery) {
        handleExpand(mystery);
      }
    }
  }, [rosaries.id]);

  return (
    <div data-testid={`rosary_view`} className={classes.mainContentRosary}>
      <section className={classes.contentRosary}>
        <div className={classes.wrapperColorPink}>
          <div className={classes.wrappertitleRosary}>
            <div className={classes.buttonBack}>
              <Button
                iconLeft={<Icon icon={ArrowBackIosIcon} />}
                type="transparent"
                text="pages.campaign.Back to School"
                onClick={handlerBackToSchoolPrayer}
                id="btn_back_to_school_prayer"
                data-testid="btn_back_to_school_prayer"
              />
            </div>
            <h1 data-testid={`rosary_view_title`}>{rosaries?.title}</h1>
            <p
              data-testid={`rosary_view_description`}
              dangerouslySetInnerHTML={{ __html: rosaries?.description }}
            />
          </div>
        </div>
        <div className={classes.wrapperAccordion}>
          {groupMysteries.map((rosary, index) => {
            return (
              <AccordionRosary
                css={classes.accordionRosary}
                key={index}
                title={rosary?.title}
                description={rosary?.groupMysteries?.description}
                finalPrayer={rosary?.groupMysteries?.mysteryPrayer}
                index={index}
                onChange={() => handleExpand(rosary)}
                isExpanded={rosary?.isExpande}
                isSm={isSm}
                isLg={isLg}
                isVideo={rosary?.groupMysteries?.video_url}
                isImage={rosary?.groupMysteries?.image}
                handlevideo={() =>
                  handlevideo(rosary?.groupMysteries?.video_url)
                }
                audios={rosary?.groupMysteries?.mysteries}
                toggleAudio={(current, type, audio) => {
                  toggleAudio(current, type, audio, rosary);
                }}
                day={rosary?.day}
                handleFavorite={() =>
                  handleFavorite(
                    rosary?.groupMysteries?.mysteryPrayer.id,
                    'RPY',
                    index
                  )
                }
                handleDislike={() => {
                  handleDislike(
                    rosary?.groupMysteries?.mysteryPrayer.id,
                    'RPY',
                    index
                  );
                }}
                owner={rosary?.groupMysteries?.mysteryPrayer.id}
                ownerType={'RPY'}
                mysteryIndex={expandedMystery}
                rosaryIndex={rosaries?.id}
              />
            );
          })}
          <VideoPlayer
            isSmall={isSm}
            open={video.open}
            src={video.src}
            handleClose={handleCloseVideo}
          />
        </div>
      </section>
    </div>
  );
};

export default RosaryView;
