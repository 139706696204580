import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles({
  flexContainer: (props) => {
    let style = {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      padding: 0,
      width: "100%",
      overflowX: "auto",
      height: "100%",
      "& > li": {
        padding: 0,
        justifyContent: "center",
        alignItems:'center',
        width:'auto'
      },
    };
   
    if (props.mediaQuerySm) {
      style={
        ...style,
        justifyContent :"flex-start"
        
      }
    }

    return style;
  },
});
