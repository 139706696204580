import React from "react";

export function IconStyled(props) {
  const { icon, alt, onClick, css, id } = props;

  return (
    <img
      className={css}
      data-testid={id ? id : "ico"}
      id={id ? id : "ico"}
      onClick={onClick}
      src={icon}
      alt={alt ? alt : " "}
      style={{ cursor: onClick ? "pointer" : "default" }}
    />
  );
}
