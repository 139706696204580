import makeStyles from "@material-ui/core/styles/makeStyles";
import BackgroundPope from "assets/img/background_pope_profile.svg";

export const useStyles = makeStyles({
  wrapperSection: (props) => {
    let style = {
      padding: "214px 130px 184px 108px",
      height: "100%",
      backgroundImage: `url(${BackgroundPope})`,
      backgroundSize: "cover",
    };
    if (props.mediaQuerySm) {
      style.padding = "122px 20px 168px 20px";
    }
    return style;
  },
  title: (props) => {
    let style = {
      margin: "48px 0 0 0",
      fontFamily: "Libre Baskerville",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "44px",
      lineHeight: "56px",
      color: "#444C67",
    };
    if (props.mediaQuerySm) {
      style.textAlign = "center";
      style.fontSize = "28px";
    }
    return style;
  },
  subtitle: (props) => {
    let style = {
      fontFamily: "Nunito Sans",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "14px",
      lineHeight: "24px",
      letterSpacing: "0.1em",
      color: "#444C67",
      textTransform: "uppercase",
      margin: "1em 0 0 0",
    };
    if (props.mediaQuerySm) {
      style.margin = "1em 0 2.5em 0";
      style.textAlign = "center";
    }
    return style;
  },
  avatar: (props) => {
    let style = {
      height: "152px",
      width: "152px",
    };
    if (props.mediaQuerySm) {
      style.height = "96px";
      style.width = "96px";
    }
    return style;
  },
  avatarWrapper: (props) => {
    let style = {};
    if (props.mediaQuerySm) {
      style.display = "flex";
      style.justifyContent = "center";
    }
    return style;
  },
  contentButtonMore: (props) => {
    let style = {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      margin: "54px 0px 0px 0px",
    };
    if (props.mediaQuerySm) {
      style.margin = "64px 0px 0px 0px";
    }
    return style;
  },
  contentSinglePlayer: {
    margin: "0 0 24px 0",
    "& > div": {
      "& > div": {
        padding: "24px",
        "& > div": {
          padding: "0 !important",
        },
      },
    },
  },
  list: (props) => {
    let style = {
      height: "996px",
      overflowY: "auto",
      scrollbarWidth: "none",
      "&::-webkit-scrollbar": {
        "-webkit-appearance": "none",
      },
    };
    if (props.mediaQuerySm) style = {};
    return style;
  },
});
