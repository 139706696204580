export function authReducer(state = { user: "", token: "", exp: "" }, action) {
  switch (action.type) {
    case "@auth/user":
      return { ...state, user: action.payload };
    case "@auth/token":
      return { ...state, token: action.payload };
    case "@auth/exp":
      return { ...state, exp: action.payload };
    default:
      return state;
  }
}
