import "moment/locale/es";
import "moment/locale/en-ca";
import React, { useState } from "react";
import moment from "moment";
import { useStyles } from "./styles";
import { useTranslation } from "react-i18next";
import { Grid } from "@material-ui/core";
import { Backdrop, Modal } from "@material-ui/core";
import segretaroaGeneraleSinodo from "assets/img/segretaroa-generale-sinodo.png";
import uisg_logo from "assets/img/uisg_logo.svg";
import Button from "components/Button";
import Icon from "components/Icon";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import SynodListCardStyled from "components/Cards/SynodListCard/SynodListCardStyled";
import CustomButton from "components/CustomButton";

const CampaignView = (props) => {
  const { t } = useTranslation();
  const {
    mediaQueryLg,
    mediaQueryMd,
    mediaQueryXs,
    iconMoment,
    codeCampaign,
    campaign = {},
    startedCampaign,
    handlerBackToSchoolPrayer,
    handlerGoToCommunity,
    handleClickOpenUrl
  } = props;
  const [OpenModal, setopenModal] = useState(false);
  const classes = useStyles({ mediaQueryLg, mediaQueryMd, mediaQueryXs });

  campaign?.multimedia?.length > 0 &&
    campaign?.multimedia.find(
      (image) => image?.type?.code !== "IMG_ICON_CAMPAIGN"
    )?.url;

  function WindowOpenNewLink(param) {
    window.open(param);
    setopenModal(false);
  }

  function clickOut(param) {
    if (param === true) {
      setopenModal(false);
    }
  }
  const campaignPrayerItems = campaign?.campaignPrayer || [];
  return (
    <Grid
      container
      className={classes.gral}
      justify="center"
      data-testid="campaign_view"
      onClick={() => clickOut(OpenModal)}
    >
      <Grid item xs={12} sm={11} md={10} xl={8}>
        <Grid container spacing={1} direction="column">
          <Grid item className={classes.buttonBack}>
            <Button
              iconLeft={<ArrowBackIosIcon />}
              type="cuaternary"
              text="pages.campaign.Back to School"
              onClick={handlerBackToSchoolPrayer}
              id="btn_back_to_school_prayer"
            />
          </Grid>

          <Grid item style={{ marginBottom: "1rem" }}>
            {campaign?.title && (
              <h1 data-testid="txt_campaign_title">{`${
                codeCampaign === "TIP"
                  ? t("pages.schoolPrayer.Tips for your prayer") + " -"
                  : ""
              }  ${campaign.title}`}</h1>
            )}
            <p
              data-testid="txt_campaign_description"
              dangerouslySetInnerHTML={{ __html: campaign.description }}
            />

            {campaign.link && (
              <CustomButton
                iconRight={<ArrowForwardIcon />}
                type="secondary"
                text="components.community.Share your prayer"
                onClick={handlerGoToCommunity}
                id="btn_share_community"
              />
            )}
          </Grid>

          {!startedCampaign && (
            <div
              className={classes.notStarted}
              data-testid="txt_campaign_not_start"
            >
              <span data-testid="txt_no_started">
                {t("pages.campaign.no started", {
                  date: moment(campaign.date_from).format("LL")
                })}
              </span>
              <Button
                text={t("components.button.Download the App")}
                type="primary"
                id="btn_download_app"
                onClick={() => setopenModal(true)}
              />
            </div>
          )}

          {OpenModal && (
            <Modal
              open={OpenModal}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500
              }}
            >
              <div className={classes.modalDownload}>
                <div className={classes.divModalContainer}>
                  <div className={classes.modalTitle}>
                    {" "}
                    {t("components.footer.Download the Click To Pray App")}
                  </div>
                  <div className={classes.divModal}>
                    <Button
                      data-testid="btn_appStore"
                      type="primary"
                      text="components.footer.AppStore"
                      css={classes.buttonAppstore}
                      onClick={() =>
                        WindowOpenNewLink(
                          "https://apps.apple.com/ar/app/click-to-pray/id934935942"
                        )
                      }
                    />
                    <Button
                      data-testid="btn_googlePlay"
                      type="primary"
                      text="components.footer.Google Play"
                      onClick={() =>
                        WindowOpenNewLink(
                          "https://play.google.com/store/apps/details?id=com.lamachi.clicktopray"
                        )
                      }
                    />
                  </div>
                </div>
              </div>
            </Modal>
          )}

          {startedCampaign && (
            <div>
              <div
                style={{
                  width: "100%",
                  marginTop: "1.5em"
                }}
              >
                <SynodListCardStyled
                  displayedItems={campaignPrayerItems}
                  columnsLg={5}
                  columnsMd={5}
                  columnsSm={1}
                  customIcon={iconMoment}
                  itemsLg={10}
                  itemsMd={10}
                  itemsSm={10}
                />
              </div>
            </div>
          )}
        </Grid>
        <Grid
          style={{ width: "100%" }}
          item
          className={classes.sectionLogosPartners}
        >
          <Icon
            data-testid="icon_segretaroaGeneraleSinodo"
            id="icon_segretaroaGeneraleSinodo"
            icon={segretaroaGeneraleSinodo}
            onClick={() => handleClickOpenUrl(t("components.synod.synod.va"))}
          />
          <Icon
            data-testid="icon_uisg_logo"
            id="icon_uisg_logo"
            icon={uisg_logo}
            onClick={() => handleClickOpenUrl(t("components.synod.uisg.org"))}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CampaignView;
