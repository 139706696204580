import "moment/locale/es";
import "moment/locale/en-ca";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { useStyles } from "./styles";
import { useTranslation } from "react-i18next";
import { Grid, Hidden } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { Backdrop, Modal } from "@material-ui/core";
import CampaignItemCard from "components/Cards/CampaignPrayerCard";
import Carrusel from "components/Carrusel";
import Button from "components/Button";
import Icon from "components/Icon";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { handlerBackToSection, synodCampaings } from "../../helpers";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import CustomButton from "components/CustomButton";
import segretaroaGeneraleSinodo from "assets/img/segretaroa-generale-sinodo.png";
import uisg_logo from "assets/img/uisg_logo.svg";
const CampaignView = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const {
    indexCamp,
    openWindowLink,
    mediaQueryLg,
    mediaQueryMd,
    mediaQueryXs,
    iconMoment,
    codeMoment,
    codeCampaign,
    campaign = {},
    startedCampaign,
    handlerBackToSchoolPrayer,
    handlerGoToCommunity,
    handleClickOpenUrl
  } = props;
  const [OpenModal, setopenModal] = useState(false);
  const [carruselIndex, setCarruselIndex] = useState(indexCamp);
  const classes = useStyles({ mediaQueryLg, mediaQueryMd, mediaQueryXs });
  const [betweenArrows, setTextBetweenArrows] = React.useState("");
  const imageCampaign =
    campaign?.multimedia?.length > 0 &&
    campaign?.multimedia.find(
      (image) => image?.type?.code !== "IMG_ICON_CAMPAIGN"
    )?.url;
  useEffect(() => {
    const prayer0 = campaign.campaignPrayer?.[0];
    setCarruselIndex(indexCamp);
    setTextBetweenArrows(chooseTxtToArrows(prayer0));
  }, [t, campaign, codeCampaign]);

  function chooseTxtToArrows(prayer = {}, index = 0) {
    let txt = "";
    if (campaign.campaignPrayer?.length) {
      switch (codeCampaign) {
        case "SCH":
          txt = `${moment(prayer.date).format("LL")}`;
          break;
        case "PRO":
          txt = campaign.title;
          break;
        case "UNS":
          txt = `${t("moment.fields.day")} ${index + 1}`;
          break;
        case "TIP":
          txt = `${t("pages.schoolPrayer.Advice")} ${index + 1}/${
            campaign?.campaignPrayer?.filter(
              (cp) =>
                cp.date === null ||
                moment(cp.date).format("yyyy-MM-DD") <=
                  moment().format("yyyy-MM-DD")
            )?.length
          }`;
          break;
        case "ROT":
          txt = "Click To Pray eRosary";
          break;
      }
    }

    return txt;
  }
  function WindowOpenNewLink(param) {
    window.open(param);
    setopenModal(false);
  }

  function clickOut(param) {
    if (param === true) {
      setopenModal(false);
    }
  }

  function changeCard(index = 0) {
    const prayer = campaign.campaignPrayer[index];
    const text = chooseTxtToArrows(prayer, index);
    setCarruselIndex(index);
    setTextBetweenArrows(text);
  }

  return (
    <Grid
      container
      className={classes.gral}
      justify="center"
      data-testid="campaign_view"
      onClick={() => clickOut(OpenModal)}
    >
      <Grid item xs={12} sm={11} md={10} xl={8}>
        <Grid container spacing={1} direction="column">
          <Grid item className={classes.buttonBack}>
            <Button
              iconLeft={<ArrowBackIosIcon />}
              type="cuaternary"
              text="pages.campaign.Back to School"
              onClick={handlerBackToSchoolPrayer}
              id="btn_back_to_school_prayer"
            />
          </Grid>

          {imageCampaign && (
            <div
              data-testid="img_campaign"
              className={classes.imgCampaign}
              style={{
                backgroundImage: `url("${imageCampaign}")`
              }}
            ></div>
          )}

          <Grid item style={{ marginBottom: "1rem" }}>
            {campaign?.title && (
              <h1 data-testid="txt_campaign_title">{`${
                codeCampaign === "TIP"
                  ? t("pages.schoolPrayer.Tips for your prayer") + " -"
                  : ""
              }  ${campaign.title}`}</h1>
            )}
            <p
              data-testid="txt_campaign_description"
              dangerouslySetInnerHTML={{ __html: campaign.description }}
            />

            {campaign.link && (
              <CustomButton
                iconRight={
                  synodCampaings.includes(Number(campaign.id)) ? (
                    <ArrowForwardIcon />
                  ) : null
                }
                css={classes.button}
                text={
                  synodCampaings.includes(Number(campaign.id))
                    ? "components.general.See more prayers"
                    : "pages.campaign.Read more"
                }
                type="primary"
                id="btn_campaing_link"
                onClick={
                  synodCampaings.includes(Number(campaign.id))
                    ? () => handlerBackToSection(history, `/synod`)
                    : () => openWindowLink(campaign.link)
                }
              />
            )}
            {startedCampaign && (
              <Hidden smUp>
                <p data-testid="txt_campaign_moment">
                  {iconMoment && codeCampaign !== "ROT" && (
                    <Icon icon={iconMoment} css={classes.suggestIcon} />
                  )}
                  {codeCampaign !== "ROT" &&
                    t("pages.campaign.suggest for", {
                      time: _.capitalize(
                        t(`pages.campaign.dayMoment.${codeMoment}`)
                      )
                    })}
                </p>
              </Hidden>
            )}
          </Grid>

          {!startedCampaign && (
            <div
              className={classes.notStarted}
              data-testid="txt_campaign_not_start"
            >
              <span data-testid="txt_no_started">
                {t("pages.campaign.no started", {
                  date: moment(campaign.date_from).format("LL")
                })}
              </span>
              <Button
                text={t("components.button.Download the App")}
                type="primary"
                id="btn_download_app"
                onClick={() => setopenModal(true)}
              />
            </div>
          )}

          {OpenModal && (
            <Modal
              open={OpenModal}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500
              }}
            >
              <div className={classes.modalDownload}>
                <div className={classes.divModalContainer}>
                  <div className={classes.modalTitle}>
                    {" "}
                    {t("components.footer.Download the Click To Pray App")}
                  </div>
                  <div className={classes.divModal}>
                    <Button
                      data-testid="btn_appStore"
                      type="primary"
                      text="components.footer.AppStore"
                      css={classes.buttonAppstore}
                      onClick={() =>
                        WindowOpenNewLink(
                          "https://apps.apple.com/ar/app/click-to-pray/id934935942"
                        )
                      }
                    />
                    <Button
                      data-testid="btn_googlePlay"
                      type="primary"
                      text="components.footer.Google Play"
                      onClick={() =>
                        WindowOpenNewLink(
                          "https://play.google.com/store/apps/details?id=com.lamachi.clicktopray"
                        )
                      }
                    />
                  </div>
                </div>
              </div>
            </Modal>
          )}

          {startedCampaign && (
            <div>
              <Hidden smUp>
                <div className={classes.captionCard}>{betweenArrows}</div>
              </Hidden>

              <Grid item xs={12}>
                <Carrusel
                  data-testid="carrusel_content_campaign"
                  indicators={false}
                  index={+carruselIndex}
                  autoPlay={false}
                  cycleNavigation={false}
                  className={classes.carrusel}
                  navButtonsWrapperProps={{
                    className: classes.carruselBtnWrapper
                  }}
                  navButtonsProps={{
                    className: classes.carruselBtn,
                    style: { backgroundColor: "transparent", opacity: 1 }
                  }}
                  PrevIcon={<ArrowBackIosIcon data-testid="btn_prev_card" />}
                  NextIcon={<ArrowForwardIosIcon data-testid="btn_next_card" />}
                  prev={changeCard}
                  next={changeCard}
                >
                  {campaign.campaignPrayer
                    ?.filter(
                      (cp) =>
                        cp.date === null ||
                        moment(cp.date).format("yyyy-MM-DD") <=
                          moment().format("yyyy-MM-DD")
                    )
                    ?.map((value, key) => (
                      <CampaignItemCard
                        isSm={mediaQueryXs}
                        isMd={mediaQueryMd}
                        key={key}
                        index={key + 1}
                        captionDay={betweenArrows}
                        prayer={value}
                        iconMoment={iconMoment}
                        codeMoment={codeMoment}
                        codeCampaign={codeCampaign}
                        openWindowLink={openWindowLink}
                        owner={value?.id}
                        ownerType={"CAP"}
                        indexCampaign={carruselIndex}
                        campaignItem={campaign}
                      />
                    ))}
                </Carrusel>
              </Grid>
            </div>
          )}
          {startedCampaign && synodCampaings.includes(Number(campaign.id)) && (
            <>
              <Grid item className={classes.sectionButtonShareCommunity}>
                <CustomButton
                  iconRight={<ArrowForwardIcon />}
                  type="secondary"
                  text="components.community.Share your prayer"
                  onClick={handlerGoToCommunity}
                  id="btn_share_community"
                />
              </Grid>
              <Grid
                item
                className={classes.sectionLogosPartners}
                style={{ width: "100%" }}
              >
                <Icon
                  data-testid="icon_segretaroaGeneraleSinodo"
                  id="icon_segretaroaGeneraleSinodo"
                  icon={segretaroaGeneraleSinodo}
                  onClick={() =>
                    handleClickOpenUrl(t("components.synod.synod.va"))
                  }
                />
                <Icon
                  data-testid="icon_uisg_logo"
                  id="icon_uisg_logo"
                  icon={uisg_logo}
                  onClick={() =>
                    handleClickOpenUrl(t("components.synod.uisg.org"))
                  }
                />
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CampaignView;
