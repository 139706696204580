import React from "react";
import { useStyles } from "./styles";
import { useTranslation } from "react-i18next";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Grid from "@material-ui/core/Grid";
import Button from "components/Button";
import Input from "components/Input";
import { Fade, Backdrop, Modal } from "@material-ui/core";
import Icon from "components/Icon";
import IconClose from "assets/icons/Close.svg";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import GoogleLogin from "react-google-login";
import { FACEBOOK_ID, GOOGLE_ID } from "conf/env";

export function AuthFormLogin(props) {
  const { t } = useTranslation();
  const loginScheme = Yup.object().shape({
    email: Yup.string()
      .email(t(`components.auth.You must enter a valid email`))
      .required(t(`components.auth.Email Required`)),
    password: Yup.string()
      .min(6, t(`components.auth.Must be at least 6 characters`))
      .required(t(`components.auth.Password Required`)),
  });
  const initialValueLogin = {
    email: "",
    password: "",
  };
  const {
    isOpen,
    isMd = false,
    isLg = false,
    handleClose,
    handleLoginFacebook,
    handleLoginGoogle,
    handleSubmitLogin,
    isRememberme,
    onChangeRememberme,
    handleRecoverPassword,
    navigationRegister,
    messageError,
  } = props;
  const classes = useStyles({
    mediaQueryMd: isMd,
    mediaQueryLg: isLg,
  });

  const loginContent = () => {
    return (
      <div className={`${classes.contentLogin}`}>
        <div className={classes.AuthFormLogin}>
          <div className={classes.closeIconContent}>
            <Icon
              onClick={() => handleClose()}
              icon={IconClose}
              alt="close"
              id="ico_authForm_close_modal"
            />
          </div>
          <div className={classes.wrapperLogin}>
            <div className={classes.titleContent}>
              <h1 data-testid={`txt_title_authForm`}>
                {t(`components.auth.Welcome back!`)}
              </h1>
              <p data-testid={`txt_description_authForm`}>
                {t(
                  `components.auth.Login for the exclusive contents carefully prepared for those who are seeking to grow in communion.`
                )}
              </p>
            </div>
            <div className={classes.contentButtonsAuth}>
              <FacebookLogin
                appId={FACEBOOK_ID}
                fields="name,middle_name,first_name,last_name,email,picture.type(large)"
                scope="public_profile"
                callback={handleLoginFacebook}
                render={({ onClick }) => (
                  <Button
                    id="btn_authForm_facebook"
                    text={t(`components.auth.Login with Facebook`)}
                    onClick={onClick}
                    type="facebook"
                  />
                )}
              />
              <GoogleLogin
                clientId={GOOGLE_ID}
                render={({ onClick }) => (
                  <Button
                    id="btn_authForm_gmail"
                    text={t(`components.auth.Login with Google`)}
                    onClick={onClick}
                    type="gmail"
                  />
                )}
                buttonText="Login"
                onSuccess={handleLoginGoogle}
                onFailure={(e)=> console.log(e)}
                cookiePolicy={"single_host_origin"}
              />
            </div>
            <div className={classes.contentText}>
              <p data-testid="txt_authForm_message">
                {t(`components.signUp.Or, sign up with your email`)}
              </p>
            </div>
            {messageError && (
              <p
                data-testid="txt_authForm_error"
                className={classes.messageErrorContent}
              >
                {messageError}
              </p>
            )}
            <Formik
              validationSchema={loginScheme}
              initialValues={initialValueLogin}
            >
              {({
                dirty,
                isValid,
                errors,
                values,
                handleChange,
                handleBlur,
                touched,
              }) => {
                return (
                  <Form>
                    <Grid container>
                      <Grid
                        className={classes.rowInput}
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                      >
                        <Input
                          label={t(`components.signUp.Email`)}
                          name="email"
                          id="txt_email"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          message={errors.email}
                          isError={errors.email && touched.email}
                          isSucess={!errors.email && touched.email}
                          isRequired
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Input
                          name="password"
                          label={t(`components.signUp.Password`)}
                          type="password"
                          id="txt_password"
                          value={values.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          message={errors.password}
                          isError={errors.password && touched.password}
                          isSucess={!errors.password && touched.password}
                          isRequired
                        />
                      </Grid>
                      <Grid
                        className={classes.contentRecoverPassword}
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              classes={{
                                root: classes.checkbox,
                              }}
                              checked={isRememberme}
                              onChange={onChangeRememberme}
                              name="rememberme"
                              color="default"
                              data-testid="checkbox_authForm_rememberme"
                            />
                          }
                          classes={{
                            label: classes.labelRememberme,
                          }}
                          label={t(`components.auth.Remember me`)}
                          aria-label="checkbox_authForm_rememberme"
                        />
                        <Button
                          onClick={() => handleRecoverPassword(values)}
                          type="cuaternary"
                          id="btn_authForm_recoverPassword"
                        >
                          <div>
                            <span data-testid="txt_authForm_recoverPassword">
                              {`${t(`components.auth.Forgot your password?`)}`}
                            </span>
                          </div>
                        </Button>
                      </Grid>
                      <Grid
                        className={classes.rowButtons}
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                      >
                        <Button
                          data-testid="btn_login"
                          id="btn_login"
                          onClick={() => handleSubmitLogin(values)}
                          disabled={!(isValid && dirty)}
                          type="primary"
                          text={t(`components.signUp.Login`)}
                        />
                        <Button
                          onClick={navigationRegister}
                          id="btn_register"
                          data-testid="btn_register"
                          type="cuaternary"
                        >
                          <div>
                            <span data-testid="txt_account_authForm_description">
                              {`${t(
                                `components.auth.I don’t have an account.`
                              )}`}
                              <span data-testid="txt_account_authForm_register">{`${t(
                                `components.auth.Register`
                              )}`}</span>
                            </span>
                          </div>
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <Modal
        open={isOpen}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        className={classes.loginModal}
        data-testid="login-content_modal"
        onClose={handleClose}
      >
        <Fade in={isOpen}>{loginContent()}</Fade>
      </Modal>
    </>
  );
}
