import React from "react";
import { useStyles } from "./styles";
import { useTranslation } from "react-i18next";
import { isOdd } from "./BlogAllFunctions";
import BlogCard from "components/Cards/BlogCard";
import Grid from "@material-ui/core/Grid";
import Button from "components/Button";

const BlogAllView = (props) => {
  const { blogs, isMd, handleMoreBlogs, pages, handleReadArticle } = props;
  const { t } = useTranslation();
  const classes = useStyles({ mediaQueryMd: isMd });

  return (
    <div data-testid={`blog_all_view`} className={classes.BlogContent}>
      <section className={classes.blogTitleContainer}>
        <div id="txt_blog_title" className={classes.wrappertTitle}>
          <h1 data-testid={`txt_blog_title`}>{t(`pages.blog.blogAll.Blog`)}</h1>
        </div>
      </section>
      <section className={classes.wrapperCardBlog}>
        <Grid container>
          {blogs?.length > 0 &&
            blogs?.map((blogArray, index) => {
              return blogArray.map(
                ({ title, blockquote, multimedia, id, publish_date }) => {
                  if (isOdd(index)) {
                    return (
                      <Grid
                        className={classes.itemCardBlog}
                        item
                        key={id}
                        xs={12}
                        sm={12}
                        md={12}
                        lg={6}
                        xl={6}
                      >
                        <BlogCard
                          id={id}
                          handleReadArticle={handleReadArticle}
                          description={blockquote}
                          title={title}
                          img={
                            (multimedia?.find(m=> m.type.code === 'IMG_POS_CROP'))?.url || multimedia[0]?.url
                          }
                          isReverse={false}
                          isMd={isMd}
                          date={publish_date}
                        />
                      </Grid>
                    );
                  } else {
                    return (
                      <Grid
                        className={classes.itemCardBlog}
                        item
                        key={id}
                        xs={12}
                        sm={12}
                        md={12}
                        lg={6}
                        xl={6}
                      >
                        <BlogCard
                          id={id}
                          handleReadArticle={handleReadArticle}
                          description={blockquote}
                          title={title}
                          img={
                            (multimedia?.find(m=> m.type.code === 'IMG_POS_CROP'))?.url || multimedia[0]?.url
                          }
                          isReverse={true}
                          isMd={isMd}
                          date={publish_date}
                        />
                      </Grid>
                    );
                  }
                }
              );
            })}
        </Grid>
        {pages?.total > 6 && pages?.total !== pages?.to && (
          <div className={classes.contentButtonMoreBlog}>
            <Button
              onClick={handleMoreBlogs}
              type="cuaternary"
              text="pages.blog.blogAll.Load More Articles"
              data-testid="btn_more_blogs"
            />
          </div>
        )}
      </section>
    </div>
  );
};

export default BlogAllView;
