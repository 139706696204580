/* eslint-disable */
import {
  handleMultimedia,
  parseMysterie,
  setAmen,
  unsetAmen,
} from '../../helpers';
import { getRosary, getMysterie } from 'services/services';

export async function fetchGetRosary(id, setRosarie, history) {
  try {
    const response = await getRosary(id);
    setRosarie(response);
  } catch (e) {
    history.push('/school-prayer');
  }
}

export async function fetchGetMysterie(day, id) {
  const response = await getMysterie(day, id);
  return response;
}

export async function handleExpand(
  rosary,
  groupMysteries,
  setGroupMysteries,
  rosaryId
) {
  let responseMysteries = null;

  if (!rosary.isExpande) {
    responseMysteries = parseMysterie(
      await fetchGetMysterie(rosary.dayNumber, rosaryId),
      rosary.dayNumber
    );
  }
  let updateGroupMysteries = groupMysteries.map((groupMysterie) => {
    if (groupMysterie === rosary) {
      return {
        ...groupMysterie,
        isExpande: !groupMysterie.isExpande,
        groupMysteries: responseMysteries ? responseMysteries : null,
      };
    } else return { ...groupMysterie, isExpande: false };
  });

  setGroupMysteries(updateGroupMysteries);
}

export function handleAudio(
  current,
  type,
  audio,
  rosary,
  groupMysteries,
  setGroupMysteries
) {
  let updateGroupMysteries = groupMysteries.map((groupMysterie) => {
    if (groupMysterie === rosary) {
      return {
        ...groupMysterie,
        groupMysteries: {
          ...groupMysterie.groupMysteries,
          mysteries: groupMysterie.groupMysteries.mysteries.map((mysterie) => {
            if (mysterie === audio) {
              return {
                ...mysterie,
                isOpen: type !== 'stop',
                audio: {
                  ...mysterie.audio,
                  isPlay: handleMultimedia(
                    current,
                    type,
                    mysterie.audio.isPlay
                  ),
                },
              };
            } else
              return {
                ...mysterie,
                audio: { ...mysterie.audio, isPlay: false, isOpen: false },
              };
          }),
        },
      };
    } else return groupMysterie;
  });

  setGroupMysteries(updateGroupMysteries);
}

const amenCheck = (prayerArray) => {
  let R=false;
  prayerArray.forEach((prayer) => {
    let arrayAmen = JSON.parse(localStorage.getItem('amen'));
    R = arrayAmen.find(
      (e) =>
        e.owner === prayer?.groupMysteries?.mysteryPrayer.id &&
        e.ownerType === 'RPY'
    );
  });
  return R;
};

export async function setFavorite(
  owner,
  ownerType,
  index,
  groupMysteries,
  setGroupMysteries
) {
  //console.log('dale pancho: ', groupMysteries);
  await setAmen(owner, ownerType);
  let grm = [...groupMysteries];
  grm[index].isFavorite = amenCheck(groupMysteries);
  setGroupMysteries(grm);
}

export async function handleDislike(
  owner,
  ownerType,
  index,
  groupMysteries,
  setGroupMysteries
) {
  await unsetAmen(owner, ownerType);
  let grm = [...groupMysteries];
  grm[index].isFavorite = amenCheck(groupMysteries);
  setGroupMysteries(grm);
}
