import React from "react";
import { useStyles } from "./styles";
import { Modal } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import IconClose from "assets/icons/Close.svg";
import Icon from "components/Icon";
import Button from "components/Button";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import GoogleLogin from "react-google-login";
import { FACEBOOK_ID, GOOGLE_ID } from "conf/env";

export function SignUpOptionsStyled(props) {
  const {
    open,
    isSmall,
    handleClose,
    handleSignUpFacebook,
    handleAuthGoogle,
    handleSignUp,
    navigationLogin,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles({
    mediaQuerySm: isSmall,
  });

  return (
    <>
      <Modal
        open={open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        className={classes.signUpOptionsModal}
        data-testid="signUpOptions_content"
        onClose={handleClose}
      >
        <Fade in={open}>
          <div className={classes.signUpOptionsContent}>
            <div className={classes.signUpOptions}>
              <div className={classes.closeIconContent}>
                <Icon
                  onClick={() => handleClose()}
                  icon={IconClose}
                  alt="close"
                  id="ico_signUpOptions_close_modal"
                />
              </div>
              <div className={classes.wrapperSignUpOptions}>
                <div className={classes.titleContent}>
                  <h1 data-testid="txt_title_signUpOptions">
                    {t(`components.signUp.Join our Community`)}
                  </h1>
                  <p data-testid="txt_description_signUpOptions">
                    {t(
                      `components.signUp.Sign up and access exclusive contents carefully prepared for those who are seeking to grow in communion.`
                    )}
                  </p>
                </div>
                <div className={classes.contentButtonsSignUp}>
                  <FacebookLogin
                    appId={FACEBOOK_ID}
                    fields="name,email,picture"
                    callback={handleSignUpFacebook}
                    render={({ onClick }) => (
                      <Button
                        data-testid="btn_signUpOptions_facebook"
                        id="btn_signUpOptions_facebook"
                        text={t(`components.signUp.Sign in with Facebook`)}
                        onClick={onClick}
                        type="facebook"
                      />
                    )}
                  />
                  <GoogleLogin
                    clientId={GOOGLE_ID}
                    render={({ onClick }) => (
                      <Button
                        data-testid="btn_signUpOptions_gmail"
                        id="btn_signUpOptions_gmail"
                        text="components.signUp.Sign in with Google"
                        onClick={onClick}
                        type="gmail"
                      />
                    )}
                    buttonText="Login"
                    onSuccess={handleAuthGoogle}
                    cookiePolicy={"single_host_origin"}
                  />
                </div>
                <div className={classes.contentRegister}>
                  <p>{t(`components.signUp.Or, sign up with your email`)}</p>
                  <div>
                    <Button
                      data-testid="btn_signUpOptions"
                      id="btn_signUpOptions"
                      text="components.signUp.Register here"
                      type="primary"
                      onClick={() => handleSignUp()}
                    />
                  </div>

                  <Button
                    data-testid="btn_signUpOptions_login"
                    id="btn_signUpOptions_login"
                    type="cuaternary"
                    onClick={() => navigationLogin()}
                  >
                    <div>
                      <span data-testid="txt_account_signUpOptions_description">
                        {`${t(`components.signUp.Already have an account?`)}`}
                        <span data-testid="txt_account_signUpOptions_login">{`${t(
                          `components.signUp.Login`
                        )}`}</span>
                      </span>
                    </div>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
}
