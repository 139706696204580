import React, { useState } from "react";
import { useStyles } from "../styles";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Button from "components/Button";
import Icon from "components/Icon";
import SinglePrayer from "components/SinglePrayer";
import ArrowDarkIcon from "assets/icons/chevron-down-dark.svg";
import InfiniteScroll from "react-infinite-scroll-component";
import Select from "components/Select";
import { getUser, findReported } from "services/authService";
import {deleteCommunityComment, deleteCommunityPost} from "services/services";

export function PrayerListCommunity(props) {
  const { t } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const user = getUser();
  const {
    isMd,
    isTabCommunity,
    posts,
    handleMorePost,
    languages,
    handlePostComments,
    handleReportPost,
    handleMoreComments,
    handleEditComments,
    handleReportComment,
    handleFilter,
    handleFavorite,
    handleDislike,
    onDeletePrayer,
  } = props;
  const history = useHistory();
  const classes = useStyles({ mediaQueryMd: isMd, isTabCommunity });

  const buttonLoadMorePrayers = () => {
    return (
      <div className={classes.buttonLoadMorePrayers}>
        <Button
          onClick={() => history.push("/community")}
          type="secondary"
          text={t(`components.community.Load More Prayers`)}
        />
      </div>
    );
  };
  const postsRender = (item, index) => {
    return (
      <SinglePrayer
        key={index}
        description={item?.description}
        title={item?.title}
        postImage={item?.multimedia[0]}
        isPlay={false}
        isSmall={isMd}
        isPrayer={true}
        created_at={item?.created_at}
        isPopePrayer={false}
        css={`
          ${classes.singlePlayer}
        `}
        isTotalDescription={true}
        index={index}
        avatar={item?.user?.multimedia?.url}
        totalComments={item?.comments}
        isCommunity
        handlePostComments={ async(values) => await handlePostComments(values, item, user)}
        handleReportPost={ async() => {
         let responseReport= await handleReportPost(item, user)
          if(responseReport && responseReport.code){
            item.reported = false;
            return false;
          }else{
            item.reported = true;            
            return true;
          }
        }}
        handleMoreComments={() => handleMoreComments(item)}
        handleFavorite={() => {
          handleFavorite(item?.id, "CPY", index);
        }}
        handleDislike={() => {
          handleDislike(item?.id, "CPY", index);
        }}
        person={item?.user}
        comments={item?.comment}
        handleEditComments={handleEditComments}
        handleReportComment={handleReportComment}
        handleDeletePrayer={() => {
          deleteCommunityPost(item.id);
          if (onDeletePrayer) onDeletePrayer(item.id);
        }}
        owner={item?.id}
        ownerType="CPY"
        isReported={findReported(item.id, "CPY")}
        handleDeleteComment={(id) => {
          deleteCommunityComment(id).then(() => {
            if (handleMoreComments) handleMoreComments(item);
          });
        }}
        typeMode={item.typeMode}
      />
    );
  };

  return isTabCommunity ? (
    <div className={classes.selectLanguageContent}>
      <div>
        <Select
          selected={
            selectedLanguage
              ? selectedLanguage
              : { id: "all", value: t("components.community.All") }
          }
          options={languages}
          icon={<Icon icon={ArrowDarkIcon} alt="arrow" />}
          onChangeSelect={(option) => {
            handleFilter(option);
            setSelectedLanguage(option);
          }}
          css={classes.selectLanguage}
          id={`btn_select_country_community`}
          optionsCss={classes.selectOptions}
        />
      </div>
      <InfiniteScroll
        height={967}
        dataLength={posts.length}
        next={() => handleMorePost()}
        hasMore={true}
        className={classes.singlePlayersList}
      >
        {posts?.map((post, index) => postsRender(post, index))}
      </InfiniteScroll>
    </div>
  ) : (
    <div className={classes.singlePlayersList}>
      {posts?.map((post, index) => {
        return postsRender(post, index);
      })}

      {buttonLoadMorePrayers()}
    </div>
  );
}
