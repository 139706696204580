import "moment/locale/es";
import "moment/locale/en-ca";
import "moment/locale/zh-cn";
import "moment/locale/de";
import "moment/locale/fr";
import "moment/locale/it";
import "moment/locale/pt";
import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { RouterConfig } from "navigation/RouterConfig";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { updateReducer } from "services/authService";
import moment from "moment";

function App() {
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();

  useEffect(() => {
    moment.locale(i18n.language);

    if (localStorage.getItem("user")) {
      updateReducer(dispatch);
    }
  }, []);

  useEffect(() => {
    switch (i18n.language) {
      case "zh":
        moment.locale("zh_cn");
        break;
      default:
        moment.locale(i18n.language);
        break;
    }
  }, [t]);

  return (
    <div data-testid={`app`}>
      <BrowserRouter>
        <RouterConfig />
      </BrowserRouter>
    </div>
  );
}

export default App;
