import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles({
  editorImageContent: (props) => {
    let style = {
      width: "792px",
      outline: "none",
      height: "auto",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    };

    if (props.mediaQueryMd) {
      style = {
        ...style,
      };
    }
    return style;
  },
  editorImageModal: (props) => {
    let style = {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      overflowY: "auto",
    };
    if (props.mediaQueryMd) {
      style = {
        ...style,

        height: "100%",
      };
    }
    return style;
  },
  editorImage: (props) => {
    let style = {
      background: "#FFFFFF",
      padding: "24px 24px 56px 24px",
      borderRadius: "12px",
    };
    if (props.mediaQueryMd) {
      style = {
        ...style,
        borderRadius: "none",
        padding: "8px 20px 56px 20px",
      };
    }
    return style;
  },
  closeIconContent: (props) => {
    let style = {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      "& >img": {
        cursor: "pointer",
      },
    };
    if (props.mediaQueryMd) {
      style = {
        ...style,
        padding: "12px 0px",
      };
    }
    return style;
  },
  wrapperEditorImage: (props) => {
    let style = {
      margin: "24px 122px 0px 122px",
    };
    if (props.mediaQueryMd) {
      style = {
        ...style,
        margin: "0px",
      };
    }
    return style;
  },
  titleContent: (props) => {
    let style = {
      textAlign: "center",
      "&>h1": {
        fontFamily: "Libre Baskerville",
        fontWeight: "normal",
        fontSize: "32px",
        color: "#444C67",
        margin: "0px 0px 24px 0px",
      },
    };
    if (props.mediaQueryMd) {
      style = {
        ...style,
        margin: "24px 0px 0px 0px",
      };
      style["&>h1"].fontSize = "24px";
    }
    return style;
  },
  wrapperAvatarEditor: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  scaleContent: (props) => {
    let style = {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      margin: "10px 100px 20px",
    };
    if (props.mediaQueryMd) {
      style = {
        ...style,
        margin: "10px 0px 20px",
      };
    }
    return style;
  },
  buttonsContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& button:first-child": {
      margin: "0px 16px 0px 0px",
    },
  },
  mgeError: {
    margin: "auto",
    marginBottom: "0.9rem",
    width: "max-content",
    height: "max-content",
    "&>p": {
      fontFamily: "Nunito Sans",
      width: "max-content",
      color: "#c30909",
    },
  },
});
