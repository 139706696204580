import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles({
  SignUpSuccessContent: () => {
    let style = {
      width: "792px",
      outline: "none",
      height: "auto",
    };
    return style;
  },
  SignUpSuccessModal: (props) => {
    let style = {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      overflowY: "auto",
    };
    if (props.mediaQueryMd) {
      style = {
        ...style,
        height: "100%",
      };
    }
    return style;
  },
  SignUpSuccess: (props) => {
    let style = {
      background: "#FFFFFF",
      padding: "24px 24px 48px 24px",
      borderRadius: "12px",
    };
    if (props.mediaQueryMd) {
      style = {
        ...style,
        borderRadius: "none",
        padding: "8px 20px 72px 20px",
      };
    }
    return style;
  },
  closeIconSignUpSuccess: (props) => {
    let style = {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      "& >img": {
        cursor: "pointer",
      },
    };
    if (props.mediaQueryMd) {
      style = {
        ...style,
        padding: "0px",
      };
    }
    return style;
  },
  wrapperSignUpSuccess: (props) => {
    let style = {
      margin: "24px 40px 0px 40px",
    };
    if (props.mediaQueryMd) {
      style = {
        ...style,
        margin: "0px",
      };
    }
    return style;
  },
  titleContentSignUpSuccess: (props) => {
    let style = {
      margin: "0px 0px 24px 0px",
      "&>h1": {
        fontFamily: "Libre Baskerville",
        fontWeight: "normal",
        fontSize: "32px",
        lineHeight: "40px",
        textAlign: "center",
        color: "#444C67",
        margin: "0px 0px 32px 0px",
      },
      "&>p": {
        fontFamily: "Nunito Sans",
        fontWeight: "normal",
        fontSize: "16px",
        lineHeight: "24px",
        textAlign: "center",
        color: "#444C67",
        whiteSpace: "pre-wrap",
        margin: 0,
      },
    };
    if (props.mediaQueryMd) {
      style = {
        ...style,
        margin: "24px 0px 24px 0px",
      };
      style["&>h1"].fontSize = "24px";
      style["&>h1"].lineHeight = "36px";
      style["&>h1"].margin = "0px 0px 24px 0px";
    }

    return style;
  },
  contentButtonsSignUpSuccess: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});
