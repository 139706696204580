import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles({
  mainContent: {
    overflowX: "hidden",
  },
  sectionCard: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    backgroundSize: "cover",
    height: "100%",
    width: "100%",
    backgroundColor: "#6385B1",
  },
  wrapperMain: (props) => {
    let style = {
      margin: "0 159px 0px 224px",
      width: "1100px",
    };

    if (props.mediaQuerySm) {
      style.width = "100%";
      style.maxWidth = "62em";
      style.margin = 0;
    }

    return style;
  },
  sectionTitle: (props) => {
    let style = {
      margin: "198.87px 0px 64px 0px",
      display: "flex",
      flexDirection: "column",
      "&>h1": {
        fontFamily: "Libre Baskerville",
        fontSize: "44px",
        color: "#FFFFFF",
        margin: "0px 0px 16px 0px",
        fontWeight: "normal",
      },
      "&>h6": {
        fontFamily: "Nunito Sans",
        fontSize: "14px",
        letterSpacing: "0.1em",
        textTransform: "uppercase",
        color: "#FFFFFF",
        margin: 0,
        fontWeight: 400,
      },
    };
    if (props.mediaQuerySm) {
      style = {
        ...style,
        margin: "88px 0px 33px 0px",
        width: "100%",
        textAlign: "center",
        "&>h1": {
          ...style["&>h1"],
          margin: 0,
          fontSize: "28px",
        },
        "&>h6": {
          ...style["&>h6"],
          margin: "auto",
          fontSize: "14px",
          width: "60%",
          lineHeight: "24px",
        },
      };
    }
    return style;
  },
  lineH: {
    margin: "30px auto",
    border: "1px solid rgba(255, 255, 255, 0.3)",
    height: "0",
    width: "40px",
  },
  contentBody: (props) => {
    let style = {
      display: "flex",
      flexDirection: "column",
    };
    if (props.mediaQuerySm) {
      style = {
        ...style,
        width: "100%",
        paddingRight: "0px",
        borderRight: "none",
        justifyContent: "center",
      };
    }

    return style;
  },

  contentBodySection: (props) => {
    let style = {
      display: "flex",
      margin: "0px 0px 3em 0px",
      flexDirection: "column",
      alignItems: "flex-start",
      "&:last-child": {
        marginBottom: "7em",
      },
      "&>h2": {
        fontFamily: "Libre Baskerville",
        fontSize: "32px",
        color: "#FFFFFF",
        margin: "0px 0px 26px 0px",
        fontWeight: "normal",
      },
    };
    if (props.mediaQuerySm) {
      style = {
        ...style,
        margin: "0px 20px 3em 20px",
        height: "auto",
        "&:last-child": {
          marginBottom: "3em",
        },
        "&>h2": {
          ...style["&>h2"],
          fontSize: "24px",
          textAlign: "start",
        },
      };
    }

    return style;
  },
  wrapperListCard: (props) => {
    let style = {
        "&>ul": {
          "&>li": {
            height: "224px",
              width:'200px',
              margin:'0px 0px 0px 24px',
            '&:first-child':{
              margin:0
            }
          },
        },
    };
    if (props.mediaQuerySm) {
      style = {
        ...style,
        width: "100%",
      };
    }
    return style;
  },
});
