import moment from "moment";
import { backgrounds, setAmen, unsetAmen } from "../../helpers";
import {
  getIntentionsMonthly,
  getIntentionsById,
  getIntentionAttitudes,
  getConferences,
} from "services/services";
import { findAmen } from "services/authService";

export function parseAttitudes(attitudes = [], mediaQuerySm = false) {
  attitudes = attitudes.sort((a,b)=> a.order - b.order);
  if (mediaQuerySm) {
    return attitudes;
  } else {
    let firstAttitudes;
    let secondAttitudes;
    switch (attitudes.length) {
      case 5:
        firstAttitudes = attitudes.slice(0, 3);
        secondAttitudes = attitudes.slice(3, 5);
        return [firstAttitudes, secondAttitudes];
      case 4:
        firstAttitudes = attitudes.slice(0, 2);
        secondAttitudes = attitudes.slice(2, 4);
        return [firstAttitudes, secondAttitudes];
      default:
        return [attitudes];
    }
  }
}
export function handlevideo(id) {
  return {
    id: id,
    isPlay: true,
    video: "https://www.youtube.com/embed/kP7uoGPjLCs",
  };
}
export function onChangeSelectLanguage(intentions, option, id) {
  const intentionsLanguage = intentions.map((intention) => {
    if (intention.id === id) {
      return {
        ...intention,
        details: { ...intention.details, languageConference: option },
      };
    } else return intention;
  });

  return intentionsLanguage;
}

const amenCheck = (prayer) => {
  return !!findAmen(prayer?.id, prayer?.type.code);
};

export async function handleFavorite(
  owner,
  ownerType,
  index,
  intentionIndex,
  intentions,
  setIntentions
) {
  await setAmen(owner, ownerType);
  let ints = [...intentions];
  let prayers = [...ints[intentionIndex].details.prayers];
  prayers[index].isFavorite = amenCheck(prayers[index]);
  ints[intentionIndex].details.prayers = prayers;
  setIntentions(ints);
}

export async function handleDislike(
  owner,
  ownerType,
  index,
  intentionIndex,
  intentions,
  setIntentions
) {
  await unsetAmen(owner, ownerType);
  let ints = [...intentions];
  let prayers = [...ints[intentionIndex].details.prayers];
  prayers[index].isFavorite = amenCheck(prayers[index]);
  ints[intentionIndex].details.prayers = prayers;
  setIntentions(ints);
}

export function onChangeExpandedMonthly(monthlys, intentions) {
  const moreIntentions = monthlys.map((monthly) => {
    const filterIntentions = intentions.filter(
      (intention) => monthly.id === intention.id
    );
    if (filterIntentions.length > 0) {
      return { ...monthly, expanded: filterIntentions[0].expanded };
    } else {
      return monthly;
    }
  });
  return moreIntentions;
}
export function parseConferences(conferences) {
  let parseConference = conferences.map((conference) => {
    return { ...conference, value: conference.country.name };
  });
  return parseConference;
}
export async function fetchIntentionMonthly(
  setMonthlys,
  setIsExpandedMonthly,
  setIntentions,
  mediaQuerySm,
  monthIndex
) {
  try {
    const response = await getIntentionsMonthly(
      moment().add(-12, "month").clone().startOf("month").format("YYYY-MM-DD"),
      moment().clone().endOf("month").format("YYYY-MM-DD")
    );

    if (response?.data?.length > 0) {
      let intentions = await response?.data?.map((intention, index) => {
        let backgroundIntention;
        if (mediaQuerySm)
          backgroundIntention = backgrounds(
            intention?.multimedia,
            "IMG_INT_MON_MBL"
          );
        else
          backgroundIntention = backgrounds(
            intention?.multimedia,
            "IMG_INT_MON_WEB"
          );

        let selectedIndex = (monthIndex && monthIndex == index) || (!monthIndex && index === 0);
        if (selectedIndex) {
          return {
            ...intention,
            expanded: true,
            background: backgroundIntention,
          };
        } else {
          return {
            ...intention,
            expanded: false,
            background: backgroundIntention,
          };
        }
      });

      let conferences = await getConferences(
        intentions[0].date_from,
        intentions[0].date_to
      );
      const Intention = await getIntentionsById(intentions[monthIndex || 0].id);
      let attitudes = await getIntentionAttitudes(intentions[monthIndex || 0].id);
      await Promise.all([Intention, attitudes, conferences])
        .then((values) => {
          conferences = values[2].length ? parseConferences(values[2]) : [];
          attitudes = values[1].length
            ? parseAttitudes(values[1], mediaQuerySm)
            : [];
          intentions[monthIndex || 0].details = {
            ...values[0],
            attitudes,
            conferences,
            isVideo: false,
            languageConference: conferences.length ? conferences[0] : [],
          };

          if (response?.data?.length > 6) {
            setMonthlys(intentions);
            setIsExpandedMonthly(true);
            intentions = intentions.filter((intention, index) => index < 6);
          }

          setIntentions(intentions);
        })
        .catch((error) => console.log(error));
    }
  } catch (error) {
    console.log(error);
  }
}
export async function onChangeAccordion(
  id,
  value,
  intentions,
  mediaQuerySm,
  setIntentions
) {
  let modifyIntentions;
  if (value) {
    modifyIntentions = intentions.map((intention) => {
      if (intention.id === id) {
        return { ...intention, expanded: false };
      } else return intention;
    });
    setIntentions(modifyIntentions);
  } else {
    let intention = intentions.find((intention) => intention.id === id);
    let conferences = await getConferences(
      intention.date_from,
      intention.date_to
    );
    let dataIntention = await getIntentionsById(id);
    let attitudes = await getIntentionAttitudes(id);
    await Promise.all([dataIntention, attitudes, conferences])
      .then((values) => {
        conferences = values[2].length ? parseConferences(values[2]) : [];
        let attitudes = values[1].length
          ? parseAttitudes(values[1], mediaQuerySm)
          : [];
        dataIntention = {
          ...values[0],
          isVideo: false,
          attitudes,
          conferences,
          languageConference: conferences.length ? conferences[0] : [],
        };
        modifyIntentions = intentions.map((intention) => {
          if (intention.id === id) {
            return {
              ...intention,
              expanded: true,
              details: dataIntention,
            };
          } else return { ...intention, expanded: false };
        });

        setIntentions(modifyIntentions);
      })
      .catch((error) => console.log(error));
  }
}
