import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles({
  intentionMonthlyContent: {
    msOverflowX: "hidden",
    background: "rgb(244, 246, 248)",
  },
  titleMonthlysContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    backgroundSize: "cover",
    height: "100%",
    width: "100%,",
  },
  wrappertMonthlys: (props) => {
    let style = {
      padding: "0px 108px 160px",
    };
    if (props.mediaQuerySm) {
      style.padding = "0px 20px 64px";
    }
    return style;
  },
  wrappertTitleMonthlys: (props) => {
    let style = {
      margin: "216px 212px 89px",
      textAlign: "auto",
      "&>h1": {
        fontFamily: "Libre Baskerville",
        fontSize: "44px",
        color: "#444C67",
        margin: "0px 0px 16px",
      },
      "&>h6": {
        fontFamily: "Nunito Sans",
        fontSize: "14px",
        letterSpacing: "0.1em",
        textTransform: "uppercase",
        color: "#444C67",
        margin: "0px 0px 16px",
      },
      "&>p": {
        fontFamily: "Nunito Sans",
        fontSize: "16px",
        color: "#444C67",
        margin: 0,
      },
    };
    if (props.mediaQuerySm) {
      style["&>h1"] = {
        ...style["&>h1"],
        fontSize: "28px",
      };
      style.margin = "88px 20px 64px";
      style.textAlign = "center";
      style["&>p"].textTransform = "none";
    }
    return style;
  },

  accordion: {
    marginBottom: "24px",
    "& .MuiAccordionDetails-root": {
      marginBottom: "30px",
    },
  },

  contentButtonMoreMonthly: (props) => {
    let style = {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      margin: "54px 0px 0px 0px",
    };
    if (props.mediaQuerySm) {
      style.margin = "64px 0px 0px 0px";
    }
    return style;
  },
});
