import React, { useState } from "react";
import Icon from "components/Icon";
import { useStyles } from "./styles";
import { useTranslation } from "react-i18next";

export function PrayCardStyled(props) {
  const [isHover, setHover] = useState(false);
  const { t, i18n } = useTranslation();
  const {
    icon,
    title,
    type,
    typeMode,
    position,
    textHover,
    id,
    onClick,
    isSmall,
    doubleCard,
    date,
  } = props;

  const classes = useStyles({
    mode: typeMode,
    position,
    mediaQuerySm: isSmall,
    doubleCard,
  });

  const card = () => {
    return (
      <div
        data-testid={`crd_pray_content_${id}`}
        className={`${classes.list} ${classes.card} ${
          doubleCard ? classes.doubleCard : null
        }`}
        onClick={onClick}
        id={`crd_pray_content_${id}`}
        onMouseOver={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <div className={classes.contentText} id={`content_text_${id}`}>
          {icon && <Icon id={`ico_${id}`} icon={icon} alt={title} css={classes.icon} />}
          <p data-testid={`txt_pray_title_${id}`}>
            {i18n.exists(title) ? t(title) : title}
          </p>
          {type && (
            <p className={classes.typePray} data-testid={`txt_pray_type_${id}`}>
              {i18n.exists(type) ? t(type) : type}
            </p>
          )}
          {date && (
            <div>
              <p data-testid={`txt_pray_date_${id}`}>
                {t(date.description, { date: date.moment })}
              </p>
            </div>
          )}
        </div>
        {isHover && (
          <div className={classes.textHover}>
            <div id={`content_hover_${id}`}>
              <p data-testid={`txt_pray_textHover_${id}`}>
                {i18n.exists(textHover) ? t(textHover) : textHover}
              </p>
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      {doubleCard ? (
        <div className={classes.contentDoubleCard}>
          <div className={classes.doubleCard}> </div>
          {card()}
        </div>
      ) : (
        card()
      )}
    </>
  );
}
