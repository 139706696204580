import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
  scrollTopWindow,
  handlerBackToSchoolPrayer,
  handlerBackToSection
} from "../../helpers";
import CampaignView from "./CampaignView";
import { fetchCampaign, openWindowTab } from "./CampaignFunctions";
import { handleClickOpenUrl } from "../../helpers";

export function CampaignContainer() {
  const { t, i18n } = useTranslation();
  const { id, campaignIndex } = useParams();

  const [campaign, setCampaign] = useState({});
  const [iconMoment, setIconMoment] = useState();
  const [codeMoment, setCodeMoment] = useState("");
  const [codeCampaign, setCodeCampaign] = useState("");
  const [startedCampaign, setSartedCampaign] = useState(true);
  const [indexCamp, setIndexCamp] = useState(0);

  const history = useHistory();
  const mediaQueryLg = useMediaQuery("(max-width:1280px)");
  const mediaQueryMd = useMediaQuery("(max-width:960px)");
  const mediaQueryXs = useMediaQuery("(max-width:600px)");

  useEffect(() => {
    setIndexCamp(campaignIndex || 0);
    scrollTopWindow();
    fetchCampaign(
      id,
      history,
      indexCamp,
      setSartedCampaign,
      setCampaign,
      setCodeMoment,
      setCodeCampaign,
      setIconMoment,
      i18n.language
    );
  }, [t, id, indexCamp]);

  return (
    <CampaignView
      indexCamp={indexCamp || 0}
      mediaQueryLg={mediaQueryLg}
      mediaQueryMd={mediaQueryMd}
      mediaQueryXs={mediaQueryXs}
      iconMoment={iconMoment}
      codeMoment={codeMoment}
      codeCampaign={codeCampaign}
      campaign={campaign}
      startedCampaign={startedCampaign}
      handlerBackToSchoolPrayer={() => handlerBackToSchoolPrayer(history)}
      handlerGoToCommunity={() => handlerBackToSection(history, "/community")}
      openWindowLink={(link) => openWindowTab(link)}
      totalItems={campaign?.campaignPrayer?.length || 0}
      handleClickOpenUrl={handleClickOpenUrl}
    />
  );
}
