import moment from "moment";
import { findAmen } from "services/authService";
import { getCommentsPost, getPopeProfileData, postComments, editCommentsPost } from "services/services";
import { setAmen, unsetAmen } from "../../helpers";

export async function getPapaProfile(pageSize, setPrayers, setHasNext) {
  const response = await getPopeProfileData(pageSize);
  response.data.forEach((data) => {
    data.created_at = data.show_date || data.date_from;
    data.ownerType = data.amen?.ownerType === 'INT' ? 'INT' : 'POP';
  });
  const ids = response.data?.map((post) => ({ id: post.id, type: post.ownerType }))
  const allComments = await Promise.all(
    ids.map(({id, type})=>getCommentsPost(id, type))
  );
  response.data.forEach(async (data,i) => {
    data.comments = allComments[i]?.sort(
      (a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
    )
  });
  // para hack cache de 1hr.
  let visibleYet = []
  if (response.data?.[0]?.nextPrayer) {
    visibleYet = response.data?.[0].nextPrayer.filter(p => moment().isAfter(p.show_date));
  }
  setPrayers([...visibleYet, ...response.data]);
  setHasNext(response.hasNext);
}

export async function onChangeExpandedPost(
  mediaQuerySm,
  pageSize,
  setPrayers,
  setHasNext,
  setPageSize
) {
  setPageSize(mediaQuerySm ? pageSize + 3 : pageSize + 8);
  await getPapaProfile(
    mediaQuerySm ? pageSize + 3 : pageSize + 8,
    setPrayers,
    setHasNext
  );
}

const amenCheck = (prayer) => {
  return !!findAmen(prayer?.id, prayer.typeForAmen);
};

export async function setFavorite(
  owner,
  ownerType,
  index,
  prayers,
  setPrayers
) {
  await setAmen(owner, ownerType);
  let items = [...prayers];
  items[index].isFavorite = amenCheck(prayers);
  setPrayers(items);
}

export async function handleDislike(
  owner,
  ownerType,
  index,
  prayers,
  setPrayers
) {
  await unsetAmen(owner, ownerType);
  let items = [...prayers];
  items[index].isFavorite = amenCheck(prayers);
  setPrayers(items);
}

export async function postComment(dispatch, data, prayer, user) {
  let comment = {
    description: data?.comment,
    prayer: prayer?.id,
    type: prayer?.ownerType,
  };
  try {
    const commentSaved = await postComments(comment);
    comment.user = user;
    comment.id = commentSaved.id;
    comment.created_at = commentSaved.created_at;
    prayer.comments?.unshift(comment);
    return prayer;
  } catch (error) {
    dispatch({
      type: '@modal/error',
      payload: {
        open: true,
        message: { name: error?.name, messageError: error?.message },
      },
    });
  }
}

export async function editComment(dispatch, prayer, description, comment_id) {
  try {
    await editCommentsPost({ description }, comment_id);
    const inx = prayer.comments?.findIndex(c=>c.id === comment_id);
    if (inx >=0) prayer.comments[inx].description = description;
    return prayer;
  } catch (error) {
    dispatch({
      type: '@modal/error',
      payload: {
        open: true,
        message: { name: error?.name, messageError: error?.message },
      },
    });
  }
}

export function updateComments(
  prayer
) {
  return getCommentsPost(prayer.id, prayer.type.code);
}
