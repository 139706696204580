import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles({
  videoPlayer: (props) => {
    let style = {
      padding: "100px 200px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "@media (orientation: landscape)": {},
    };

    if (props.mediaQuerySm) {
      style.padding = "100px 20px";
      style["@media (orientation: landscape)"].padding = 0;
    }

    return style;
  },

  contentVideoPlayer: (props) => {
    let style = {
      background: "#F4F6F8",
      width: "100%",
      height: "100%",
      outline: "none",
      "& iframe": {
        border: "none",
      },
      "@media (orientation: landscape)": {},
    };
    if (props.mediaQuerySm) {
      style["@media (orientation: landscape)"] = {
        width: "100%",
        height: "100%",
      };
    }
    return style;
  },
});
