import React, { useEffect, useState } from "react";
import PopeProfileView from "./PopeProfileView";
import { useTranslation } from "react-i18next";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useDispatch } from 'react-redux';
import {
  getPapaProfile,
  onChangeExpandedPost,
  setFavorite,
  handleDislike,
  postComment,
  editComment,
  updateComments,
} from "./PopeProfileFunctions";
import { getUser } from "services/authService";
import { reportCommentsPost} from "services/services";

export function PopeProfileContainer() {
  const mediaQuerySm = useMediaQuery("(max-width:960px)");
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [pageSize, setPageSize] = useState(mediaQuerySm ? 3 : 8);
  const [prayers, setPrayers] = useState([]);
  const [hasNext, setHasNext] = useState({});
  const user = getUser();
  useEffect(() => {
    getPapaProfile(mediaQuerySm ? 3 : 8, setPrayers, setHasNext);
  }, [t, mediaQuerySm]);

  return (
    <PopeProfileView
      prayers={prayers}
      hasNext={hasNext}
      isSm={mediaQuerySm}
      onChangeExpandedPost={() =>
        onChangeExpandedPost(
          mediaQuerySm,
          pageSize,
          setPrayers,
          setHasNext,
          setPageSize
        )
      }
      handleFavorite={(owner, ownerType, index) => setFavorite(owner, ownerType, index, prayers, setPrayers)}
      handleDislike={(owner, ownerType, index) => handleDislike(owner, ownerType, index, prayers, setPrayers)}
      handlePostComments={async (values, prayer) => {
        if (user) {
          const prayerUpdated = await postComment(dispatch, values, prayer, { ...user, ...user.person });
          const inx = prayers.findIndex((p)=>p.id === prayerUpdated.id);
          if (inx>=0) {
            const newState = [...prayers];
            newState[inx] = prayerUpdated;
            setPrayers(newState)
          }
        } else dispatch({ type: '@modal/login', payload: { open: true } });
      }}
      handleEditComments={(prayer, inx)=> async (values, comment)=> {
        const prayerUpdated = await editComment(dispatch, prayer, values.comment, comment.id);
        const newState = [...prayers];
        newState[inx] = prayerUpdated;
        setPrayers(newState)
      }}
      handleReportComment={(prayer, inx)=> (comment)=> {
        reportCommentsPost(comment.id);
        const inxComm = prayer.comments?.findIndex(c=>c.id === comment.id);
        if (inxComm >=0) {
          const newState = [...prayers];
          newState[inx].comments[inxComm].reported = true;
          setPrayers(newState);
        }
      }}
      handleUpdateComments={async (prayer, inx) => {
        const newComments = await updateComments(prayer);
        let items = [...prayers];
        items[inx].comments = newComments;
        setPrayers(items);
      }}
    />
  );
}
