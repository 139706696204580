import React, { useEffect, useState } from "react";
import _ from "lodash"
import { useStyles } from "./styles";
import Grid from "@material-ui/core/Grid";
import FacebookIcon from "assets/icons/facebookRound.svg";
import YoutubeIcon from "assets/icons/youtube.svg";
import TwitterIcon from "assets/icons/Twitter.svg";
import InstagramIcon from "assets/icons/instagram.svg";
import ArrowIcon from "assets/icons/chevron-down-dark.svg";
import Logo from "assets/img/logo_fotter.svg";
import VaticanMedia from "assets/img/vatican_media.svg";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Button from "components/Button";
import Select from "components/Select";
import Icon from "components/Icon";
import useLogos from "components/Logos";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import { useTranslation } from "react-i18next";
import moment from "moment";
import pkg from "../../../package.json";
import { HOW_DOES_CTP, WHAT_IS_CTP, LEGAL } from "navigation/CONSTANTS";

import { branchOffices } from "../../offices";
import AddressListCard from "components/Cards/AddressListCard";

import { useHistory } from "react-router-dom";

export function footerFunctions() {
  function handleChange(panel, newExpanded) {
    return newExpanded !== panel ? newExpanded : false;
  }

  return {
    handleChange,
  };
}

export function FooterStyled(props) {
  const history = useHistory();
  const { isSmall } = props;
  const { t } = useTranslation();
  let { PopeWorldWide, WayOfHeart, ThePopeVideo, Eym } = useLogos();
  const classes = useStyles({ mediaQuerySm: isSmall });
  const [expanded, setExpanded] = useState("");
  const [officesInfo, setOfficesInfo] = useState([]);
  const [officesList, setOfficesList] = React.useState([]);
  const [officeSelected, setOfficeSelected] = React.useState({ id: "", value: t("components.footer.select_country") });


  const neatBranch = _.sortBy(branchOffices, 'name')
  const makeOfficeList = () => {
   return [
    { id: "", value: t("components.footer.select_country") },
    ...neatBranch.map((item) => {
    return (
      {
      id: item.name,
      value: `${item.name} - ${item.code}`,
    
    })}),
  ];
  };

  const buttonsSocial = [
    {
      url: t("components.footer.link_facebook"),
      icon: FacebookIcon,
      alt: "Facebook",
      id: "btn_facebook",
    },
    {
      url: t("components.footer.link_twitter"),
      icon: TwitterIcon,
      alt: "Twitter",
      id: "btn_twitter",
    },
    {
      url: t("components.footer.link_youtube"),
      icon: YoutubeIcon,
      alt: "Youtube",
      id: "btn_youtube",
    },
    {
      url: t("components.footer.link_instagram"),
      icon: InstagramIcon,
      alt: "Instagram",
      id: "btn_instagram",
    },
  ];
  let linksAbout = [
    {
      href: WHAT_IS_CTP, //t("components.footer.link_what_is_click_to_pray"),
      text: t("components.footer.What is Click to Pray?"),
      id: "lnk_what_click_to_pray",
    },
    {
      href: HOW_DOES_CTP, //t("components.footer.link_does_click_to_pray_work"),
      text: t("components.footer.How does Click to Pray work?"),
      id: "lnk_how_does_click_to_pray",
    },
    {
      href: LEGAL, //t("components.footer.link_legal_privacy"),
      text: t("components.footer.Legal notice, privacy policy and cookies"),
      id: "lnk_privacy_click_to_pray",
    },
  ];

  const filterOffices = (id) => {
    if (!id) {
      setOfficesInfo([]);
      return;
    }
    const filtered = branchOffices.find((item) => item.name == id);
    setOfficesInfo(filtered.offices);
    setOfficeSelected({
      id: filtered.name,
      value: `${filtered.name} - ${filtered.code}`,
    });
  };

  useEffect(() => {
    const unlisten = history.listen(() => window.scrollTo(0, 0));
    return () => unlisten();
  }, []);

  useEffect(() => {
    const listCountry = makeOfficeList();
    setOfficesList(listCountry);
    if (!officeSelected.id) setOfficeSelected(listCountry[0]);
  }, [t]);

  return (
    <div data-testid={`footer`} className={classes.fotterContent}>
      {isSmall ? (
        <>
          <div>
            <Icon
              data-testid="ico_logo_footer"
              id="ico_logo_footer"
              icon={Logo}
              alt="logo"
            />
          </div>

          <Accordion
            square
            expanded={expanded === "panel1"}
            onChange={() =>
              setExpanded(footerFunctions().handleChange(expanded, "panel1"))
            }
            TransitionProps={{ unmountOnExit: true }}
            id="acc_component_panel1"
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              id="acc_panel1_summary"
            >
              <div
                id="txt_content_initiative"
                className={classes.contentSumary}
              >
                <h2 data-testid="txt_content_initiative">
                  {t("components.footer.An Initiative of")}
                </h2>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div className={classes.contentDetailsColumn}>
                <Icon
                  css={classes.imgPointer}
                  id="ico_pope_world_wide"
                  icon={PopeWorldWide}
                  alt="pope World Wide"
                  onClick={() => window.open(t("components.footer.link_RMOP"))}
                />
                <Icon
                  css={classes.imgPointer}
                  data-testid="ico_eym"
                  id="ico_eym"
                  icon={Eym}
                  alt="eym"
                  onClick={() => window.open(t("components.footer.link_Mej"))}
                />
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion
            square
            expanded={expanded === "panel2"}
            onChange={() =>
              setExpanded(footerFunctions().handleChange(expanded, "panel2"))
            }
            id="acc_component_panel2"
          >
            <AccordionSummary
              aria-controls="panel2d-content"
              id="acc_panel2_summary"
            >
              <div
                id="txt_content_collaboration"
                className={classes.contentSumary}
              >
                <h2 data-testid="txt_content_collaboration">
                  {t("components.footer.In collaboration with")}
                </h2>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div className={classes.contentDetailsColumn}>
                <Icon
                  data-testid="ico_vaticanMedia"
                  id="ico_vaticanMedia"
                  icon={VaticanMedia}
                  alt="vatican media"
                  css={classes.imgPointer}
                  onClick={() =>
                    window.open(t("components.footer.link_Vatica_Media"))
                  }
                />
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion
            square
            expanded={expanded === "panel3"}
            onChange={() =>
              setExpanded(footerFunctions().handleChange(expanded, "panel3"))
            }
            id="acc_component_panel3"
          >
            <AccordionSummary
              aria-controls="panel3d-content"
              id="acc_panel3_summary"
            >
              <div
                id="txt_content_initiatives_pwpn"
                className={classes.contentSumary}
              >
                <h2 data-testid="txt_content_initiatives_pwpn">
                  {t("components.footer.Other projects")}
                </h2>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div className={classes.contentSumaryRow}>
                <Icon
                  data-testid="ico_pope_video"
                  id="ico_pope_video"
                  icon={ThePopeVideo}
                  css={classes.imgPointer}
                  onClick={() =>
                    window.open(t("components.footer.link_the_pope_video"))
                  }
                  alt="the pope video"
                />
                <Icon
                  data-testid="ico_pope_cdc"
                  id="ico_pope_cdc"
                  icon={WayOfHeart}
                  css={classes.imgPointer}
                  onClick={() =>
                    window.open(t("components.footer.link_way_of_heart"))
                  }
                  alt="the way of the heart"
                />
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion
            square
            expanded={expanded === "panel4"}
            onChange={() => setExpanded("panel4")}
            id="acc_component_panel4"
          >
            <AccordionSummary
              aria-controls="panel4d-content"
              id="acc_panel4_summary"
            >
              <div
                id="txt_content_more_about"
                className={classes.contentSumary}
              >
                <h2 data-testid="txt_content_more_about">
                  {t("components.footer.More About Click To Pray")}
                </h2>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div className={classes.contentDetailsColumn}>
                {linksAbout.map(({ href, text, id }, index) => (
                  <div key={index} id={id} onClick={() => history.push(href)}>
                    <p data-testid={id}>{text}</p>
                  </div>
                ))}
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion
            square
            expanded={expanded === "panel5"}
            onChange={() =>
              setExpanded(footerFunctions().handleChange(expanded, "panel5"))
            }
            elevation={0}
            id="acc_component_panel5"
          >
            <AccordionSummary
              aria-controls="panel5d-content"
              id="acc_panel5_summary"
            >
              <div id="txt_content_contact" className={classes.contentSumary}>
                <h2 data-testid="txt_content_contact">
                  {t("components.footer.Contact your country's office")}
                </h2>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div
                id="txt_content_address"
                className={classes.contentDetailsColumn}
              >
                <Select
                  id={"txt_content_address_select"}
                  css={classes.select}
                  options={officesList}
                  onChangeSelect={(option) => filterOffices(option.id)}
                  selected={officeSelected}
                  icon={
                    <Icon
                      id="ico_arrow_footer_contact"
                      icon={ArrowIcon}
                      alt="arrow"
                    />
                  }
                />
                <Grid container spacing={3}>
                  <AddressListCard offices={officesInfo} />
                </Grid>
              </div>
            </AccordionDetails>
          </Accordion>
          <div id="txt_content_download" className={classes.download}>
            <h2 data-testid="txt_content_download">
              {t("components.footer.Download the Click To Pray App")}
            </h2>
            <div>
              <Breadcrumbs aria-label="breadcrumb">
                <a
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    window.open(
                      "https://apps.apple.com/ar/app/click-to-pray/id934935942"
                    )
                  }
                  id="lnk_appStore"
                >
                  <p data-testid="lnk_appStore">
                    {t("components.footer.AppStore")}
                  </p>
                </a>
                <a
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    window.open(
                      "https://play.google.com/store/apps/details?id=com.lamachi.clicktopray"
                    )
                  }
                  id="lnk_googlePlay"
                >
                  <p data-testid="lnk_googlePlay">
                    {t("components.footer.Google Play")}
                  </p>
                </a>
              </Breadcrumbs>
            </div>
            <div>
              {buttonsSocial.map(({ url, icon, alt, id }, index) => (
                <Button
                  key={index}
                  type="cuaternary"
                  data-testid={id}
                  icon={<Icon icon={icon} alt={alt} />}
                  css={classes.butonIcon}
                  onClick={() => window.open(url, "_blank")}
                />
              ))}
            </div>
            <div id="txt_content_copyright">
              <span data-testid="txt_content_copyright">
                {t(
                  "components.footer.Copyright © {{year}} Pope's Worldwide Prayer Network Click To Pray. v{{version}}",
                  { year: moment().year(), version: pkg.version }
                )}
              </span>
            </div>
          </div>
        </>
      ) : (
        <>
          <Grid justify="center" alignItems="center" container>
            <Grid item xs={2}>
              <Icon
                data-testid="ico_logo_footer"
                id="ico_logo_footer"
                icon={Logo}
                alt="logo"
              />
            </Grid>
            <Grid
              id="txt_content_initiative"
              className={classes.initiativeContent}
              item
              xs={8}
            >
              <h2 data-testid="txt_content_initiative">
                {t("components.footer.An Initiative of")}
              </h2>
              <Icon
                css={classes.imgPointer}
                data-testid="ico_pope_world_wide"
                id="ico_pope_world_wide"
                icon={PopeWorldWide}
                alt="pope World Wide"
                onClick={() => window.open(t("components.footer.link_RMOP"))}
              />
              <Icon
                css={classes.imgPointer}
                data-testid="icoym"
                id="icoym"
                icon={Eym}
                alt="eym"
                onClick={() => window.open(t("components.footer.link_Mej"))}
              />
            </Grid>
            <Grid id="txt_content_collaboration" item xs={2}>
              <h2 data-testid="txt_content_collaboration">
                {t("components.footer.In collaboration with")}
              </h2>
              <Icon
                data-testid="ico_vaticanMedia"
                id="ico_vaticanMedia"
                icon={VaticanMedia}
                css={classes.imgPointer}
                onClick={() =>
                  window.open(t("components.footer.link_Vatica_Media"))
                }
                alt="vatican media"
              />
            </Grid>
          </Grid>
          <hr />
          <Grid className={classes.secondGrid} justify="center" container>
            <Grid id="txt_content_more_about" item xs={3}>
              <h2 data-testid="txt_content_more_about">
                {t("components.footer.More About Click To Pray")}
              </h2>
              {linksAbout.map(({ href, text, id }, index) => (
                <a
                  style={{ cursor: "pointer" }}
                  key={index}
                  id={id}
                  onClick={() => history.push(href)}
                >
                  <p data-testid={id}>{text}</p>
                </a>
              ))}
            </Grid>
            <Grid item xs={2}>
              <div id="txt_content_contact">
                <h2 data-testid="txt_content_contact">
                  {t("components.footer.Contact your country's office")}
                </h2>
                <div
                  id="txt_content_address"
                  className={classes.contentDetailsColumn}
                >
                  <Select
                    css={classes.select}
                    options={officesList}
                    onChangeSelect={(option) => filterOffices(option.id)}
                    selected={officeSelected}
                    icon={
                      <Icon
                        data-testid="ico_arrow_footer_contact"
                        id="ico_arrow_footer_contact"
                        icon={ArrowIcon}
                        alt="arrow"
                      />
                    }
                  />
                </div>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className={classes.contentDetailsColumn}>
                  <AddressListCard offices={officesInfo} />
              </div>
            </Grid>
            <Grid id="txt_content_initiatives_pwpn" item xs={3}>
              <h2 data-testid="txt_content_initiatives_pwpn">
                {t("components.footer.Other projects")}
              </h2>
              <div className={classes.contentPwpn}>
                <Icon
                  data-testid="ico_pope_video"
                  id="ico_pope_video"
                  icon={ThePopeVideo}
                  css={classes.imgPointer}
                  onClick={() =>
                    window.open(t("components.footer.link_the_pope_video"))
                  }
                  alt="the pope video"
                />
                <Icon
                  data-testid="ico_pope_cdc"
                  id="ico_pope_cdc"
                  icon={WayOfHeart}
                  css={classes.imgPointer}
                  onClick={() =>
                    window.open(t("components.footer.link_way_of_heart"))
                  }
                  alt="the way of the heart"
                />
              </div>
            </Grid>
          </Grid>
          <Grid
            className={classes.thirdGrid}
            justify="center"
            alignItems="center"
            container
          >
            <Grid id="txt_content_download" item xs={7}>
              <h2 data-testid="txt_content_download">
                {t("components.footer.Download the Click To Pray App")}
              </h2>
              <Breadcrumbs aria-label="breadcrumb">
                <a
                  style={{ cursor: "pointer" }}
                  id="txt_appStore"
                  onClick={() =>
                    window.open(
                      "https://apps.apple.com/ar/app/click-to-pray/id934935942"
                    )
                  }
                >
                  <p data-testid="txt_appStore">
                    {t("components.footer.AppStore")}
                  </p>
                </a>
                <a
                  style={{ cursor: "pointer" }}
                  id="txt_googlePlay"
                  onClick={() =>
                    window.open(
                      "https://play.google.com/store/apps/details?id=com.lamachi.clicktopray"
                    )
                  }
                >
                  <p data-testid="txt_googlePlay">
                    {t("components.footer.Google Play")}
                  </p>
                </a>
              </Breadcrumbs>
              {buttonsSocial.map(({ url, icon, alt, id }, index) => (
                <Button
                  data-testid={id}
                  key={index}
                  type="cuaternary"
                  icon={<Icon icon={icon} alt={alt} />}
                  css={classes.butonIcon}
                  onClick={() => window.open(url, "_blank")}
                />
              ))}
            </Grid>
            <Grid id="txt_content_copyright" item xs={5}>
              <span data-testid="txt_content_copyright">
                {t(
                  "components.footer.Copyright © {{year}} Pope's Worldwide Prayer Network Click To Pray. v{{version}}",
                  { year: moment().year(), version: pkg.version }
                )}
              </span>
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
}
