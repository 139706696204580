import React, { useState, useEffect, useCallback, useRef } from "react";
import { useStyles } from "./styles";
import { useHistory } from "react-router-dom";
import Button from "components/Button";
import CustomPrayCard from "components/Cards/CustomPrayCard";
import { handlerBackToSection } from "../../../helpers";

const SynodListCardStyled = (props) => {
  const history = useHistory();
  const {
    customIcon,
    displayedItems = [],
    columnsLg = 4,
    columnsMd = 3,
    columnsSm = 2,
    itemsLg = 12,
    itemsMd = 8,
    itemsSm = 4
  } = props;

  const lgResolution = 1000;
  const mdResolution = 720;
  const containerRef = useRef(null);

  const getColumnCount = useCallback(() => {
    if (window.innerWidth >= lgResolution) return columnsLg;
    if (window.innerWidth >= mdResolution) return columnsMd;
    return columnsSm;
  }, [columnsLg, columnsMd, columnsSm]);

  const getInitialDisplayCount = useCallback(() => {
    if (window.innerWidth >= lgResolution) return itemsLg;
    if (window.innerWidth >= mdResolution) return itemsMd;
    return itemsSm;
  }, [itemsLg, itemsMd, itemsSm]);

  const [currentIndex, setCurrentIndex] = useState(() =>
    getInitialDisplayCount()
  );
  const [columnCount, setColumnCount] = useState(() => getColumnCount());

  useEffect(() => {
    const handleResize = () => {
      setColumnCount(getColumnCount());
      setCurrentIndex((prevIndex) =>
        Math.max(prevIndex, getInitialDisplayCount())
      );
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [getColumnCount, getInitialDisplayCount]);

  const classes = useStyles({ columnsLg, columnsMd, columnsSm });

  const handleLoadMore = () => {
    const itemsToLoad = getInitialDisplayCount();
    const previousIndex = currentIndex;

    setCurrentIndex((prevIndex) => {
      const newIndex = Math.min(prevIndex + itemsToLoad, displayedItems.length);

      setTimeout(() => {
        if (containerRef.current) {
          const gridItems = containerRef.current.getElementsByClassName(
            classes.gridItem
          );
          if (gridItems.length > previousIndex) {
            const targetElement = gridItems[previousIndex];
            targetElement.scrollIntoView({
              behavior: "smooth",
              block: "start"
            });
          }
        }
      }, 100);

      return newIndex;
    });
  };

  const rowCount = Math.ceil(currentIndex / columnCount);

  return (
    <div className={classes.root} ref={containerRef}>
      <div className={classes.gridContainer}>
        {displayedItems.slice(0, currentIndex).map((item, index) => {
          const row = Math.floor(index / columnCount);
          const col = index % columnCount;
          const isFirstRow = row === 0;
          const isLastRow = row === rowCount - 1;
          const isFirstInRow = col === 0;
          const isLastInRow =
            col === columnCount - 1 || index === currentIndex - 1;

          return (
            <div
              key={index}
              className={`${classes.gridItem}
                ${isFirstRow && isFirstInRow ? classes.roundedTopLeft : ""}
                ${isFirstRow && isLastInRow ? classes.roundedTopRight : ""}
                ${isLastRow && isFirstInRow ? classes.roundedBottomLeft : ""}
                ${isLastRow && isLastInRow ? classes.roundedBottomRight : ""}
              `}
            >
              <CustomPrayCard
                className={classes.noMargin}
                position={index}
                icon={customIcon}
                title={item.title}
                type={null}
                typeMode={"morning"}
                textHover={"pages.campaign.Read more"}
                id={index}
                date={item?.date}
                roundedCorners={true}
                borderHover={true}
                onClick={() =>
                  handlerBackToSection(history, `/synod-prayer/${item.id}`)
                }
                isFirstRow={isFirstRow}
                isLastRow={isLastRow}
                isFirstInRow={isFirstInRow}
                isLastInRow={isLastInRow}
              />
            </div>
          );
        })}
      </div>
      {currentIndex < displayedItems.length && (
        <div className={classes.buttonContainer}>
          <Button
            type="cuaternary"
            text="components.general.Load more prayers"
            onClick={handleLoadMore}
            id="btn_show_more"
          />
        </div>
      )}
    </div>
  );
};

export default SynodListCardStyled;
