import React from "react";
import { List, ListItem } from "@material-ui/core";
import { useStyles } from "./styles";
import PrayCard from "components/Cards/PrayCard";

export function ListCardStyled(props) {
  const { list, handleClickPrayCard, isSmall, doubleCard, cssCard } = props;
  const classes = useStyles({ mediaQuerySm: isSmall, doubleCard });
  return (
    <List
      id="crd_list_content"
      data-testid={`crd_list_content`}
      className={classes.flexContainer}
    >
      {list?.length > 0
        ? list.map(
            (pray, index) =>
              pray?.typeMode !== "offering" && (
                <ListItem key={index} id="crd_list" data-testid={`crd_list`}>
                  <PrayCard
                    position={index}
                    icon={pray?.icon}
                    title={pray?.title}
                    type={pray?.type}
                    typeMode={pray?.typeMode}
                    textHover={pray?.textHover}
                    id={index}
                    onClick={() => handleClickPrayCard(pray)}
                    isSmall={isSmall}
                    doubleCard={doubleCard}
                    date={pray?.date}
                    css={cssCard}
                  />
                </ListItem>
              )
          )
        : null}
    </List>
  );
}
