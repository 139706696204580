import React from "react";
import { useStyles } from 'pages/Monthly/styles';
import { useTranslation } from "react-i18next";
import ButtonMailto  from '../../components/ButtonMailto/ButtonMailto';



const HowCtp = () =>{
    const { t } = useTranslation();
    const classes = useStyles();
    return(
        <div data-testid={`monthly_view`} className={classes.intentionMonthlyContent}>
            <section className={classes.titleMonthlysContent}>
                <div id="txt_title_how_ctp" className={classes.wrappertTitleMonthlys}>
                <h1 data-testid={`txt_title`}>{t("howCTP.title")}</h1>
                <p>{t("howCTP.p1")}</p><br/>
                <p>{t("howCTP.p2")}</p><br/>
                <p>{t("howCTP.p3")}</p><br/>
                <p>{t("howCTP.p4")}</p><br/>
                <p>{t("howCTP.p5")}</p><br/>
                <p>{t("howCTP.p6")}</p><br/>
                <br/>
                <h6 data-testid={`txt_contact_title`}>{t("howCTP.contact title")}</h6>
                <p>{t("howCTP.contact info")} <ButtonMailto label={t("howCTP.mail")} mailto={t("howCTP.mail")} /></p>
                </div>
            </section>
        </div>
    )
}

export default HowCtp;