import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { fetchBlogs, handleMoreBlogs } from "./BlogAllFunctions";
import { scrollTopWindow, handleReadArticle } from "../../../helpers";
import { useHistory } from "react-router-dom";
import BlogAllView from "./BlogAllView";
import useMediaQuery from "@material-ui/core/useMediaQuery";

export function BlogAllContainer() {
  const { t } = useTranslation();
  const history = useHistory();
  const mediaQueryMd = useMediaQuery("(max-width:960px)");
  const [blogs, setBlogs] = useState([]);
  const [pages, setPages] = useState({ current_page: 1, to: 0, total: 0 });
  useEffect(() => {
    scrollTopWindow();
    fetchBlogs(setPages, setBlogs);
  }, [t]);

  return (
    <BlogAllView
      handleMoreBlogs={() => handleMoreBlogs(pages, blogs, setPages, setBlogs)}
      isMd={mediaQueryMd}
      blogs={blogs}
      pages={pages}
      handleReadArticle={(id) => handleReadArticle(id, history)}
    />
  );
}
