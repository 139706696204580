import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles(() => ({
  contentControls: {
    width: "100%",
    backgroundColor: "#F4F6F8",
    display: "flex",
    flexDirection: "row",
    "& .MuiSlider-rail": {
      color: "#FFFFFF",
    },
    "& .MuiSlider-track": {
      color: "#444C67",
    },
    "& .MuiSlider-thumb": {
      color: "#444C67",
    },
  },
  buttonsControls: {
    padding: 0,
    minWidth: "10px !important",

    "& img": {
      width: "15px",
      height: "15px",
    },
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  buttonStop: {
    marginRight: "30px",
  },

  buttonPlayAndPause: {
    marginRight: "17px",
  },
  musicControl: {
    display: "flex",
    alignItems: "center",
  },
  progressContent: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    "& >p": {
      margin: "0 10px",
      fontFamily: "Nunito Sans",
      fontWeight: 600,
      fontSize: "14px",
      color: "#444C67",
    },
  },
  volumeContent: {
    width: "10%",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    "&>button": {
      marginRight: "9px",
    },
  },

  slider: {
    root: {
      color: "#3f51b5",
      height: "5px",
      borderRadius: "10px",
    },
    rail: {
      color: "#FFFFFF",
    },
    track: {
      color: "#444C67",
    },
    thumb: {
      color: "#444C67",
    },
  },
  contentCard: (props) => {
    let style = {
      background: "#FFFFFF",
      borderRadius: "6px 6px 0px 0px",
      padding: "48px 52px",
      boxShadow:
        "-4px -4px 10px rgba(0, 0, 0, 0.05), 4px 4px 10px rgba(0, 0, 0, 0.05)",
      margin: "0px 0px 16px 0px",
    };

    if (props.isOpen) {
      style = {
        ...style,
        margin: 0,
      };
    }
    if (props.mediaQueryMd) {
      style = {
        ...style,
        padding: "24px 20px 24px 20px",
      };
    }

    return style;
  },
  buttonMultimedia: {
    width: "60px",
    height: "60px",
    marginRight: "24px",
    borderRadius: "50% !important",
    "&.MuiButton-containedPrimary": {
      backgroundColor: "#5D688E !important",
    },
  },
  contentAudioCard: (props) => {
    let style = {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      zIndex: 9999,
      "& p": {
        fontFamily: "Nunito Sans",
        fontSize: "14px",
        color: "#5D688E",
        margin: "0px 0px 4px 0px",
      },
      "& h1": {
        fontFamily: "Nunito Sans",
        fontWeight: 600,
        fontSize: "22px",
        color: "#444C67",
        margin: 0,
      },
    };
    if (props.mediaQueryMd) {
      style["& h1"].fontSize = "18px";
    }
    return style;
  },
  audioPlayerCard: (props) => {
    let style = {
      padding: "16px 48px",
      width: "auto !important",
      position: "absolute",
      top: "-59px",
      zIndex: -1,
      margin: 0,
    };
    if (props.isOpen) {
      style = {
        ...style,
        position: "relative",
        top: "0",
        zIndex: 1,
        margin: "0px 0px 16px 0px",
      };
    }
    if (props.mediaQueryMd) {
      style = {
        ...style,
        padding: "16px 24px",
      };
    }
    return style;
  },
}));
