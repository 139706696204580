import _ from 'lodash';
import moment from "moment";
import {
  getPrayerById,
  getCommunityPrayerByID,
  getCommentsPost,
  getIntentionsById,
  getAmen,
  postAmen,
  deleteAmen,
  postComments,
  postPrayersCommunity,
  putUserImaege,
} from "services/services";

export async function fetchPrayer(type, id, setPrayers, setError) {
  try {
    let prayer;
    switch (type) {
      case "POP":
        prayer = await fetchPopePrayer(id, type);
        break;
      case "INT":
        prayer = await fetchIntentionPrayer(id, type);
        break;
      case "CPY":
      default:
        prayer = await fetchCommunityPrayer(id, type);
        break;
    }
    setPrayers([prayer]);
  } catch (err) {
    setError({ open: true, name: err.name, message: err.message });
  }
}

async function fetchPopePrayer(id, type) {
  return Promise.all([
    getPrayerById(id),
    getCommentsPost(id, "POP"),
    getAmen(id, type),
  ]).then(([prayer, comments, amen]) => {
    prayer.totalComments = comments?.length;
    prayer.comments = comments?.sort(
      (a, b) =>
        new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
    );
    prayer.amen = { ...amen, owner: id, ownerType: type };
    return prayer;
  });
}
async function fetchIntentionPrayer(id, type) {
  return Promise.all([
    getIntentionsById(id),
    getCommentsPost(id, "INT"),
    getAmen(id, type),
  ]).then(([prayer, comments, amen]) => {
    prayer.ownerType = type;
    prayer.totalComments = comments?.length;
    prayer.comments = comments?.sort(
      (a, b) =>
        new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
    );
    prayer.amen = { ...amen, owner: id, ownerType: type };
    return prayer;
  });
}

async function fetchCommunityPrayer(id, type) {
  return Promise.all([
    getCommunityPrayerByID(id),
    getCommentsPost(id, type),
    getAmen(id, type),
  ]).then(([prayer, comments, amen]) => {
    prayer.comments = comments.length;
    prayer.comment = _.reverse(comments).slice(0, 2);
    prayer.amen = { ...amen, owner: id, ownerType: type };
    return prayer;
  });
}

export function handleFavorite(owner, ownerType) {
  postAmen({ owner, ownerType });
}

export function handleDislike(owner, ownerType) {
  deleteAmen({ owner, ownerType });
}

export async function handlePostCommunity(
  data,
  setIsSuccessPost,
  setPrayers,
  prayers,
  dispatch
) {
  const post = {
    description: data?.content,
    title: data?.title,
  };
  const imgPost = data.file;
  const msjError = data.text;

  const parseUploadImage = () =>
    ["image/png", "image/jpg", "image/jpeg"].includes(imgPost.type);

  try {
    let savedPost;

    if (imgPost) {
      if (!parseUploadImage()) throw new Error("invalid format image");
      savedPost = await postPrayersCommunity(post);
      const metadata = [
        {
          name: imgPost?.name,
          type: "IMG",
          owners: [{ owner: savedPost.id, ownerType: "CPY" }],
        },
      ];
      let formData = new FormData();

      formData.append("", imgPost);
      formData.append("metadata", JSON.stringify(metadata));
      await putUserImaege(formData);
    } else {
      savedPost = await postPrayersCommunity(post);
    }

    setIsSuccessPost(true);
    setTimeout(() => {
      setIsSuccessPost(false);
    }, 3000);

    const newPost = await getCommunityPrayerByID(savedPost.id);
    const updatedPrayers = [...prayers];
    updatedPrayers.unshift(newPost);
    setPrayers(updatedPrayers);
  } catch (error) {
    dispatch({
      type: "@modal/error",
      payload: {
        open: true,
        message: { name: msjError, messageError: "" },
      },
    });
  }
}

export function handlePostComments(
  userLogged,
  type,
  prayers,
  setPrayers,
  dispatch
) {
  let prop;
  switch (type) {
    case "POP":
      prop = "comments";
      break;
    case "CPY":
    default:
      prop = "comment";
  }
  return async function (values, prayer) {
    if (userLogged) {
      const commentSaved = await postComments({
        description: values.comment,
        prayer: prayer.id,
        type,
      });

      commentSaved.user = { ...userLogged.person, ...userLogged };
      commentSaved.person = commentSaved.user;
      commentSaved.prayer = { id: prayer.id, type };

      const updatedPrayers = [...prayers];
      const prayerInx = prayers.findIndex((p) => p.id == prayer.id);
      updatedPrayers[prayerInx][prop].unshift(commentSaved);
      setPrayers(updatedPrayers);
    } else {
      dispatch({
        type: "@modal/login",
        payload: { open: true },
      });
    }
  };
}

export function generateShareLink(type, prayer) {
  const prayerType = _.upperCase(type);
  const id = prayer.id;
  const title = prayer.title;
  const show_date = moment().format("YYYY-MM-DD");
  let url = window.location.origin;

  switch (prayerType) {
    case 'POP':
    case 'INT':
    case 'CPY':
      url += `/prayers/${_.lowerCase(prayerType)}.${id}/${_.kebabCase(title)}`;
      break;
    case 'MOR':
    case 'AFT':
    case 'EVE':
      url += `/daily/${_.lowerCase(prayerType)}.${id}/${_.kebabCase(prayer.translatedTitle)}/${prayer.prayerDate || show_date}`;
      break;
    case 'MON':
      url += '/monthly';
      break;
    default:
      url = window.location.href;
  }
  return url;
}
