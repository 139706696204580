import makeStyles from "@material-ui/core/styles/makeStyles";
import CommunityImage from "assets/img/Community_Home.svg";
import CommunityTabImage from "assets/img/CommunityTab.svg";

export const useStyles = makeStyles({
  communityContent: (props) => {
    let style = {
      backgroundSize: "cover",
      backgroundImage: `url(${CommunityImage})`,
      padding: "120px 130px 184px 111px",
    };
    if (props.isTabCommunity) {
      style = {
        ...style,
        backgroundColor: "rgb(244, 246, 248)",
        backgroundPosition: "center center",
        backgroundImage: `url(${CommunityTabImage})`,
        padding: "216px 130px 184px 111px",
      };
    }
    if (props.mediaQueryMd) {
      if (props.isTabCommunity)
        style = {
          ...style,
          padding: "100px 20px 150px 20px",
        };
      else
        style = {
          ...style,
          padding: "64px 20px 64px 20px",
        };
    }

    return style;
  },
  community: (props) => {
    let style = {
      display: "flex",
      flexDirection: "row",
      margin: "0 auto",
      maxWidth: "1200px",
      "& .communityColLeft": {
        paddingRight: "5em"
      },
    };
    if (props.mediaQueryMd) {
      style = {
        ...style,
        flexDirection: "column",
        "& .communityColLeft": {
          paddingRight: "0"
        },
      };
    }

    return style;
  },

  formContent: (props) => {
    let style = {
      width: "100%",
      "&>h3": {
        fontFamily: "Nunito Sans",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        letterSpacing: "0.1em",
        textTransform: "uppercase",
        color: "#5D688E",
        margin: "0px 0px 16px",
      },
      "&>h1": {
        fontFamily: "Libre Baskerville",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "44px",
        color: "#444C67",
        margin: "0px 0px 24px",
      },
      "&>p": {
        fontFamily: "Nunito Sans",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "16px",
        color: "#444C67",
        margin: "0px 0px 48px",
      },
    };

    if (props.mediaQueryMd) {
      style["&>h1"].fontSize = "28px";
      style["&>p"].margin = "0px 0px 16px";
    }
    return style;
  },

  input: (props) => {
    let style = {
      margin: "0px 0px 32px",
    };
    if (props.mediaQueryMd)
      style = {
        ...style,
        margin: "0px 0px 16px",
      };

    return style;
  },
  uploadImage: {
    "&>span": {
      "&>div": {
        display: "flex",
          "&>img": {
          margin: "0px 10.5px 0px 0px",
        },
      },
    },
  },
  form: {
    display: "flex",
    flexDirection: "column",
    aligItems: "center",
    justifyContent: "flex-start",
  },
  uploadImageContent: (props) => {
    let style = {
      display: "flex",
      flexDirection: "row",
      margin: "0px 0px 32px",
    };
    if (props.mediaQueryMd) {
      style = {
        ...style,
        flexDirection: "column",
        margin: "0px 0px 16px",
      };
    }

    return style;
  },
  file: (props) => {
    let style = {
      display: "flex",
      flexDirection: "rows",
      height: "45px",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      "&>p": {
        fontFamily: "Nunito Sans",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "14px",
        color: "#444C67",
        margin: "0px 20.51px 0px 0px",
      },
      "&>p:first-child": {
        margin: "0px 0px 0px 16px",
      },
      "&>img": {
        width: "15px",
        height: "15px",
        cursor: "pointer",
      },
    };
    if (props.mediaQueryMd) {
      style = {
        ...style,
        justifyContent: "flex-start",
      };
    }

    return style;
  },
  helper: {
    fontFamily: "Nunito Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    color: "#444C67",
  },
  singlePlayersList: (props) => {
    let style = {
      width: "100%",
      overflowY: "auto",
      scrollbarWidth: "none",
      // margin: "0px 0px  0px 128px",
      "&::-webkit-scrollbar": {
        "-webkit-appearance": "none",
      },
      "&>div": {
        "&>div": { width: "100% !important" },
      },
    };
    if (!props.isTabCommunity) {
      style = {
        ...style,
        height: "100%",
      };
    }
    if (props.mediaQueryMd) {
      style = {
        ...style,
        margin: "0px",
        height: "100%",
      };
    }

    return style;
  },
  singlePlayer: {
    margin: "0 0 24px 0",
    "& > div": {
      "& > div": {
        padding: "24px",
        "& > div": {
          padding: "0 !important",
        },
      },
    },
  },
  buttonPublish: (props) => {
    let style = {};
    if (props.mediaQueryMd) {
      style = {
        ...style,
        height: "48px",
        width: "100%",
        margin: "0px 0px 64px",
      };
    }

    return style;
  },
  buttonLoadMorePrayers: (props) => {
    let style = {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    };
    if (props.mediaQueryMd) {
      style = {
        ...style,
        margin: "64px 0px 0px ",
      };
    }
    return style;
  },
  selectLanguage: {
    width: "auto !important",
    "&>button": {
      width: "auto !important",
      padding: 0,
      "& img": {
        width: "9px",
        height: "9px",
        marginLeft: "13px",
      },
    },
  },

  selectLanguageContent: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    "&>div": {
      margin: "0px 0px  24px 0px",
      width: "100%",
      display: "flex",
      justifyContent: "flex-end",
    },
  },
  selectOptions: {
    minHeight: "330px",
    width: "auto !important",
  },
  successContent: {
    display: "flex",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      flexDirection: "column",
      "&>div:first-child": {
      display: "flex",
        "&>p": {
        fontFamily: "Nunito Sans",
          fontSize: "16px",
          color: "#5D688E",
          margin: "0px 0px 18px 10px ",
      },
      "&>svg": {
        fill: "#71AF7B",
          width: "20px",
          height: "20px",
      },
    },
    "&>div:last-child": {
      "&>p": {
        fontFamily: "Mulish",
          fontWeight: "bold",
          fontSize: "12px",
          color: "#71AF7B",
          margin: 0,
      },
    },
  },
});
