import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useStyles } from "./styles";
import { Modal } from "@material-ui/core";
import { parseUploadImage } from "./EditorImageFunctions";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import IconClose from "assets/icons/Close.svg";
import Icon from "components/Icon";
import Button from "components/Button";
import AvatarEditor from "react-avatar-editor";
import Slider from "@material-ui/core/Slider";
import lessIcon from "assets/icons/less.svg";
import moreIcon from "assets/icons/add.svg";

export function EditorImageStyled(props) {
  const { isMd, open, handleClose, handleEditImage, avatar } = props;
  const classes = useStyles({ mediaQueryMd: isMd });
  const refEditor = useRef();
  const { t } = useTranslation();
  const refUploadInput = useRef();
  const [scale, setScale] = useState(1);
  const [uploadImage, setUploadImage] = useState(null);
  const [failFormat, setFailFormat] = useState(false);

  return (
    <>
      <Modal
        open={open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        className={classes.editorImageModal}
        data-testid="editorImage_content"
      >
        <Fade in={open}>
          <div className={classes.editorImageContent}>
            <div className={classes.editorImage}>
              <div className={classes.closeIconContent}>
                <Icon
                  onClick={() => handleClose()}
                  icon={IconClose}
                  alt="close"
                  id="ico_editorImage_close_modal"
                />
              </div>
              <div className={classes.wrapperEditorImage}>
                <div className={classes.titleContent}>
                  <h1 data-testid="txt_title_editorImage">
                    {t("components.editorImage.Modify profile picture")}
                  </h1>
                </div>
              </div>
              <div className={classes.wrapperAvatarEditor}>
                <AvatarEditor
                  ref={refEditor}
                  image={uploadImage ? uploadImage : avatar}
                  width={151}
                  height={151}
                  border={50}
                  borderRadius={100}
                  color={[255, 255, 255, 0.6]} // RGBA
                  scale={scale}
                  rotate={0}
                  crossOrigin="anonymous"
                  style={{
                    width: "300px",
                    height: "300px",
                    backgroundColor: "grey",
                  }}
                />
              </div>
              <form>
                <div className={classes.scaleContent}>
                  <div>
                    <Button
                      id="btn_less"
                      type="transparent"
                      disabled={scale === 1}
                      icon={<Icon icon={lessIcon} />}
                      onClick={() => {
                        if (scale !== 1) setScale(scale - 0.1);
                      }}
                    />
                  </div>
                  <Slider
                    step={0.1}
                    min={1}
                    max={10}
                    data-testid="sld_EditorImage"
                    onChange={(e, value) => {
                      setScale(value);
                    }}
                    value={scale}
                  />
                  <div>
                    <Button
                      id="btn_more"
                      type="transparent"
                      icon={<Icon icon={moreIcon} />}
                      disabled={scale === 10}
                      onClick={() => {
                        if (scale !== 10) setScale(scale + 0.1);
                      }}
                    />
                  </div>
                </div>
                {failFormat && <div className={classes.mgeError}>
                  <p>{t('components.editorImage.use')}</p>
                </div>}
                <div className={classes.buttonsContent}>
                  <Button
                    disabled={failFormat}
                    id="btn_update_image"
                    type="primary"
                    text="pages.profile.Update"
                    onClick={() => {
                      handleEditImage(refEditor.current);
                    }}
                  />
                  <Button
                    id="btn_upload_image"
                    type="primary"
                    text="components.editorImage.Upload"
                    onClick={() => {
                      refUploadInput.current.click();
                    }}
                  />
                  <input
                    name="image"
                    accept=".png, .jpg, .jpeg"
                    onChange={(event) => {
                      if (event.target.files[0]) {
                        parseUploadImage(event.target.files[0], setUploadImage, setFailFormat);
                      }
                    }}
                    style={{ display: "none" }}
                    type="file"
                    ref={refUploadInput}
                  />
                </div>
              </form>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
}
