import React, { useState, useEffect } from "react";
import { useStyles } from "./styles";
import Button from "components/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import IconButton from "@material-ui/core/IconButton";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { FixedSizeList as List } from "react-window";
import { useTranslation } from "react-i18next";

export function SelectStyled(props) {
  const { t } = useTranslation();
  const {
    options = [],
    onChangeSelect,
    selected,
    icon,
    css,
    id,
    optionsCss,
    isSearch,
    onChangeSearch,
    isError,
    isSucess,
    onClickAwayListener,
  } = props;
  const [active, setActive] = useState(false);
  const [optionSelected, setOptionSelected] = useState(selected);
  const classes = useStyles({ isSearch: isSearch });

  useEffect(() => {
    setOptionSelected(selected);
  }, [selected]);
  function handleClickSelect(active) {
    setActive(!active);
  }
  function handleHideOptions() {
    setActive(false);
  }
  function handleOptionSelect(option) {
    setActive(false);
    setOptionSelected(option);
    onChangeSelect(option);
  }

  return (
    <ClickAwayListener
      onClickAway={() => {
        onClickAwayListener && active && onClickAwayListener(optionSelected);
        handleHideOptions();
      }}
    >
      <div
        id={id ? id : "cmb_language"}
        className={`${classes.selectBox} ${css}`}
      >
        <div className={classes.contentButtonSelected}>
          {(isError || isSucess) && (
            <IconButton
              className={`${classes.buttons} ${
                isError ? classes.error : classes.sucess
              }`}
              aria-label="icon_status"
            >
              {isError && <ErrorOutlineIcon />}
              {isSucess && <CheckCircleOutlineIcon />}
            </IconButton>
          )}
          <Button
            text={optionSelected 
              ? optionSelected?.display || optionSelected?.value 
              : selected?.display || selected?.value
            }
            onClick={() => handleClickSelect(active)}
            css={classes.select}
            iconRight={icon}
            id="btn_select"
          />
        </div>

        {active && (
          <div className={`${classes.options} ${optionsCss}`}>
            {isSearch && (
              <Paper
                classes={{
                  elevation1: classes.elevation,
                }}
                className={classes.contentSearch}
                component="div"
              >
                <div>
                  <InputBase
                    className={classes.inputSearch}
                    placeholder={t("components.select.Search")}
                    onChange={(event) => onChangeSearch(event)}
                    id="txt_search"
                  />
                  <IconButton
                    className={classes.buttonSearch}
                    aria-label="search"
                  >
                    <SearchIcon />
                  </IconButton>
                </div>
              </Paper>
            )}
            {50 > options.length ? (
              options.map((option, index) => (
                <div key={index} className={classes.contentOptions}>
                  <Button
                    id={`btn_select_option_${index}`}
                    onClick={() => handleOptionSelect(option)}
                    css={`
                      ${classes.select}
                    `}
                    text={option.display || option.value}
                    iconLeft={option.iconLeft ? option.iconLeft : null}
                    iconRight={option.iconRight ? option.iconRight : null}
                  />
                </div>
              ))
            ) : (
              <List
                height={150}
                itemCount={options.length}
                itemSize={40}
                width={382}
                className={classes.listOptions}
              >
                {({ index, style }) => {
                  return (
                    <div
                      style={{
                        ...style,
                        display: "flex",
                      }}
                      key={index}
                    >
                      <Button
                        id={`btn_select_option_${index}`}
                        onClick={() => handleOptionSelect(options[index])}
                        text={options[index].value}
                        css={`
                          ${classes.select}
                        `}
                      />
                    </div>
                  );
                }}
              </List>
            )}
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
}
