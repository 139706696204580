import { getPrayerSchoolTab } from "services/services";
import { parseSchoolsPrayer } from "helpers";
import moment from "moment";
export async function fetchGetPrayerSchoolTab(
  setCampaigns,
  setERosary,
  setProyects
) {
  try {
    const response = await getPrayerSchoolTab({
      date: moment().format("YYYY-MM-DD"),
    });

    const parseCampaign =
      response?.campaign.length > 0
        ? response?.campaign.map((elementCampaign) =>
            parseSchoolsPrayer(elementCampaign)
          )
        : [];
    const parseProject =
      response?.learn.length > 0
        ? response?.learn.map((elementProject) =>
            parseSchoolsPrayer(elementProject)
          )
        : [];
    const parseRosary =
      response?.rosary.length > 0
        ? response?.rosary.map((elementRosary) =>
            parseSchoolsPrayer(elementRosary)
          )
        : [];
    setCampaigns(parseCampaign);
    setERosary(parseRosary);
    setProyects(parseProject);
  } catch (error) {
    console.log(error);
  }
}
