import React from "react";
import { useStyles } from "./styles";
import ListCard from "components/Cards/ListCard";

export function PrayWeekStyled(props) {
  const {
    intentionDate,
    isMd,
    isSm,
    day,
    dayNumber,
    month,
    prayers,
    handleClickPrayCard,
  } = props;
  const classes = useStyles({
    mediaQueryMd: isMd,
    mediaQuerySm: isSm,
  });

  return (
    <div data-testid={"pray_week"} className={classes.contentPrayWeek}>
      {!isMd && (
        <div id="txt_title_pray_week" className={classes.contentWeek}>
          <h1 data-testid="txt_title_pray_week">{dayNumber}</h1>
          <div>
            <p data-testid="txt_day_pray_week">{day}</p>
            <p data-testid="txt_month_pray_week">{month}</p>
          </div>
        </div>
      )}
      <div className={classes.contentList}>
        <ListCard
          handleClickPrayCard={handleClickPrayCard}
          isSmall={isMd}
          list={prayers?.map(prayer => ({...prayer, intentionDate}))}
        />
      </div>
    </div>
  );
}
