import makeStyles from "@material-ui/core/styles/makeStyles";
import ImageDefault from "assets/img/Logo_simple.svg";
export const useStyles = makeStyles({
  contentBlogCard: (props) => {
    let style = {
      width: "600px",
      height: "280px",
      background: "#FFFFFF",
      boxShadow:
        "-4px -4px 10px rgba(0, 0, 0, 0.05), 4px 4px 10px rgba(0, 0, 0, 0.05)",
      borderRadius: "6px",
    };
    if (props.reverse) {
      style = {
        ...style,
        flexDirection: "row-reverse",
      };
    }
    if (props.mediaQueryMd) {
      style = {
        ...style,
        flexDirection: "none",
        height: "100%",
      };
    }
    return style;
  },

  contentImage: (props) => {
    let style = {
      "&>div": {
        backgroundColor: "grey",
        width: "100%",
        height: "100%",
        backgroundImage: `url(${ImageDefault})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "10%",
        "&>img": {
          width: "100%",
          height: "100%",
          objectFit: "cover",
          backgroundSize: "cover",
          backgroundPosition: "center",
        },
      },
      height: "100%",
      borderRadius: "6px 0px 0px 6px",
      overflow: "hidden",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
    };
    if (props.reverse) {
      style = {
        ...style,
        borderRadius: "0px 6px 6px 0px",
      };
    }
    if (props.mediaQueryMd) {
      style = {
        ...style,
        height: "180px",
        "&>img": {
          ...style["&>img"],
          width: "100%",
        },
        borderRadius: "6px 6px 0px 0px",
      };
    }
    return style;
  },
  contentTextBlog: (props) => {
    let style = {
      padding: "36px 40px",
    };
    if (props.mediaQueryMd) {
      style = {
        ...style,
        padding: "24px",
      };
    }
    return style;
  },
  contentText: () => {
    return {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "center",
      flexDirection: "column",

      "&>p:first-child": {
        fontFamily: "Nunito Sans",
        fontSize: "12px",
        color: "#5D688E",
        margin: "0px 0px 8px 0px",
      },
      "&>h1": {
        display: "-webkit-box",
        "-webkit-line-clamp": 2,
        "-webkit-box-orient": "vertical",
        overflow: "hidden",
        fontFamily: "Nunito Sans",
        fontSize: "22px",
        color: "#444C67",
        fontWeight: 600,
        margin: "0px 0px 16px 0px",
      },
    };
  },
  descriptionBlog: (props) => {
    return {
      display: "-webkit-box",
      "-webkit-line-clamp": props.lineDescription,
      "-webkit-box-orient": "vertical",
      overflow: "hidden",
      fontFamily: "Nunito Sans",
      fontSize: "16px",
      color: "#444C67",
      margin: "0px 0px 13.5px 0px",
    };
  },
});
