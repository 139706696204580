import React from "react";
import { capitalize } from "lodash";
import { useTranslation } from "react-i18next";
import { useStyles } from "./styles";
import { Paper, Grid } from "@material-ui/core";
import Button from "components/Button";
import Icon from "components/Icon";
import SocialSection from "../../SocialSection";
import { CAMPAIGN_PAGE } from "../../../navigation/CONSTANTS";

export function CampaignPrayerStyled(props) {
  const { t } = useTranslation();
  const {
    isMd,
    isSm,
    prayer = {},
    index = 0,
    captionDay,
    iconMoment,
    codeMoment,
    codeCampaign,
    openWindowLink,
    indexCampaign,
    campaignItem 
  } = props;

  const classes = useStyles({
    mediaQueryMd: isMd,
    mediaQuerySm: isSm,
    codeCampaign,
  });

  return (
    <Paper
      className={classes.gral}
      data-testid={`card_prayer_campaign_${index}`}
    >
      <Grid container direction="row">
        <Grid item className={classes.imgCard}>
          <div
            className="img"
            style={{
              backgroundImage: `url("${prayer.multimedia?.[0]?.url}")`,
            }}
          >
            {isMd && (
              <img
                src={prayer.multimedia?.[0]?.url}
                data-testid={`img_prayer_${index}`}
              />
            )}
          </div>
        </Grid>
        <Grid item className={classes.contentCard}>
          <Grid
            container
            direction="column"
            justify="space-between"
            className={classes.contain}
          >
            <div data-testid={`txt_prayer_${index}_suggest_for`}>
              <h6 className={classes.daySpan}>
                {!isMd && iconMoment && (
                  <Icon
                    data-testid={`ico_prayer_${index}`}
                    icon={iconMoment}
                    alt={prayer.moment}
                  />
                )}
                <span className="day">{`${captionDay}`}</span>
                {codeCampaign !== "TIP" && codeCampaign !== "ROT" && (
                  <span>
                    {` - ${t("pages.campaign.prayer of", {
                      time: capitalize(
                        t(`pages.campaign.dayMoment.${codeMoment}`)
                      ),
                    })}`}
                  </span>
                )}
              </h6>
            </div>
            <div
              className={`middle ${classes.gradiantScroll} ${classes.scrolled}`}
            >
              <div>
                <h3
                  data-testid={`txt_prayer_${index}_title`}
                  id={`campaign${index}`}
                >
                  {prayer.title}
                </h3>
                <p
                  data-testid={`txt_prayer_${index}_description`}
                  dangerouslySetInnerHTML={{ __html: prayer.description }}
                />
                {prayer?.hashtag && (
                  <>
                    <Button
                      text={t("pages.campaign.More")}
                      variant="text"
                      id={`btn_prayer_${index}_link`}
                      onClick={() => openWindowLink(prayer.hashtag)}
                    />
                  </>
                )}
              </div>
            </div>
            <div>
                <div className={classes.redes}>
                  <div>
                  </div>
                  <div>
                    <SocialSection
                      title={prayer.title}
                      imageUrl={prayer.multimedia?.[0]?.url || ''}
                      description={prayer?.description || ''}
                      size={40}
                      mt={0.5}
                      url={
                        (indexCampaign || indexCampaign === 0) && campaignItem
                          ? window.location.origin + `${CAMPAIGN_PAGE}/${campaignItem.id}/${indexCampaign}`
                          : window.location.href
                      }
                    />
                  </div>
                </div>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}
