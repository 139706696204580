import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles({
  root: {
    padding: "0.75em 1em",
    boxShadow: "none",
    borderRadius: "0.375em",
    width: "max-content",
    minWidth: "max-content",
    "&>button": {
      marginLeft: 7,
    },
  },
});
