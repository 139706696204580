import React from "react";
import { Avatar, Grid } from "@material-ui/core";
import PhotoPope from "assets/icons/Pope_photo.svg";
import Button from "components/Button";
import Icon from "components/Icon";
import VideoIcon from "assets/icons/Video.svg";
import FireIcon from "assets/icons/Fire.svg";
import DoveIcon from "assets/icons/Dove.svg";
import ArrowIcon from "assets/icons/Arrow Right.svg";
import { useStyles } from "./styles";

import { useTranslation } from "react-i18next";

export function PopeCardStyled(props) {
  const { t } = useTranslation();
  const {
    description,
    video,
    month,
    handleVideo,
    handlePrayer,
    handleAttitudes,
    isSmall,
    id,
    idTitle,
    idDescription,
    idAvatar,
  } = props;

  const buttons = [
    {
      iconLeft: VideoIcon,
      iconRight: ArrowIcon,
      altIconLeft: "Video",
      altIconRight: "Arrow",
      text: "components.popeCard.The Pope Video",
      id: `btn_pope_card_video`,
      handleClick: handleVideo,
    },
    {
      iconLeft: FireIcon,
      iconRight: ArrowIcon,
      altIconLeft: "Fire",
      altIconRight: "Arrow",
      text: t("components.popeCard.{{month}} Prayer", { month }),
      id: `btn_pope_card_prayer`,
      handleClick: handlePrayer,
    },
    {
      iconLeft: DoveIcon,
      iconRight: ArrowIcon,
      altIconLeft: "Dove",
      altIconRight: "Arrow",
      text: "components.popeCard.Attitudes for the month",
      id: `btn_pope_card_attitudes`,
      handleClick: handleAttitudes,
    },
  ];

  const classes = useStyles({ mediaQuerySm: isSmall });
  return (
    <Grid
      data-testid={`crd_pope_container`}
      className={classes.contentCard}
      container
      id={id ? id : "crd_pope_container"}
    >
      <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
        <div className={classes.description}>
          {isSmall ? (
            <>
              <div id={idTitle ? idTitle : "txt_content_title"}>
                <Avatar
                  id={idAvatar ? idAvatar : "ico_pope"}
                  className={classes.avatar}
                  src={PhotoPope}
                />
              </div>
              <div className={classes.scroll}>
                <div className={classes.contentScroll}>
                  <p data-testid={`txt_description`}>{description}</p>
                </div>
              </div>
            </>
          ) : (
            <>
              <div>
                <Avatar
                  id={idAvatar ? idAvatar : "ico_pope"}
                  className={classes.avatar}
                  src={PhotoPope}
                />
              </div>
              <div
                id={idTitle ? idTitle : "txt_content_title"}
                className={classes.scroll}
              >
                <div className={classes.contentScroll}>
                  <p
                    id={
                      idDescription ? idDescription : "txt_content_description"
                    }
                    data-testid={
                      idDescription ? idDescription : "txt_content_description"
                    }
                  >
                    {description}
                  </p>
                </div>
              </div>
            </>
          )}
        </div>
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
        <div className={classes.contentButtons}>
          {buttons.map(
            (
              {
                iconLeft,
                iconRight,
                altIconLeft,
                altIconRight,
                text,
                id,
                handleClick,
              },
              index
            ) => {
              if (text === "components.popeCard.The Pope Video") {
                return (
                  video && (
                    <Button
                      key={index}
                      iconLeft={<Icon icon={iconLeft} alt={altIconLeft}></Icon>}
                      iconRight={
                        <Icon icon={iconRight} alto={altIconRight}></Icon>
                      }
                      type="cuaternary"
                      text={text}
                      css={classes.button}
                      onClick={handleClick}
                      id={id}
                    ></Button>
                  )
                );
              } else {
                return (
                  <Button
                    key={index}
                    iconLeft={<Icon icon={iconLeft} alt={altIconLeft}></Icon>}
                    iconRight={
                      <Icon icon={iconRight} alto={altIconRight}></Icon>
                    }
                    type="cuaternary"
                    text={text}
                    css={classes.button}
                    onClick={handleClick}
                    id={id}
                  ></Button>
                );
              }
            }
          )}
        </div>
      </Grid>
    </Grid>
  );
}
