import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles({
  contentAudioPlayer: (props) => {
    let style = {
      background: "#F4F6F8",
      borderBottomRightRadius: props.audio ? "6px" : "0px",
      borderBottomLeftRadius: props.audio ? "6px" : "0px",
      padding: props.audio ? "16px 48px 16px" : "16px 40px 0px",
    };
    if (props.mediaQuerySm) {
      style.padding = "0px 24px 0px 24px";
    }
    return style;
  },
  contentSinglePrayer: (props) => {
    let style = {
    width: "800px",
    outline: "none",
    }
    if (props.isModal) {
      style.maxWidth = "95%";
    }
    return style;
  },
  singlePrayerContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflowY: "auto",
  },
  singlePrayer: (props) => {
    let style = {
      borderRadius: "6px",
      borderBottomRightRadius: props.audio ? "0px" : "6px",
      borderBottomLeftRadius: props.audio ? "0px" : "6px",
      borderTopRightRadius: "6px",
      borderTopLeftRadius: "6px",
      background: " #FFFFFF",
      padding: "24px 24px 0px 24px",
      filter:
        "drop-shadow(-4px -4px 10px rgba(0, 0, 0, 0.05)), drop-shadow(4px 4px 10px rgba(0, 0, 0, 0.05))",
    };

    if (props.mediaQuerySm) {
      style.padding = props.audio
        ? "24px 20px 16px 20px"
        : "24px  20px 0px 20px";
    }
    return style;
  },
  wrapperSinglePrayer: (props) => {
    let style = {
      padding: props.isModal ? "0px 24px 46px 24px" : "24px 24px 46px 24px",
    };
    if (props.mediaQuerySm)
      style.padding = props.audio ? "0px 0px 0px 0px" : "0px 0px 24px 0px";

    return style;
  },
  paragraph: {
    whiteSpace:"nowrap",
    //overFlow: "hidden",
    display: "block",
    wordWrap: "break-word",
  },
  closeIconContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    "& >img": {
      cursor: "pointer",
    },
  },
  textHoverContent: {
    display: "flex",
    alignItems: "center",
    margin: "0 0 0.25em",
    "& >img": {
      marginRight: "0.5625em",
    },
    "& >p": {
      fontFamily: " Nunito Sans",
      fontSize: "0.875em",
      color: "#5D688E",
      margin: 0,
    },
  },
  titleContent: {
    "& >h1": {
      fontFamily: "Nunito Sans",
      fontWeight: 600,
      fontSize: "1.375em",
      color: "#444C67",
      margin: 0,
    },
  },
  contentTitleAndSubtitle: {
    display: "flex",
    marginBottom: "14px",
  },
  buttonMultimedia: {
    width: "60px",
    height: "60px",
    marginRight: "1.5em",
    borderRadius: "50% !important",
    "&.MuiButton-containedPrimary": {
      backgroundColor: "#5D688E !important",
    },
  },
  contentSentence: (props) => {
    let style = {
      width: "100%",
      height: "193px",
      "&>div": {
        "&>div": {
          scrollbarWidth: "thin",
          scrollbarColor: "#797979 white",

          "&::-webkit-scrollbar": {
            "-webkit-appearance": "none",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#797979",
            borderRadius: "10px",
            border: "2px solid #f1f2f3",
            outline: "none",
          },
          "&::-webkit-scrollbar-track": {
            borderRadius: "10px",
          },
          "&::-webkit-scrollbar:vertical": {
            width: "10px",
          },
          "&::-webkit-scrollbar-button:increment,.contenedor::-webkit-scrollbar-button": {
            display: "none",
          },
        },
      },
    };
    if (props.isTotalDescription) {
      style.height = "100%";
    }
    if (props.isModal) {
      style.maxWidth = "100%";
      if (props.mediaQuerySm) {
        style.height = "56vh";
      }
      if (props.audio) {
        style.height = "46vh";
      }
    }
    if (props.mediaQuerySm && props.isDaily) {
      style.height = '300px';
    }
    return style;
  },
  sentence: (props) => {
    let style = {
      fontFamily: "Nunito Sans",
      fontSize: "1em",
      color: "#444C67",
      lineHeight: "24px",
      margin: "10px 0px 0px",
      paddingRight: "15px",
      paddingBottom: "8px",
      whiteSpace: "pre-line",
      overflow: "hidden",
    };
    if (props.isPopePrayer) {
      style = {
        ...style,
        margin: "8px 0px 16px",
      };
    }
    return style;
  },
  actionsSinglePrayerContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "24px 0px 0px 0px",
  },
  buttonFavorite: {
    padding: "8px 20px",
    "& div": {
      display: "flex",
      "& img:first-child": {
        marginRight: "0.60375em",
      },
    },
  },
  buttonSocialMedia: {
    minWidth: "0px !important",
    margin: "0px 0px  0px 28px",
    padding: 0,
    "& >span": {
      "& >img": {
        width: "1.25em",
        height: "1.25em",
      },
    },
  },
  offeringContent: {
    marginTop: "20px",
  },
  popeHeader: {
    display: "flex",
    alignItems: "center",
    "& div": {
      "& div": {
        "& img": {
          width: "48px",
          height: "48px",
        },
      },
      "& > p:first-child": {
        padding: "0 0 0 16px",
        height: "24px",
        top: "24px",
        fontFamily: "Nunito Sans",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "16px",
        lineHeight: "24px",
        color: "#444C67",
        margin: 0,
      },
      "& > p:last-child": {
        padding: "0 0 0 16px",
        height: "16px",
        top: "52px",
        fontFamily: "Nunito Sans",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "12px",
        lineHeight: "16px",
        color: "#5D688E",
        margin: "4px 0 0 0",
      },
    },
  },
  popeTitle: {
    fontFamily: "Nunito Sans",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "24px",
    color: "#444C67",
    margin: "2px 0px 0px 0px",
    wordBreak:"break-word"
  },
  video: {
    height: "300px !important",
    width: "100% !important",
    margin: "2px 0px 14px 0px !important",
    outline: "none",
    "& iframe": {
      border: "none",
    },
  },
  avatar: {
    width: "48px",
    height: "48px",
  },
  header: {
    width: "100%",
    display: "flex",
    aligItems: "center",
    justifyContent: "space-between",
  },
  iconMore: {
    cursor: "pointer",
  },
  iconMoreComment: {
    padding: "0 !important",
  },
  contentButtonsReport: {
    display: "flex",
    flexDirection: "column",
  },
  buttonComments: (props) => {
    let style = {
      "&>span": {
        "&>div": {
          display: "flex",
          flexDirection: "rows",
          "&>img": {
            margin: "0px 11px 0px 0px",
          },
        },
      },
    };
    if (props.mediaQuerySm) {
      style["&>span"]["&>div"]["&>img"].margin = "0px";
    }

    return style;
  },
  contentComments: {
    margin: "16px 0px 0px",
  },
  comment: {
    boxShadow: "0px -1px 0px #E7ECF0",
  },
  commentHeader: {
    display: "flex",
    flexDirection: "rows",
    aligItems: "center",
    justifyContent: "space-between",
    padding: "24px 0px 0px 0px",
    margin: "0px 0px 8px 0px",
    "&>div:first-child": {
      "&>h3": {
        fontFamily: "Nunito Sans",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "14px",
        color: "#444C67",
        margin: 0,
      },
    },
    "&>div:last-child": {
      display: "flex",
      "&>p": {
        fontFamily: "Nunito Sans",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "12px",
        color: "#444C67",
      },
      "&>p:first-child": {
        margin: "0px 24px 0px 0px",
      },
      "&>p:last-child": {
        cursor: "pointer",
        margin: "0px",
        "&:hover": {
          textDecoration: "underline",
        },
      },
    },
  },
  commentBody: {
    "&>p": {
      fontFamily: "Nunito Sans",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        color: "#444C67",
        margin: "0px 0px 24px 0px",
        wordBreak: "break-word",
        whiteSpace: "break-spaces",
        maxWidth:"580px",
        paddingRight:"15px",
    },
  },
  formComment: {
    padding: "24px 0px 0px 0px",
    "&>div:last-child": {
    display: "flex",
      aligItems: "center",
      justifyContent: "flex-end",
      margin: "0px 0px 24px 0px",
    },
  },
  input: {
    margin: "0px 0px 40px 0px",
  },
  buttonContent: {
    display: "flex",
  },
  postImage: {
    objectFit: "contain",
    width: "100%",
  },
  postImageBox: {
    maxWidth: "800px",
  },
  buttonDeletePrayer: {
    "& span": {
      color: "#ff4444 !important",
    },
  },
  nameUserPost: {
    height: "max-content !important",
  },
});
