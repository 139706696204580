import React from "react";
import { useStyles } from "./styles";
import { Modal } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import IconClose from "assets/icons/Close.svg";
import Icon from "components/Icon";
import Button from "components/Button";

export function SignUpSuccessStyled(props) {
  const { open, isMd, handleClose, navigationLogin } = props;
  const { t } = useTranslation();
  const classes = useStyles({
    mediaQueryMd: isMd,
  });
  return (
    <>
      <Modal
        open={open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        className={classes.SignUpSuccessModal}
        data-testid="signUpSuccess_content"
      >
        <Fade in={open}>
          <div className={classes.SignUpSuccessContent}>
            <div className={classes.SignUpSuccess}>
              <div className={classes.closeIconSignUpSuccess}>
                <Icon
                  onClick={() => handleClose()}
                  icon={IconClose}
                  alt="close"
                  id="ico_signUpSuccess_close_modal"
                />
              </div>
              <div className={classes.wrapperSignUpSuccess}>
                <div className={classes.titleContentSignUpSuccess}>
                  <h1 data-testid="txt_title_signUpSuccess">
                    {t(`components.signUp.¡Welcome to our Community!`)}
                  </h1>
                  <p data-testid="txt_description_signUpSuccess">
                    {t(
                      `components.signUp.Your user has been succesfully created. Please, check your email inbox to validate your account.`
                    )}
                  </p>
                </div>
                <div className={classes.contentButtonsSignUpSuccess}>
                  <Button
                    data-testid="btn_signUpSuccess_login"
                    id="btn_signUpSuccess_login"
                    text="components.signUp.Go to login"
                    type="primary"
                    onClick={() => navigationLogin()}
                  />
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
}
