import React from "react";
import { useStyles } from "./styles";
import { Modal } from "@material-ui/core";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import IconClose from "assets/icons/Close.svg";
import Icon from "components/Icon";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";

export function MessageErrorsStyled(props) {
  const { open, isMd, handleClose, messageError, title, messageIcon } = props;

  const classes = useStyles({
    mediaQueryMd: isMd,
  });
  return (
    <>
      <Modal
        open={open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        className={classes.messageErrorsModal}
        data-testid="messageErrors_content"
      >
        <Fade in={open}>
          <div className={classes.messageErrorsContent}>
            <div className={classes.messageErrors}>
              <div className={classes.closeIconContent}>
                <Icon
                  onClick={() => handleClose()}
                  icon={IconClose}
                  alt="close"
                  id="ico_messageErrors_close_modal"
                />
              </div>
              <div className={classes.wrapperMessageErrors}>
                <div className={classes.titleContent}>
                  <div>
                    {messageIcon || <ErrorOutlineIcon />}
                    <h1 data-testid="txt_title_messageErrors">{title}</h1>
                  </div>
                  <p data-testid="txt_description_messageErrors">
                    {messageError}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
}
