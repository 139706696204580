/* eslint-disable */
import PrayerMorningIcon from "assets/icons/PrayerMorning.svg";
import PrayerEveningIcon from "assets/icons/PrayerEvening.svg";
import PrayerAfternoonIcon from "assets/icons/PrayerAfternoon.svg";
import PrayerPopeIcon from "assets/icons/Pope.svg";
import PrayerMonthIcon from "assets/icons/PrayerMonth.svg";
import PrayAfternoonIcon from "assets/icons/Pray-1.svg";
import PrayEveningIcon from "assets/icons/Pray.svg";
import PrayMorningIcon from "assets/icons/Pray-2.svg";
import RosaryAfternoon from "assets/icons/RosaryAfternoon.svg";
import RosaryEvening from "assets/icons/RosaryEvening.svg";
import RosaryMorning from "assets/icons/RosaryMorning.svg";
import ClandeMorning from "assets/icons/Candle-2.svg";
import ClandeAfternoon from "assets/icons/Candle-1.svg";
import ClandeEvening from "assets/icons/Candle.svg";
import LogoLightEn from "assets/img/CTP_Light_EN.svg";
import LogoLightEs from "assets/img/CTP_Light_SP.svg";
import LogoLightDE from "assets/img/CTP_Light_DE.svg";
import LogoLightIT from "assets/img/CTP_Light_IT.svg";
import LogoLightPT from "assets/img/CTP_Light_PT.svg";
import LogoLightZH from "assets/img/CTP_Light_ZH.svg";
import LogoLightFR from "assets/img/CTP_Light_FR.svg";
import LogoDarkEn from "assets/img/CTP_Dark_EN.svg";
import LogoDarkEs from "assets/img/CTP_Dark_SP.svg";
import LogoDarkDE from "assets/img/CTP_Dark_DE.svg";
import LogoDarkIT from "assets/img/CTP_Dark_IT.svg";
import LogoDarkPT from "assets/img/CTP_Dark_PT.svg";
import LogoDarkZH from "assets/img/CTP_Dark_ZH.svg";
import LogoDarkFR from "assets/img/CTP_Dark_FR.svg";
import { matchSorter } from "match-sorter";
import numeral from "numeral";
import "numeral/locales/es";
import "numeral/locales/en-au";
import "numeral/locales/chs";
import "numeral/locales/it";
import "numeral/locales/fr";
import "numeral/locales/pt-pt";
import { getI18n } from "react-i18next";
import moment from "moment";
import { countrysPath } from "countrys";

import {
  deleteAmen,
  editCommentsPost,
  getCommentsPost,
  getCountries,
  getMapFriends,
  getPostsFacebook,
  getPrayersCommunity,
  postAmen,
  postComments,
  postPrayersCommunity,
  putUserImaege,
  reportCommentsPost,
  reportPosts
} from "services/services";
import { logout } from "services/authService";

export function backgrounds(multimedia, comparisonMultimedia) {
  let image = multimedia?.find(
    (img) => img?.type?.code === comparisonMultimedia
  );
  if (!image)
    return {
      url: null,
      color: "gray"
    };
  else return { color: null, url: image?.url };
}
export function handleMultimedia(audio, type, audioplay) {
  switch (type) {
    case "stop":
      audio.current.pause();
      audio.current.currentTime = 0;
      return false;
    case "button":
      return !audioplay;
    case "cancel":
      return false;
    default:
      audio.current.paused ? audio.current.play() : audio.current.pause();
      return !audio.current.paused;
  }
}
export function parsePrayer(prayer) {
  let parsedPrayer = {};
  switch (prayer.type.description) {
    case "Pope":
      if (
        prayer.nextPrayer &&
        moment(moment()).isAfter(prayer.nextPrayer.show_date)
      ) {
        prayer = prayer.nextPrayer; // esto para hackear el cache de 1hr.
      }
      parsedPrayer = {
        icon: PrayerPopeIcon,
        title: prayer?.title,
        type: "components.prayCard.Pope Francis’ Request",
        typeMode: "pope",
        textHover: "components.prayCard.Special Request",
        id: prayer?.id,
        typeForAmen: prayer?.type.code,
        groupData: prayer?.groupData
      };
      break;

    case "Morning":
      parsedPrayer = {
        icon: PrayerMorningIcon,
        title: "components.prayCard.With Jesus in the morning",
        type: "components.prayCard.Start your day",
        typeMode: "morning",
        textHover: "components.prayCard.Morning Prayer",
        typeForAmen: prayer?.type.code,
        groupData: prayer?.groupData
      };
      break;

    case "Monthly":
      parsedPrayer = {
        icon: PrayerMonthIcon,
        title: prayer?.title,
        type: "components.prayCard.Month’s Prayer",
        typeMode: "monthly",
        textHover: "components.prayCard.Monthly Prayer",
        typeForAmen: prayer?.type.code,
        groupData: prayer?.groupData
      };
      break;

    case "Afternoon":
      parsedPrayer = {
        icon: PrayerAfternoonIcon,
        title: "components.prayCard.With Jesus in the Afternoon",
        type: "components.prayCard.Take a break",
        typeMode: "afternoon",
        textHover: "components.prayCard.Afternoon Prayer",
        typeForAmen: prayer?.type.code,
        groupData: prayer?.groupData
      };
      break;

    case "Evening":
      parsedPrayer = {
        icon: PrayerEveningIcon,
        title: "components.prayCard.With Jesus at night",
        type: "components.prayCard.Contemplate and rest",
        typeMode: "evening",
        textHover: "components.prayCard.Evening Prayer",
        typeForAmen: prayer?.type.code,
        groupData: prayer?.groupData
      };

      break;
    case "Offering":
      parsedPrayer = {
        icon: null,
        title: prayer?.title,
        type: null,
        typeMode: "offering",
        textHover: "components.prayCard.Offering Prayer",
        typeForAmen: prayer?.type.code
      };

      break;
  }

  parsedPrayer.id = prayer?.id;
  parsedPrayer.description = prayer?.description;
  parsedPrayer.audio = prayer?.multimedia?.find((m) => m.type?.code === "AUD");
  return parsedPrayer;
}
export function handleReadArticle(id, history) {
  history.push(`/articles/${id}`);
}
export function scrollTopWindow() {
  window.scroll(0, 0);
}
export function toHome() {
  return window.location.replace("/");
}
export function parseSchoolsPrayer(SchoolPrayer) {
  let dateNow = moment().format("YYYY-MM-DD");
  const iconPack = getIcon(SchoolPrayer);
  let R;
  switch (SchoolPrayer?.type?.code) {
    case "SCH":
      R = {
        ...SchoolPrayer,
        typeCampaigns: SchoolPrayer.type,
        type: "pages.home.Campaign",
        date: {
          description: moment(dateNow).isAfter(SchoolPrayer.date_from)
            ? "pages.home.Started {{date}}"
            : "pages.home.Start {{date}}",
          moment: moment(SchoolPrayer.date_from).format("l")
        }
      };
      break;
    case "UNS":
      R = {
        ...SchoolPrayer,
        typeCampaigns: SchoolPrayer.type,
        type: "pages.home.Campaign"
      };
      break;
    case "TIP":
      R = {
        ...SchoolPrayer,
        typeCampaigns: SchoolPrayer.type,
        type: "pages.schoolPrayer.Tips for your prayer"
      };
      break;
    case "PRO":
      R = {
        ...SchoolPrayer,
        typeCampaigns: SchoolPrayer.type,
        type: "pages.schoolPrayer.Project"
      };
      break;
    case "ROS":
    default:
      R = {
        ...SchoolPrayer,
        typeCampaigns: SchoolPrayer.type,
        type: "Click To Pray eRosary"
      };
      break;
  }

  R.icon = iconPack.icon;
  R.textHover = iconPack.hover;
  R.typeMode = iconPack.mode;

  return R;
}

const campaignIcons = {
  SCH: {
    MOR: PrayMorningIcon,
    AFT: PrayAfternoonIcon,
    EVE: PrayEveningIcon
  },
  UNS: {
    MOR: PrayMorningIcon,
    AFT: PrayAfternoonIcon,
    EVE: PrayEveningIcon
  },
  TIP: {
    MOR: ClandeMorning,
    AFT: ClandeAfternoon,
    EVE: ClandeEvening
  },
  ROS: {
    MOR: RosaryMorning,
    AFT: RosaryAfternoon,
    EVE: RosaryEvening
  },
  ROT: {
    MOR: RosaryMorning,
    AFT: RosaryAfternoon,
    EVE: RosaryEvening
  }
};

const campaignText = {
  MOR: {
    hover: "pages.home.Suggested for Morning",
    mode: "morning"
  },
  AFT: {
    hover: "pages.home.Suggested for Afternoon",
    mode: "afternoon"
  },
  EVE: {
    hover: "pages.home.Suggested for Evening",
    mode: "evening"
  }
};

function getIcon(campaign) {
  let iconProject = campaign.multimedia?.find(
    (image) => image?.type?.code === "IMG_ICON_CAMPAIGN"
  )?.url;
  const campMoment = campaign.moment?.code;
  const campType = campaign.type?.code;
  if (!iconProject) {
    iconProject = campaignIcons[campType]?.[campMoment];
  }
  return {
    icon: iconProject,
    hover: campaignText[campMoment]?.hover,
    mode: campaignText[campMoment]?.mode
  };
}
export function navigateSchoolsPrayers(Campaings, history) {
  switch (Campaings?.typeCampaigns?.code) {
    case "ROS":
      history.push(`/eRosary/${Campaings.id}`);
      break;
    case "ROT":
      history.push(`/campaigns/${Campaings.id}`);
      break;
    default:
      history.push(`/campaigns/${Campaings.id}`);
      break;
  }
}
export function handlerBackToSchoolPrayer(history) {
  history.push("/school-prayer");
}

export function handlerBackToSection(history, section) {
  history.push(section);
}
export function onChangeSearch(elements, event) {
  return matchSorter(elements, event.target.value, { keys: ["value"] });
}
export function parseMysterie(mysterie, dayNumber) {
  let mysteriesUpdate = mysterie;
  //let indexMultimediaDay = mysterie.days.indexOf(dayNumber);

  numeral.locale(localeNumeralCountry(getI18n().language));

  let updateMysterie = mysteriesUpdate.mysteries.map((mysterieElement) => {
    return {
      ...mysterieElement,
      order: numeral(mysterieElement.order).format("0o"),
      subtitle: mysterie?.title,
      audio: {
        isPlay: false,
        src: mysterieElement.multimedia.find(
          (audio) => audio.type.code === "AUD"
        )?.url
      }
    };
  });

  mysteriesUpdate.mysteries = updateMysterie;

  mysteriesUpdate.image = mysteriesUpdate.multimedia.find(
    (image) => image.type.code === "IMG"
  )?.url;

  return mysteriesUpdate;
}
export function localeNumeralCountry(country) {
  switch (country) {
    case "es":
      return "es";
    case "en":
      return "en-au";
    case "zh":
      return "chs";
    case "de":
      return "de";
    case "it":
      return "it";
    case "fr":
      return "fr";
    case "pt":
      return "pt-pt";
  }
}
export async function getCountrys(setCountry, isAll, t) {
  const response = await getCountries();
  let countrys = response?.map((country) => {
    return { ...country, value: country.name };
  });
  if (isAll) countrys.unshift({ value: t("components.community.All") });

  setCountry(countrys);
}
export async function fetchCountrysPath(setCountrys) {
  let response = await getMapFriends();

  let countryFills = countrysPath.map((countryPath) => {
    let findCountry = response?.find(
      (countryResponse) => countryPath.dataId === countryResponse.alpha2_code
    );
    if (findCountry) {
      return { ...countryPath, fill: fillPathCountry(findCountry.color) };
    } else return countryPath;
  });

  setCountrys(countryFills);
}

export function fillPathCountry(colorOrder) {
  switch (colorOrder) {
    case 4:
      return "#DABB75";
    case 3:
      return "#E7C26D";
    case 2:
      return "#F9DA94";
    case 1:
      return "#F8EDD3";
  }
}

export async function getPosts(setPosts) {
  try {
    const response = await getPostsFacebook();
    setPosts(response);
  } catch (error) {
    console.log(error);
    setPosts([]);
  }
}

export function parseComment(number) {
  numeral.locale(localeNumeralCountry(getI18n().language));
  switch (true) {
    case number >= 1000:
      return numeral(number).format("0 a");
    case number >= 1000000:
      return numeral(number).format("0.0a");

    default:
      return number;
  }
}

export function getLogo(mode) {
  switch (getI18n().language) {
    case "es":
      return mode === "dark" || mode === "pink" ? LogoLightEs : LogoDarkEs;
    case "pt":
      return mode === "dark" || mode === "pink" ? LogoLightPT : LogoDarkPT;
    case "fr":
      return mode === "dark" || mode === "pink" ? LogoLightFR : LogoDarkFR;
    case "it":
      return mode === "dark" || mode === "pink" ? LogoLightIT : LogoDarkIT;
    case "de":
      return mode === "dark" || mode === "pink" ? LogoLightDE : LogoDarkDE;
    case "zh":
      return mode === "dark" || mode === "pink" ? LogoLightZH : LogoDarkZH;
    case "en":
      return mode === "dark" || mode === "pink" ? LogoLightEn : LogoDarkEn;
  }
}

export async function handlePostCommunity(
  data,
  setIsSuccessPost,
  setPostsComunnity,
  dispatch,
  options
) {
  const post = {
    description: data?.content,
    title: data?.title
  };
  const imgPost = data.file;
  const msjError = data.text;

  const parseUploadImage = () =>
    ["image/png", "image/jpg", "image/jpeg"].includes(imgPost.type);

  try {
    if (imgPost) {
      if (parseUploadImage()) {
        const savedPost = await postPrayersCommunity(post);
        const owner = [
          {
            name: imgPost?.name,
            type: "IMG",
            owners: [{ owner: savedPost.id, ownerType: "CPY" }]
          }
        ];
        let formData = new FormData();

        formData.append("", imgPost);
        formData.append("metadata", JSON.stringify(owner));
        await putUserImaege(formData);
        await setIsSuccessPost(true);
        await fetchPrayersCommunity(setPostsComunnity, options);
        await setTimeout(() => {
          setIsSuccessPost(false);
        }, 3000);
      } else {
        dispatch({
          type: "@modal/error",
          payload: {
            open: true,
            message: { name: msjError, messageError: "" }
          }
        });
      }
    } else {
      await postPrayersCommunity(post);
      await setIsSuccessPost(true);
      await fetchPrayersCommunity(setPostsComunnity, options);
      await setTimeout(() => {
        setIsSuccessPost(false);
      }, 3000);
    }
  } catch (error) {
    logout(dispatch);
    dispatch({
      type: "@modal/login",
      payload: { open: true }
    });
  }
}

export async function handlePostComments(
  data,
  post,
  setPostsComunnity,
  prayers,
  dispatch,
  options
) {
  let comment = {
    description: data?.comment,
    prayer: post?.id
  };
  try {
    await postComments(comment);
    await fetchPrayersCommunity(setPostsComunnity, options);
    await getCommentsPosts(post?.id, setPostsComunnity, prayers, dispatch);
    return "OK";
  } catch (error) {
    logout(dispatch);
    dispatch({
      type: "@modal/error",
      payload: {
        open: true,
        message: { name: error?.name, messageError: error?.message }
      }
    });
    return error;
  }
}

export async function fetchPrayersCommunity(
  setPostsComunnity,
  options,
  setNexPage
) {
  try {
    const response = await getPrayersCommunity(options);
    if (setNexPage) setNexPage(response.next_page);
    if (response?.data.length > 0) {
      setPostsComunnity(response.data);
    } else setPostsComunnity([]);
  } catch (error) {
    console.log(error);
  }
}

export async function reportsPosts(post, setPostsComunnity, dispatch, options) {
  try {
    await reportPosts(post?.id);
    await fetchPrayersCommunity(setPostsComunnity, options);
  } catch (error) {
    dispatch({
      type: "@modal/error",
      payload: {
        open: true,
        message: { name: error?.name, messageError: error?.message }
      }
    });
  }
}

export async function getCommentsPosts(
  postId,
  setPostsComunnity,
  prayers,
  dispatch
) {
  try {
    const response = await getCommentsPost(postId);

    if (response?.length > 0) {
      response.sort(function (a, b) {
        return new Date(b.created_at) - new Date(a.created_at);
      });
    }
    const postsComments = prayers.map((prayer) => {
      if (prayer.id === postId) {
        return { ...prayer, comment: response?.slice(0, 2) || [] };
      } else return prayer;
    });

    await setPostsComunnity(postsComments);
  } catch (error) {
    dispatch({
      type: "@modal/error",
      payload: {
        open: true,
        message: { name: error?.name, messageError: error?.message }
      }
    });
  }
}

export async function editCommentsPosts(
  value,
  comment,
  prayers,
  setPostsComunnity,
  dispatch
) {
  const editComment = {
    description: value.comment
  };
  try {
    await editCommentsPost(editComment, comment.id);
    await getCommentsPosts(
      comment.prayer?.id || comment.prayer,
      setPostsComunnity,
      prayers,
      dispatch
    );
  } catch (error) {
    dispatch({
      type: "@modal/error",
      payload: {
        open: true,
        message: { name: error?.name, messageError: error?.message }
      }
    });
  }
}

export async function reportComment(
  comment,
  setPostsComunnity,
  prayers,
  dispatch
) {
  try {
    if (!comment.reported) await reportCommentsPost(comment?.id);
    await getCommentsPosts(
      comment.prayer?.id || comment.prayer,
      setPostsComunnity,
      prayers,
      dispatch
    );
  } catch (error) {
    dispatch({
      type: "@modal/error",
      payload: {
        open: true,
        message: { name: error?.name, messageError: error?.message }
      }
    });
  }
}

export async function reportPrayers(prayers, reportPosts, dispatch) {
  try {
    let resp = true;
    if (!prayers.reported) resp = await reportPosts(prayers?.id);
    return resp;
  } catch (error) {
    logout(dispatch);
    dispatch({
      type: "@modal/error",
      payload: {
        open: true,
        message: { name: error?.name, messageError: error?.message }
      }
    });
    return error;
  }
}

export async function MorePrayersCommunity(
  setPostsComunnity,
  options,
  prayers,
  setNexPage
) {
  try {
    const response = await getPrayersCommunity(options);
    if (setNexPage) setNexPage(response.next_page);
    if (response?.data.length > 0) {
      let filterPostReport = prayers.concat(response?.data);
      setPostsComunnity(filterPostReport);
    } else setPostsComunnity([]);
  } catch (error) {
    console.log(error);
  }
}
export const pswRegex = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[#?!@$%^&*-]?)([a-zA-Z0-9#?!@$%^&*-]{8,50})$/;

export async function setAmen(owner, ownerType) {
  try {
    await postAmen({ owner: owner, ownerType: ownerType });
  } catch (e) {
    console.warn(e);
  }
}

export async function unsetAmen(owner, ownerType) {
  try {
    await deleteAmen({ owner: owner, ownerType: ownerType });
  } catch (e) {
    console.warn(e);
  }
}

export const languagesSelect = [
  {
    id: "es",
    value: "ES",
    display: "Español"
  },
  {
    id: "en",
    value: "EN",
    display: "English"
  },
  {
    id: "pt",
    value: "PT",
    display: "Português"
  },
  {
    id: "it",
    value: "IT",
    display: "Italiano"
  },
  {
    id: "fr",
    value: "FR",
    display: "Français"
  },
  {
    id: "de",
    value: "DE",
    display: "Deutsch"
  },
  {
    id: "zh",
    value: "ZH",
    display: "中文"
  }
];

export const synodCampaings = [104, 105, 116, 117, 118, 868];

export const handleClickOpenUrl = (url) => {
  if (url) {
    window.open(url, "_blank");
  }
};
