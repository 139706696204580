import React, { useState, useEffect } from "react";
import { Switch, Route, useLocation, Redirect } from "react-router-dom";
import {
  DAILY,
  MONTHLY,
  ROOT,
  DEFAULT,
  BLOG,
  BLOG_ARTICLE,
  SCHOOL_PRAYER,
  CAMPAIGN_PAGE,
  SYNOD,
  SYNOD_PRAYER_PAGE,
  POPE,
  TEMPORAL,
  PROFILE,
  ROSARY_PAGE,
  DONATE,
  COMMUNITY,
  SHARE_PRAYER,
  WHAT_IS_CTP,
  HOW_DOES_CTP,
  LEGAL
} from "./CONSTANTS";
import {
  fetchCountrysPath,
  getPosts,
  handlePostCommunity,
  handlePostComments,
  fetchPrayersCommunity,
  //reportsPosts,
  getCommentsPosts,
  editCommentsPosts,
  reportComment
} from "helpers";
import Home from "pages/Home";
import Monthly from "pages/Monthly";
import Daily from "pages/Daily";
import Blog from "pages/Blog/BlogAll";
import BlogArticle from "pages/Blog/BlogArticle";
import CampaignPage from "pages/Campaign";
import SynodPage from "pages/Synod";
import SynodPrayerPage from "pages/SynodPrayer";
import NotFound from "pages/NotFound";
import PopeProfile from "pages/PopeProfile";
import SchoolPrayer from "pages/SchoolPrayer";
import Rosary from "pages/Rosary";
import Temporal from "pages/Temporal";
import Profile from "pages/Profile";
import DonatePage from "pages/Donate";
import Community from "pages/Community";
import Legal from "pages/Legal";
import SharePrayer from "pages/SharePrayer";
import Footer from "components/Footer";
import Header from "components/Header";
import CommunityComponents from "components/Community";
import MessageErrors from "components/MessageErrors";
import NewPassword from "components/NewPassword";
import DonateSection from "components/DonateSection";
import Posts from "components/Posts";
import Map from "components/Map";
import { useTranslation } from "react-i18next";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import AuthFormLogin from "components/Auth";
import SignUpOptions from "components/SignUp/SignUpOptions";
import SignUpForm from "components/SignUp/SignUpForm";
import RecoverPassword from "components/RecoverPassword";
import SignUpSuccess from "components/SignUp/SignUpSuccess";
import { useDispatch, useSelector } from "react-redux";
import {
  signInRSS,
  signUpRSS,
  singIn,
  singUp,
  sendEmailRecoverPassword,
  recoverPassword
} from "services/authService";
import { reportPosts } from "services/services";
import WhatIsCTP from "pages/WhatIsCTP/WhatIsCTP";
import HowCtp from "pages/HowCTP/HowCtp";

export const RouterConfig = () => {
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const modal = useSelector((store) => store.modal);
  const mediaQuerySmHeaderAndFooter = useMediaQuery("(max-width:1088px)");
  const mediaQueryLg = useMediaQuery("(max-width:1294px)");
  const mediaQueryMd = useMediaQuery("(max-width:960px");
  const mediaQuerySm = useMediaQuery("(max-width:600px");
  const [isCommunity, setIsCommunity] = useState(false);
  const [modeHeader, setModeHeader] = useState("dark");
  const [isDonateSection, setIsDonateSection] = useState(false);
  const [isOpenSignUpOptions, setIsOpenSignUpOptions] = useState(false);
  const [isOpenSignUpForm, setIsOpenSignUpForm] = useState(false);
  const [isOpenSignInForm, setIsOpenSignInForm] = useState(false);
  const [isOpenSignUpSuccess, setIsOpenSignUpSuccess] = useState(false);
  const [openRecoverPassword, setOpenRecoverPassword] = useState({
    isOpen: false,
    email: ""
  });
  const [isSuccessPost, setIsSuccessPost] = useState(false);
  const [isRememberme, setIsRememberme] = useState(false);
  const [messageErrorLogin, setMessageErrorLogin] = useState(null);
  const [isPosts, setIsPosts] = useState(false);
  const [isMap, setIsMap] = useState(false);
  const [countrys, setCountrys] = useState([]);
  const [posts, setPosts] = useState([]);
  const [postsComunnity, setPostsComunnity] = useState([]);

  const is = (path) => new RegExp(path, "i").test(location.pathname);

  useEffect(() => {
    switch (true) {
      case is(MONTHLY):
      case is(WHAT_IS_CTP):
        setIsCommunity(false);
        setModeHeader("light");
        setIsDonateSection(true);
        setIsPosts(false);
        setIsMap(false);
        break;
      case is(HOW_DOES_CTP):
        setIsCommunity(false);
        setModeHeader("light");
        setIsDonateSection(true);
        setIsPosts(false);
        setIsMap(false);
        break;
      case is(LEGAL):
        setIsCommunity(false);
        setModeHeader("light");
        setIsDonateSection(false);
        setIsPosts(false);
        setIsMap(false);
        break;
      case is(POPE):
      case is(BLOG):
      case is(COMMUNITY):
      case is(SYNOD):
      case is(`(${SYNOD_PRAYER_PAGE})(/[0-9]+)`):
        setIsCommunity(false);
        setModeHeader("light");
        setIsDonateSection(true);
        setIsPosts(false);
        setIsMap(false);
        break;
      case is(`(${SHARE_PRAYER})(/[\\w]{3})([.])([0-9]+)(/[\\w]+)`):
      case is(`(${BLOG_ARTICLE})(/[0-9]+)`):
      case is(`(${CAMPAIGN_PAGE})(/[0-9]+)`):
        setIsCommunity(false);
        setModeHeader("light");
        setIsDonateSection(true);
        setIsPosts(false);
        setIsMap(false);
        break;
      case is(`(${ROSARY_PAGE})(/[0-9]+)`):
        setIsCommunity(false);
        setModeHeader("pink");
        setIsDonateSection(true);
        setIsPosts(false);
        setIsMap(false);
        break;
      case is(DAILY):
      case is(SCHOOL_PRAYER):
        setIsCommunity(false);
        setModeHeader("dark");
        setIsDonateSection(true);
        setIsPosts(false);
        setIsMap(false);
        break;
      case is(DONATE):
        setIsCommunity(false);
        setModeHeader("light");
        setIsDonateSection(false);
        setIsPosts(false);
        setIsMap(false);
        break;
      case is(PROFILE):
        setIsCommunity(false);
        setModeHeader("light");
        setIsPosts(false);
        setIsMap(false);
        break;
      case is(TEMPORAL):
        setIsCommunity(false);
        setIsMap(false);
        setIsPosts(false);
        setIsDonateSection(false);
        setModeHeader("light");
        break;
      case is(`(${ROOT})$`):
        fetchPrayersCommunity(setPostsComunnity);
        setIsCommunity(true);
        getPosts(setPosts);
        fetchCountrysPath(setCountrys);
        setIsPosts(true);
        setIsMap(true);
        setModeHeader("dark");
        setIsDonateSection(true);
        break;
      default:
        // not_found
        setIsPosts(false);
        setIsMap(false);
        setIsCommunity(false);
        setIsDonateSection(false);
        setModeHeader("light");
        break;
    }
  }, [location]);

  useEffect(() => {
    if (localStorage.getItem("session"))
      setIsRememberme(JSON.parse(localStorage.getItem("session")).rememberme);
  }, []);

  useEffect(() => {
    getPosts(setPosts);
  }, [i18n.language]);

  function handleChangeLanguage(locale) {
    if (locale instanceof Object) locale = locale.id;
    i18n.changeLanguage(locale);
    localStorage.setItem("lenguage", locale);
  }

  async function handleSignUp(e) {
    try {
      await singUp(e.email, e.password, e.name, e.surname, e.country?.id);
      setIsOpenSignUpForm(false);
      setIsOpenSignUpSuccess(true);
    } catch (error) {
      setIsOpenSignUpForm(true);
      dispatch({
        type: "@modal/error",
        payload: {
          open: true,
          message: {
            name: error?.name,
            messageError: error?.message,
            code: error?.code
          }
        }
      });
    }
  }

  async function handleAuthRss({ response, isFacebook, isLogin }) {
    try {
      const token = response.accessToken;
      if (isLogin) {
        await signInRSS({ token, isFacebook, dispatch });
        dispatch({
          type: "@modal/login",
          payload: { open: false }
        });
        setIsOpenSignInForm(false);
      } else {
        await signUpRSS({ token, isFacebook });
        setIsOpenSignUpOptions(false);
        dispatch({
          type: "@modal/login",
          payload: { open: true }
        });
      }
    } catch (error) {
      if (isLogin)
        dispatch({
          type: "@modal/login",
          payload: { open: true }
        });
      else {
        setIsOpenSignUpOptions(false);
      }
      dispatch({
        type: "@modal/error",
        payload: {
          open: true,
          message: {
            name: error?.name,
            messageError: error?.message,
            code: error?.code
          }
        }
      });
    }
  }

  async function handleLogin(event) {
    try {
      setOpenRecoverPassword({
        isOpen: false,
        email: event.email
      });
      await singIn({
        email: event.email,
        password: event.password,
        dispatch
      });
      setIsOpenSignInForm(false);
    } catch (error) {
      setIsOpenSignInForm(true);
      /*
      dispatch({
        type: "@modal/login",
        payload: { open: false },
      });
      */
      dispatch({
        type: "@modal/error",
        payload: {
          open: true,
          message: {
            name: error?.name,
            messageError: error?.message,
            code: error?.code
          }
        }
      });
    }
  }

  function onChangeRememberme(event) {
    if (event.target.checked)
      localStorage.setItem(
        "session",
        JSON.stringify({ rememberme: event.target.checked })
      );
    else localStorage.removeItem("session");
    setIsRememberme(!isRememberme);
  }

  function handleRecoverPasswordLogin(values) {
    setOpenRecoverPassword({
      isOpen: true,
      email: values.email
    });
    dispatch({
      type: "@modal/login",
      payload: { open: false }
    });
  }

  function handleCloseRecoverPassword() {
    setOpenRecoverPassword({
      ...openRecoverPassword,
      isOpen: false
    });
    dispatch({
      type: "@modal/login",
      payload: { open: true }
    });
  }

  async function handleSendEmailRecoverPassword(email) {
    try {
      await sendEmailRecoverPassword({ email: email });
      setOpenRecoverPassword({
        ...openRecoverPassword,
        isOpen: false
      });
    } catch (error) {
      setOpenRecoverPassword({
        ...openRecoverPassword,
        isOpen: false
      });
      dispatch({
        type: "@modal/error",
        payload: {
          open: true,
          message: {
            name: error?.name,
            messageError: error?.message,
            code: error?.code
          }
        }
      });
    }
  }

  async function handlerecoverPassword(passwordValue) {
    try {
      await recoverPassword(
        { password: passwordValue.password },
        modal.recoverPassword.hash
      );
      await singIn({
        email: modal.recoverPassword.email,
        password: passwordValue.password,
        dispatch
      });
      dispatch({
        type: "@modal/recoverPassword",
        payload: {
          open: false
        }
      });
    } catch (error) {
      dispatch({
        type: "@modal/recoverPassword",
        payload: {
          open: false
        }
      });
      dispatch({
        type: "@modal/error",
        payload: {
          open: true,
          message: {
            name: t("components.recoverPassword.link expired"),
            messageError: t("components.recoverPassword.link expired message"),
            code: error?.code
          }
        }
      });
    }
  }

  function handleCloseErrorModal() {
    dispatch({
      type: "@modal/error",
      payload: { open: false, message: "" }
    });
    /*
    dispatch({
      type: "@modal/login",
      payload: { open: false },
    });
    */

    //If the error was produced by a code 1007 (expired credentials). Show recover password modal
    if (modal?.error?.message?.code === 1007)
      setOpenRecoverPassword({
        isOpen: true,
        email: openRecoverPassword.email
      });
  }

  return (
    <div style={{ overflowX: "hidden" }}>
      <Header
        isSmall={mediaQuerySmHeaderAndFooter}
        isLarge={mediaQueryLg}
        mode={modeHeader}
        handleChangeLanguage={handleChangeLanguage}
        handleLogin={() =>
          dispatch({
            type: "@modal/login",
            payload: { open: true }
          })
        }
      />
      <AuthFormLogin
        messageError={messageErrorLogin}
        isOpen={modal?.login?.open || isOpenSignInForm}
        handleLoginFacebook={(response) =>
          handleAuthRss({ response, isFacebook: true, isLogin: true })
        }
        handleLoginGoogle={(response) =>
          handleAuthRss({ response, isFacebook: false, isLogin: true })
        }
        handleSubmitLogin={handleLogin}
        handleClose={() => {
          setMessageErrorLogin(null);
          setIsOpenSignInForm(false);
          dispatch({
            type: "@modal/login",
            payload: { open: false }
          });
        }}
        onChangeRememberme={onChangeRememberme}
        isRememberme={isRememberme}
        isMd={mediaQueryMd}
        isLg={mediaQueryLg}
        handleRecoverPassword={handleRecoverPasswordLogin}
        navigationRegister={() => {
          dispatch({
            type: "@modal/login",
            payload: { open: false }
          });
          setIsOpenSignUpOptions(true);
          setIsOpenSignInForm(false);
        }}
      ></AuthFormLogin>
      <SignUpOptions
        handleClose={() => {
          setIsOpenSignUpOptions(false);
        }}
        open={isOpenSignUpOptions}
        isSmall={mediaQueryMd}
        handleSignUpFacebook={(response) =>
          handleAuthRss({ response, isFacebook: true, isLogin: false })
        }
        handleAuthGoogle={(response) =>
          handleAuthRss({ response, isFacebook: false, isLogin: false })
        }
        handleSignUp={() => {
          setIsOpenSignUpOptions(false);
          setIsOpenSignUpForm(true);
        }}
        navigationLogin={() => {
          dispatch({
            type: "@modal/login",
            payload: { open: true }
          });
          setIsOpenSignUpOptions(false);
        }}
      />
      <SignUpForm
        handleSignUp={(values) => handleSignUp(values)}
        handleClose={() => setIsOpenSignUpForm(false)}
        open={isOpenSignUpForm}
        isMd={mediaQueryMd}
        isLg={mediaQueryLg}
        navigationLogin={() => {
          dispatch({
            type: "@modal/login",
            payload: { open: true }
          });
          setIsOpenSignUpForm(false);
        }}
        handleTermAndConditions={() =>
          window.open(t("components.signUp.link_Terms&Conditions"))
        }
      />
      <RecoverPassword
        open={openRecoverPassword.isOpen}
        email={openRecoverPassword.email}
        handleClose={handleCloseRecoverPassword}
        handleRecoverPassword={handleSendEmailRecoverPassword}
        isMd={mediaQueryMd}
        isLg={mediaQueryLg}
      />
      <SignUpSuccess
        open={isOpenSignUpSuccess}
        isMd={mediaQueryMd}
        handleClose={() => setIsOpenSignUpSuccess(false)}
        navigationLogin={() => {
          setIsOpenSignUpSuccess(false);
          dispatch({
            type: "@modal/login",
            payload: { open: true }
          });
        }}
      />
      <NewPassword
        handleNewPassword={handlerecoverPassword}
        isMd={mediaQueryMd}
        open={modal?.recoverPassword?.open}
        handleClose={() =>
          dispatch({
            type: "@modal/recoverPassword",
            payload: { open: false }
          })
        }
      />
      <MessageErrors
        open={modal?.error?.open}
        title={modal?.error?.message?.name}
        messageError={modal?.error?.message?.messageError}
        messageIcon={modal?.error?.message?.icon}
        handleClose={handleCloseErrorModal}
        isMd={mediaQueryMd}
      />
      <Switch>
        {/* List all public routes here */}
        <Route exact path={ROOT} DAILY component={Home} />

        <Route
          exact
          path={PROFILE}
          render={(props) =>
            localStorage.getItem("user") ? (
              <Profile {...props} />
            ) : (
              <Redirect to={ROOT} />
            )
          }
        />
        <Route exact path={POPE} component={PopeProfile} />
        <Route exact path={`${MONTHLY}/:monthIndex?`} component={Monthly} />
        <Route exact path={DAILY} component={Daily} />
        <Route
          exact
          path={`${DAILY}/:prayer_id/:title/:prayer_date`}
          component={Daily}
        />
        <Route exact path={BLOG} component={Blog} />
        <Route exact path={SCHOOL_PRAYER} component={SchoolPrayer} />
        <Route exact path={DONATE} component={DonatePage} />
        <Route exact path={COMMUNITY} component={Community} />
        <Route
          exact
          path={`${SHARE_PRAYER}/:type_id/:title`}
          component={SharePrayer}
        />
        <Route exact path={`${BLOG_ARTICLE}/:id`} component={BlogArticle} />
        <Route
          exact
          path={`${SYNOD_PRAYER_PAGE}/:campaignIndex?`}
          component={SynodPrayerPage}
        />
        <Route
          exact
          path={`${CAMPAIGN_PAGE}/:id/:campaignIndex?`}
          component={CampaignPage}
        />
        <Route exact path={`${SYNOD}`} component={SynodPage} />
        <Route exact path={TEMPORAL} component={Temporal} />
        <Route
          exact
          path={`${ROSARY_PAGE}/:id/:expandedMystery?`}
          component={Rosary}
        />
        <Route exact path={WHAT_IS_CTP} component={WhatIsCTP} />
        <Route exact path={HOW_DOES_CTP} component={HowCtp} />
        <Route exact path={LEGAL} component={Legal} />
        <Route exact path={ROOT} component={Home} />

        <Route exact path="/es">
          <Redirect to={ROOT} />
        </Route>
        <Route exact path="/en">
          <Redirect to={ROOT} />
        </Route>
        <Route exact path="/de">
          <Redirect to={ROOT} />
        </Route>
        <Route exact path="/fr">
          <Redirect to={ROOT} />
        </Route>
        <Route exact path="/it">
          <Redirect to={ROOT} />
        </Route>
        <Route exact path="/fr">
          <Redirect to={ROOT} />
        </Route>
        <Route exact path="/zh">
          <Redirect to={ROOT} />
        </Route>

        {/* List a generic 404-Not Found route here */}
        <Route path={DEFAULT} component={NotFound} />
      </Switch>

      {isDonateSection && <DonateSection isMd={mediaQueryMd} />}
      {isCommunity && (
        <CommunityComponents
          isMd={mediaQueryMd}
          posts={postsComunnity?.slice(0, 2)}
          handlePostCommunity={(values) =>
            handlePostCommunity(
              values,
              setIsSuccessPost,
              setPostsComunnity,
              dispatch
            )
          }
          handlePostComments={(values, post, userLogged) => {
            setIsOpenSignInForm(false);
            if (userLogged)
              handlePostComments(
                values,
                post,
                setPostsComunnity,
                postsComunnity,
                dispatch
              );
            else setIsOpenSignInForm(true);
          }}
          handleReportPost={(post, userLogged) => {
            setIsOpenSignInForm(false);
            if (userLogged) reportPosts(post?.id);
            else setIsOpenSignInForm(true);
          }}
          isSuccessPost={isSuccessPost}
          handleMoreComments={(post) =>
            getCommentsPosts(
              post?.id,
              setPostsComunnity,
              postsComunnity,
              dispatch
            )
          }
          handleEditComments={(values, comment) =>
            editCommentsPosts(
              values,
              comment,
              postsComunnity,
              setPostsComunnity,
              dispatch
            )
          }
          handleReportComment={(comment) =>
            reportComment(comment, setPostsComunnity, postsComunnity, dispatch)
          }
          onDeletePrayer={() => fetchPrayersCommunity(setPostsComunnity)}
        />
      )}
      {isMap && (
        <Map isLg={mediaQueryLg} isSm={mediaQuerySm} countrys={countrys} />
      )}
      {isPosts && posts?.length > 0 && (
        <Posts posts={posts} isMd={mediaQueryMd} />
      )}
      <section>
        <Footer isSmall={mediaQuerySmHeaderAndFooter} />
      </section>
    </div>
  );
};
