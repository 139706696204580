import { setAmen, unsetAmen } from '../../helpers';

const amenCheck = (prayer) => {
  let arrayAmen = JSON.parse(localStorage.getItem('amen'));
  let exist = arrayAmen.find(
    (e) => e.owner === prayer?.id && e.ownerType === prayer.typeForAmen
  );
  if (exist) {
    return true;
  } else {
    return false;
  }
};

export async function setFavorite(
  owner,
  ownerType,
  index,
  postsComunnity,
  setPostCommunnity
) {
  await setAmen(owner, ownerType);
  let items = [...postsComunnity];
  items[index].isFavorite = amenCheck(postsComunnity);
  setPostCommunnity(items);
}

export async function handleDislike(
  owner,
  ownerType,
  index,
  postsComunnity,
  setPostCommunnity
) {
  await unsetAmen(owner, ownerType);
  let items = [...postsComunnity];
  items[index].isFavorite = amenCheck(postsComunnity);
  setPostCommunnity(items);
}
