import React, { useState, useEffect } from 'react';
import { scrollTopWindow } from '../../helpers';
import { useTranslation } from 'react-i18next';
import MonthlyView from './MonthlyView';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useParams } from "react-router-dom";
import {
  handlevideo,
  onChangeSelectLanguage,
  handleFavorite,
  onChangeExpandedMonthly,
  fetchIntentionMonthly,
  onChangeAccordion,
  handleDislike,
} from './MonthlyFunctions';

export function MonthlyContainer() {
  const { t } = useTranslation();
  const mediaQuerySm = useMediaQuery('(max-width:960px)');
  const { monthIndex } = useParams();
  const [video, setVideo] = useState({
    isPlay: false,
    video: '',
  });
  const [intentions, setIntentions] = useState([]);
  const [isExpandedMonthly, setIsExpandedMonthly] = useState(false);
  const [monthlys, setMonthlys] = useState([]);
  useEffect(() => {
    scrollTopWindow();
    fetchIntentionMonthly(
      setMonthlys,
      setIsExpandedMonthly,
      setIntentions,
      mediaQuerySm,
      monthIndex
    );
  }, [t, mediaQuerySm]);

  return (
    <MonthlyView
      onChangeAccordion={(id, value) =>
        onChangeAccordion(id, value, intentions, mediaQuerySm, setIntentions)
      }
      intentions={intentions}
      onChangeExpandedMonthly={() => {
        setIntentions(onChangeExpandedMonthly(monthlys, intentions));
        setIsExpandedMonthly(false);
      }}
      isExpandedMonthly={isExpandedMonthly}
      handleFavorite={(owner, ownerType, index, intentionIndex) =>
        handleFavorite(
          owner,
          ownerType,
          index,
          intentionIndex,
          intentions,
          setIntentions
        )
      }
      handleDislike={(owner, ownerType, index, intentionIndex) =>
        handleDislike(
          owner,
          ownerType,
          index,
          intentionIndex,
          intentions,
          setIntentions
        )
      }
      handlevideo={(id) => setVideo(handlevideo(id))}
      handleCloseVideo={() =>
        setVideo({
          isPlay: false,
          video: '',
        })
      }
      video={video}
      onChangeSelectLanguage={(option, id) =>
        setIntentions(onChangeSelectLanguage(intentions, option, id))
      }
      isSmall={mediaQuerySm}
    />
  );
}
