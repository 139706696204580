import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { handlerBackToSchoolPrayer, scrollTopWindow } from '../../helpers';
import RosaryView from './RosaryView';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
  fetchGetRosary,
  handleExpand,
  handleAudio,
  setFavorite,
  handleDislike,
} from './RosaryFunctions';

export function RosaryContainer() {
  const mediaQuerySm = useMediaQuery('(max-width:960px)');
  const mediaQueryLg = useMediaQuery('(max-width:1280px)');
  const { t } = useTranslation();
  const { id, expandedMystery } = useParams();
  const history = useHistory();
  const [video, setVideo] = useState({
    open: false,
    src: '',
  });
  const [rosarie, setRosarie] = useState({});
  const [groupMysteries, setGroupMysteries] = React.useState([]);
  const [indexMysteryDay, setIndexMysteryDay] = useState(-1);

  useEffect(() => {
    scrollTopWindow();
    fetchGetRosary(id, setRosarie, history);
    setIndexMysteryDay(expandedMystery || -1);
    setGroupMysteries([
      {
        day: t('days.Monday'),
        title: t('pages.eRosary.The Joyful Mysteries'),
        isExpande: false,
        dayNumber: 1,
      },
      {
        day: t('days.Tuesday'),
        title: t('pages.eRosary.The Sorrowful Mysteries'),
        isExpande: false,
        dayNumber: 2,
      },
      {
        day: t('days.Wednesday'),
        title: t('pages.eRosary.The Glorious Mysteries'),
        isExpande: false,
        dayNumber: 3,
      },
      {
        day: t('days.Thursday'),
        title: t('pages.eRosary.The Luminous Mysteries'),
        isExpande: false,
        dayNumber: 4,
      },
      {
        day: t('days.Friday'),
        title: t('pages.eRosary.The Sorrowful Mysteries'),
        isExpande: false,
        dayNumber: 5,
      },
      {
        day: t('days.Saturday'),
        title: t('pages.eRosary.The Joyful Mysteries'),
        isExpande: false,
        dayNumber: 6,
      },
      {
        day: t('days.Sunday'),
        title: t('pages.eRosary.The Glorious Mysteries'),
        isExpande: false,
        dayNumber: 0,
      },
    ]);
  }, [t, indexMysteryDay]);

  return (
    <div data-testid="rosary_container">
      <RosaryView
        rosaries={rosarie}
        groupMysteries={groupMysteries}
        isSm={mediaQuerySm}
        isLg={mediaQueryLg}
        handlerBackToSchoolPrayer={() => handlerBackToSchoolPrayer(history)}
        handlevideo={(url) =>
          setVideo({
            ...video,
            open: !video.open,
            src: url,
          })
        }
        handleExpand={(rosary) =>
          handleExpand(rosary, groupMysteries, setGroupMysteries, rosarie.id)
        }
        video={video}
        handleCloseVideo={() =>
          setVideo({
            ...video,
            open: false,
          })
        }
        toggleAudio={(current, type, audio, rosary) =>
          handleAudio(
            current,
            type,
            audio,
            rosary,
            groupMysteries,
            setGroupMysteries
          )
        }
        handleFavorite={(owner, ownerType, index) =>
          setFavorite(
            owner,
            ownerType,
            index,
            groupMysteries,
            setGroupMysteries
          )
        }
        handleDislike={(owner, ownerType, index) =>
          handleDislike(
            owner,
            ownerType,
            index,
            groupMysteries,
            setGroupMysteries
          )
        }
        expandedMystery={indexMysteryDay}
      />
    </div>
  );
}
