import React, { useState, useEffect } from "react";
import {
  handleCardIntentionVideo,
  handleCardIntentionPrayer,
  handleCardIntentionAttitudes,
  navigateSchoolPrayer,
  navigateBlog,
  fetchPrayingTips,
  handleClickPrayCard,
  moveCarrusel,
  fetchIntention,
  fetchBlogsHome,
  fetchPrayerSchoolHome,
  setFavorite,
  handleDislike,
  setHandler500Page
} from "./HomeFunctions";
import { useHistory } from "react-router-dom";
import {
  handleMultimedia,
  handleReadArticle,
  navigateSchoolsPrayers,
  scrollTopWindow
} from "../../helpers";
import { useTranslation } from "react-i18next";
import moment from "moment";
import HomeView from "./HomeView";
import useMediaQuery from "@material-ui/core/useMediaQuery";

export function HomeContainer() {
  const { t } = useTranslation();
  const mediaQuerySm = useMediaQuery("(max-width:960px)");
  const mediaQueryXs = useMediaQuery("(max-width:500px)");
  const history = useHistory();
  const [dateIntention, setDateIntention] = useState({
    dayNumber: 0,
    month: "",
    day: "",
    year: "",
    video: ""
  });
  const [intention, setIntention] = useState({
    title: "",
    description: "",
    video: null
  });
  const [isModalSinglePrayer, setIsModalSinglePrayer] = useState(false);
  const [singlePrayer, setSinglePrayer] = useState({
    description: "",
    title: "",
    icon: ""
  });
  const [backgroundImage, setBackgroundImage] = useState({
    url: null,
    color: null
  });
  const [initialDate, setInitialDate] = useState([]);
  const [prayingTips, setPrayingTips] = useState([]);
  const [prayers, setPrayers] = useState([]);
  const [positionIntention, setPositionIntention] = useState(3);
  const [countBack, setCountBack] = useState(0);
  const [countNext, setCountNext] = useState(0);
  const [Audioplay, setAudioPlay] = useState(false);
  const [blogs, setBlogs] = useState([]);
  const [schoolPrayers, setSchoolPrayers] = useState([]);
  const [isPopeVideo, setIsPopeVideo] = useState(false);

  const handleChangePrayer = (prayerId) => {
    const newPrayer = prayers.find((prayer) => prayer.id === prayerId);
    setSinglePrayer(newPrayer);
  };

  useEffect(() => {
    scrollTopWindow();
    try {
      setInitialDate([]);
      fetchPrayingTips(setPrayingTips);
      fetchIntention(
        moment().format("YYYY-MM-DD"),
        mediaQuerySm,
        setInitialDate,
        setIntention,
        setBackgroundImage,
        setPrayers,
        setDateIntention,
        setPositionIntention,
        [],
        countBack,
        countNext,
        setHandler500Page(t, history)
      );
      fetchPrayerSchoolHome(moment().format("YYYY-MM-DD"), setSchoolPrayers);
      fetchBlogsHome(setBlogs);
    } catch (error) {
      console.log(error);
    }
  }, [t, mediaQuerySm]);

  return (
    <HomeView
      dateIntention={dateIntention}
      intention={intention}
      initialDate={initialDate}
      prayingTips={prayingTips}
      prayers={prayers}
      positionIntention={positionIntention}
      nextIntention={() => {
        moveCarrusel(countBack, countNext, setCountBack, setCountNext);
        fetchIntention(
          initialDate[positionIntention + 1],
          mediaQuerySm,
          setInitialDate,
          setIntention,
          setBackgroundImage,
          setPrayers,
          setDateIntention,
          setPositionIntention,
          initialDate,
          countBack,
          countNext
        );
      }}
      backIntention={() => {
        moveCarrusel(countNext, countBack, setCountNext, setCountBack);
        fetchIntention(
          initialDate[positionIntention - 1],
          mediaQuerySm,
          setInitialDate,
          setIntention,
          setBackgroundImage,
          setPrayers,
          setDateIntention,
          setPositionIntention,
          initialDate,
          countBack,
          countNext
        );
      }}
      backgroundImage={backgroundImage}
      handleCardIntentionVideo={() => handleCardIntentionVideo(setIsPopeVideo)}
      handleCardIntentionPrayer={() => handleCardIntentionPrayer(history)}
      handleCardIntentionAttitudes={() => handleCardIntentionAttitudes(history)}
      handleClickPrayCard={(pray) =>
        handleClickPrayCard(
          pray,
          prayers,
          setSinglePrayer,
          setIsModalSinglePrayer
        )
      }
      handleCloseSinglePrayer={() => {
        setIsModalSinglePrayer(false);
      }}
      handleMultimedia={(audio, type) => {
        setAudioPlay(handleMultimedia(audio, type, Audioplay));
      }}
      isModalSinglePrayer={isModalSinglePrayer}
      singlePrayer={singlePrayer}
      isPlay={Audioplay}
      isSmall={mediaQuerySm}
      isXs={mediaQueryXs}
      blogs={blogs}
      handleReadArticle={(id) => handleReadArticle(id, history)}
      schoolPrayers={schoolPrayers}
      navigateSchoolPrayer={() => navigateSchoolPrayer(history)}
      navigateBlog={() => navigateBlog(history)}
      handleClickSchoolPrayer={(schoolPrayer) =>
        navigateSchoolsPrayers(schoolPrayer, history)
      }
      isPopeVideo={isPopeVideo}
      handleClosePopeVideo={() => setIsPopeVideo(false)}
      handleFavorite={(owner, ownerType) =>
        setFavorite(owner, ownerType, singlePrayer, setSinglePrayer)
      }
      handleDislike={(owner, ownerType) =>
        handleDislike(owner, ownerType, singlePrayer, setSinglePrayer)
      }
      handleChangePrayer={handleChangePrayer}
    />
  );
}
