import makeStyles from "@material-ui/core/styles/makeStyles";
import mode from "styles";
const typeStyled = (type) => {
  return {
    backgroundColor: mode.light[type]?.color.main,
    "& span": {
      color: mode.light[type]?.color.text
    },
    "&:hover": {
      backgroundColor: mode.light[type]?.action.hover,
      boxShadow: "none"
    },
    "&:disabled": {
      backgroundColor: mode.light[type]?.action.disabled
    }
  };
};

export const useStyles = makeStyles(() => ({
  button: {
    borderRadius: "0.375em",
    padding: "0.75em 1em",
    boxShadow: "none",
    "& span": {
      fontFamily: "Nunito Sans",
      fontWeight: 600,
      fontSize: "1em",
      lineHeight: "1.5em",
      textTransform: "none",
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    }
  },

  type: (props) => typeStyled(props.type)
}));
