import React, {useState, useEffect} from "react";
import {Avatar, Grid} from "@material-ui/core";
import {
  DAILY,
  MONTHLY,
  POPE,
  SCHOOL_PRAYER,
  DONATE,
  COMMUNITY,
} from "navigation/CONSTANTS";
import PropTypes from "prop-types";
import {useStyles} from "./styles";
import closeIcon from "assets/icons/Close.svg";
import LogoSimple from "assets/img/Logo_simple.svg";
import MenuIconWhite from "assets/icons/M_menu.svg";
import MenuIconBlack from "assets/icons/menu_black.svg";
import Button from "components/Button";
import Icon from "components/Icon";
import ArrowIconLight from "assets/icons/chevron-down-light.svg";
import ArrowIconDark from "assets/icons/chevron-down-dark.svg";
import Popover from "@material-ui/core/Popover";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {useTranslation} from "react-i18next";
import Select from "components/Select";
import "../../../src/i18n";
import {useHistory} from "react-router-dom";
import {getLogo, languagesSelect} from "helpers";
import {useSelector} from "react-redux";

export function headerFunctions() {
  function handleClick(event) {
    return event.currentTarget;
  }

  function handleClose() {
    return null;
  }

  function searchLenguages(languages) {
    let lenguage;
    if (localStorage.getItem("lenguage")) {
      lenguage = languages.filter(
        (language) => localStorage.getItem("lenguage") === language.id
      );
    } else {
      lenguage = languages.filter(
        (language) => navigator.language.split("-")[0] === language.id
      );
    }
    return lenguage[0];
  }

  return {
    handleClick,
    handleClose,
    searchLenguages,
  };
}

export function HeaderStyled(props) {
  const {t} = useTranslation();
  const history = useHistory();
  const user = useSelector((store) => store.auth.user);
  const [logo, setLogo] = useState(null);
  const [menu, setMenu] = useState(null);
  const [lenguageSelected, setLenguageSelected] = useState("");
  const {mode, handleLogin, handleChangeLanguage, isSmall, isLarge} = props;
  const classes = useStyles({
    mode,
    mediaQuerySm: isSmall,
    mediaQueryLg: isLarge,
  });
  const open = Boolean(menu);
  const id = open ? "simple-popover" : undefined;
  const buttons = [
    {
      text: "components.header.Pope Francis",
      id: "btn_pope",
      href: POPE,
    },
    {
      text: "components.header.Prayer Intention",
      id: "btn_monthly",
      href: MONTHLY,
    },
    {
      text: "components.header.Pray every day",
      id: "btn_daily",
      href: DAILY,
    },
    {
      text: "components.header.School of Prayer",
      id: "btn_school_prayer",
      href: SCHOOL_PRAYER,
    },
    {
      text: "components.header.Community",
      id: "btn_school_community",
      href: COMMUNITY,
    },
    {
      text: "components.button.Donate",
      id: "btn_donate",
      href: DONATE,
    },
  ];

  useEffect(() => {
    setLenguageSelected(headerFunctions().searchLenguages(languagesSelect));
  }, []);

  useEffect(() => {
    setLogo(getLogo(mode));
  }, [t, mode]);

  return (
    <Grid
      data-testid="header"
      container
      justify='space-between'
      className={`${classes.mode} ${classes.container}`}
    >
      <Grid xs={6} sm={6} md={1} lg={1} xl={4} item>
        {isLarge ? (
          <div
            style={{ zIndex: 3, cursor: "pointer", width: "max-content" }}
          >
            <Icon
              data-testid="ico_click_to_pray"
              css={classes.logoSimple}
              id="ico_click_to_pray"
              icon={LogoSimple}
              alt="Logo"
              onClick={() => history.push("/")}
            />
          </div>

        ) : (
          <div
            onClick={() => history.push("/")}
            style={{zIndex: 3, cursor: "pointer", width: "max-content" }}
          >
            <Icon
              data-testid="ico_click_to_pray"
              css={classes.logo}
              id="ico_click_to_pray"
              icon={logo}
              alt="Logo"
            />
          </div>
        )}
      </Grid>

      <Grid xs={6} sm={6} md={11} lg='auto' item>
        {isSmall ? (
          <>
            <img
              data-testid="ico_Menu"
              className={classes.menu}
              id="ico_Menu"
              src={
                mode === "dark" || mode === "pink"
                  ? MenuIconWhite
                  : MenuIconBlack
              }
              alt="Logo"
              onClick={(event) => setMenu(headerFunctions().handleClick(event))}
            />
            <Popover
              id={id}
              open={open}
              anchorEl={menu}
              onClose={() => setMenu(headerFunctions().handleClose())}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              PaperProps={{style:{width:"100%",marginTop:"0px",margin:"0px"}}}
              
            >
              <div>
                <div className={classes.contentHeaderMenu}>
                  <Icon
                    data-testid="ico_click_to_pray_menu"
                    id="ico_click_to_pray_menu"
                    icon={LogoSimple}
                    alt="Logo"
                  />
                  <img
                    data-testid="ico_close"
                    id="ico_close"
                    src={closeIcon}
                    alt="Logo"
                    onClick={() => setMenu(headerFunctions().handleClose())}
                  />
                </div>
                {buttons.map(({text, id, href}, index) => (
                  <div key={index} className={classes.contentButton}>
                    <Button
                      type="cuaternary"
                      text={text}
                      css={classes.buttonStyle}
                      data-testid={id}
                      onClick={() => {
                        setMenu(headerFunctions().handleClose());
                        href && history.push(href);
                      }}
                    />
                  </div>
                ))}
                <div className={classes.contentButton}>
                  <Accordion
                    elevation={0}
                    id="acc_lenguage"
                    data-testid={`acc_lenguage`}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon/>}
                      aria-controls="panel1a-content"
                      id="acc_panel1"
                    >
                      <div className={classes.contentSumary}>
                        <p data-testid="change_lenguage">
                          {t("components.header.Change language")}
                        </p>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails classes={{root: classes.contentMenu}}>
                      <div>
                        {languagesSelect.map((lenguage, index) => {
                          return (
                            <div key={index} className={classes.contentButton}>
                              <Button
                                onClick={() => {
                                  setMenu(headerFunctions().handleClose());
                                  handleChangeLanguage(lenguage);
                                }}
                                type="cuaternary"
                                text={lenguage.display}
                                css={classes.buttonStyle}
                                data-testid={`btn_lenguage_${index}`}
                                id={`btn_lenguage_${index}`}
                              />
                            </div>
                          );
                        })}
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
                <div className={classes.contentButton}>
                  {user ? (
                    <Avatar
                      className={classes.avatar}
                      data-testid="img_avatar"
                      id="img_avatar"
                      src={user.avatar}
                      onClick={() => history.push("/profile")}
                    />
                  ) : (
                    <Button
                      type="cuaternary"
                      text="components.button.Login"
                      css={classes.buttonStyle}
                      onClick={handleLogin}
                      data-testid="btn_login"
                      id="btn_login"
                    />
                  )}
                </div>
              </div>
            </Popover>
          </>
        ) : (

          <>
            <nav>
              <ul>
                {buttons.map(({text, id, href}, index) => {
                  if (text !== "components.button.Donate") {
                    return (
                      <li id={id} key={index}>
                        <a data-testid={id} href={href}>
                          {t(text)}
                        </a>
                      </li>
                    );
                  }
                })}
              </ul>
            </nav>
            <div className={classes.button}>
              <Button
                data-testid="btn_donate"
                text="components.button.Donate"
                type="secondary"
                id="btn_donate"
                onClick={() => history.push(DONATE)}
              />
            </div>
            {user ? (
              <Avatar
                className={classes.avatar}
                data-testid="avatar_user"
                src={user.avatar}
                onClick={() => history.push("/profile")}
              />
            ) : (
              <div className={classes.contentButtonLogin}>
                <p data-testid="btn_login" id="btn_login" onClick={handleLogin}>
                  {t("components.button.Login")}
                </p>
              </div>
            )}
            <div>
              <Select
                options={languagesSelect}
                onChangeSelect={handleChangeLanguage}
                selected={lenguageSelected}
                icon={
                  <Icon
                    icon={
                      mode === "dark" || mode === "pink"
                        ? ArrowIconLight
                        : ArrowIconDark
                    }
                    alt="arrow"
                  />
                }
                css={classes.select}
                optionsCss={classes.options}
                id="cmb_language"
              />
            </div>
          </>
        )}
      </Grid>


    </Grid>
  );
}

HeaderStyled.propTypes = {
  mode: PropTypes.string.isRequired,
};
