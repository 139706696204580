import React, { useRef, useState, useEffect } from "react";
import { useStyles } from "./styles";
import { useFormik } from "formik";
import { Modal, Fade, Backdrop, Avatar } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import moment from "moment";
import Button from "components/Button";
import IconClose from "assets/icons/Close.svg";
import Icon from "components/Icon";
import Input from "components/Input";
import IconPause from "assets/icons/PauseWhite.svg";
import IconPlay from "assets/icons/PlayWhite.svg";
import OfferingIcon from "assets/icons/Offering.svg";
import FireIcon from "assets/icons/Fire.svg";
import Fire from "assets/icons/vatican_news.svg";
import PopePhotoLarge from "assets/icons/Pope_photo_large.svg";
import GradientScroll from "react-gradient-scroll-indicator";
import AudioPlayer from "components/AudioPlayer";
import moreIcon from "assets/icons/More Vertical.svg";
import Popover from "@material-ui/core/Popover";
import CommentsIcon from "assets/icons/Comments.svg";
import * as Yup from "yup";
import { getUser, addReported, findReported } from "services/authService";
import SocialSection from "../SocialSection";
import AmenButton from "components/AmenButton";
import { generateShareLink } from "pages/SharePrayer/SharePrayerFunctions";
import { MONTHLY } from "../../navigation/CONSTANTS";

export function SinglePrayerStyled(props) {
  const { t, i18n } = useTranslation();
  const [popoverReport, setPopoverReport] = useState({
    anchorEl: null,
    open: false
  });
  const [popoverComment, setPopoverComment] = useState({
    [0]: {
      anchorEl: null,
      open: false
    },
    [1]: {
      anchorEl: null,
      open: false
    }
  });
  const [isOpenComments, setIsOpenComments] = useState(false);
  const [reported, setReported] = useState(false);
  const [totalComments, setTotalComments] = useState(0);
  const [Edit, setEdit] = useState({
    isEdit: false,
    comment: null
  });
  const offeringRef = useRef(null);
  const titleRef = useRef(null);

  const defaultHandleClose = () => {
    setOpen(false);
  };

  const {
    prayers = [],
    currentPrayerId,
    handleChangePrayer,
    prayerDate,
    style,
    icon,
    textHover,
    title,
    description,
    postImage,
    created_at,
    isTotalDescription,
    audio,
    open,
    handleClose = defaultHandleClose,
    handleMultimedia,
    handleDislike,
    handleFavorite,
    isPlay,
    isModal,
    isSmall,
    css,
    owner,
    ownerGroup,
    ownerType,
    isPopePrayer = false,
    offering = null,
    isPrayer = true,
    index = 0,
    video = null,
    isCommunity = false,
    isDaily = false,
    avatar,
    totalComments: numberTotalComments,
    comments,
    handlePostComments,
    handleReportPost,
    handleMoreComments,
    handleEditComments,
    handleReportComment,
    handleDeletePrayer,
    handleDeleteComment,
    person,
    isReported,
    isMonthly,
    indexAccordion,
    typeMode
  } = props;
  useEffect(() => {
    setReported(isReported);
  }, [isReported]);
  useEffect(() => {
    setTotalComments(numberTotalComments);
  }, [title]);
  const [isOpen, setOpen] = useState(false);
  const handleCloseModal = () => {
    setOpen(false);
  };
  const classes = useStyles({
    mediaQuerySm: isSmall,
    audio: audio,
    isTotalDescription: isTotalDescription,
    isModal: isModal,
    isPopePrayer: isPopePrayer,
    isDaily: isDaily
  });
  const scrollRef = useRef(null);
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = 0; // Restablecer la posición del scroll al inicio
    }
  }, [currentPrayerId]);

  function generateMonthlyLink(accordionIndex) {
    return window.location.origin + `${MONTHLY}/${accordionIndex}`;
  }
  useEffect(() => {
    if (currentPrayerId && prayers.length > 0) {
      const currentPrayer = prayers.find(
        (prayer) => prayer.id === currentPrayerId
      );

      if (currentPrayer?.typeMode === "morning") {
        // Desplazar al subtítulo "Oración de ofrecimiento"
        setTimeout(() => {
          if (offeringRef.current) {
            offeringRef.current.scrollIntoView({ behavior: "smooth" });
          }
        }, 500); // Ajusta el tiempo si es necesario
      } else if (currentPrayer?.typeMode === "afternoon") {
        // Desplazar al título de la oración mensual
        setTimeout(() => {
          if (titleRef.current) {
            titleRef.current.scrollIntoView({ behavior: "smooth" });
          }
        }, 500); // Ajusta el tiempo si es necesario
      }
    }
  }, [currentPrayerId, prayers]);
  const user = getUser();
  const CommentScheme = Yup.object().shape({
    comment: Yup.string()
      .required(t("components.community.Comment required"))
      .test(
        "required",
        t("components.community.Comment required"),
        (val) => !!val?.trim().length
      )
      .max(300, t("components.community.max characters", { max: 300 }))
  });
  const initiallValueComment = {
    comment: ""
  };
  const formikComment = useFormik({
    initialValues: initiallValueComment,
    validationSchema: CommentScheme,
    enableReinitialize: true
  });

  const contentDynamicIcon = (id, icon, alt) => {
    return <Icon id={id} data-testid={id} icon={icon} alt={alt} />;
  };

  const contentPlayPauseIcon = (isPlay, index) => {
    const id = isPlay ? `ico_pause_${index}` : `ico_play_${index}`;
    const icon = isPlay ? IconPause : IconPlay;
    const alt = "icon multimedia";
    return contentDynamicIcon(id, icon, alt);
  };

  const validateTransalateExist = (key) => {
    const originalFallbackLng = i18n.options.fallbackLng;
    i18n.options.fallbackLng = false;

    const translationExists = i18n.exists(key);

    i18n.options.fallbackLng = originalFallbackLng;
    return translationExists;
  };

  const nativeShareButton = () => {
    const url = generateShareLink(ownerType, { id: owner, title });
    return typeof window.navigator?.share === "function" ? (
      <Button
        onClick={() => {
          window.navigator.share({
            url: url,
            text: `${t(title)}\n`
          });
        }}
        id={`btn_singlePrayer_share`}
        type="cuaternary"
        text={t("components.community.Share prayer")}
      />
    ) : (
      <SocialSection title={t(title)} url={url} />
    );
  };

  const popoverOptions = () => {
    return (
      <div>
        <Button
          aria-describedby="popover_posts"
          type="transparent"
          css={classes.iconMore}
          icon={<Icon icon={moreIcon} />}
          onClick={(event) => {
            setPopoverReport({
              ...popoverReport,
              anchorEl: !popoverReport.open ? event.currentTarget : undefined,
              open: !popoverReport.open
            });
          }}
        />
        <Popover
          open={popoverReport.open}
          id="popover_posts"
          anchorEl={popoverReport.anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          onClose={() => {
            setPopoverReport({
              ...popoverReport,
              anchorEl: undefined,
              open: false
            });
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left"
          }}
        >
          <div className={classes.contentButtonsReport}>
            {(isCommunity || isPopePrayer) && isSmall && nativeShareButton()}
            {user?.id !== person?.id && !isPopePrayer && (
              <Button
                onClick={async () => {
                  setPopoverReport({
                    ...popoverReport,
                    anchorEl: undefined,
                    open: false
                  });
                  addReported(owner, "CPY");
                  let resp = await handleReportPost();
                  if (resp) {
                    setReported(true);
                  } else {
                    setReported(false);
                  }
                }}
                id={`btn_singlePrayer_report`}
                type="cuaternary"
                text={
                  !reported
                    ? t("components.community.Report")
                    : t("components.community.Reported")
                }
                disabled={reported}
              />
            )}
            {user && user?.id === person?.id && (
              <Button
                onClick={() => {
                  setPopoverReport({
                    ...popoverReport,
                    anchorEl: undefined,
                    open: false
                  });
                  if (handleDeletePrayer) handleDeletePrayer();
                }}
                id={`btn_singlePrayer_delete`}
                type="cuaternary"
                css={classes.buttonDeletePrayer}
                text={t("components.community.Delete")}
              />
            )}
          </div>
        </Popover>
      </div>
    );
  };

  const descriptionContent = () => {
    return (
      <div>
        {video && (
          <div className={classes.video}>
            <iframe
              width="100%"
              height="100%"
              src={video}
              title="YouTube video player"
              allowFullScreen
              data-testid="video_pope"
            ></iframe>
          </div>
        )}
        <div id={`txt_sentence_${index}`} className={classes.contentSentence}>
          {(isPopePrayer || isCommunity) && (
            <div className={classes.postImageBox}>
              {postImage && (
                <img
                  className={classes.postImage}
                  src={postImage.url}
                  alt={postImage.original_name}
                />
              )}
              <p className={classes.popeTitle}>{title}</p>
            </div>
          )}

          {isTotalDescription ? (
            <div className={classes.paragraph}>
              <p
                data-testid={`txt_sentence_${index}`}
                className={classes.sentence}
              >
                {description}
              </p>
            </div>
          ) : (
            <GradientScroll
              ref={scrollRef} // Referencia para controlar el scroll
              fadeColor="rgba(255,255,255,0)"
              primaryColor="#ffffff"
              fadeHeight="20px"
            >
              <div ref={titleRef}>
                <p
                  data-testid={`txt_sentence_${index}`}
                  className={classes.sentence}
                >
                  {description}
                </p>
              </div>

              {offering && (
                <div
                  id={`txt_sentence_offering_${index}`}
                  data-testid="offering"
                  className={classes.offeringContent}
                  ref={offeringRef}
                >
                  <div
                    id={`txt_offering_subtitle_${index}`}
                    className={classes.textHoverContent}
                  >
                    <p data-testid={`txt_offering_subtitle_${index}`}>
                      {t(offering?.textHover)}
                    </p>
                  </div>
                  <div
                    id={`txt_offering_title_${index}`}
                    className={classes.titleContent}
                  >
                    <h1 data-testid={`txt_offering_title_${index}`}>
                      {offering?.title}
                    </h1>
                  </div>
                  <p
                    data-testid={`txt_offering_sentence_${index}`}
                    className={classes.sentence}
                  >
                    {offering?.description}
                  </p>
                </div>
              )}
            </GradientScroll>
          )}
        </div>
        {prayers && prayers.length > 0 && (
          <div className={classes.prayerSelectionContainer}>
            <div className={classes.prayerButtonsContainer}>
              {prayers
                .filter((prayer) => {
                  if (typeMode === "morning") {
                    return prayer.typeMode === "offering";
                  } else if (typeMode === "afternoon") {
                    return prayer.typeMode === "monthly";
                  } else {
                    return false;
                  }
                })
                .map((pray) => (
                  <Button
                    key={pray.id}
                    type="cuaternary"
                    css={classes.buttonWithIcon}
                    onClick={() => handleChangePrayer(pray.id, true)}
                  >
                    <Icon
                      icon={
                        typeMode === "morning"
                          ? OfferingIcon
                          : typeMode === "afternoon"
                          ? FireIcon
                          : null
                      }
                      alt={pray.typeMode}
                    />
                    <span>
                      {typeMode === "morning"
                        ? t("components.accordionMonthly.Offering Prayer")
                        : typeMode === "afternoon"
                        ? t("components.accordionMonthly.Monthly Prayer")
                        : pray.title}
                    </span>
                  </Button>
                ))}
            </div>
          </div>
        )}
        {isPrayer && (
          <div className={classes.actionsSinglePrayerContent}>
            <div>
              <AmenButton
                index={index}
                owner={owner}
                ownerGroup={ownerGroup}
                ownerType={ownerType}
                handleAmen={handleFavorite}
                handleUnAmen={handleDislike}
                testid={`btn_singlePrayer_favorite`}
              />
            </div>
            <div className={classes.buttonContent}>
              {(isCommunity || isPopePrayer) && (
                <Button
                  onClick={() => {
                    formikComment.resetForm();
                    setEdit({ isEdit: false, comment: null });
                    setIsOpenComments(!isOpenComments);
                    if (!isOpenComments) handleMoreComments();
                  }}
                  id={`btn_singlePrayer_comment`}
                  type="cuaternary"
                  text={
                    !isSmall
                      ? `${totalComments} ${t(`components.community.Comments`)}`
                      : null
                  }
                  iconLeft={<Icon icon={CommentsIcon} alt="comments" />}
                  css={classes.buttonComments}
                />
              )}

              {isSmall && (isCommunity || isPopePrayer) && popoverOptions()}
              {!isSmall || (!isCommunity && !isPopePrayer) ? (
                <SocialSection
                  description={description}
                  title={!isPopePrayer ? t(title) : title}
                  date={prayerDate}
                  url={
                    isMonthly
                      ? generateMonthlyLink(indexAccordion)
                      : generateShareLink(ownerType, {
                          id: owner,
                          title,
                          prayerDate,
                          translatedTitle: t(title)
                        })
                  }
                />
              ) : null}
            </div>
          </div>
        )}
      </div>
    );
  };

  const popoverCommentOptions = (index, comment) => {
    return (
      <div>
        <Button
          aria-describedby={`popover_comment${comment.id}`}
          type="transparent"
          css={classes.iconMoreComment}
          icon={<Icon icon={moreIcon} />}
          onClick={(event) => {
            setPopoverComment({
              ...popoverComment,
              [index]: {
                anchorEl: !popoverComment[index].open
                  ? event.currentTarget
                  : undefined,
                open: !popoverComment[index].open
              }
            });
          }}
        />
        <Popover
          open={popoverComment[index].open}
          id={`popover_comment${comment.id}`}
          anchorEl={popoverComment[index].anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          onClose={() => {
            setPopoverComment({
              ...popoverComment,
              [index]: {
                anchorEl: undefined,
                open: false
              }
            });
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left"
          }}
        >
          <div className={classes.contentButtonsReport}>
            {user && user?.id === comment?.user?.id && (
              <>
                <Button
                  onClick={() => {
                    setPopoverComment({
                      ...popoverComment,
                      [index]: {
                        anchorEl: undefined,
                        open: false
                      }
                    });
                    if (handleDeleteComment) {
                      handleDeleteComment(comment.id);
                      setTotalComments((prev) => (prev <= 0 ? 0 : prev - 1));
                    }
                  }}
                  id={`btn_comment_delete`}
                  type="cuaternary"
                  css={classes.buttonDeletePrayer}
                  text={t("components.community.Delete")}
                />
                <Button
                  onClick={() => {
                    if (!comment.reported) {
                      formikComment.setFieldValue(
                        "comment",
                        comment.description
                      );
                      setEdit({ isEdit: true, comment: comment });
                    }
                  }}
                  type="cuaternary"
                  data-testid="txt_Edit"
                >
                  {!comment.reported
                    ? t(`components.community.Edit`)
                    : t("components.community.Reported")}
                </Button>
              </>
            )}
          </div>
        </Popover>
      </div>
    );
  };

  const commentsComponent = () => {
    return (
      <div className={classes.contentComments}>
        {comments?.map((comment, index) => {
          return (
            <div key={index} className={classes.comment}>
              <div className={classes.commentHeader}>
                <div>
                  <h3 data-testid={"txt_name_user"}>
                    {comment?.user?.first_name
                      ? comment?.user?.first_name +
                        " " +
                        comment?.user?.last_name
                      : null}
                  </h3>
                </div>
                <div>
                  <p>
                    {moment(comment.created_at).format("LLL")}{" "}
                    {moment(comment.created_at).format("hh:mma")}
                  </p>
                  {user?.id === comment?.user?.id
                    ? popoverCommentOptions(index, comment)
                    : user && (
                        <p
                          onClick={() => {
                            if (!findReported(comment.id, "COM")) {
                              handleReportComment(comment);
                              addReported(comment.id, "COM");
                            }
                          }}
                          data-testid="txt_report"
                        >
                          {!findReported(comment.id, "COM")
                            ? t(`components.community.Report`)
                            : t("components.community.Reported")}
                        </p>
                      )}
                </div>
              </div>
              <div className={classes.commentBody}>
                <p>{comment.description}</p>
              </div>
            </div>
          );
        })}

        <div className={classes.comment}>
          <form className={classes.formComment}>
            <Input
              isSucess={
                !formikComment.errors.comment &&
                formikComment.touched.comment &&
                formikComment.dirty
              }
              isError={
                formikComment.errors.comment && formikComment.touched.comment
              }
              name="comment"
              label={t(`components.community.Add a comment`)}
              isRequired
              multiline
              css={classes.input}
              id="txt_comment_community"
              onChange={formikComment.handleChange}
              onBlur={formikComment.handleBlur}
              message={formikComment.errors.comment}
              value={formikComment.values.comment}
            />
            <div>
              <Button
                type="primary"
                data-testid={`btn_singlePrayer_submit_comment`}
                onClick={async () => {
                  if (Edit.isEdit) {
                    handleEditComments(formikComment.values, Edit.comment);
                    setEdit({ isEdit: false, comment: null });
                  } else {
                    let respComents = await handlePostComments(
                      formikComment.values
                    );

                    if (respComents === "OK") {
                      setTotalComments((prev) => prev + 1);
                    }
                  }
                  formikComment.resetForm();
                }}
                text={
                  Edit.isEdit
                    ? t(`components.community.Edit`)
                    : t(`components.community.Submit`)
                }
                disabled={!(formikComment.isValid && formikComment.dirty)}
              />
            </div>
          </form>
        </div>
      </div>
    );
  };

  const contentSinglePrayer = (isCancelModal) => {
    return (
      <div className={`${classes.contentSinglePrayer}`}>
        <div className={classes.singlePrayer}>
          {isCancelModal && (
            <div className={classes.closeIconContent}>
              <Icon
                onClick={() => {
                  if (audio) {
                    handleMultimedia(null, "cancel");
                    handleClose();
                  } else {
                    handleClose();
                  }
                }}
                icon={IconClose}
                alt="close"
                id={`ico_singlePrayer_close_modal`}
                data-testid={`ico_singlePrayer_close_modal`}
              />
            </div>
          )}
          <div className={classes.wrapperSinglePrayer}>
            <div
              className={classes.contentTitleAndSubtitle}
              id={`prayer${index}`}
            >
              {audio && (
                <Button
                  data-testid={`btn_singlePrayer_multimedia_${index}`}
                  onClick={() => handleMultimedia(null, "button")}
                  css={classes.buttonMultimedia}
                  icon={contentPlayPauseIcon(isPlay, index)}
                />
              )}
              {/* Title */}
              {!isPopePrayer && !isCommunity ? (
                <div>
                  {
                    <div
                      id={`txt_content_icon_${index}`}
                      data-testid={`txt_content_icon_${index}`}
                      className={classes.textHoverContent}
                    >
                      <Icon
                        data-testid={`ico_singlePrayer_${index}`}
                        id={`ico_singlePrayer_${index}`}
                        icon={typeMode === "offering" ? OfferingIcon : icon}
                        alt="type"
                      />
                      <p data-testid={`txt_textHover_${index}`}>
                        {t(textHover)}
                      </p>
                    </div>
                  }
                  <div
                    id={`txt_title_${index}`}
                    className={classes.titleContent}
                  >
                    <h1 data-testid={`txt_title_${index}`}>{t(title)}</h1>
                  </div>

                  {validateTransalateExist(
                    "components.prayCard.link_vatican_new_pray"
                  ) &&
                    typeMode &&
                    typeMode === "morning" && (
                      <div
                        id={`txt_content_icon_${index}`}
                        data-testid={`txt_content_icon_${index}`}
                        className={classes.textHoverContent}
                        style={{ margin: "1em 0 0.25em", cursor: "pointer" }}
                        onClick={() =>
                          window.open(
                            t("components.prayCard.link_vatican_new_pray"),
                            "_blank"
                          )
                        }
                      >
                        <Icon
                          data-testid={`ico_singlePrayer_${index}`}
                          id={`ico_singlePrayer_${index}`}
                          icon={Fire}
                          alt="type"
                        />
                        <p data-testid={`txt_textHover_${index}`}>
                          {t("components.prayCard.title_vatican_new_pray")}
                        </p>
                      </div>
                    )}
                </div>
              ) : (
                <div className={classes.header}>
                  <div className={`${classes.popeHeader}`}>
                    <div>
                      <Avatar
                        id={"ico_pope_single_prayer"}
                        data-testid={"ico_pope_single_prayer"}
                        className={classes.avatar}
                        src={isCommunity ? avatar : PopePhotoLarge}
                      />
                    </div>
                    <div>
                      <p className={classes.nameUserPost}>
                        {!isCommunity
                          ? t("components.prayCard.Pope Francis")
                          : person?.first_name
                          ? person?.first_name + " " + person?.last_name
                          : null}
                      </p>
                      <p>
                        {moment(created_at).format("LLL")}{" "}
                        {moment(created_at).format("hh:mma")}
                      </p>
                    </div>
                  </div>
                  {isCommunity && !isSmall && popoverOptions()}
                </div>
              )}
            </div>

            {descriptionContent()}
            {isOpenComments && commentsComponent()}
          </div>
        </div>

        {audio ? (
          <div className={classes.contentAudioPlayer}>
            <div>
              <AudioPlayer
                src={audio.url}
                playing={isPlay}
                toggleAudio={handleMultimedia}
              />
            </div>
          </div>
        ) : null}
      </div>
    );
  };

  return (
    <>
      {isModal ? (
        <Modal
          open={open && isModal}
          onClose={() => {
            if (audio) {
              handleMultimedia(null, "cancel");
              handleCloseModal();
            } else {
              handleCloseModal();
            }
          }}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500
          }}
          className={classes.singlePrayerContent}
          data-testid="singlePrayer-content_modal"
        >
          <Fade in={open}>{contentSinglePrayer(true)}</Fade>
        </Modal>
      ) : (
        <div
          className={`${classes.singlePrayerContent} ${css}`}
          data-testid="singlePrayer-content_card"
          style={style}
        >
          {contentSinglePrayer(false)}
          {isOpen && (
            <Modal
              open={isOpen}
              onClose={handleCloseModal}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500
              }}
              className={classes.singlePrayerContent}
              data-testid="singlePrayer-content_modal"
            >
              <Fade in={isOpen}>{contentSinglePrayer(true)}</Fade>
            </Modal>
          )}
        </div>
      )}
    </>
  );
}
