import React from "react";
import { useTranslation } from "react-i18next";
import { useStyles } from "./styles";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

const LegalView = (props) => {
  const { isMd } = props;
  const { t } = useTranslation();
  const classes = useStyles({
    mediaQueryMd: isMd,
  });

  return (
    <div data-testid={`monthly_view`} className={classes.Container}>
      <section className={classes.Content}>
        {/* LEGALES */}
        <div id="txt_title_legal" className={classes.wrapperTop}>
          <h1 data-testid={`txt_title`}>{t("Legal.title")}</h1>
          <h2>{t("Legal.subtitle1")}</h2>
          <p>{t("Legal.p1")}</p>
          <p>{t("Legal.telephone")}</p>
          <p>{t("Legal.fax")}</p>
          <p>{t("Legal.contact title")}</p>
          <p>{t("Legal.mail")}</p>
          <p>{t("Legal.p2")}</p>
          <h2>{t("Legal.subtitle2")}</h2>
          <p>{t("Legal.p3")}</p>
          <p>{t("Legal.p4")}</p>
          <h2>{t("Legal.subtitle3")}</h2>
          <p>{t("Legal.p5")}</p>
          <p>{t("Legal.p6")}</p>
          <p>{t("Legal.subtilte4")}</p>
          <p>{t("Legal.p7")}</p>
          <p>{t("Legal.p8")}</p>
          <p>{t("Legal.p9")}</p>
          <p>{t("Legal.p10")}</p>
          <p>{t("Legal.p11")}</p>
          <p>{t("Legal.p12")}</p>
          <h2>{t("Legal.subtitle5")}</h2>
          <p>{t("Legal.p13")}</p>
          <p>{t("Legal.p14")}</p>
          <p>{t("Legal.p15")}</p>
          <h2>{t("Legal.subtitle6")}</h2>
          <p>{t("Legal.p16")}</p>
          <p>{t("Legal.p17")}</p>
          <h2>{t("Legal.subtitle7")}</h2>
          <p>{t("Legal.p18")}</p>
          <p>{t("Legal.p19")}</p>
          <p>{t("Legal.p20")}</p>
          <h2>{t("Legal.subtitle8")}</h2>
          <p>{t("Legal.p21")}</p>
          <p>{t("Legal.p22")}</p>
          <p>{t("Legal.p23")}</p>
          <h2>{t("Legal.subtitle9")}</h2>
          <p>{t("Legal.p24")}</p>
          <p>{t("Legal.p25")}</p>
          <p>{t("Legal.p25-1")}</p>
          <p>{t("Legal.p25-2")}</p>
          <p>{t("Legal.p25-3")}</p>
          <p>{t("Legal.p25-4")}</p>
          <p>{t("Legal.p25-5")}</p>
          <p>{t("Legal.p25-6")}</p>
          <p>{t("Legal.p25-7")}</p>
          <p>{t("Legal.p25-8")}</p>
          <p>{t("Legal.p25-9")}</p>
          <p>{t("Legal.p25-10")}</p>
          <p>{t("Legal.p25-11")}</p>
          <p>{t("Legal.p25-12")}</p>
          <p>{t("Legal.p25-13")}</p>
          <p>{t("Legal.p25-14")}</p>
          <p>{t("Legal.p25-15")}</p>
          <p>{t("Legal.p25-16")}</p>
          <p>{t("Legal.p25-17")}</p>
          <p>{t("Legal.p26")}</p>
          <p>{t("Legal.p27")}</p>
          <h2>{t("Legal.subtitle10")}</h2>
          <p>{t("Legal.p28")}</p>
          <p>{t("Legal.p29")}</p>
          <p>{t("Legal.p30")}</p>
          <p>{t("Legal.p31")}</p>
          <p>{t("Legal.p31-1")}</p>
          <p>{t("Legal.p31-2")}</p>
          <p>{t("Legal.p31-3")}</p>
          <p>{t("Legal.p32")}</p>
          <h2>{t("Legal.subtitle11")}</h2>
          <p>{t("Legal.p33")}</p>
          <p>{t("Legal.p33-1")}</p>
          <p>{t("Legal.p33-2")}</p>
          <p>{t("Legal.p34")}</p>
          <p>{t("Legal.p34-1")}</p>
          <p>{t("Legal.p34-2")}</p>
          <p>{t("Legal.p34-3")}</p>
          <p>{t("Legal.p34-4")}</p>
          <p>{t("Legal.p34-5")}</p>
          <h2>{t("Legal.subtitle12")}</h2>
          <p>{t("Legal.p35")}</p>
          <p>{t("Legal.p36")}</p>
          <h2>{t("Legal.subtitle13")}</h2>
          <p>{t("Legal.p37")}</p>
          <p>{t("Legal.p37-1")}</p>
          <p>{t("Legal.p37-2")}</p>
          <p>{t("Legal.p37-3")}</p>
          <p>{t("Legal.p37-4")}</p>
          <p>{t("Legal.p37-5")}</p>
          <p>{t("Legal.p38")}</p>
          <h2>{t("Legal.subtitle14")}</h2>
          <p>{t("Legal.p39")}</p>
          <p>{t("Legal.p40")}</p>
          <h2>{t("Legal.subtitle15")}</h2>
          <p>{t("Legal.p41")}</p>
          <p>{t("Legal.p42")}</p>
          <p>{t("Legal.p43")}</p>
          <p>{t("Legal.p44")}</p>
          <h2>{t("Legal.subtitle16")}</h2>
          <p>{t("Legal.p45")}</p>
          <p>{t("Legal.p46")}</p>
          <h2>{t("Legal.subtitle17")}</h2>
          <p>{t("Legal.p47")}</p>
          <p>{t("Legal.p48")}</p>
          <p>{t("Legal.p49")}</p>
          <h2>{t("Legal.subtitle18")}</h2>
          <p>{t("Legal.p50")}</p>
        </div>

        {/* POLITICAS DE PRIVACIDAD */}
        <div id="txt_title_politics" className={classes.wrapperSection}>
          <h1 data-testid={`txt_politics_title`}>{t("Politics.title")}</h1>
          <h2>{t("Politics.subtitle1")}</h2>
          <p>{t("Politics.p1")}</p>
          <p>{t("Politics.p2")}</p>
          <p>{t("Politics.p3")}</p>
          <h2>{t("Politics.subtitle2")}</h2>
          <p>{t("Politics.adress")}</p>
          <p>{t("Politics.telephone")}</p>
          <p>{t("Politics.fax")}</p>
          <p>{t("Politics.mail label")}</p>
          <p>{t("Politics.mail data")}</p>
          <h2>{t("Politics.subtitle3")}</h2>
          <p>{t("Politics.p4")}</p>
          <p>{t("Politics.p4-1")}</p>
          <p>{t("Politics.p4-2")}</p>
          <p>{t("Politics.p4-3")}</p>
          <p>{t("Politics.p5")}</p>
          <p>{t("Politics.p6")}</p>
          <p>{t("Politics.p7")}</p>
          <h2>{t("Politics.subtitle4")}</h2>
          <p>{t("Politics.p8")}</p>
          <p>{t("Politics.p9")}</p>
          <p>{t("Politics.p10")}</p>
          <p>{t("Politics.p11")}</p>
          <h2>{t("Politics.subtitle5")}</h2>
          <p>{t("Politics.p12")}</p>
          <p>{t("Politics.p13")}</p>
          <p>{t("Politics.p13-1")}</p>
          <p>{t("Politics.p13-2")}</p>
          <p>{t("Politics.p13-3")}</p>
          <p>{t("Politics.p13-4")}</p>
          <p>{t("Politics.p13-5")}</p>
          <p>{t("Politics.p13-6")}</p>
          <p>{t("Politics.p13-7")}</p>
          <p>{t("Politics.p13-8")}</p>
          <p>{t("Politics.p13-9")}</p>
          <h2>{t("Politics.subtitle6")}</h2>
          <p>{t("Politics.p14")}</p>
          <p>{t("Politics.p14-1")}</p>
          <p>{t("Politics.p14-2")}</p>
          <p>{t("Politics.p14-3")}</p>
          <p>{t("Politics.p14-4")}</p>
          <p>{t("Politics.p14-5")}</p>
          <p>{t("Politics.p15")}</p>
          <p>{t("Politics.p16")}</p>
          <p>{t("Politics.p16-1")}</p>
          <p>{t("Politics.p16-2")}</p>
          <p>{t("Politics.p17")}</p>
          <p>{t("Politics.p18")}</p>
          <p>{t("Politics.edps mail")}</p>
        </div>

        {/* COOKIES */}
        <div id="txt_title_cookies" className={classes.wrapperSection}>
          <h1 data-testid={`txt_cookies_title`}>{t("Cookies.title")}</h1>
          <h2>{t("Cookies.subtitle1")}</h2>
          <p>{t("Cookies.p1")}</p>
          <h2>{t("Cookies.subtitle2")}</h2>
          <p>{t("Cookies.p2")}</p>
          <h2>{t("Cookies.subtitle3")}</h2>
          <p>{t("Cookies.p3")}</p>
          <p>{t("Cookies.p3-1")}</p>
          <p>{t("Cookies.p3-2")}</p>
          <p>{t("Cookies.p3-3")}</p>
          <p>{t("Cookies.p4")}</p>
          <p>{t("Cookies.p4-1")}</p>
          <p>{t("Cookies.p4-2")}</p>
          <p>{t("Cookies.p4-3")}</p>
          <p>{t("Cookies.p4-4")}</p>
          <p>{t("Cookies.p4-5")}</p>
          <h2>{t("Cookies.subtitle4")}</h2>
          <p>{t("Cookies.p5")}</p>
          <p>{t("Cookies.p6")}</p>

          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>{t("Cookies.tab1_col1_head")}</TableCell>
                  <TableCell align="right">
                    {t("Cookies.tab1_col2_head")}
                  </TableCell>
                  <TableCell align="right">
                    {t("Cookies.tab1_col3_head")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow key={`table1_row`}>
                  <TableCell component="th" scope="row">
                    {t("Cookies.tab1_col1_row1")}
                  </TableCell>
                  <TableCell align="right">
                    {t("Cookies.tab1_col2_row1")}
                  </TableCell>
                  <TableCell align="right">
                    {t("Cookies.tab1_col3_row1")}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <p>{t("Cookies.p7")}</p>

          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>{t("Cookies.tab2_col1_head")}</TableCell>
                  <TableCell align="right">
                    {t("Cookies.tab2_col2_head")}
                  </TableCell>
                  <TableCell align="right">
                    {t("Cookies.tab2_col3_head")}
                  </TableCell>
                  <TableCell align="right">
                    {t("Cookies.tab2_col4_head")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow key={`table2_row1`}>
                  <TableCell component="th" scope="row">
                    {t("Cookies.tab2_col1_row1")}
                  </TableCell>
                  <TableCell align="right">
                    {t("Cookies.tab2_col2_row1")}
                  </TableCell>
                  <TableCell align="right">
                    {t("Cookies.tab2_col3_row1")}
                  </TableCell>
                  <TableCell align="right">
                    {t("Cookies.tab2_col4_row1")}
                  </TableCell>
                </TableRow>
                <TableRow key={`table2_row2`}>
                  <TableCell component="th" scope="row">
                    {t("Cookies.tab2_col1_row2")}
                  </TableCell>
                  <TableCell align="right">
                    {t("Cookies.tab2_col2_row2")}
                  </TableCell>
                  <TableCell align="right">
                    {t("Cookies.tab2_col3_row2")}
                  </TableCell>
                  <TableCell align="right">
                    {t("Cookies.tab2_col4_row2")}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <p>{t("Cookies.p8")}</p>
          <h2>{t("Cookies.subtitle5")}</h2>
          <p>{t("Cookies.p9")}</p>
          <p>{t("Cookies.browser ie")}</p>
          <p>{t("Cookies.browser safari")}</p>
          <p>{t("Cookies.browser chrome")}</p>
          <p>{t("Cookies.browser firefox")}</p>
          <h2>{t("Cookies.subtitle6")}</h2>
          <p>{t("Cookies.p10")}</p>
          <p>{t("Cookies.p11")}</p>
          <h2>{t("Cookies.subtitle7")}</h2>
          <p>{t("Cookies.p12")}</p>
        </div>
      </section>
    </div>
  );
};

export default LegalView;
