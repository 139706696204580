export function parseUploadImage(file, setUploadImage, setFailFormat) {
  setFailFormat(false);
  const reader = new FileReader();
  if (['image/png','image/jpg', 'image/jpeg'].includes(file.type)) {
    file.name.replace(/(.jpeg)$/ig, '.jpg');
    reader.readAsDataURL(file);
    reader.onloadend = () => {
    setUploadImage(file);
  };
  } else {
    setFailFormat(true);
  }
}
