import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles({
  gral: (props) => {
    const style = {
      color: "#444C67",
      fontFamily: "Nunito Sans",
      padding: "15px",
      paddingTop: "9em",
      paddingBottom: "6em",

      "&>div": { maxWidth: "1400px" },
      backgroundColor: "#f4f6f8",
      "& p": {
        whiteSpace: "break-spaces",
        lineHeight: "24px",
        marginBottom: "24px"
      },
      "& h1": {
        fontFamily: "Libre Baskerville",
        lineHeight: "40px",
        margin: 0
      }
    };
    if (props.mediaQueryMd) {
      style.paddingTop = "6.2em";
      style.paddingBottom = "3em";
    }
    return style;
  },

  buttonBack: (props) => {
    const style = {
      cursor: "pointer",
      width: "max-content",
      "& div": {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        "& span": {
          textTransform: "capitalize",
          width: "max-content",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          marginLeft: "0.3em",
          marginRight: "0.3em"
        }
      }
    };
    if (props.mediaQueryXs) {
      style.marginBottom = "2em";
    }
    return style;
  },

  sectionLogosPartners: (props) => {
    const style = {
      marginTop: "3em",
      gap: "10em",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    };
    if (props.mediaQueryLg) {
      style.flexDirection = "row";
    }
    if (props.mediaQueryXs) {
      style.flexDirection = "row";
      style.gap = "2em";
    }
    return style;
  },
  iconContainer: (props) => {
    const style = {};
    if (props.mediaQueryXs) {
      style.width = "30%";
    }
    if (props.mediaQueryMd) {
      style.width = "auto";
    }
    return style;
  },

  imgCampaign: (props) => {
    const style = {
      height: "24em",
      maxHeight: "400px",
      width: "100%",
      padding: "4px",
      boxSizing: "border-box",
      backgroundPosition: "center center",
      backgroundSize: "100%",
      backgroundRepeat: "no-repeat"
    };
    if (props.mediaQueryMd) {
      style.padding = "6px";
    }
    if (props.mediaQueryXs) {
      style.backgroundSize = "auto 100%";
    }
    return style;
  },

  notStarted: {
    marginLeft: "auto",
    "&>span": {
      marginRight: "1em",
      marginBottom: "1em"
    },
    "& span, & button": {
      display: "inline-block"
    }
  },

  suggestIcon: {
    marginRight: "0.5em",
    verticalAlign: "middle"
  },
  captionCard: {
    margin: "auto",
    padding: "10px",
    boxSizing: "content-box",
    width: "max-content"
  },

  carrusel: (props) => {
    const style = {
      overflow: "visible"
    };
    if (props.mediaQueryLg) {
      style.height = "auto";
    }
    if (props.mediaQueryXs) {
      style.overflow = "visible";
      style.height = "100vh";
      style.height = "auto";
      style.paddingBottom = "0.1em";
    }
    return style;
  },
  carruselBtnWrapper: (props) =>
    props.mediaQueryXs
      ? {
          marginTop: "-3em",
          height: "max-content"
        }
      : "",

  carruselBtn: {
    color: "#444C67",
    height: "1.5em",
    backgroundColor: "transparent",
    opacity: "1!important",
    "&:hover": {
      backgroundColor: "transparent",
      opacity: "1!important"
    }
  },

  modalDownload: {
    display: "flex",
    marginTop: "9em",
    alignItems: "center",
    fontFamily: "Nunito Sans",
    justifyContent: "flex-end",
    flexDirection: "column",
    alignContent: "space-around",
    flexWrap: "nowrap"
  },

  divModalContainer: {
    backgroundColor: "white",
    width: "40%",
    height: "40%",
    padding: "5%",
    borderRadius: "8px"
  },

  divModal: {
    display: "flex",
    justifyContent: "space-evenly"
  },

  modalTitle: {
    display: "flex",
    justifyContent: "space-around",
    flexDirection: "row",
    margin: "0.5em",
    marginBottom: "2rem",
    color: "rgb(68, 76, 103)"
  }
});
