import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles({
  messageErrorsContent: (props) => {
    let style = {
      width: "792px",
      outline: "none",
    };
    if (props.mediaQueryMd) {
      style = {
        ...style,
        width: "100%",
        height: "auto",
      };
    }
    return style;
  },
  messageErrorsModal: (props) => {
    let style = {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      overflowY: "auto",
    };
    if (props.mediaQueryMd) {
      style = {
        ...style,
        width: "100%",
      };
    }
    return style;
  },
  messageErrors: (props) => {
    let style = {
      background: "#FFFFFF",
      padding: "24px 24px 56px 24px",
      borderRadius: "12px",
    };
    if (props.mediaQueryMd) {
      style = {
        ...style,
        height: "100%",
        borderRadius: "none",
        padding: "8px 20px 56px 20px",
      };
    }
    return style;
  },
  wrapperMessageErrors: (props) => {
    let style = {
      margin: "24px 0px 0px 0px",
    };
    if (props.mediaQueryMd) {
      style = {
        margin: "0px",
      };
    }
    return style;
  },
  closeIconContent: (props) => {
    let style = {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      "& >img": {
        cursor: "pointer",
      },
    };
    if (props.mediaQueryMd) {
      style = {
        ...style,
        padding: "12px 0px",
      };
    }
    return style;
  },

  titleContent: (props) => {
    let style = {
      textAlign: "center",
      "&>div": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: "0px 0px 24px 0px",
        "&>svg": {
          fill: "#ECA25E",
          width: "40px",
          height: "40px",
          margin: "0px 10px 0px 0px",
        },
      },
      "& h1": {
        fontFamily: "Libre Baskerville",
        fontWeight: "normal",
        fontSize: "32px",
        color: "#444C67",
        margin: 0,
      },
      "& p": {
        fontFamily: "Nunito Sans",
        fontSize: "16px",
        color: "#5D688E",
        margin: "0px",
      },
    };
    if (props.mediaQueryMd) {
      style = {
        ...style,
        margin: "24px 0px 0px 0px",
      };
      style["& h1"].fontSize = "24px";
      style["&>div"]["&>svg"].width = "30px";
      style["&>div"]["&>svg"].height = "30px";
    }
    return style;
  },
});
