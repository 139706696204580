import React, { useEffect, useState } from "react";
import NotFoundView from "./NotFoundView";

export function NotFoundContainer({ location }) {
  const [error, setError] = useState({
    code: 404,
    title: "pages.notFound.title",
    message: "pages.notFound.message",
    buttonBack: true,
  });

  useEffect(() => {
    if (location.state) {
      setError(location.state);
    }
  }, [location.state]);

  return <NotFoundView {...error} />;
}
