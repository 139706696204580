import React from 'react';
import { useStyles } from './styles';
import { useTranslation } from 'react-i18next';
import AccordionMonthly from 'components/AccordionMonthly';
import VideoPlayer from 'components/VideoPlayer';
import Button from 'components/Button';
import moment from 'moment';

const MonthlyView = (props) => {
  const {
    intentions,
    onChangeAccordion,
    onChangeExpandedMonthly,
    isExpandedMonthly,
    handleFavorite,
    handleDislike,
    handlevideo,
    handleCloseVideo,
    video,
    onChangeSelectLanguage,
    isSmall,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles({ mediaQuerySm: isSmall });
  return (
    <div
      data-testid={`monthly_view`}
      className={classes.intentionMonthlyContent}
    >
      <section className={classes.titleMonthlysContent}>
        <div
          id="txt_title_intentions_page"
          className={classes.wrappertTitleMonthlys}
        >
          <h1 data-testid={`txt_title_intentions_page`}>
            {t(`pages.monthly.Pope’s Intentions`)}
          </h1>
          <h6 data-testid={`txt_changllLenges_page`}>
            {t(
              `pages.monthly.for the challLenges of humanity and mission of the church`
            )}
          </h6>
          <p data-testid={`txt_every_month_page`}>
            {t(
              'pages.monthly.Pope Francis entrusts his Worldwide Prayer Network each month with prayer intentions that express his great concern for humanity and for the mission of the Church. His monthly prayer intention is a worldwide call to transform our prayer into specific actions; it is a compass for a mission of compassion for the world.'
            )}
          </p>
        </div>
      </section>
      <section>
        <div className={classes.wrappertMonthlys}>
          {intentions.length > 0 &&
            intentions.map(
              (
                {
                  description,
                  title,
                  date_from,
                  expanded,
                  id,
                  details,
                  isVideo,
                  background,
                },
                index
              ) => {
                return (
                  <AccordionMonthly
                    index={index}
                    key={index}
                    description={description}
                    title={title}
                    monthly={moment(date_from).format('MMMM')}
                    year={moment(date_from).format('YYYY')}
                    css={classes.accordion}
                    isExpanded={expanded}
                    onChange={(value) => onChangeAccordion(id, value)}
                    handleFavorite={handleFavorite}
                    handleDislike={handleDislike}
                    details={details}
                    onChangeSelectLanguage={(option) =>
                      onChangeSelectLanguage(option, id)
                    }
                    handlevideo={() => handlevideo(id)}
                    isSmall={isSmall}
                    isVideo={isVideo}
                    background={background}
                    isTotalDescription={true}
                  />
                );
              }
            )}
          {isExpandedMonthly && (
            <div className={classes.contentButtonMoreMonthly}>
              <Button
                type="cuaternary"
                onClick={() => onChangeExpandedMonthly()}
                text="pages.monthly.Load Previous Months"
                id={`btn_expanded_monthly`}
              />
            </div>
          )}
        </div>
        {video.isPlay && (
          <VideoPlayer
            src={video?.video}
            open={video?.isPlay}
            handleClose={handleCloseVideo}
            isSmall={isSmall}
          />
        )}
      </section>
    </div>
  );
};

export default MonthlyView;
