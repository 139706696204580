import makeStyles from "@material-ui/core/styles/makeStyles";
import ImageRosary from "assets/img/rosary_image.svg";
import ImageRosarySm from "assets/img/rosary_image_sm.svg";
export const useStyles = makeStyles({
  mainContentRosary: {
    overflowX: "hidden",
  },
  contentRosary: (props) => {
    let style = {
      backgroundImage: `url(${ImageRosary})`,
      backgroundPosition: "top",
      backgroundSize: "100% auto",
      backgroundColor: "#F4F6F8",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      height: "auto",
      width: "100%",
    };
    if (props.mediaQuerySm) {
      style = {
        ...style,
        backgroundImage: `url(${ImageRosarySm})`,
      };
    }

    return style;
  },
  wrapperColorPink: {
    backgroundColor: "rgba(159, 16, 117, 0.5)",
    width: "100%",
  },
  wrappertitleRosary: (props) => {
    let style = {
      display: "flex",
      alignItems: "flex-start",
      flexDirection: "column",
      justifyContent: "flex-start",
      textAling: "start",
      padding: "216px  108px 64px",
      "&>h1": {
        fontFamily: "Libre Baskerville",
        fontWeight: "normal",
        fontSize: "32px",
        color: "#FFFFFF",
        margin: "0px 0px 16px 0px",
      },
      "&>p": {
        fontFamily: "Nunito Sans",
        fontWeight: 600,
        fontSize: "18px",
        color: "#FFFFFF",
        margin: 0,
      },
    };
    if (props.mediaQuerySm) {
      style["&>h1"].margin = "0px 0px 24px 0px";
      style["&>h1"].lineHeight = "none";
      style.padding = "96px 20px 64px";
    }
    return style;
  },
  wrapperAccordion: (props) => {
    let style = {
      width: "90%",
      margin: "120px 0px 120px",
    };
    if (props.mediaQuerySm) {
      style = {
        ...style,
        margin: "64px 0px 64px",
      };
    }
    return style;
  },

  buttonBack: (props) => {
    let style = {
      cursor: "pointer",
      width: "max-content",
      margin: "0px 0px 40px 0px",
      "&>button": {
        padding: "8px 4px 8px 6.25px",
      },
      "& div": {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",

        "& span": {
          fontFamily: "Nunito Sans",
          fontWeight: 600,
          fontSize: "14px",
          marginLeft: "15px",
          marginRight: "0.3em",
          color: "#FFFFFF",
        },
      },
    };
    if (props.mediaQueryXs) {
      style.marginBottom = "2em";
    }
    return style;
  },
  accordionRosary: (props) => {
    let style = {
      margin: "0px 0px 24px 0px",
    };
    if (props.mediaQuerySm) {
      style.margin = "0px 0px 16px 0px";
    }
    return style;
  },
});
