import { getBlogs } from "services/services";

export function sliceArray(array, chunkCount) {
  let chunks = [],
    i,
    j;
  for (i = 0, j = array.length; i < j; i += chunkCount) {
    chunks.push(array.slice(i, i + chunkCount));
  }
  return chunks;
}
export function isOdd(number) {
  if (number % 2 !== 0) {
    return true;
  } else {
    return false;
  }
}
export async function fetchBlogs(setPages, setBlogs) {
  try {
    const response = await getBlogs({ per_page: "6" });
    setPages({
      current_page: response?.current_page,
      to: response?.to,
      total: response?.total,
    });

    setBlogs(sliceArray(response?.data, 2));
  } catch (error) {
    console.log(error);
  }
}
export async function handleMoreBlogs(pages, blogs, setPages, setBlogs) {
  try {
    const response = await getBlogs({
      per_page: "6",
      page: pages?.current_page + 1,
    });
    setPages({
      ...pages,
      current_page: response?.current_page,
      to: response?.to,
    });
    let arrayblogs = sliceArray(response?.data, 2);
    setBlogs([...blogs, ...arrayblogs]);
  } catch (error) {
    console.log(error);
  }
}
