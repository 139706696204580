import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles({
  contentText: {
    height: "100%",
    width: "150px",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    "& p": {
      margin: "10px 0 8px 0",
      fontFamily: "Nunito Sans;",
      color: "#444C67",
      fontWeight: 600,
      fontSize: "1.125em",
      display: "-webkit-box",
      "-webkit-line-clamp": 2,
      "-webkit-box-orient": "vertical",
      overflow: "hidden",
      lineHeight: "24px",
    },
    "&>div:last-child": {
      background: "#E7ECF0 !important",
      borderRadius: "100px !important",
      padding: "4px 16px",
      "&>p": {
        margin: 0,
        color: "#444C67",
        fontSize: "14px",
      },
    },
  },
  typePray: {
    fontFamily: "Nunito Sans !important",
    margin: "0px 0px 8px 0px !important",
    fontWeight: "normal !important",
    color: "#5D688E !important",
    fontSize: "0.875em !important",
    lineHeight: "24px !important",
  },
  doubleCard: {
    display: "flex",
    zIndex: 1,
    margin: "-16px 0px 16px 16px",
    width: "184px",
    height: "208px",
    position: "absolute",
    background: "#DAE3EC",
    boxShadow:
      "-4px -4px 10px rgba(0, 0, 0, 0.05), 4px 4px 10px rgba(0, 0, 0, 0.05)",
    borderRadius: "12px",
  },
  textHover: {
    width: "100%",
    display: "flex",
    alignItems: "flex-end",
    height: "32px",
    "&>div": {
      width: "100%",
      height: "2em",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "& p:nth-child(1)": {
        fontFamily: "Nunito Sans;",
        margin: 0,
        color: "#FFFFFF",
        fontSize: "0.75em",
      },
    },
  },
  contentDoubleCard: () => {
    return {
      display: "flex",
      alignItems: "flex-end",
      height: "224px",
      width: "200px",
      margin: "0px",
    };
  },
  list: (props) => {
    let style = {
      cursor: "pointer",
      margin: "0 0 0 0.8em",
      boxShadow: "0 0.25em 0.625em rgba(0, 0, 0, 0.15)",
      padding: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      border: "none",
      height: "13.5em",
      width: "12em",
    };
    if (props.doubleCard) {
      style = {
        ...style,
        margin: 0,
      };
    }
    return style;
  },
  dobleCard: () => {
    return {
      borderTopRightRadius: "12px !important",
      borderBottomRightRadius: "12px !important",
      borderBottomLeftRadius: "12px !important",
      borderTopLeftRadius: "12px !important",
      width: "192px",
      height: "216px",
      zIndex: "1",
      position: "relative",
    };
  },
  card: (props) => {
    switch (props.mode) {
      case "pope": {
        let style = {
          marginLeft: 0,
          borderTopLeftRadius: "0.75em",
          borderBottomLeftRadius: "0.75em",
          padding: "0",
          background:
            "linear-gradient(282.7deg, #FFE48F 0.06%, #E7C26D 99.94%)",

          "&>div:last-child": {
            "&>div": {
              background: "#E7C26D",
              borderBottomLeftRadius: "0.75em",
            },
          },
          "& >div:first-child": {},
        };

        if (props.mediaQuerySm) {
          style["& >div:first-child"] = {
            padding: "0 1.625em",
          };
        }
        return style;
      }
      case "monthly":
      case "morning": {
        let style = {};
        if (props.position === 0) {
          style = {
            marginLeft: 0,
            borderTopLeftRadius: "0.75em",
            borderBottomLeftRadius: "0.75em",
            background: "#FFFFFF",
            boxShadow:
              "-4px -4px 10px rgba(0, 0, 0, 0.05), 4px 4px 10px rgba(0, 0, 0, 0.05)",
            padding: "0",
            "& >div:last-child": {
              "&>div": {
                background: "#ECA25E",
                borderBottomLeftRadius: "0.75em",
              },
            },

            "& >div:first-child": {},
          };
          if (props.doubleCard) {
            style = {
              ...style,
              "& >div:last-child": {
                ...style["& >div:last-child"],
                "&>div": {
                  background: "#ECA25E",
                  borderBottomLeftRadius: "0.75em",
                  borderBottomRightRadius: "0.75em",
                },
              },
            };
          }
          if (props.mediaQuerySm) {
            style["& >div:first-child"] = {
              padding: "0 1.625em",
            };
          }
        } else {
          style = {
            padding: "0",
            background: "#FFFFFF",
            boxShadow:
              "-4px -4px 10px rgba(0, 0, 0, 0.05), 4px 4px 10px rgba(0, 0, 0, 0.05)",
            "& >div:last-child": {
              "&>div": {
                background: "#ECA25E",
              },
            },

            "& >div:first-child": {},
          };
          if (props.doubleCard) {
            style = {
              ...style,

              "& >div:last-child": {
                ...style["& >div:last-child"],
                "&>div": {
                  background: "#ECA25E",
                  borderBottomLeftRadius: "0.75em",
                  borderBottomRightRadius: "0.75em",
                },
              },
            };
          }
          if (props.mediaQuerySm) {
            style["& >div:first-child"] = {
              padding: "0 1.625em",
            };
          }
        }
        return style;
      }
      case "afternoon": {
        let style = {
          background: "#FFFFFF",
          padding: "0",
          borderRadius: "none",
          boxShadow:
            "-4px -4px 10px rgba(0, 0, 0, 0.05), 4px 4px 10px rgba(0, 0, 0, 0.05)",

          "& >div:last-child": {
            "&>div": {
              background: "#71AF7B",
            },
          },

          "& >div:first-child": {},
        };
        if (props.doubleCard) {
          style = {
            ...style,
            "& >div:last-child": {
              ...style["& >div:last-child"],
              "&>div": {
                background: "#71AF7B",
                borderBottomLeftRadius: "0.75em",
                borderBottomRightRadius: "0.75em",
              },
            },
          };
        }

        if (props.mediaQuerySm) {
          style["& >div:first-child"] = {
            padding: "0 1.625em",
          };
        }
        return style;
      }
      case "evening": {
        let style = {
          borderTopRightRadius: "12px",
          borderBottomRightRadius: "12px",
          background: "#FFFFFF",
          boxShadow:
            "-4px -4px 10px rgba(0, 0, 0, 0.05), 4px 4px 10px rgba(0, 0, 0, 0.05)",
          padding: "0",
          "& >div:last-child": {
            "&>div": {
              background: "#A15F99",
              borderBottomRightRadius: "12px",
            },
          },

          "& >div:first-child": {},
        };
        if (props.doubleCard) {
          style = {
            ...style,
            "& >div:last-child": {
              ...style["& >div:last-child"],
              "&>div": {
                background: "#A15F99",
                borderBottomLeftRadius: "0.75em",
                borderBottomRightRadius: "0.75em",
              },
            },
          };
        }
        if (props.mediaQuerySm) {
          style["& >div:first-child"] = {
            padding: "0 1.625em",
          };
        }
        return style;
      }
    }
  },
  icon: {
    maxHeight: '1.5rem',
    maxWidth: '1.5rem',
  }
});
