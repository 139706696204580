import makeStyles from "@material-ui/core/styles/makeStyles";
import BackgroundPope from "assets/img/background_pope_profile.svg";

export const useStyles = makeStyles({
  root: (props) => {
    const style = {
      width: "100%",
      minHeight: "85vh",
      height: "max-content",
      backgroundImage: `url(${BackgroundPope})`,
      backgroundSize: "cover",
      paddingTop: "12em",
      paddingBottom: "5em",
      fontWeight: 400,
      color: "#444C67",
    };
    if (props.mediaQuerySm) {
      style.paddingTop = "8em";
      style.minHeight = "60vh";
    }
    return style;
  },
  container: {
    "& p": {
      margin: "0.5rem",
      whiteSpace: "break-spaces",
    },
  },
  code: {
    fontFamily: "Libre Baskerville",
    fontSize: 64,
  },
  title: {
    fontFamily: "Nunito Sans",
    textTransform: "uppercase",
    fontSize: 14,
  },
  message: {
    fontFamily: "Nunito Sans",
    fontSize: 16,
  },
  buttonBack: (props) => {
    const style = {
      cursor: "pointer",
      width: "max-content",
      marginTop: "1.5em",
      "& div": {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        "& span": {
          width: "max-content",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          marginLeft: "0.3em",
          marginRight: "0.3em",
        },
      },
    };
    if (props.mediaQueryXs) {
      style.marginBottom = "2em";
    }
    return style;
  },
});
