/* eslint-disable */
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import qs from "qs";
import LegalView from "./LegalView";
import { verifyAccount } from "services/services";
import useMediaQuery from "@material-ui/core/useMediaQuery";

export function LegalContainer() {
  const mediaQueryMd = useMediaQuery("(max-width:960px)");
  return <LegalView isMd={mediaQueryMd} />;
}
