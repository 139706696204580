import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles({
  contentPrayWeek: (props) => {
    let style = {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "flex-start",
    };
    if (props.mediaQuerySm && props.mediaQueryMd) {
      style = {
        ...style,
        justifyContent: "flex-start",
        alignItems: "flex-start",
      };
    }
    if (props.mediaQueryMd && !props.mediaQuerySm) {
      style = {
        ...style,
        justifyContent: "center",
        alignItems: "center",
      };
    }

    return style;
  },
  contentWeek: () => {
    let style = {
      display: "flex",
      flexDirection: "row",
      alignItems: "start",
      marginLeft: "12px",
      "&>h1": {
        fontFamily: "Libre Baskerville",
        fontWeight: "normal",
        fontSize: "44px",
        color: "#444C67",
        margin: "0px 16px 0px 0px",
      },
      "&>div": {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        "&>p": {
          textTransform: "capitalize",
          fontFamily: "Nunito Sans",
          fontWeight: "normal",
          fontSize: "14px",
          color: "#444C67",
          margin: 0,
          lineHeight: "24px",
        },
        "&>p:last-child": {
          textTransform: "uppercase",
        },
      },
    };
    return style;
  },
  contentList: (props) => {
    let style = {
      "& ul": {
        "& li": {
          padding: "8px 8px",
          "& div": {
            margin: "0px 8px 0px 0px",
            "&:last-child": {
              margin: 0,
            },
          },
        },
      },
    };
    if (props.mediaQueryMd) {
      style = {
        "& ul": {
          "& li": {
            padding: "10px",
            "& div": {
              margin: "0px 8px 0px 0px",
              "&:last-child": {
                margin: 0,
              },
            },
          },
        },
      };
    }
    return style;
  },
});
