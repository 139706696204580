import React from "react";
import { Button, Typography } from "@material-ui/core";
import { useStyles } from "./styles";
import { useTranslation } from "react-i18next";

export function ButtonSyled(props) {
  const { t } = useTranslation();
  const {
    text,
    type,
    iconLeft,
    iconRight,
    icon,
    variant,
    color,
    disabled,
    css,
    id,
    children,
    ...rest
  } = props;

  const classes = useStyles({ type });

  return (
    <Button
      {...rest}
      variant={variant || "contained"}
      color={color || "primary"}
      disabled={disabled}
      className={`${classes.button} ${classes.type} ${css}`}
      data-testid={id ? id : "btn"}
    >
      {icon ? (
        icon
      ) : children ? (
        children
      ) : (
        <div>
          {iconLeft ? iconLeft : null}
          <Typography component="span" data-testid={id ? id : "text"}>
            {t(text)}
          </Typography>
          {iconRight ? iconRight : null}
        </div>
      )}
    </Button>
  );
}
