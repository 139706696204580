import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles({
  signUpOptionsContent: (props) => {
    let style = {
      width: "792px",
      outline: "none",
    };
    if (props.mediaQuerySm) {
      style = {
        ...style,
        width: "100%",
        height: "100%",
      };
    }
    return style;
  },
  signUpOptionsModal: (props) => {
    let style = {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      overflowY: "auto",
    };
    if (props.mediaQuerySm) {
      style = {
        ...style,
        width: "100%",
      };
    }
    return style;
  },
  signUpOptions: (props) => {
    let style = {
      background: "#FFFFFF",
      padding: "24px 24px 56px 24px",
      borderRadius: "12px",
    };
    if (props.mediaQuerySm) {
      style = {
        ...style,
        height: "100%",
        borderRadius: "none",
        padding: "8px 20px 86px 20px",
      };
    }
    return style;
  },
  wrapperSignUpOptions: (props) => {
    let style = {
      margin: "24px 131px 0px 131px",
    };
    if (props.mediaQuerySm) {
      style = {
        margin: "0px",
      };
    }
    return style;
  },
  closeIconContent: (props) => {
    let style = {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      "& >img": {
        cursor: "pointer",
      },
    };
    if (props.mediaQuerySm) {
      style = {
        ...style,
        padding: "12px 0px",
      };
    }
    return style;
  },

  titleContent: (props) => {
    let style = {
      textAlign: "center",
      "&>h1": {
        fontFamily: "Libre Baskerville",
        fontWeight: "normal",
        fontSize: "32px",
        color: "#444C67",
        margin: "0px 0px 24px 0px",
      },
      "&>p": {
        fontFamily: "Nunito Sans",
        fontSize: "16px",
        color: "#5D688E",
        margin: "0px 0px  32px 0px",
      },
    };
    if (props.mediaQuerySm) {
      style = {
        ...style,
        margin: "24px 0px 0px 0px",
      };
    }
    return style;
  },
  contentButtonsSignUp: (props) => {
    let style = {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "&>button:first-child": {
        margin: "0px 16px 0px 0px",
      },
    };
    if (props.mediaQuerySm) {
      style = {
        ...style,
        flexDirection: "column",
        "&>button:first-child": {
          margin: "0px 0px 16px 0px",
        },
      };
    }
    return style;
  },
  contentRegister: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: "32px 0px 0px 0px",
    "&>p": {
      fontFamily: "Nunito Sans",
      fontWeight: 600,
      fontSize: "14px",
      color: "#444C67",
    },
    "&>p:first-child": {
      margin: "0px 0px 16px 0px",
    },
    "&>button:last-child": {
      margin: "24px 0px 0px 0px",
      "&>span": {
        "&>div": {
          "&>span": {
            "&>span": {
              textDecoration: "underline",
              margin: "0px 0px 0px 4px",
            },
          },
        },
      },
    },
  },
});
