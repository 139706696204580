import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles({
  Container: {
    msOverflowX: "hidden",
    background: "rgb(244, 246, 248)",
  },
  Content: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    backgroundSize: "cover",
    height: "100%",
    width: "100%,",
  },
  wrapperTop: (props) => {
    let style = {
      margin: "216px 212px 89px",
      textAlign: "auto",
      "&>h1": {
        fontFamily: "Libre Baskerville",
        fontSize: "44px",
        color: "#444C67",
        margin: "0px 0px 16px",
      },
      "&>h2": {
        fontFamily: "Nunito Sans",
        fontSize: "20px",
        textTransform: "uppercase",
        color: "#444C67",
        margin: "16px 0px 16px",
      },
      "&>p": {
        fontFamily: "Nunito Sans",
        fontSize: "16px",
        color: "#444C67",
        margin: 0,
        padding: 10,
      },
    };
    if (props.mediaQuerySm) {
      style["&>h1"] = {
        ...style["&>h1"],
        fontSize: "28px",
      };
      style.margin = "88px 20px 64px";
      style.textAlign = "center";
      style["&>p"].textTransform = "none";
    }
    return style;
  },
  wrapperSection: (props) => {
    let style = {
      margin: "50px 212px 89px",
      textAlign: "auto",
      "&>h1": {
        fontFamily: "Libre Baskerville",
        fontSize: "44px",
        color: "#444C67",
        margin: "0px 0px 16px",
      },
      "&>h2": {
        fontFamily: "Nunito Sans",
        fontSize: "20px",
        textTransform: "uppercase",
        color: "#444C67",
        margin: "16px 0px 16px",
      },
      "&>p": {
        fontFamily: "Nunito Sans",
        fontSize: "16px",
        color: "#444C67",
        margin: 0,
        padding: 10,
      },
    };
    if (props.mediaQuerySm) {
      style["&>h1"] = {
        ...style["&>h1"],
        fontSize: "28px",
      };
      style.margin = "88px 20px 64px";
      style.textAlign = "center";
      style["&>p"].textTransform = "none";
    }
    return style;
  },

  // accordion: {
  //   marginBottom: "24px",
  //   "& .MuiAccordionDetails-root": {
  //     marginBottom: "30px",
  //   },
  // },

  // contentButtonMoreMonthly: (props) => {
  //   let style = {
  //     width: "100%",
  //     display: "flex",
  //     alignItems: "center",
  //     justifyContent: "center",
  //     margin: "54px 0px 0px 0px",
  //   };
  //   if (props.mediaQuerySm) {
  //     style.margin = "64px 0px 0px 0px";
  //   }
  //   return style;
  // },
});
