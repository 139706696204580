import React from "react";

const ButtonMailto = ({ mailto, label }) => {
    return (
        <a href={`mailto:${mailto}`}>
            {label}
        </a>
    );
};

export default ButtonMailto;