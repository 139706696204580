import React, { useState, useEffect } from "react";
import { fetchArticle, handlerSeeAll } from "./BlogArticleFunctions";
import { useParams, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { scrollTopWindow } from "../../../helpers";
import BlogArticleView from "./BlogArticleView";
import useMediaQuery from "@material-ui/core/useMediaQuery";

export function BlogArticleContainer() {
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const [article, setArticle] = useState({});
  const [prevArticle, setPrevArticle] = useState();
  const [nextArticle, setNextArticle] = useState();

  const history = useHistory();
  const mediaQueryMd = useMediaQuery("(max-width:960px)");

  useEffect(() => {
    scrollTopWindow();
    fetchArticle(id, history, setArticle, setPrevArticle, setNextArticle, i18n.language);
  }, [t, id]);

  return (
    <div data-testid="blog_article">
      <BlogArticleView
        mediaQueryMd={mediaQueryMd}
        article={article}
        prevArticle={prevArticle}
        nextArticle={nextArticle}
        handlerChangeArticle={(id) =>
          fetchArticle(id, history, setArticle, setPrevArticle, setNextArticle, i18n.language)
        }
        handlerSeeAll={() => handlerSeeAll(history)}
      />
    </div>
  );
}
