import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles({
  gral: (props) => {
    const style = {
      padding: "3em",
      boxSizing: "border-box",
      color: "#444C67",
      margin: "auto",
      flexDirection: "row",
      borderRadius: "10px",
      width: "90%",
      maxWidth: "800px",
      maxHeight: "524px",
      "&>div": {
        minHeight: "430px",
      },
    };
    if (props.codeCampaign) {
      style.marginBottom = "6em";
    }
    if (props.mediaQueryMd) {
      style.padding = 0;
      style.maxWidth = "80%";
      style.maxHeight = "max-content";
      style.width = "100%";
      style.borderRadius = "6px";
    }
    if (props.mediaQuerySm) {
      style.padding = 0;
      style.maxWidth = "99%";
      style.maxHeight = "max-content";
      style["&>div"] = {
        flexDirection: "column",
      };
    }
    return style;
  },

  imgCard: (props) => {
    let style = {
      width: "60%",
      maxWidth: "500px",
      maxHeight: "435px",
      minHeight: "100px",
      minWidth: "150px",
      "& div.img": {
        position: "relative ",
        width: "100%",
        height: "100%",
        overflow: "hidden",
        backgroundColor: "#BBBBBB",
        backgroundSize: "cover",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
      },
    };
    if (props.mediaQueryMd) {
      style.width = "100%";
      style.maxWidth = "100%";
      style.minHeight = "max-content";
      style.maxHeight = "max-content";

      style["& div.img"].height = "max-content";
      style["& div.img"].borderTopLeftRadius = "6px";
      style["& div.img"].borderTopRightRadius = "6px";

      style["& img"] = {
        height: "auto",
        width: "100%",
        minWidth: "200px",
        backgroundColor: "#bfbaba",
        borderTopLeftRadius: "10px",
        borderTopRightRadius: "10px",
      };
    }
    if (props.mediaQuerySm) {
      style.minHeight = "max-content";
    }
    return style;
  },

  contentCard: (props) => {
    const style = {
      width: "40%",
      paddingLeft: "1.5em",
      minWidth: "40%",
      "& h3, & h6, & em": {
        margin: 0,
        marginBottom: "0.2rem",
      },
      "& p": {
        margin: "0px 0px 10px",
      },
      "& h3": {
        fontSize: "22px",
        marginBottom: "24px",
      },
    };
    if (props.mediaQueryMd) {
      style.width = "100%";
      style.padding = "2em";
    }
    return style;
  },

  contain: (props) => {
    const style = {
      height: "100%",
      "&>div": {
        flexGrow: 0,
        flexShrink: 1,
        flexBasis: "auto",
        minHeight: "max-content",
      },
      "&>div.middle": {
        flexGrow: 1,
      },
    };
    if (props.mediaQueryMd) {
      style["&>div.middle"] = {
        minHeight: "130px",
        height: "max-content",
        maxHeight: "350px",
        overflow: "auto",
        overflowX: "hidden",
      };
    }
    if (props.mediaQuerySm) {
      style["&>div.middle"].minHeight = "160px";
      if (props.codeCampaign === "TIP")
        style["&>div.middle"].minHeight = "250px";
    }
    return style;
  },

  daySpan: {
    "& img": {
      verticalAlign: "middle",
      marginRight: "0.6em",
    },
    "& span.day": {
      marginRight: "0.3em",
    },
  },

  redes: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "&>div": {
      width: "max-content",
    },
  },

  button: {
    marginTop: "0.5rem",
    cursor: "pointer",
    width: "max-content",
    "& div": {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      "& span": {
        textTransform: "capitalize",
        width: "max-content",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        marginLeft: "0.3em",
        marginRight: "0.3em",
      },
    },
  },

  gradiantScroll: {
    minHeight: "150px",
    height: "max-content",
    position: "relative",
    overflow: "auto",
    overflowX: "hidden",
    margin: 0,
    marginTop: "0.4rem",
    marginBottom: "0.4rem",
    "&>div": {
      position: "absolute",
      width: "100%",
    },
  },

  scrolled: {
    scrollbarWidth: "thin",
    scrollbarColor: "#797979 white",
    "&::-webkit-scrollbar": {
      "-webkit-appearance": "none",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#797979",
      borderRadius: "10px",
      border: "2px solid #f1f2f3",
      outline: "none",
    },
    "&::-webkit-scrollbar-track": {
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar:vertical": {
      width: "10px",
    },
    "&::-webkit-scrollbar-button:increment,.contenedor::-webkit-scrollbar-button": {
      display: "none",
    },
  },
});
