import { getCampaign } from "services/services";
import { setAmen, unsetAmen } from "../../helpers";
import moment from "moment";
import MorningIcon from "assets/icons/PrayerMorning.svg";
import AfternoonIcon from "assets/icons/PrayerAfternoon.svg";
import EveningIcon from "assets/icons/PrayerEvening.svg";
import ClandeMorning from "assets/icons/Candle-2.svg";
import ClandeAfternoon from "assets/icons/Candle-1.svg";
import ClandeEvening from "assets/icons/Candle.svg";
import RosaryAfternoon from "assets/icons/RosaryAfternoon.svg";
import RosaryEvening from "assets/icons/RosaryEvening.svg";
import RosaryMorning from "assets/icons/RosaryMorning.svg";
import _ from "lodash";
import { findAmen } from "services/authService";

export async function fetchCampaign(
  id_camp,
  history,
  indexCamp,
  setSartedCampaign,
  setCampaign,
  setCodeMoment,
  setCodeCampaign,
  setIconMoment,
  language
) {
  try {
    let camp = await getCampaign(id_camp);
    if (!camp) return history.replace("/school-prayer");
    language = language.toUpperCase();
    if (camp.language && camp.language.code !== language) {
      let arrIds = camp.otherLanguages || [];
      const other_languages = _.toPairs(_.merge(...arrIds));
      const other_id = other_languages.find((lang) => lang[0] === language);
      if (!other_id?.[1]) return history.replace("/school-prayer");
      camp = await getCampaign(other_id[1]);
      if (!camp) return history.replace("/school-prayer");
    }

    if (indexCamp) {
      history.replace(`/campaigns/${id_camp}/${indexCamp}`);
    } else {
      history.replace(`/campaigns/${id_camp}`);
    }

    const wasStart =
      !camp.date_from || moment(camp.date_from).isBefore(moment());

    await sortPrayers(camp);
    setSartedCampaign(wasStart);
    setCampaign(camp);
    setCodeMoment(camp.moment?.code);
    setCodeCampaign(camp.type.code);
    chooseIconMoment(camp.moment?.code, camp, setIconMoment);
  } catch (e) {
    return history.replace("/school-prayer");
  }
}

export async function sortPrayers(camp) {
  const byDate = (a, b) =>
    new Date(a.date).getTime() - new Date(b.date).getTime();
  const byOrder = (a, b) => b.order - a.order;

  const typeCampaign = camp.type?.code;
  switch (typeCampaign) {
    case "SCH":
      camp.campaignPrayer = camp.campaignPrayer.sort(byDate);
      break;
    case "UNS":
    case "PRO":
      camp.campaignPrayer = camp.campaignPrayer.sort(byOrder);
      break;
  }
}

export function chooseIconMoment(campaign_moment, camp, setIconMoment) {
  switch (campaign_moment) {
    case "MOR":
      switch (camp.type.code) {
        case "SCH":
        case "UNS":
          setIconMoment(MorningIcon);
          break;
        case "TIP":
          setIconMoment(ClandeMorning);
          break;
        case "PRO":
          setIconMoment(
            camp?.multimedia?.length > 0 &&
              camp?.multimedia.find(
                (image) => image?.type?.code === "IMG_ICON_CAMPAIGN"
              )?.url
          );
          break;
        case "ROT":
          setIconMoment(RosaryMorning);
          break;
      }

      break;
    case "AFT":
      switch (camp.type.code) {
        case "SCH":
        case "UNS":
          setIconMoment(AfternoonIcon);
          break;
        case "TIP":
          setIconMoment(ClandeAfternoon);
          break;
        case "PRO":
          setIconMoment(
            camp?.multimedia?.length > 0 &&
              camp?.multimedia.find(
                (image) => image?.type?.code === "IMG_ICON_CAMPAIGN"
              )?.url
          );
          break;
        case "ROT":
          setIconMoment(RosaryAfternoon);
          break;
      }

      break;
    case "EVE":
      switch (camp.type.code) {
        case "SCH":
        case "UNS":
          setIconMoment(EveningIcon);
          break;
        case "TIP":
          setIconMoment(ClandeEvening);
          break;
        case "PRO":
          setIconMoment(
            camp?.multimedia?.length > 0 &&
              camp?.multimedia.find(
                (image) => image?.type?.code === "IMG_ICON_CAMPAIGN"
              )?.url
          );
          break;
        case "ROT":
          setIconMoment(RosaryEvening);
          break;
      }

      break;
  }
}

export function openWindowTab(link) {
  const url = !/^((http:\/\/)|(https:\/\/))/i.test(link)
    ? "https://" + link
    : link;
  window.open(url);
}

const amenCheck = (prayer) => {
  return !!findAmen(prayer?.id, prayer?.typeForAmen);
};

export async function handleFavorite(
  owner,
  ownerType,
  index,
  campaign,
  setCampaign
) {
  await setAmen(owner, ownerType);
  let items = [...campaign.campaignPrayer];
  items[index].isFavorite = amenCheck(items[index]);
  setCampaign({ ...campaign, campaignPrayer: items });
}

export async function handleDislike(
  owner,
  ownerType,
  index,
  campaign,
  setCampaign
) {
  await unsetAmen(owner, ownerType);
  let items = [...campaign.campaignPrayer];
  items[index].isFavorite = amenCheck(items[index]);
  setCampaign({ ...campaign, campaignPrayer: items });
}
