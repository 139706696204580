import _ from 'lodash';
import { getBlogArticle } from "services/services";

export async function fetchArticle(
  id_post,
  history,
  setArticle,
  setPrevArticle,
  setNextArticle,
  language
) {
  try {
    let data = await getBlogArticle(id_post);
    language = language.toUpperCase();
    if (!data) return history.replace("/blogs");
    if (data.language && data.language.code !== language) {
      let arrIds = data.otherLanguages || [];
      const other_languages = _.toPairs(_.merge(...arrIds));
      const other_id = other_languages.find(
        (lang) => lang[0] === language
      );
      if (!other_id?.[1]) return history.replace("/blogs");
      data = await getBlogArticle(other_id[1]);
    }
    if (!data) return history.replace("/blogs");
    history.replace(`/articles/${data.id}`);
    setArticle(data);
    setPrevArticle(data.previous);
    setNextArticle(data.next);
  } catch (e) {
    return history.replace("/blogs")
  }
}

export function handlerSeeAll(history) {
  history.push("/blogs");
}
