import React from 'react';
import Community from "components/Community";
import { useStyles } from "./styles";
import PopeProfileView from "pages/PopeProfile/PopeProfileView";

function SharePrayerView(props) {
  const {
    mediaQueryMd,
    prayers,
    type,
    onChargeMore,
    handleFavorite,
    handleDislike,
    handlePopePostComments,
    handleEditPopeComments,
    handleReportPopeComment,
    isSuccessPost,
    handlePostCommunity,
    handlePostComments,
    handleEditComments,
    handleReportComment,
    handleReportPost,
    onDeletePrayer,
  } = props;
  const classes = useStyles({ mediaQueryMd });

  const isPopePrayer = () => {
    return (
      <div className={classes.isPopeProfile}>
        <PopeProfileView
          prayers={prayers}
          hasNext
          isSm={mediaQueryMd}
          onChangeExpandedPost={onChargeMore}
          handleFavorite={handleFavorite}
          handleDislike={handleDislike}
          handlePostComments={handlePopePostComments}
          handleEditComments={handleEditPopeComments}
          handleReportComment={handleReportPopeComment}
        />
      </div>
    );
  };

  const isCommunityPrayer = () => {
    return (
      <div className={classes.root}>
        <Community
          isMd={mediaQueryMd}
          posts={prayers}
          handleMoreComments={() => {}}
          isSuccessPost={isSuccessPost}
          handlePostCommunity={handlePostCommunity}
          handleFavorite={handleFavorite}
          handleDislike={handleDislike}
          handlePostComments={handlePostComments}
          handleEditComments={handleEditComments}
          handleReportComment={handleReportComment}
          handleReportPost={handleReportPost}
          onDeletePrayer={onDeletePrayer}
        />
      </div>
    );
  };

  return (
    <div data-testid="share_prayer_container">
      {type === "CPY" ? isCommunityPrayer(): isPopePrayer()}
    </div>
  );
}

export default SharePrayerView;
