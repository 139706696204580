import _ from "lodash";
import { useTranslation } from "react-i18next";
import WorldWide_EN from "assets/img/pope_worldwide.svg";
import WorldWide_ES from "assets/img/pope_worldwide_es.svg";
import WorldWide_PT from "assets/img/pope_worldwide_pt.svg";
import WorldWide_IT from "assets/img/pope_worldwide_it.svg";
import WorldWide_FR from "assets/img/pope_worldwide_fr.svg";
import WorldWide_DE from "assets/img/pope_worldwide_de.svg";
import WorldWide_ZH from "assets/img/pope_worldwide_zh.svg";
import Cdc_EN from "assets/img/CDC_en.svg";
import Cdc_ES from "assets/img/CDC.svg";
import Cdc_PT from "assets/img/CDC_pt.svg";
import Cdc_IT from "assets/img/CDC_it.svg";
import Cdc_FR from "assets/img/CDC_fr.svg";
import Cdc_DE from "assets/img/CDC_de.svg";
import Cdc_ZH from "assets/img/CDC_zh.svg";
import popeVideo_EN from "assets/img/the_pope_video.svg";
import popeVideo_ES from "assets/img/the_pope_video_es.svg";
import popeVideo_PT from "assets/img/the_pope_video_pt.svg";
import popeVideo_IT from "assets/img/the_pope_video_it.svg";
import popeVideo_FR from "assets/img/the_pope_video_fr.svg";
import popeVideo_DE from "assets/img/the_pope_video_de.svg";
import popeVideo_ZH from "assets/img/the_pope_video_zh.svg";
import eym_EN from "assets/img/eym.svg";
import eym_ES from "assets/img/eym_es.svg";
import eym_PT from "assets/img/eym_pt.svg";
import eym_IT from "assets/img/eym_it.svg";
import eym_FR from "assets/img/eym_fr.svg";
import eym_DE from "assets/img/eym_de.svg";
import eym_ZH from "assets/img/eym_zh.svg";

const FALLBACK_IMG_LANGUAGE = "es";

/* import svg files and set here name and languages */
const Images = {
  PopeWorldWide: {
    en: WorldWide_EN,
    es: WorldWide_ES,
    pt: WorldWide_PT,
    it: WorldWide_IT,
    fr: WorldWide_FR,
    de: WorldWide_DE,
    zh: WorldWide_ZH,
  },

  WayOfHeart: {
    en: Cdc_EN,
    es: Cdc_ES,
    pt: Cdc_PT,
    it: Cdc_IT,
    fr: Cdc_FR,
    de: Cdc_DE,
    zh: Cdc_ZH,
  },

  ThePopeVideo: {
    en: popeVideo_EN,
    es: popeVideo_ES,
    pt: popeVideo_PT,
    it: popeVideo_IT,
    fr: popeVideo_FR,
    de: popeVideo_DE,
    zh: popeVideo_ZH,
  },

  Eym: {
    en: eym_EN,
    es: eym_ES,
    pt: eym_PT,
    it: eym_IT,
    fr: eym_FR,
    de: eym_DE,
    zh: eym_ZH,
  },
};

export default function useLogos() {
  const { i18n } = useTranslation();
  return _.transform(
    Images,
    (r, v, k) => (r[k] = v[i18n.language] || v[FALLBACK_IMG_LANGUAGE]),
    {}
  );
}
