import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles({
  description: (props) => {
    let style = {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "sapce-between",
      paddingRight: "1em",
      "& h2": {
        fontFamily: " Nunito Sans",
        fontWeight: 600,
        fontSize: "1.125em",
        color: "#444C67",
        margin: "0 0 16px 0",
      },

      "& p": {
        fontFamily: "Nunito Sans",
        fontSize: "1em",
        color: "#444C67",
        wordBreak: "break-word",
        margin: 0,
      },
    };
    if (props.mediaQuerySm) {
      style.flexDirection = "column";
      style.paddingRight = "0";
      style.paddingBottom = "1em";
      style["&>div:first-child"] = {
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "row",
        marginBottom: "1.5em",
      };
      style["& div:last-child"] = {
        margin: 0,
      };
      style["& h2"] = {
        ...style["& h2"],
        margin: 0,
      };
      style["& p"] = {
        ...style["& p"],
        margin: 0,
      };
    }
    return style;
  },
  contentCard: (props) => {
    let style = {
      padding: "3em 2em 2.125em 2em",
      background: "#FFFFFF",
      boxShadow:
        "-4px -4px 10px rgba(0, 0, 0, 0.05), 4px 4px 10px rgba(0, 0, 0, 0.05)",
      borderRadius: " 6px",
      maxWidth: "1009px",
    };
    if (props.mediaQuerySm) {
      style.padding = "1.5em";
    }
    return style;
  },

  avatar: {
    marginRight: "1.5em",
    width: "72px",
    height: "72px",
  },

  scroll: {
    width: "100%",
    "& div": {
      "& div": {
        "&::-webkit-scrollbar": {
          display: "none",
        },
      },
    },
  },

  
  lineBreak:()=>{
    return {
      whiteSpace: "normal",
    };
  },
  contentScroll: () => {
    return {
      width: "100%",
      whiteSpace: "pre-line",
    };
  },

  contentButtons: (props) => {
    let style = {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      borderLeft: "1px solid rgb(99,133,177,0.2)",
      paddingLeft: "1em",
    };
    if (props.mediaQuerySm) {
      style.borderLeft = "none";
      style.paddingLeft = "0";
      style.paddingTop = "1em";
      style.borderTop = "1px solid rgb(99,133,177,0.2)";
    }
    return style;
  },
  button: {
    width: "100%",
    "& div": {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      "& img:first-child": {
        width: "1.5em",
        height: "1.5em",
        marginRight: "0.1875em",
      },
      "& span": {
        textTransform: "capitalize",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
      },
    },
  },
});
