import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles({
  postsContent: (props) => {
    let style = {
      backgroundColor: "#FFFFFF",
    };
    if (props.mediaQueryMd) {
      style = {
        ...style,
        height: "auto",
      };
    }
    return style;
  },

  titleContent: (props) => {
    let style = {
      textAlign: "center",
      padding: "96px 60px 0px",
      "&>h1": {
        fontFamily: "Libre Baskerville",
        fontWeight: "normal",
        fontSize: "32px",
        lineHeight: "36px",
        color: "#444C67",
        margin: "0px 0px 56px 0px",
      },
    };
    if (props.mediaQueryMd) {
      style = {
        ...style,
        padding: "40px 21px 0px",
        margin: "0px 0px 24px 0px",
      };
      style["&>h1"] = {
        ...style["&>h1"],
        fontFamily: "Nunito Sans",
        fontWeight: "600",
        fontSize: "24px",
        lineHeight: "32px",
      };
    }

    return style;
  },
  postsSection: (props) => {
    let style = {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      padding: "0px 67px 96px",
    };
    if (props.mediaQueryMd) {
      style = {
        ...style,
        padding: "0px 21px 40px",
      };
    }

    return style;
  },
  carruselPosts: () => {
    return {
      overflowX: "auto",
      scrollbarWidth: "none !important",
      scrollBehavior: "smooth",
      display: "flex",
      alignItems: "center",
      justifyContent: "left",
      "&::-webkit-scrollbar": {
        display: "none",
      },
      "&>div:last-child": {
        margin: 0,
      },
    };
  },
  buttonsCarrusel: () => {
    return {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    };
  },
  buttonsBackPosts: (props) => {
    let style = {
      width: "24px",
      margin: "0px 35px 0px 0px",
    };
    if (props.mediaQueryMd) {
      style = {
        ...style,
        margin: "0px 20px 0px 0px",
      };
    }

    return style;
  },
  buttonsNextPosts: (props) => {
    let style = {
      width: "24px",
      margin: "0px 0px 0px 35px",
    };
    if (props.mediaQueryMd) {
      style = {
        ...style,
        margin: "0px 0px 0px 20px",
      };
    }

    return style;
  },
  button: {
    cursor: "pointer",
  },
  buttonDisabled: {
    visibility: "hidden",
  },

  post: () => {
    return {
      width: "inherit",
      maxWidth: "250px",
      maxHeight: "250px",
      objectFit: "cover",

      "&>img": {
        width: "250px",
        height: "auto",
        objectFit: "cover",
      },
    };
  },

  carruselContent: {
    "& .rec-carousel-item": {
      "&>div": {
        padding: "0px  !important",
      },
      display: "flex",
      alignItems: "center",
      justifyContent: "center",

      cursor: "pointer",
      "& :focus": {
        outline: "none",
      },
    },
  },
});
