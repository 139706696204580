export function modalReducer(
  state = {
    login: { open: false },
    error: { open: false, message: "", code: null },
    recoverPassword: { open: false, email: "", hash: "" },
  },
  action
) {
  switch (action.type) {
    case "@modal/login":
      return { ...state, login: action.payload };
    case "@modal/error":
      return { ...state, error: action.payload };
    case "@modal/recoverPassword":
      return { ...state, recoverPassword: action.payload };
    default:
      return state;
  }
}
