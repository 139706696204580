import React from "react";
import { Modal } from "@material-ui/core";
import { useStyles } from "./styles";
import { useTranslation } from "react-i18next";
import Button from "components/Button";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import IconClose from "assets/icons/Close.svg";
import Icon from "components/Icon";

export function CloseAccountStyled(props) {
  const { isMd, handleClose, open, handleCloseAccount } = props;
  const { t } = useTranslation();
  const classes = useStyles({ mediaQueryMd: isMd });
  return (
    <>
      <Modal
        open={open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        className={classes.closeAccountModal}
        data-testid="closeAccount_content"
      >
        <Fade in={open}>
          <div className={classes.closeAccountContent}>
            <div className={classes.closeAccount}>
              <div className={classes.closeIconContent}>
                <Icon
                  onClick={() => handleClose()}
                  icon={IconClose}
                  alt="close"
                  id="ico_closeAccount_close_modal"
                />
              </div>
              <div className={classes.wrapperCloseAccount}>
                <div className={classes.titleContentCloseAccount}>
                  <h1 data-testid="txt_title_closeAccount">
                    {t(`components.closeAccount.Are you sure?`)}
                  </h1>
                  <p data-testid="txt_description_closeAccount">
                    {t(
                      `components.closeAccount.Remember you are always welcomed to join again to the Pope’s Praying Network.`
                    )}
                  </p>
                </div>
                <div className={classes.buttonCloseAccount}>
                  <Button
                    id="btn_closeAccount"
                    text="components.closeAccount.Close my account"
                    type="primary"
                    onClick={() => handleCloseAccount()}
                  />
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
}
