import React, { useState, useEffect } from "react";
import { fetchGetPrayerSchoolTab } from "./SchoolPrayerFunctions";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { scrollTopWindow, navigateSchoolsPrayers } from "helpers";
import SchoolPrayerView from "./SchoolPrayerView";
import useMediaQuery from "@material-ui/core/useMediaQuery";

export function SchoolPrayerContainer() {
  const { t } = useTranslation();
  const history = useHistory();
  const mediaQuerySm = useMediaQuery("(max-width:960px)");
  const [campaigns, setCampaigns] = useState([]);
  const [eRosary, setERosary] = useState([]);
  const [proyects, setProyects] = useState([]);
  useEffect(() => {
    fetchGetPrayerSchoolTab(setCampaigns, setERosary, setProyects);
    scrollTopWindow();
  }, [t, mediaQuerySm]);

  return (
    <SchoolPrayerView
      isSm={mediaQuerySm}
      campaigns={campaigns}
      eRosary={eRosary}
      proyects={proyects}
      handleClickSchoolPrayer={(schoolPrayer) =>
        navigateSchoolsPrayers(schoolPrayer, history)
      }
    />
  );
}
