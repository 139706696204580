import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles({
  closeAccountContent: (props) => {
    let style = {
      width: "792px",
      outline: "none",
      height: "auto",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    };
    if (props.mediaQueryLg) {
      style = {
        ...style,
        height: "600px",
      };
    }
    if (props.mediaQueryMd) {
      style = {
        ...style,
        width: "100%",
        height: "100%",
      };
    }
    return style;
  },
  closeAccountModal: (props) => {
    let style = {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      overflowY: "auto",
    };
    if (props.mediaQueryMd) {
      style = {
        ...style,

        height: "100%",
      };
    }
    return style;
  },
  closeAccount: (props) => {
    let style = {
      background: "#FFFFFF",
      padding: "24px 24px 48px",
      borderRadius: "12px",
    };
    if (props.mediaQueryMd) {
      style = {
        ...style,

        borderRadius: "none",
        padding: "0px 20px 64px",
      };
    }
    return style;
  },
  closeIconContent: (props) => {
    let style = {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      "& >img": {
        cursor: "pointer",
      },
    };
    if (props.mediaQueryMd) {
      style = {
        ...style,
        padding: "12px 0px",
      };
    }
    return style;
  },
  wrapperCloseAccount: (props) => {
    let style = {
      margin: "24px 40px 0px",
    };
    if (props.mediaQueryMd) {
      style = {
        ...style,
        margin: "0px",
      };
    }
    return style;
  },
  titleContentCloseAccount: (props) => {
    let style = {
      textAlign: "center",
      "&>h1": {
        fontFamily: "Libre Baskerville",
        fontWeight: "normal",
        fontSize: "32px",
        color: "#444C67",
        margin: "0px 0px 32px 0px",
        lineHeight: "40px",
      },
      "&>p": {
        fontFamily: "Nunito Sans",
        fontSize: "16px",
        color: "#5D688E",
        margin: "0px 0px  24px 0px",
        lineHeight: "24px",
      },
    };
    if (props.mediaQueryMd) {
      style["&>h1"].fontSize = "24px";
      style["&>h1"].lineHeight = "36px";
      style["&>h1"].margin = "0px 0px 24px 0px";
    }
    return style;
  },
  buttonCloseAccount: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});
