import makeStyles from "@material-ui/core/styles/makeStyles";

// import ArrowIconDark from "assets/icons/chevron-down-dark.svg";
// import ArrowIconLight from "assets/icons/chevron-down-light.svg";

export const useStyles = makeStyles({
  container: (props) => {
    let style = {
      position: "absolute",
      backgroundColor: "transparent",
      padding: "1.18em 2em ",
      display: "flex",
      "& >div:nth-child(2)": {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        "& p": {
          fontFamily: "Nunito Sans",
          fontSize: "1em",
          lineHeight: "1.5em",
          margin: "0 0 0 0 ",
          cursor: "pointer",
        },
        "& nav": {
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          "& ul": {
            padding: 0,
            listStyleType: "none",
            display: "flex",
            alignItems: "center",
            textAlign: "center",
            "& li": {
              marginRight: "1.5em",
              float: "left",
              "& a": {
                fontFamily: "Nunito Sans",
                textDecoration: "none",
              },
            },
          },
        },
      },
    };
    if (props.mediaQuerySm) {
      style = {
        ...style,
        padding: "10px 20px",
        "&>div:first-child": {
          height: "28px",
        },
      };
    }
    if (props.mediaQueryLg) {
      style = {
        ...style,
        padding: "1.18em 24px ",
      };
    }

    return style;
  },
  button: {
    marginRight: "1.5em",
  },
  select: (props) => {
    let style = {
      marginLeft: "0.6em",
      "& button": {
        padding: "5px 0px 12px",
        "& div": {},
      },
    };
    switch (props.mode) {
      case "pink":
      case "dark":
        style["& button"]["& div"] = {
          color: "white",
        };
        break;
      case "light":
        style["& button"]["& div"] = {
          color: "#444C67",
        };
        break;
    }
    return style;
  },
  options: {
    zIndex: 3000,
    maxHeight: "260px",
    "& button": {
      "& span": {
        color: "#444C67",
      },
      "& img": {
        width: "15px",
        height: "15px",
        marginRight: "10px",
      },
    },
  },
  contentButtonLogin: (props) => {
    switch (props.mode) {
      case "pink":
      case "dark":
        return {
          padding: "0px 0px 8px",
          "&:hover": {
            borderBottomStyle: "solid",
            borderBottomWidth: "1px",
            borderBottomColor: "#FFFFFF",
          },
          "&>p": {
            color: "#FFFFFF",
          },
        };
      case "light":
        return {
          padding: "0px 0px 8px",
          "&:hover": {
            borderBottomStyle: "solid",
            borderBottomWidth: "1px",
            borderBottomColor: "#444C67",
          },
          "&>p": {
            color: "#444C67",
          },
        };
    }
  },
  mode: (props) => {
    let style = {
      background: "transparent",
      boxShadow: "none",
      "& div:nth-child(2)": {
        "& p": {
          color: "#FFFFFF",
        },
        "& nav": {
          "& ul": {
            "& li": {
              padding: "0px 0px 8px",
              "&:hover": {
                borderBottomStyle: "solid",
                borderBottomWidth: "1px",
                borderBottomColor: "#FFFFFF",
              },
              "& a": {
                color: "#FFFFFF",
              },
            },
          },
        },
      },
    };
    switch (props.mode) {
      case "dark":
        style = {
          ...style,
          background: "transparent",
          boxShadow: "none",
        };
        style["& div:nth-child(2)"]["& p"].color = "#FFFFFF";
        style["& div:nth-child(2)"]["& nav"]["& ul"]["& li"][
          "&:hover"
        ].borderBottomColor = "#FFFFFF";
        style["& div:nth-child(2)"]["& nav"]["& ul"]["& li"]["& a"].color =
          "#FFFFFF";
        return style;
      case "light":
        style = {
          ...style,
          background: "#F4F6F8",
          boxShadow:
            "-4px -4px 10px rgba(0, 0, 0, 0.05), 4px 4px 10px rgba(0, 0, 0, 0.05)",
        };
        style["& div:nth-child(2)"]["& p"].color = "#444C67";
        style["& div:nth-child(2)"]["& nav"]["& ul"]["& li"][
          "&:hover"
        ].borderBottomColor = "#444C67";
        style["& div:nth-child(2)"]["& nav"]["& ul"]["& li"]["& a"].color =
          "#444C67";
        return style;
      case "pink":
        style = {
          ...style,
          background: "#C87FB3",
          boxShadow:
            "-4px -4px 10px rgba(0, 0, 0, 0.05), 4px 4px 10px rgba(0, 0, 0, 0.05)",
        };
        style["& div:nth-child(2)"]["& p"].color = "#FFFFFF";
        style["& div:nth-child(2)"]["& nav"]["& ul"]["& li"][
          "&:hover"
        ].borderBottomColor = "#FFFFFF";
        style["& div:nth-child(2)"]["& nav"]["& ul"]["& li"]["& a"].color =
          "#FFFFFF";
        return style;
    }
  },
  contentMenu: {
    justifyContent: "center",
  },
  contentHeaderMenu: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0.625em 1.25em",
  },
  contentButton: {
    textAlign: "center",
    padding: "0.625em 1.25em",
    boxShadow: "0px -1px 0px #E7ECF0",
  },
  buttonStyle: {
    width: "100%",
    padding: "1em 0",
  },
  contentSumary: {
    flexBasis: "100%",
    "& p": {
      fontFamily: "Nunito Sans",
      fontWeight: 600,
      fontSize: "1em",
      lineHeight: "1.5em",
      textTransform: "none",
      color: "rgb(68, 76, 103)",
    },
  },
  logoSimple: (props) => {
    let style = {
      width: "40px",
      height: "40px",
    };
    if (props.mediaQuerySm) {
      style = {
        width: "28px",
        height: "28px",
      };
    }
    return style;
  },
  logo: {
    width: "294px",
    height: "56px",
    "@media (min-width: 1280px) and (max-width: 1350px) ": {
      width: "250px",
    },
  },
  menu: (props) => {
    let style = {
      width: "40px",
      height: "40px",
    };
    if (props.mediaQuerySm) {
      style = {
        ...style,
        width: "24px",
        height: "24px",
      };
    }
    return style;
  },
  avatar: {
    cursor: "pointer",
  },
}, {index: 1});
