import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles({
  contentLogin: (props) => {
    let style = {
      width: "792px",
      outline: "none",
      height: "95%",
    };
    if (props.mediaQueryLg) {
      style = {
        ...style,
        height: "600px",
      };
    }
    if (props.mediaQueryMd) {
      style = {
        ...style,
        height: "100%",
      };
    }
    return style;
  },
  loginModal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflowY: "auto",
  },
  AuthFormLogin: (props) => {
    let style = {
      borderRadius: "6px",
      borderTopRightRadius: "6px",
      borderTopLeftRadius: "6px",
      background: " #FFFFFF",
      padding: "24px 24px 56px 24px",
      filter:
        "drop-shadow(-4px -4px 10px rgba(0, 0, 0, 0.05)), drop-shadow(4px 4px 10px rgba(0, 0, 0, 0.05))",
    };

    if (props.mediaQueryMd) {
      style = {
        ...style,
        padding: "8px 20px 69.5px 20px",
        borderRadius: "none",   
      };
    }
    return style;
  },
  wrapperLogin: (props) => {
    let style = {
      margin: "0px 122px 0px 122px",
    };
    if (props.mediaQueryMd) style.margin = "0px";

    return style;
  },
  closeIconContent: (props) => {
    let style = {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      "& >img": {
        cursor: "pointer",
      },
    };
    if (props.mediaQueryMd) {
      style = {
        ...style,
        padding: "12px 0px",
        "& >img": {
          cursor: "pointer",
        },
      };
    }
    return style;
  },
  titleContent: (props) => {
    let style = {
      textAlign: "center",
      margin: "24px 0px 32px 0px",
      "&>h1": {
        fontFamily: "Libre Baskerville",
        fontWeight: "normal",
        fontSize: "32px",
        color: "#444C67",
        margin: "0px 0px 32px 0px",
      },
      "&>p": {
        fontFamily: "Nunito Sans",
        fontSize: "16px",
        color: "#5D688E",
        margin: "0px",
      },
    };
    if (props.mediaQueryMd) {
      style = {
        ...style,
        margin: "24px 0px 24px 0px",
      };
    }
    return style;
  },
  contentButtonsAuth: (props) => {
    let style = {
      display: "flex",
      alignContent: "center",
      justifyContent: "center",
      "&>button:first-child": {
        margin: "0px 16px 0px 0px",
      },
    };
    if (props.mediaQueryMd) {
      style = {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        "&>button:first-child": {
          margin: "0px 0px 16px 0px",
        },
      };
    }
    return style;
  },
  contentText: {
    textAlign: "center",
    margin: "40px 0px 32px 0px",
    "&>p": {
      fontFamily: "Nunito Sans",
      fontWeight: 600,
      fontSize: "14px",
      color: "#444C67",
    },
  },
  rowInput: {
    margin: "0px 0px 16px 0px",
  },
  rowButtons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    "&>button:first-child": {
      margin: "0px 0px 24px 0px",
    },
    "&>button:last-child": {
      "&>span": {
        "&>div": {
          "&>span": {
            "&>span": {
              textDecoration: "underline",
              margin: "0px 0px 0px 4px",
            },
          },
        },
      },
    },
  },
  contentRecoverPassword: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "17.5px 0px 17.5px 0px",
    "&>button": {
      "&>span": {
        "&>div": {
          "&>span": {
            textDecoration: "underline",
            margin: "0px 0px 0px 4px",
          },
        },
      },
    },
  },
  checkbox: {
    color: "#444C67",
    "& checked": {
      color: "#444C67",
    },
  },
  labelRememberme: {
    fontFamily: "Nunito Sans",
    fontWeight: 400,
    fontSize: "12px",
    color: "#444C67",
  },
  messageErrorContent: {
    color: "#ECA25E",
    fontFamily: "Mulish",
    fontWeight: "bold",
    fontSize: "12px",
  },
});
