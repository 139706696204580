import React from "react";
import { useStyles } from "./styles";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
} from "react-share";

export function SocialSectionStyled(props) {
  const {title, size, mt, url, description} = props;
  const classes = useStyles();
  
  function truncateText(url, content) {
    const lengthToTruncate = (220 - url.length);
    
    return (url.length + content.length) > 220 ?
          `${content.substring(0, lengthToTruncate)}...` : content
  }

  let separatedTitle = title ? `${title}\r\n\r\n` : "";
  let twitterTitle = description ? `${separatedTitle}${description}\r\n` : title;
  return (
    <div style={{marginTop: `${mt}rem` || "0.5rem"}} className={classes.root}>
      <TwitterShareButton
        url={url}
        title={truncateText(url, twitterTitle)}
        data-testid="btn_singlePrayer_twitter"
      >
        <TwitterIcon size={size || 32} bgStyle={{fill: "white"}} iconFillColor="#444C67"/>
      </TwitterShareButton>

      <FacebookShareButton url={url} data-testid="btn_singlePrayer_facebook">
        <FacebookIcon size={size || 32} bgStyle={{fill: "white"}} iconFillColor="#444C67"/>
      </FacebookShareButton>
    </div>
  );
}
