import makeStyles from "@material-ui/core/styles/makeStyles";
import ImageDefault from "assets/img/Logo_simple.svg";
export const useStyles = makeStyles({
  gralBlog: (props) => {
    const style = {
      color: "#444C67",
      fontFamily: "Nunito Sans",
      paddingTop: "9em",
      paddingBottom: "4.5em",

      "& h1": {
        fontFamily: "Libre Baskerville",
        fontSize: "2.45em",
        margin: "0px 0px 16px 0px",
        fontWeight: "normal",
      },
      "& h6": {
        fontSize: "14px",
        fontWeight: "normal",
        letterSpacing: "0.1em",
        textTransform: "uppercase",
        margin: 0,
        marginBottom: "0.3em",
        display: "block",
        marginRight: "0.7em",
      },
      "& p": {
        whiteSpace: "break-spaces",
      },
    };
    if (props.mediaQueryMd) {
      style.paddingLeft = "0.5em";
      style.paddingRight = "0.3em";
      style.paddingTop = "5.5em";
      style["& h6"].display = "inline-block";
    }
    return style;
  },
  imgBlog: (props) => {
    const style = {
      height: "100%",
      width: "100%",
      backgroundColor: "none",
      backgroundImage: `url(${ImageDefault})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "10%",
      borderRadius: "6px",
      "&>img": {
        width: "100%",
        height: "100%",
        borderRadius: "6px",
        backgroundSize: "cover",
        display: "block",
      },
    };
    if (!props.isImage) {
      style.height = "50vh";
      style.backgroundColor = "grey";
    }
    return style;
  },
  authorSection: (props) => {
    const style = {
      fontFamily: "Nunito Sans",
      paddingRight: "2.5em",
      "&>div": {
        borderBottom: "1px solid #DAE3EC",
        width: "auto",
        margin: "0.4em",
        paddingTop: "1em",
        paddingBottom: "1em",
        boxSizing: "border-box",
        "&>span": {
          display: "inline-block",
          whiteSpace: "break-spaces",
        },
      },
      "&>div:first-child": {
        paddingTop: 0,
      },
    };
    if (props.mediaQueryMd) {
      style.width = "100%";
    }
    return style;
  },
  blockquote: {
    fontWeight: "bold",
    marginBottom: "2.1em",
  },
  footArticle: {
    
  },
  button: {
    marginTop: "1.5em",
    marginBottom: "1em",
    cursor: "pointer",
    "& div": {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      "& img:first-child": {
        width: "1.5em",
        height: "1.5em",
        marginRight: "0.1875em",
      },
      "& span": {
        textTransform: "capitalize",
        width: "max-content",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        marginLeft: "0.3em",
        marginRight: "0.3em",
      },
    },
  },
});
