import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles({
  BlogContent: {
    backgroundColor: "#F4F6F8",

    overflowX: "hidden",
  },
  wrappertTitle: (props) => {
    let style = {
      margin: "216px 0px 64px 0px",
      "&>h1": {
        margin: 0,
        fontFamily: "Libre Baskerville",
        fontWeight: "normal",
        fontSize: "44px",
        color: "#444C67",
      },
    };
    if (props.mediaQueryMd) {
      style.margin = "88px 0px 48px 0px";
      style["&>h1"] = {
        ...style["&>h1"],
        fontSize: "28px",
        lineHeight: "40px",
      };
    }
    return style;
  },
  blogTitleContainer: () => {
    let style = {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
      width: "100%,",
    };

    return style;
  },
  wrapperCardBlog: (props) => {
    let style = {
      margin: "0px 0px 120px 0px",
    };
    if (props.mediaQueryMd) {
      style = {
        ...style,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        margin: "0px 20px 72px 20px",
      };
    }
    return style;
  },
  itemCardBlog: (props) => {
    let style = {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      margin: "0px 0px 48px 0px",
    };
    if (props.mediaQueryMd) {
      style = {
        ...style,
      };
    }
    return style;
  },
  contentButtonMoreBlog: () => {
    let style = {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    };
    return style;
  },
});
