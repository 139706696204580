import moment from "moment";

moment.updateLocale("en", {
  longDateFormat: {
    l: "MMM Do",
    LL: "MMMM Do",
    ll: "MMM Do",
    LLL: "MMMM Do, YYYY",
    lll: "MMM Do YYYY",
    LLLL: "dddd, LLL",
  },
});

moment.updateLocale("es", {
  longDateFormat: {
    l: "MMM D",
    LL: "D [de] MMMM",
    ll: "D [de] MMM",
    LLL: "LL [de] YYYY",
    lll: "ll [de] YYYY",
    LLLL: "dddd, LLL",
  },
});

moment.updateLocale("fr", {
  longDateFormat: {
    l: "D MMM",
    LL: "D MMMM",
    ll: "D MMM",
    LLL: "LL YYYY",
    lll: "l YYYY",
    LLLL: "dddd, LLL",
  },
});

moment.updateLocale("zh", {
  longDateFormat: {
    l: "LL",
    LL: "MMMM D",
    ll: "LL",
    LLL: "YYYY MMMM D",
    lll: "YYYY MMM D",
    LLLL: "LLL (dddd)",
  },
});

moment.updateLocale("pt", {
  longDateFormat: {
    l: "D MMM",
    LL: "D [de] MMMM",
    ll: "D [de] MMM",
    LLL: "LL [de] YYYY",
    lll: "ll [de] YYYY",
    LLLL: "dddd, LLL",
  },
});

moment.updateLocale("de", {
  longDateFormat: {
    l: "[der] D.MM",
    LL: "[der] D. MMMM",
    ll: "[der] D. MMM",
    LLL: "LL YYYY",
    lll: "[der] D.MM.YYYY",
    LLLL: "[der] dddd, LLL",
  },
});
