import makeStyles from "@material-ui/core/styles/makeStyles";
import monthlyDonateImage from "assets/img/monthly_donate.svg";
export const useStyles = makeStyles({
  donateContent: (props) => {
    let style = {
      height: "416px",
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundImage: `url(${monthlyDonateImage})`,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      flexDirection: "column",
      padding: "auto",
      "&>h1": {
        fontFamily: "Libre Baskerville",
        fontSize: "32px",
        color: "#FFFFFF",
        margin: "34px 0px 16px",
      },
      "&>p": {
        fontFamily: " Nunito Sans",
        fontSize: "16px",
        color: "#FFFFFF",
        margin: "0px 0px 32px",
      },
    };
    if (props.mediaQueryMd) {
      style["&>h1"].fontSize = "24px";
      style["&>p"].margin = "0px 0px 16px";
      style.padding = "40px 20px";
      style.height = "280px";
    }
    return style;
  },
});
