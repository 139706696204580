import React, { useState, useEffect } from "react";
import { useStyles } from "./styles";
import { onChangeSearch } from "../../../helpers";
import { Formik, Form } from "formik";
import { Modal } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import ArrowDarkIcon from "assets/icons/chevron-down-dark.svg";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import IconClose from "assets/icons/Close.svg";
import Grid from "@material-ui/core/Grid";
import Icon from "components/Icon";
import Input from "components/Input";
import Button from "components/Button";
import Select from "components/Select";
import * as Yup from "yup";
import { getCountrys, pswRegex } from "helpers.js";

export function SignUpFormStyled(props) {
  const [searchCountry, setSearchCountry] = useState(null);
  const [country, setCountry] = useState([]);
  const {
    isMd,
    isLg,
    open,
    handleClose,
    navigationLogin,
    handleSignUp,
    handleTermAndConditions,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles({
    mediaQueryMd: isMd,
    mediaQueryLg: isLg,
  });
  const signUpScheme = Yup.object().shape({
    name: Yup.string()
      .required(t("components.signUp.Name required"))
      .matches(/^(?!.*[/.:@%&()]).*/, t("components.signUp.Incorrect format"))
      .test(
        "required",
        t("components.signUp.Name required"),
        (val) => !!val?.trim().length
      )
      .max(50, t("components.signUp.The field must not exceed 50 characters")),
    surname: Yup.string()
      .required(t("components.signUp.Last name required"))
      .matches(/^(?!.*[/.:@%&()]).*/, t("components.signUp.Incorrect format"))
      .test(
        "required",
        t("components.signUp.Last name required"),
        (val) => !!val?.trim().length
      )
      .max(50, t("components.signUp.The field must not exceed 50 characters")),
    country: Yup.object(),
    email: Yup.string()
      .email(t(`components.auth.You must enter a valid email`))
      .required(t(`components.auth.Email Required`)),
    password: Yup.string()
      .required(t("components.signUp.Password required"))
      .matches(pswRegex, t("components.signUp.Incorrect format"))
      .max(50, t("components.signUp.The field must not exceed 50 characters")),
    passwordConfirmation: Yup.string()
      .oneOf(
        [Yup.ref("password"), null],
        t("components.signUp.Passwords must match")
      )
      .required(t("components.signUp.Passwords must match")),
  });
  const initialValueSignUp = {
    name: "",
    surname: "",
    country: "",
    email: "",
    password: "",
    passwordConfirmation: "",
  };
  useEffect(() => {
    if (open) getCountrys(setCountry);
  }, [open]);

  return (
    <>
      <Modal
        open={open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        className={classes.signUpFormModal}
        data-testid="signUpForm_content"
        onClose={handleClose}
      >
        <Fade in={open}>
          <div className={classes.signUpFormContent}>
            <div className={classes.signUpForm}>
              <div className={classes.closeIconContent}>
                <Icon
                  onClick={() => handleClose()}
                  icon={IconClose}
                  alt="close"
                  id="ico_signUpForm_close_modal"
                />
              </div>
              <div className={classes.wrapperSignUpForm}>
                <div className={classes.titleContent}>
                  <h1 data-testid="txt_title_signUpForm">
                    {t(`components.signUp.Join our Community`)}
                  </h1>
                  <p data-testid="txt_description_signUpForm">
                    {t(
                      `components.signUp.Sign up and access exclusive contents carefully prepared for those who are seeking to grow in communion.`
                    )}
                  </p>
                </div>
                <Formik
                  validationSchema={signUpScheme}
                  initialValues={initialValueSignUp}
                >
                  {({
                    dirty,
                    isValid,
                    errors,
                    values,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                    setFieldTouched,
                    touched,
                  }) => {
                    return (
                      <Form>
                        <Grid className={classes.gridForm} container>
                          <Grid
                            className={classes.rowInput}
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            lg={6}
                            xl={6}
                          >
                            <Input
                              isSucess={!errors.name && touched.name}
                              isError={errors.name && touched.name}
                              name="name"
                              label={t(`components.signUp.Name`)}
                              isRequired
                              css={classes.inputs}
                              id="txt_name"
                              value={values.name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              message={errors.name}
                            />
                          </Grid>
                          <Grid
                            className={classes.rowInput}
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            lg={6}
                            xl={6}
                          >
                            <Input
                              isSucess={!errors.surname && touched.surname}
                              isError={errors.surname && touched.surname}
                              name="surname"
                              label={t(`components.signUp.Surname`)}
                              isRequired
                              value={values.surname}
                              id="txt_surname"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              message={errors.surname}
                            />
                          </Grid>
                          <Grid
                            className={classes.rowInput}
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                          >
                            <div className={classes.label}>
                              <label data-testid={`txt_country_label`}>
                                {t(`components.signUp.Country`)}
                              </label>
                            </div>
                            <Select
                              isSucess={values.country && touched.country}
                              isError={!values.country && touched.country}
                              isSearch={true}
                              options={searchCountry ? searchCountry : country}
                              icon={<Icon icon={ArrowDarkIcon} alt="arrow" />}
                              onChangeSelect={(option) => {
                                setFieldTouched("country", true, true);
                                setFieldValue("country", option, false);
                                setSearchCountry(null);
                              }}
                              css={classes.select}
                              onChangeSearch={(event) => {
                                setSearchCountry(
                                  onChangeSearch(country, event)
                                );
                              }}
                              onClickAwayListener={() => {
                                if (!values.country)
                                  setFieldTouched("country", true, true);
                              }}
                              id={`btn_select_country`}
                              optionsCss={classes.optionsCountry}
                            />
                          </Grid>
                          <Grid
                            className={classes.rowInput}
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                          >
                            <Input
                              isSucess={!errors.email && touched.email}
                              isError={errors.email && touched.email}
                              name="email"
                              label={t(`components.signUp.Email`)}
                              id="txt_email"
                              value={values.email}
                              isRequired
                              onChange={handleChange}
                              onBlur={handleBlur}
                              message={errors.email}
                            />
                          </Grid>
                          <Grid
                            className={classes.rowInput}
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={6}
                            xl={6}
                          >
                            <Input
                              type="password"
                              messageInfo={t(
                                "components.signUp.password validation text"
                              )}
                              isSucess={!errors.password && touched.password}
                              isError={errors.password && touched.password}
                              value={values.password}
                              label={t(`components.signUp.Password`)}
                              isRequired
                              css={classes.inputs}
                              id="txt_password"
                              idButtonPassword="password"
                              name="password"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              message={errors.password}
                            />
                          </Grid>
                          <Grid
                            className={classes.rowInput}
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={6}
                            xl={6}
                          >
                            <Input
                              type="password"
                              isSucess={
                                !errors.passwordConfirmation &&
                                touched.passwordConfirmation
                              }
                              isError={
                                errors.passwordConfirmation &&
                                touched.passwordConfirmation
                              }
                              value={values.passwordConfirmation}
                              label={t(`components.signUp.Repeat Password`)}
                              isRequired
                              id="txt_repeat_password"
                              idButtonPassword="repeat_password"
                              name="passwordConfirmation"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              message={errors.passwordConfirmation}
                            />
                          </Grid>
                        </Grid>
                        <div className={classes.contentRegister}>
                          <Button
                            data-testid="btn_signUpForm_term_and_conditions"
                            id="btn_signUpForm_term_and_conditions"
                            type="cuaternary"
                            onClick={() => handleTermAndConditions()}
                          >
                            <div>
                              <span data-testid="txt_signUpForm_term_and_conditions_description">
                                {`${t(
                                  `components.signUp.By registering myself, I accept all`
                                )}`}
                                <span data-testid="txt_signUpForm_term_and_conditions">{`${t(
                                  `components.signUp.Terms & Conditions`
                                )}`}</span>
                              </span>
                            </div>
                          </Button>
                          <div>
                            <Button
                              data-testid="btn_signUpForm"
                              id="btn_signUpForm"
                              text="components.signUp.Create my account"
                              type="primary"
                              onClick={() => handleSignUp(values)}
                              disabled={!(isValid && dirty)}
                            />
                          </div>
                          <Button
                            data-testid="btn_login"
                            id="btn_login"
                            type="cuaternary"
                            onClick={() => navigationLogin()}
                          >
                            <div>
                              <span data-testid="txt_account_signUpForm_description">
                                {`${t(
                                  `components.signUp.Already have an account?`
                                )}`}
                                <span data-testid="txt_account_signUpForm_login">{`${t(
                                  `components.signUp.Login`
                                )}`}</span>
                              </span>
                            </div>
                          </Button>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
}
