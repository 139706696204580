import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import ProfileView from "./ProfileView";
import { useTranslation } from "react-i18next";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { getCountrys } from "helpers.js";
import {
  handleModifyPassword,
  handleEditUser,
  handleEditorAvatar,
  handleCloseAccountUser,
  getGenders,
} from "./profileFunctions";
import { logout } from "services/authService";
import { useSelector } from "react-redux";

export function ProfileContainer() {
  const dispatch = useDispatch();
  const user = useSelector((store) => store.auth.user);
  const history = useHistory();
  const [isCloseAccount, setCloseAccount] = useState(false);
  const mediaQueryMd = useMediaQuery("(max-width:960px)");
  const mediaQueryLg = useMediaQuery("(max-width:1294px)");
  const { t } = useTranslation();
  const [countrys, setCountrys] = useState([]);
  const [Genders, setGenders] = useState([]);
  useEffect(() => {
    getCountrys(setCountrys);
    getGenders(setGenders, t);
  }, [t]);

  return (
    <ProfileView
      user={user}
      isLg={mediaQueryLg}
      isMd={mediaQueryMd}
      countrys={countrys}
      Genders={Genders}
      handleCloseModalAccount={() => setCloseAccount(false)}
      handleOpenCloseaccount={() => setCloseAccount(true)}
      handleModifyPassword={(value) => handleModifyPassword(value, dispatch)}
      handleEditUser={(value) => handleEditUser(value, dispatch, user)}
      handleCloseAccount={async () => {
        await handleCloseAccountUser(dispatch, user);
        logout(dispatch);
        history.push("/");
      }}
      isCloseAccount={isCloseAccount}
      handleCloseSession={() => {
        logout(dispatch);
        history.push("/");
      }}
      handleEditImage={(image) => {
        handleEditorAvatar(image, dispatch, user);
      }}
    />
  );
}
