import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CommunityView from './CommunityView';
import {
  scrollTopWindow,
  languagesSelect,
  fetchPrayersCommunity,
  //reportsPosts,
  getCommentsPosts,
  editCommentsPosts,
  reportComment,
  handlePostComments,
  handlePostCommunity,
  MorePrayersCommunity,
  reportPrayers,
} from 'helpers';
import { reportPosts } from 'services/services';
import { useDispatch } from 'react-redux';
import { setFavorite, handleDislike } from './CommunityFunctions';

export function CommunityContainer() {
  const isMd = useMediaQuery('(max-width:960px)');
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isSuccessPost, setIsSuccessPost] = useState(false);
  const [postsComunnity, setPostCommunnity] = useState([]);
  const [pagination, setPagination] = useState({
    per_page: 5,
    page: 1,
    language: null,
  });
  const [nexPage, setNexPage] = useState(null);
  const languages = [
    {
      id: 'all',
      value: t('components.community.All'),
    },
  ].concat(languagesSelect);

  function handleMorePost() {
    if (nexPage) {
      setPagination({
        per_page: pagination.per_page,
        page: pagination.page + 1,
        language: pagination.language,
      });
      MorePrayersCommunity(
        setPostCommunnity,
        {
          per_page: pagination.per_page,
          page: pagination.page + 1,
          language: pagination.language,
        },
        postsComunnity,
        setNexPage
      );
    }
  }
  function handleFilter(option) {
    setPagination({
      per_page: 5,
      page: 1,
      language: option.id !== 'all' ? option.id : null,
    });
    fetchPrayersCommunity(
      setPostCommunnity,
      {
        per_page: 5,
        page: 1,
        language: option.id !== 'all' ? option.id : null,
      },
      setNexPage
    );
  }
  useEffect(() => {
    fetchPrayersCommunity(setPostCommunnity, pagination, setNexPage);
    scrollTopWindow();
  }, [t]);
  return (
    <CommunityView
      isSuccessPost={isSuccessPost}
      languages={languages}
      isMd={isMd}
      posts={postsComunnity}
      handleMorePost={handleMorePost}
      handlePostComments={(values, post, userLogged) => {
        if (userLogged)
        return  handlePostComments(
            values,
            post,
            setPostCommunnity,
            postsComunnity,
            dispatch,
            pagination
          );
        else {
         
          dispatch({
            type: '@modal/login',
            payload: { open: true },
          });
          return {code: 1004};
        }
      }}
      handlePostCommunity={(values) =>
        handlePostCommunity(
          values,
          setIsSuccessPost,
          setPostCommunnity,
          dispatch,
          pagination
        )
      }
      handleMoreComments={(post) =>
        getCommentsPosts(post?.id, setPostCommunnity, postsComunnity, dispatch)
      }
      handleEditComments={(values, comment) =>
        editCommentsPosts(
          values,
          comment,
          postsComunnity,
          setPostCommunnity,
          dispatch
        )
      }
      handleReportComment={(comment) =>
        reportComment(comment, setPostCommunnity, postsComunnity, dispatch)
      }
      handleReportPost={  (post, userLogged) => {
        if (userLogged) {   
              return  reportPrayers(post, reportPosts, dispatch);
        }else {
          dispatch({
            type: '@modal/login',
            payload: { open: true },
          });
          return {code:1004};
        }
      }}
      handleFilter={handleFilter}
      onDeletePrayer={() =>
        fetchPrayersCommunity(setPostCommunnity, pagination, setNexPage)
      }
      handleFavorite={(owner, ownerType, index) =>
        setFavorite(owner, ownerType, index, postsComunnity, setPostCommunnity)
      }
      handleDislike={(owner, ownerType, index) =>
        handleDislike(
          owner,
          ownerType,
          index,
          postsComunnity,
          setPostCommunnity
        )
      }
    />
  );
}
