import "moment/locale/es";
import "moment/locale/en-ca";
import _ from "lodash";
import React, { useEffect, useState, useCallback, useMemo } from "react";
import moment from "moment";
import { useStyles } from "./styles";
import { useTranslation } from "react-i18next";
import segretaroaGeneraleSinodo from "assets/img/segretaroa-generale-sinodo.png";
import uisg_logo from "assets/img/uisg_logo.svg";
import { Grid, Hidden } from "@material-ui/core";
import { Backdrop, Modal } from "@material-ui/core";
import CampaignItemCard from "components/Cards/CampaignPrayerCard";
import Carrusel from "components/Carrusel";
import Button from "components/Button";
import Icon from "components/Icon";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import CustomButton from "components/CustomButton";

const CampaignView = (props) => {
  const { t } = useTranslation();
  const {
    indexCamp,
    openWindowLink,
    mediaQueryLg,
    mediaQueryMd,
    mediaQueryXs,
    iconMoment,
    codeMoment,
    codeCampaign,
    campaign = {},
    startedCampaign,
    handlerBackToSynod,
    handlerGoToCommunity,
    handleClickOpenUrl
  } = props;

  const [openModal, setOpenModal] = useState(false);
  const [carruselIndex, setCarruselIndex] = useState(0);
  const [betweenArrows, setTextBetweenArrows] = useState("");

  const classes = useStyles({ mediaQueryLg, mediaQueryMd, mediaQueryXs });

  const imageCampaign = useMemo(
    () =>
      campaign?.multimedia?.find(
        (image) => image?.type?.code !== "IMG_ICON_CAMPAIGN"
      )?.url,
    [campaign]
  );

  const validCampaignPrayers = useMemo(
    () =>
      campaign.campaignPrayer?.filter(
        (cp) =>
          cp.date === null || moment(cp.date).isSameOrBefore(moment(), "day")
      ) || [],
    [campaign.campaignPrayer]
  );

  const findCarruselIndex = useCallback(() => {
    if (!validCampaignPrayers.length) return 0;
    const index = validCampaignPrayers.findIndex(
      (prayer) => prayer.id === Number(indexCamp)
    );
    return index !== -1 ? index : 0;
  }, [validCampaignPrayers, indexCamp]);

  const chooseTxtToArrows = useCallback(
    (prayer = {}, index = 0) => {
      if (!validCampaignPrayers.length) return "";

      switch (codeCampaign) {
        case "SCH":
          return `${moment(prayer.date).format("LL")}`;
        case "PRO":
          return campaign.title;
        case "UNS":
          return `${t("moment.fields.day")} ${index + 1}`;
        case "TIP":
          return `${t("pages.schoolPrayer.Advice")} ${index + 1}/${
            validCampaignPrayers.length
          }`;
        case "ROT":
          return "Click To Pray eRosary";
        default:
          return "";
      }
    },
    [validCampaignPrayers, codeCampaign, campaign.title, t]
  );

  useEffect(() => {
    const index = findCarruselIndex();
    setCarruselIndex(index);
    const prayer = validCampaignPrayers[index];
    setTextBetweenArrows(chooseTxtToArrows(prayer, index));
  }, [findCarruselIndex, validCampaignPrayers, chooseTxtToArrows]);

  const changeCard = useCallback(
    (index) => {
      if (validCampaignPrayers.length > 0) {
        const newIndex =
          (index + validCampaignPrayers.length) % validCampaignPrayers.length;
        const prayer = validCampaignPrayers[newIndex];
        const text = chooseTxtToArrows(prayer, newIndex);
        setCarruselIndex(newIndex);
        setTextBetweenArrows(text);
      }
    },
    [validCampaignPrayers, chooseTxtToArrows]
  );

  const windowOpenNewLink = useCallback((param) => {
    window.open(param, "_blank", "noopener,noreferrer");
    setOpenModal(false);
  }, []);

  return (
    <Grid
      container
      className={classes.gral}
      justify="center"
      data-testid="campaign_view"
    >
      <Grid item xs={12} sm={11} md={10} xl={8}>
        <Grid container spacing={1} direction="column">
          <Grid item className={classes.buttonBack}>
            <Button
              iconLeft={<ArrowBackIosIcon />}
              type="cuaternary"
              text={t("components.general.Go back")}
              onClick={handlerBackToSynod}
              id="btn_back_to_synod"
            />
          </Grid>

          {imageCampaign && (
            <div
              data-testid="img_campaign"
              className={classes.imgCampaign}
              style={{
                backgroundImage: `url("${imageCampaign}")`
              }}
            />
          )}

          <Grid item style={{ marginBottom: "1rem" }}>
            {campaign?.title && (
              <h1 data-testid="txt_campaign_title">
                {codeCampaign === "TIP"
                  ? `${t("pages.schoolPrayer.Tips for your prayer")} - `
                  : ""}
                {campaign.title}
              </h1>
            )}
            <p
              data-testid="txt_campaign_description"
              dangerouslySetInnerHTML={{ __html: campaign.description }}
            />

            {campaign.link && (
              <CustomButton
                iconRight={<ArrowForwardIcon />}
                css={classes.button}
                text={t("components.general.See more prayers")}
                type="primary"
                id="btn_campaing_link"
                onClick={handlerBackToSynod}
              />
            )}

            {startedCampaign && (
              <Hidden smUp>
                <p data-testid="txt_campaign_moment">
                  {iconMoment && codeCampaign !== "ROT" && (
                    <Icon icon={iconMoment} css={classes.suggestIcon} />
                  )}
                  {codeCampaign !== "ROT" &&
                    t("pages.campaign.suggest for", {
                      time: _.capitalize(
                        t(`pages.campaign.dayMoment.${codeMoment}`)
                      )
                    })}
                </p>
              </Hidden>
            )}
          </Grid>

          {!startedCampaign && (
            <div
              className={classes.notStarted}
              data-testid="txt_campaign_not_start"
            >
              <span data-testid="txt_no_started">
                {t("pages.campaign.no started", {
                  date: moment(campaign.date_from).format("LL")
                })}
              </span>
              <Button
                text={t("components.button.Download the App")}
                type="primary"
                id="btn_download_app"
                onClick={() => setOpenModal(true)}
              />
            </div>
          )}

          <Modal
            open={openModal}
            onClose={() => setOpenModal(false)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500
            }}
          >
            <div className={classes.modalDownload}>
              <div className={classes.divModalContainer}>
                <div className={classes.modalTitle}>
                  {t("components.footer.Download the Click To Pray App")}
                </div>
                <div className={classes.divModal}>
                  <Button
                    data-testid="btn_appStore"
                    type="primary"
                    text={t("components.footer.AppStore")}
                    css={classes.buttonAppstore}
                    onClick={() =>
                      windowOpenNewLink(
                        "https://apps.apple.com/ar/app/click-to-pray/id934935942"
                      )
                    }
                  />
                  <Button
                    data-testid="btn_googlePlay"
                    type="primary"
                    text={t("components.footer.Google Play")}
                    onClick={() =>
                      windowOpenNewLink(
                        "https://play.google.com/store/apps/details?id=com.lamachi.clicktopray"
                      )
                    }
                  />
                </div>
              </div>
            </div>
          </Modal>

          {startedCampaign && validCampaignPrayers.length > 0 && (
            <div>
              <Hidden smUp>
                <div className={classes.captionCard}>{betweenArrows}</div>
              </Hidden>

              <Grid item xs={12}>
                <Carrusel
                  data-testid="carrusel_content_campaign"
                  indicators={false}
                  index={carruselIndex}
                  autoPlay={false}
                  cycleNavigation={false}
                  className={classes.carrusel}
                  navButtonsWrapperProps={{
                    className: classes.carruselBtnWrapper
                  }}
                  navButtonsProps={{
                    className: classes.carruselBtn,
                    style: { backgroundColor: "transparent", opacity: 1 }
                  }}
                  PrevIcon={<ArrowBackIosIcon data-testid="btn_prev_card" />}
                  NextIcon={<ArrowForwardIosIcon data-testid="btn_next_card" />}
                  prev={(index) => changeCard(index - 1)}
                  next={(index) => changeCard(index + 1)}
                >
                  {validCampaignPrayers.map((value, key) => (
                    <CampaignItemCard
                      key={value.id}
                      isSm={mediaQueryXs}
                      isMd={mediaQueryMd}
                      index={key + 1}
                      captionDay={betweenArrows}
                      prayer={value}
                      iconMoment={iconMoment}
                      codeMoment={codeMoment}
                      codeCampaign={codeCampaign}
                      openWindowLink={openWindowLink}
                      owner={value.id}
                      ownerType="CAP"
                      indexCampaign={key}
                      campaignItem={campaign}
                    />
                  ))}
                </Carrusel>
              </Grid>
            </div>
          )}
          <Grid item className={classes.sectionButtonShareCommunity}>
            <CustomButton
              iconRight={<ArrowForwardIcon />}
              type="secondary"
              text="components.community.Share your prayer"
              onClick={handlerGoToCommunity}
              id="btn_share_community"
            />
          </Grid>
          <Grid
            style={{ width: "100%" }}
            item
            className={classes.sectionLogosPartners}
          >
            <Icon
              data-testid="icon_segretaroaGeneraleSinodo"
              id="icon_segretaroaGeneraleSinodo"
              icon={segretaroaGeneraleSinodo}
              onClick={() => handleClickOpenUrl(t("components.synod.synod.va"))}
            />
            <Icon
              data-testid="icon_uisg_logo"
              id="icon_uisg_logo"
              icon={uisg_logo}
              onClick={() => handleClickOpenUrl(t("components.synod.uisg.org"))}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default React.memo(CampaignView);
