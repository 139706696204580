import makeStyles from "@material-ui/core/styles/makeStyles";
export const useStyles = makeStyles({
  contentDay: (props) => {
    let style = {
      fontFamily: "Nunito Sans",
      fontSize: "14px",
      textTransform: "uppercase",
      color: "#444C67",
      letterSpacing: "0.1em",
      margin: "40px 0px 8px 0px",
    };
    if (props.mediaQuerySm) {
      style = {
        ...style,
        margin: "24px 0px 8px 0px",
      };
    }

    return style;
  },
  description: (props) => {
    let style = {
      display: "flex",
      marginBottom: "32px",
      alignItems: "flex-start",
      textTransform: "none",
    };
    if (props.mediaQuerySm) {
      style.marginBottom = "32px";
    }
    return style;
  },
  contentDescription: (props) => {
    let style = {
      width: "100%",
      wordBreak: "break-word",
      "& p": {
        fontFamily: "Nunito Sans",
        color: "#444C67",
        fontSize: "18px",
        fontWeight: 600,
        margin: 0,
      },
    };
    if (props.mediaQuerySm) {
      style["& p"].fontSize = "16px";
    }
    return style;
  },
  titleRosary: (props) => {
    let style = {
      fontFamily: "Libre Baskerville",
      fontWeight: "normal",
      fontSize: "32px",
      color: "#444C67",
      margin: "0px 0px 40px 0px",
    };
    if (props.mediaQuerySm)
      style = { ...style, fontSize: "24px", margin: "0px 0px 24px 0px" };
    return style;
  },
  formControlLabel: {
    margin: 0,
  },
  buttonVideo: {
    margin: "0px 0px 32px 0px",
  },
  contentDetails: (props) => {
    let style = {
      padding: "56px  304px 56px 88px",
    };
    if (props.mediaQueryLg) {
      style = { ...style, padding: "16px 8px 8px 8px" };
    }
    if (props.mediaQuerySm) {
      style = { ...style, padding: "16px 8px 8px 8px" };
    }

    return style;
  },
  contentFinalPrayer: (props) => {
    let style = {
      margin: "48px 0px 0px 0px",
      "&>h1": {
        fontFamily: "Nunito Sans",
        color: "#444C67",
        fontSize: "22px",
        fontWeight: 600,
        margin: "0px 0px 24px 0px",
      },
      "&>p": {
        fontFamily: "Nunito Sans",
        color: "#444C67",
        fontSize: "16px",
        margin: "0px 0px 64px 0px",
      },
    };
    if (props.mediaQuerySm) {
      style.margin = "40px 0px 0px 0px";
      style["&>p"].margin = "0px 0px 24px 0px";
    }
    return style;
  },
  buttonSocialMedia: {
    minWidth: "0px !important",
    margin: "0px 0px  0px 28px",
    padding: 0,
    "& >span": {
      "& >img": {
        width: "1.25em",
        height: "1.25em",
      },
    },
  },
  buttonFinalPrayer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  buttonFavorite: {
    padding: "0.75em 0px",
    "& div": {
      display: "flex",
      "& img:first-child": {
        marginRight: "0.60375em",
      },
    },
  },
  contentImageRosary: (props) => {
    let style = {
      maxHeight: "300x",
      minHeight: "300px",
      width: "100%",
      background: "#E7ECF0",
      margin: "0px 0px 10px 0px",
      backgroundSize: "cover",
      "&>img": {
        width: "100%",
        height: "100%",
        backgroundSize: "cover",
        backgroundPosition: "center center",
      },
    };
    if (props.mediaQueryLg) {
      style.minHeight = "200px";
    }
    if (props.mediaQuerySm) {
      style.minHeight = "100px";
    }

    return style;
  },
});
