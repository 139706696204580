import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles({
  selectBox: {
    position: "relative",
    width: "100%",
  },
  select: (props) => {
    let style = {
      "& div": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexDirection: "row",
      },
      background: "transparent",
      width: "100%",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "transparent",
        boxShadow: "none",
      },
      zIndex: 300,
      "& > span": {
        fontFamily: "Nunito Sans",
        fontSize: "14px",
        color: "#444C67",

        "& > div:first-child": {
          "&>span": {
            margin: "0px 5px 0px 0px",
          },
        },
      },
    };
    if (props.isSearch) style["& > span"]["& > div:first-child"].width = "100%";

    return style;
  },
  options: {
    // width: "130px",
    scrollbarWidth: "none !important",
    padding: "8px 8px 0px 8px",
    background: "white",
    borderBottom: "1px solid #FFFFFF",
    boxShadow:
      "-4px -4px 10px rgba(0, 0, 0, 0.05), 4px 4px 10px rgba(0, 0, 0, 0.05)",
    borderRadius: "6px",
    maxHeight: "10.5em",
    overflow: "auto",
    display: "block",
    flexDirection: "column",
    alignItems: "flex-start",
    zIndex: 200,
    position: "absolute",
    cursor: "pointer",

    "&::-webkit-scrollbar": {
      display: "none",
    },
    "& div:last-child": {
      borderBottom: "none",
    },
  },

  contentSearch: {
    borderBottom: "1px solid #E7ECF0",
    "& .MuiPaper-rounded": {
      border: "none ",
    },
    "&>div": {
      padding: "8px 16px",
      display: "flex",
    },
  },
  buttonSearch: {
    padding: 0,
  },
  inputSearch: {
    width: "100%",
    "& .MuiInputBase-input": {
      padding: 0,
      fontFamily: "Nunito Sans",
      fontSize: "16px",
      "&::placeholder": {
        color: "#444C67",
        fontWeight: 600,
        opacity: 1,
      },
    },
  },
  elevation: {
    boxShadow: "none",
  },
  contentButtonSelected: {
    display: "flex",
  },
  buttons: {
    cursor: "auto",
    padding: 0,
    margin: "0px 0px 0px 0px",
  },
  error: {
    "&>span:first-child": {
      "&>svg": {
        fill: "#ECA25E",
        width: "20px",
        height: "20px",
      },
    },
  },
  sucess: {
    "&>span:first-child": {
      "&>svg": {
        fill: "#71AF7B",
        width: "20px",
        height: "20px",
      },
    },
  },
  listOptions: {
    scrollbarWidth: "none !important",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "&::-webkit-scrollbar-button:increment,.contenedor::-webkit-scrollbar-button": {
      display: "none",
    },
    "&>div": {
      "& div": {
        borderBottom: "1px solid #E7ECF0",
        "& button": {
          padding: "8px 16px",
        },
      },
    },
  },
});
