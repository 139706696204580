import moment from 'moment';
import request from './http';

export function getPrayingTips(date) {
  return request({
    url: `backoffice/tips`,
    params: { date },
  });
}

export function getIntention(params) {
  return request({
    url: `backoffice/intentions/home`,
    params: { ...params },
  });
}
export function getIntentionsMonthly(date_from, date_to) {
  return request({
    url: `backoffice/intentions`,
    params: { date_from, date_to },
  });
}

export function getIntentionsById(id) {
  return request({
    url: `backoffice/intentions/${id}`,
  });
}

export function getIntentionAttitudes(id) {
  return request({
    url: `backoffice/intentions/${id}/attitudes`,
  });
}
export function getPrayer(params) {
  return request({
    url: `backoffice/prayers`,
    params: { ...params },
  });
}

export function getPrayerById(id) {
  return request({
    url: `backoffice/prayers/${id}`,
  });
}

export function getConferences(date_from, date_to) {
  return request({
    url: `backoffice/bishop-conferences`,
    params: { date_from, date_to },
  });
}

export function getBlogs(params) {
  return request({
    url: `blog/posts`,
    params: { ...params, date: moment().format('YYYY-MM-DD') },
  });
}

export function getBlogsHome(params) {
  return request({
    url: `blog/posts/home`,
    params: { ...params, date: moment().format('YYYY-MM-DD') },
  });
}

export function getBlogArticle(id) {
  return request({
    url: `blog/posts/${id}`,
    params: { date: moment().format('YYYY-MM-DD'), other_languages: true },
  });
}
export function getPopeProfileData(per_page) {
  return request({
    url: `backoffice/pope-profile`,
    params: { per_page },
  });
}

export function getPrayerSchoolHome(params) {
  return request({
    url: `backoffice/prayers-school/home`,
    params: { ...params },
  });
}

export function getPrayerSchoolTab(params) {
  return request({
    url: `backoffice/prayers-school/tab`,
    params: { ...params },
  });
}
export function getCampaign(id) {
  return request({
    url: `backoffice/campaigns/${id}`,
    params: { other_languages: true }
  });
}

export function getCountries() {
  return request({
    url: `backoffice/countries`,
  });
}

export function getRosary(id) {
  return request({
    url: `backoffice/eRosary/${id}`,
  });
}

export function getMysterie(day, id) {
  return request({
    url: `backoffice/mysteries/?day=${day}&rosary=${id}`,
  });
}

export function getMapFriends() {
  return request({
    url: `backoffice/countries/friends`,
  });
}

export function getPostsFacebook() {
  return request({
    url: `backoffice/facebook`,
  });
}

export function postSignUp(data) {
  return request({
    url: 'user/users/sign-up',
    method: 'POST',
    data,
  });
}

export function verifyAccount(hash) {
  return request({
    url: 'user/users/verify',
    method: 'POST',
    data: hash,
  });
}

export function postLogin(data) {
  return request({
    url: 'user/auth/sign-in',
    method: 'POST',
    data,
  });
}

export function updatePassword(data) {
  return request({
    url: 'user/users/modify-password',
    method: 'PUT',
    data,
  });
}

export function signUpFacebook(access_token) {
  return request({
    url: 'user/users/sign-up/facebook',
    method: 'POST',
    data: access_token,
  });
}

export function signUpGoogle(access_token) {
  return request({
    url: 'user/users/sign-up/google',
    method: 'POST',
    data: access_token,
  });
}

export function signInFacebook(access_token) {
  return request({
    url: 'user/auth/sign-in/facebook',
    method: 'POST',
    data: access_token,
  });
}

export function signInGoogle(access_token) {
  return request({
    url: 'user/auth/sign-in/google',
    method: 'POST',
    data: access_token,
  });
}

export function recoverPasswordSendEmail(email) {
  return request({
    url: 'user/users/recover-password',
    method: 'PUT',
    data: email,
  });
}

export function postRecoverPassword(password, hash) {
  return request({
    url: `user/users/recover-password/${hash}`,
    method: 'PUT',
    data: password,
  });
}

export function putUser(user, id) {
  return request({
    url: `user/users/${id}/person`,
    method: 'PUT',
    data: user,
  });
}

export function putUserImaege(data) {
  return request({
    headers: { 'Content-Type': 'multipart/form-data' },
    url: `multimedia/multimedia/prayers`,
    method: 'POST',
    data,
  });
}

export function deleteUser(id) {
  return request({
    url: `user/users/${id}`,
    method: 'DELETE',
  });
}

export function genders() {
  return request({
    url: `user/genders`,
    method: 'GET',
  });
}

export function refreshToken(refreshToken) {
  return request({
    url: `user/auth/refresh-token/${refreshToken}`,
    method: 'GET',
  });
}

export function getAmen(owner, ownerType) {
  return request({
    url: `community/amen`,
    params: { owner, ownerType },
  });
}

export function getAmenGroup(owner, ownerType, ownersGroup) {
  return request({
    url: `community/amen/group`,
    params: { group: ownersGroup.join(','), ownerType, owner },
  });
}

export function postAmen(data) {
  return request({
    url: 'community/amen',
    method: 'POST',
    data,
  });
}

export function deleteAmen(data) {
  return request({
    url: `community/amen?owner=${data.owner}&ownerType=${data.ownerType}`,
    method: 'DELETE',
    data,
  });
}

export function getCommunityPrayerByID(id) {
  return request({
    url: `community/prayers/${id}`,
    method: 'GET',
  });
}

export function getPrayersCommunity(params) {
  return request({
    url: `community/prayers`,
    method: 'GET',
    params: { ...params },
  });
}

export function postPrayersCommunity(data) {
  return request({
    url: `community/prayers`,
    method: 'POST',
    data,
  });
}

export function postComments(data) {
  return request({
    url: `community/comments`,
    method: 'POST',
    data,
  });
}

export function reportPosts(id) {
  return request({
    url: `community/prayers/${id}/report`,
    method: 'POST',
  });
}

export function deleteCommunityPost(id) {
  return request({
    url: `community/prayers/delete/${id}`,
    method: 'DELETE',
  });
}

export function getCommentsPost(id, type) {
  return request({
    url: `community/comments/${id}`,
    method: 'GET',
    params: { type }
  });
}

export function editCommentsPost(data, id) {
  return request({
    url: `community/comments/modify/${id}`,
    method: 'PUT',
    data,
  });
}

export function reportCommentsPost(id) {
  return request({
    url: `community/comments/${id}/report`,
    method: 'POST',
  });
}

export function deleteCommunityComment(id) {
  return request({
    url: `community/comments/delete/${id}`,
    method: 'DELETE',
  });
}
