import React from "react";
import Link from "@material-ui/core/Link";

import { useStyles } from "./styles";
import Typography from '@material-ui/core/Typography';

export function AddressCardStyled(props) {
  const { code, name, role, email } = props;
  const classes = useStyles();
  return (
    <div className={classes.address}>
      <Typography noWrap><strong>{code}:</strong></Typography>
      <Typography noWrap>{name}</Typography>
      <Typography noWrap>{role}</Typography>
      <Typography noWrap><Link href={`mailto:${email}`}>{email}</Link></Typography>
    </div>
  );
}
