/* eslint-disable */
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import qs from "qs";
import TemporalView from "./TemporalView";
import { verifyAccount } from "services/services";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { isExpireLink } from "services/authService";

export function TemporalContainer() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const mediaQueryMd = useMediaQuery("(max-width:960px)");
  const history = useHistory();
  useEffect(async () => {
    const params = qs.parse(location.search, { ignoreQueryPrefix: true });
    if (params?.["recover-password"]) {
     /* if (isExpireLink(params?.["recover-password"])) {
        dispatch({
          type: "@modal/error",
          payload: {
            open: true,
            message: {
              name: t('components.recoverPassword.link expired'),
              messageError: t('components.recoverPassword.link expired message'),
              code: 1002,
            },
          },
        });
      } else {*/
      dispatch({
        type: "@modal/recoverPassword",
        payload: {
          open: true,
          hash: params?.["recover-password"],
          email: params?.["email"],
        },
      });
     // }
    }
    if (params && params["verify-account"]) {
      try {
        await verifyAccount({ hash: params["verify-account"] });
        await dispatch({
          type: "@modal/login",
          payload: { open: true },
        });
      } catch (error) {
        if (error?.name)
          dispatch({
            type: "@modal/error",
            payload: {
              open: true,
              message: { name: error?.name, messageError: error?.message },
            },
          });
        else
          dispatch({
            type: "@modal/error",
            payload: {
              open: true,
              message: {
                name: t(`pages.temporal.Error:error code`),
                messageError: t(`pages.temporal.Error:error code`),
                code: null
              },
            },
          });
      }
    }
    history.push("/");
  }, []);
  return <TemporalView isMd={mediaQueryMd} />;
}
