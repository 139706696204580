import React from "react";
import { Formik, Form } from "formik";
import { useStyles } from "./styles";
import { useTranslation } from "react-i18next";
import { Modal } from "@material-ui/core";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import Icon from "components/Icon";
import IconClose from "assets/icons/Close.svg";
import Input from "components/Input";
import Button from "components/Button";
import * as Yup from "yup";

export function RecoverPasswordStyled(props) {
  const { isMd, isLg, open, email, handleClose, handleRecoverPassword } = props;
  const initialValueRecoverPassword = {
    email: email !== "" ? email : "",
  };
  const { t } = useTranslation();
  const classes = useStyles({
    mediaQueryMd: isMd,
    mediaQueryLg: isLg,
  });

  const recoverPasswordScheme = Yup.object().shape({
    email: Yup.string()
      .email(t(`components.auth.You must enter a valid email`))
      .required(t(`components.auth.Email Required`)),
  });

  return (
    <Modal
      open={open}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      className={classes.recoverPasswordModal}
      data-testid="recoverPassword-content"
    >
      <Fade in={open}>
        <div className={classes.recoverPasswordContent}>
          <div className={classes.recoverPassword}>
            <div className={classes.closeIconRecoverPassword}>
              <Icon
                onClick={() => handleClose()}
                icon={IconClose}
                alt="close"
                id="ico_recoverPassword_close_modal"
              />
            </div>
            <div className={classes.wrapperRecoverPassword}>
              <div className={classes.textRecoverPasswordContent}>
                <p data-testid="txt_recoverPassword_title">
                  {t(
                    `components.recoverPassword.Please enter your email address. You will receive a link to create a new password via email`
                  )}
                </p>
              </div>
              <Formik
                validationSchema={recoverPasswordScheme}
                initialValues={initialValueRecoverPassword}
                validateOnMount
              >
                {({
                  isValid,
                  errors,
                  values,
                  handleChange,
                  handleBlur,
                  touched,
                }) => {
                  return (
                    <Form>
                      <Input
                        isSucess={
                          !errors.email && (values.email || touched.email)
                        }
                        isError={
                          errors.email && (values.email || touched.email)
                        }
                        name="email"
                        placeholder={t(
                          "components.recoverPassword.Your email here!"
                        )}
                        id="txt_email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        message={errors.email}
                      />
                      <div className={classes.contentButton}>
                        <Button
                          data-testid="btn_recoverPassword"
                          id="btn_recoverPassword"
                          text="components.recoverPassword.Get new password"
                          type="primary"
                          onClick={() => handleRecoverPassword(values.email)}
                          disabled={!isValid}
                        />
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}
