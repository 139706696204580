import React, { useState, useEffect } from "react";
import SharePrayerView from "./SharePrayerView";
import { useParams, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import MessageErrors from "components/MessageErrors";
import {
  fetchPrayer,
  handleFavorite,
  handleDislike,
  handlePostCommunity,
  handlePostComments,
} from "./SharePrayerFunctions";
import { scrollTopWindow, editCommentsPosts, reportComment } from "helpers";
import { POPE, COMMUNITY } from "navigation/CONSTANTS";
import { getUser } from "services/authService";
import { editComment } from "pages/PopeProfile/PopeProfileFunctions";
import { useDispatch } from "react-redux";
import { reportCommentsPost, reportPosts } from "services/services";

export function SharePrayerContainer() {
  const { t } = useTranslation();
  const { type_id } = useParams();
  const [prayers, setPrayers] = useState([]);
  const [isSuccessPost, setIsSuccessPost] = useState(false);
  const [isError, setError] = useState({ open: false });

  const history = useHistory();
  const dispatch = useDispatch();
  const mediaQueryMd = useMediaQuery("(max-width:960px)");
  const [type, id] = type_id.split(".");
  const prayerType = String(type).toUpperCase();
  const user = getUser();
  const onChargeMore = () => history.push(prayerType === "POP" ? POPE : COMMUNITY);

  useEffect(() => {
    scrollTopWindow();
    fetchPrayer(prayerType, id, setPrayers, setError);
  }, [t, id, type]);

  return (
  <div>
    <MessageErrors
        open={isError.open}
        title={isError.name}
        messageError={isError.message}
        handleClose={onChargeMore}
        isMd={mediaQueryMd}
      />

    <SharePrayerView
      prayers={prayers}
      mediaQueryMd={mediaQueryMd}
      type={prayerType}
      onChargeMore={onChargeMore}
      handleFavorite={handleFavorite}
      handleDislike={handleDislike}
      // -- pope
      handlePopePostComments={handlePostComments(
        user,
        prayerType,
        prayers,
        setPrayers,
        dispatch
      )}
      handleEditPopeComments={(prayer, inx) => async (values, comment) => {
        const prayerUpdated = await editComment(
          dispatch,
          prayer,
          values.comment,
          comment.id
        );
        const newState = [...prayers];
        newState[inx] = prayerUpdated;
        setPrayers(newState);
      }}
      handleReportPopeComment={(prayer, inx) => (comment) => {
        reportCommentsPost(comment.id);
        const inxComm = prayer.comments?.findIndex((c) => c.id == comment.id);
        if (inxComm >= 0) {
          const newState = [...prayers];
          newState[inx].comments[inxComm].reported = true;
          setPrayers(newState);
        }
      }}
      // -- community
      isSuccessPost={isSuccessPost}
      handlePostCommunity={(values) =>
        handlePostCommunity(
          values,
          setIsSuccessPost,
          setPrayers,
          prayers,
          dispatch
        )
      }
      handlePostComments={handlePostComments(
        user,
        prayerType,
        prayers,
        setPrayers,
        dispatch
      )}
      handleEditComments={(values, comment) =>
        editCommentsPosts(values, comment, prayers, setPrayers, dispatch)
      }
      handleReportComment={(comment) =>
        reportComment(comment, setPrayers, prayers, dispatch)
      }
      handleReportPost={(post, userLogged) => {
        if (userLogged) reportPosts(post?.id);
        else {
          dispatch({
            type: "@modal/login",
            payload: { open: true },
          });
        }
      }}
      onDeletePrayer={(prayer_id) => {
        const prayerInx = prayers.findIndex((p) => p.id == prayer_id);
        const updatedPrayers = [...prayers];
        if (prayerInx >= 0) updatedPrayers.splice(prayerInx, 1);
        setPrayers(updatedPrayers);
      }}
    />
  </div>
  );
}
