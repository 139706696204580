import makeStyles from "@material-ui/core/styles/makeStyles";
import ImageWorldMap from "assets/img/world-map-background.svg";

export const useStyles = makeStyles({
  sectionWorldMap: () => {
    return {
      width: "100%",
      backgroundImage: `url(${ImageWorldMap})`,
      backgroundSize: "cover",
      backgroundPosition: "center center",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      padding: "43px 0px 43px",
    };
  },
  containerGrid: () => {
    return {
      display: "flex",
      flexDirection: "row-reverse",
    };
  },
  contentMap: (props) => {
    let style = {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "1000px",
    };
    if (props.mediaQuerySm) {
      style = {
        ...style,
        "&>div": {
          width: "1000px",
          overflowX: "auto",
        },
      };
    }
    return style;
  },
  contentTitleMap: (props) => {
    let style = {
      whiteSpace: "pre-wrap",
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "& h1": {
        color: "rgb(255, 255, 255)",
        fontSize: "32px",
        fontFamily: "Libre Baskerville",
        fontWeight: 600,
        margin: 0,
      },
    };
    if (props.mediaQuerySm) {
      style = {
        ...style,
        padding: "0px 0px 48px",
      };
      style["& h1"] = {
        ...style["& h1"],
        fontSize: "24px",
      };
    }
    if (props.mediaQueryLg) {
      style = {
        ...style,
        padding: "0px 0px 48px",
      };
      style["& h1"] = {
        ...style["& h1"],

        fontSize: "24px",
      };
    }

    return style;
  },
  map: () => {
    return {
      strokeLinejoin: "round",
      stroke: "#5D688E",
      fill: "#3D4B6C",
      height: "647px",
      width: "1000px",
    };
  },
});
