import React, { useState, useEffect } from "react";
import { useStyles } from "./styles";
import { useTranslation } from "react-i18next";
import { Avatar, Grid } from "@material-ui/core";
import { useFormik } from "formik";
import { onChangeSearch, pswRegex } from "helpers";
import Button from "components/Button";
import Input from "components/Input";
import Icon from "components/Icon";
import Select from "components/Select";
import EditorImage from "components/EditorImage";
import ArrowRight from "assets/icons/Chevron right.svg";
import ArrowBottom from "assets/icons/chevron-down-dark.svg";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import CloseAccount from "components/CloseAccount";
import * as Yup from "yup";
import cross from "assets/icons/cruz.svg";
const ProfileView = (props) => {
  const [searchCountry, setSearchCountry] = useState(null);
  const [isAddImage, setIsAddImage] = useState(false);
  const [isEditorImage, setIsEditorImage] = useState(false);
  const [isFormUser, setIsFormUser] = useState(true);
  const [isChangePassword, setIsChangePassword] = useState(false);

  const {
    user,
    isLg,
    isMd,
    countrys,
    Genders,
    handleCloseModalAccount,
    isCloseAccount,
    handleCloseAccount,
    handleOpenCloseaccount,
    handleCloseSession,
    handleEditImage,
    handleModifyPassword,
    handleEditUser,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles({ mediaQueryMd: isMd, mediaQueryLg: isLg });

  const initialValueProfileFormUser = {
    name: user?.person?.first_name || "",
    surname: user?.person?.last_name || "",
    email: user?.email || "",
    country: user?.person?.id_country
      ? countrys.find(
          (country) => country.id === parseInt(user?.person?.id_country)
        )
      : "",
    dateOfBirth: user?.person?.borned_date || undefined,
    gender: user?.person?.id_gender
      ? Genders.find((gender) => gender.id === user?.person?.id_gender)
      : "",
  };

  const profilFormUsereScheme = Yup.object().shape({
    name: Yup.string()
      .required(t("components.signUp.Name required"))
      .matches(/^[A-Za-z ]*$/, t("components.signUp.Incorrect format"))
      .test(
        "required",
        t("components.signUp.Name required"),
        (val) => !!val?.trim().length
      )
      .max(50, t("components.signUp.The field must not exceed 50 characters")),
    surname: Yup.string()
      .required(t("components.signUp.Last name required"))
      .matches(/^[A-Za-z ]*$/, t("components.signUp.Incorrect format"))
      .test(
        "required",
        t("components.signUp.Last name required"),
        (val) => !!val?.trim().length
      )
      .max(50, t("components.signUp.The field must not exceed 50 characters")),
    country: Yup.object(),
    email: Yup.string()
      .email(t(`components.auth.You must enter a valid email`))
      .required(t(`components.auth.Email Required`)),
    dateOfBirth: Yup.date().max(
      new Date(),
      "la fecha debe ser menor a la fecha de hoy"
    ),
    gender: Yup.object(),
  });

  const profilFormChangePasswordScheme = Yup.object().shape({
    currentPassword: Yup.string()
      .required(t("components.signUp.Password required"))
      .matches(
        pswRegex,
        t("components.signUp.Incorrect format")
      )
      .max(50, t("components.signUp.The field must not exceed 50 characters")),
    newpassword: Yup.string()
      .required(t("components.signUp.Password required"))
      .matches(
        pswRegex,
        t("components.signUp.Incorrect format")
      )
      .notOneOf(
        [Yup.ref("currentPassword"), null],
        t("components.signUp.The old password must not match the new password")
      )
      .max(50, t("components.signUp.The field must not exceed 50 characters")),
    repeatNewPassword: Yup.string()
      .oneOf(
        [Yup.ref("newpassword"), null],
        t("components.signUp.Passwords must match")
      )
      .required(t("components.signUp.Passwords must match")),
  });
  const initialValueProfilFormChangePassword = {
    currentPassword: "",
    newpassword: "",
    repeatNewPassword: "",
  };

  const formikFormUser = useFormik({
    initialValues: initialValueProfileFormUser,
    validationSchema: profilFormUsereScheme,
    enableReinitialize: true,
  });
  const formikFormChangePassword = useFormik({
    initialValues: initialValueProfilFormChangePassword,
    validationSchema: profilFormChangePasswordScheme,
  });
  useEffect(() => {
    setIsFormUser(true);
  }, [isMd]);

  const formUser = () => {
    return (
      <form className={classes.form}>
        <div className={classes.profileContainerForm}>
          <div className={classes.profileContainerFormInput}>
            <div className={classes.profileInputsLeft}>
              <Input
                isSucess={
                  !formikFormUser.errors.name &&
                  formikFormUser.touched.name &&
                  formikFormUser.dirty
                }
                isError={
                  formikFormUser.errors.name && formikFormUser.touched.name
                }
                name="name"
                label={t(`components.signUp.Name`)}
                isRequired
                css={classes.input}
                id="txt_name_profile"
                onChange={formikFormUser.handleChange}
                onBlur={formikFormUser.handleBlur}
                message={formikFormUser.errors.name}
                value={formikFormUser.values.name}
              />
            </div>
            <div className={classes.profileInputs}>
              <Input
                isSucess={
                  !formikFormUser.errors.surname &&
                  formikFormUser.touched.surname &&
                  formikFormUser.dirty
                }
                isError={
                  formikFormUser.errors.surname &&
                  formikFormUser.touched.surname
                }
                name="surname"
                label={t(`components.signUp.Surname`)}
                isRequired
                id="txt_surname_profile"
                onChange={formikFormUser.handleChange}
                onBlur={formikFormUser.handleBlur}
                message={formikFormUser.errors.surname}
                value={formikFormUser.values.surname}
              />
            </div>
          </div>

          <div className={classes.profileInputs}>
            <Input
              value={formikFormUser.values.email}
              name="email"
              label={t(`components.signUp.Email`)}
              id="txt_email_profile"
              readOnly={true}
              onChange={formikFormUser.handleChange}
              onBlur={formikFormUser.handleBlur}
            />
          </div>
          <div className={classes.profileInputs}>
            <div className={classes.label}>
              <label data-testid={`txt_country_label_profile`}>
                {t(`components.signUp.Country`)}
              </label>
            </div>
            <Select
              isSucess={formikFormUser.touched.country && formikFormUser.dirty}
              isSearch={true}
              selected={formikFormUser.values.country}
              options={searchCountry ? searchCountry : countrys}
              icon={<Icon icon={ArrowBottom} alt="arrow" />}
              onChangeSelect={(option) => {
                formikFormUser.setFieldTouched("country", true, true);
                formikFormUser.setFieldValue("country", option, false);
                setSearchCountry(null);
              }}
              css={classes.select}
              onChangeSearch={(event) => {
                setSearchCountry(onChangeSearch(countrys, event));
              }}
              onClickAwayListener={() => {
                if (!formikFormUser.values.country)
                  formikFormUser.setFieldTouched("country", true, true);
              }}
              id={`btn_select_country`}
            />
          </div>
          <div className={classes.profileContainerFormInput}>
            <div className={classes.profileInputsLeft}>
              <Input
                isSucess={
                  !formikFormUser.errors.dateOfBirth &&
                  formikFormUser.touched.dateOfBirth &&
                  formikFormUser.dirty
                }
                isError={
                  formikFormUser.errors.dateOfBirth &&
                  formikFormUser.touched.dateOfBirth
                }
                type="date"
                name="dateOfBirth"
                label={t(`components.signUp.Date of Birth`)}
                id="txt_date_profile"
                css={classes.input}
                onChange={formikFormUser.handleChange}
                onBlur={formikFormUser.handleBlur}
                value={formikFormUser.values.dateOfBirth}
                message={formikFormUser.errors.dateOfBirth}
              />
            </div>
            <div className={classes.profileInputs}>
              <div className={classes.label}>
                <label data-testid={`txt_gender_label_profile`}>
                  {t(`components.signUp.Gender`)}
                </label>
              </div>
              <Select
                isSucess={formikFormUser.touched.gender && formikFormUser.dirty}
                isError={false}
                options={Genders}
                selected={formikFormUser.values.gender}
                icon={<Icon icon={ArrowBottom} alt="arrow" />}
                onChangeSelect={(option) => {
                  formikFormUser.setFieldTouched("gender", true, true);
                  formikFormUser.setFieldValue("gender", option, false);
                }}
                css={classes.select}
                onClickAwayListener={() => {
                  if (!formikFormUser.values.country)
                    formikFormUser.setFieldTouched("gender", true, true);
                }}
                id={`btn_select_gender_profile`}
              />
            </div>
          </div>
          <div className={classes.buttonContent}>
            <Button
              id="btn_update"
              css={classes.buttonUpdate}
              type="primary"
              text="pages.profile.Update"
              onClick={() => {
                handleEditUser(formikFormUser.values);
                formikFormUser.resetForm();
              }}
              disabled={!(formikFormUser.isValid && formikFormUser.dirty)}
            />
          </div>

        </div>
      </form>
    );
  };

  const formChangePassword = () => {
    return (
      <form>
        <Grid container>
          <Grid
            className={classes.profileInputs}
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
          >
            <Input
              isSucess={
                !formikFormChangePassword.errors.currentPassword &&
                formikFormChangePassword.touched.currentPassword
              }
              isError={
                formikFormChangePassword.errors.currentPassword &&
                formikFormChangePassword.touched.currentPassword
              }
              type="password"
              value={formikFormChangePassword.values.currentPassword}
              label={t(`pages.profile.Current Password`)}
              isRequired
              id="txt_password_current"
              idButtonPassword="password_current"
              name="currentPassword"
              onChange={formikFormChangePassword.handleChange}
              onBlur={formikFormChangePassword.handleBlur}
              message={formikFormChangePassword.errors.currentPassword}
            />
          </Grid>
          <Grid
            className={classes.profileInputs}
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
          >
            <Input
              isSucess={
                !formikFormChangePassword.errors.newpassword &&
                formikFormChangePassword.touched.newpassword
              }
              isError={
                formikFormChangePassword.errors.newpassword &&
                formikFormChangePassword.touched.newpassword
              }
              type="password"
              value={formikFormChangePassword.values.newpassword}
              label={t(`pages.profile.New Password`)}
              isRequired
              id="txt_password_new"
              idButtonPassword="password_new"
              name="newpassword"
              onChange={formikFormChangePassword.handleChange}
              onBlur={formikFormChangePassword.handleBlur}
              message={formikFormChangePassword.errors.newpassword}
            />
          </Grid>
          <Grid
            className={classes.profileInputs}
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
          >
            <Input
              isSucess={
                !formikFormChangePassword.errors.repeatNewPassword &&
                formikFormChangePassword.touched.repeatNewPassword
              }
              isError={
                formikFormChangePassword.errors.repeatNewPassword &&
                formikFormChangePassword.touched.repeatNewPassword
              }
              value={formikFormChangePassword.values.repeatNewPassword}
              type="password"
              label={t(`pages.profile.Repeat New Password`)}
              isRequired
              id="txt_password_repeat"
              idButtonPassword="password_repeat"
              name="repeatNewPassword"
              onChange={formikFormChangePassword.handleChange}
              onBlur={formikFormChangePassword.handleBlur}
              message={formikFormChangePassword.errors.repeatNewPassword}
            />
          </Grid>
          <Grid
            className={classes.buttonContent}
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
          >
            <Button
              id="btn_update"
              css={classes.buttonUpdate}
              type="primary"
              text="pages.profile.Update"
              onClick={() => {
                formikFormChangePassword.resetForm();
                handleModifyPassword(formikFormChangePassword.values);
              }}
              disabled={
                !(
                  formikFormChangePassword.isValid &&
                  formikFormChangePassword.dirty
                )
              }
            />
          </Grid>
        </Grid>
      </form>
    );
  };
  const buttonsAccount = () => {
    return (
      <>
        <Button
          css={classes.buttonLogOutAccount}
          type="secondary"
          text="pages.profile.Log out from my account"
          id="btn_logOutAccount"
          onClick={handleCloseSession}
        />
        <Button
          css={classes.buttonCloseAccount}
          type="cuaternary"
          text="pages.profile.Close my account"
          id="btn_closeAccount"
          onClick={handleOpenCloseaccount}
        />
      </>
    );
  };

  return (
    <div className={classes.profileContainer} data-testid={`profile_view`}>
      {isCloseAccount && (
        <CloseAccount
          open={isCloseAccount}
          isMd={isMd}
          handleClose={handleCloseModalAccount}
          handleCloseAccount={handleCloseAccount}
        />
      )}
      <div className={classes.profile}>
        <EditorImage
          open={isEditorImage}
          isMd={isMd}
          handleClose={() => setIsEditorImage(false)}
          handleEditImage={(image) => {
            handleEditImage(image);
            setIsEditorImage(false);
          }}
          avatar={user?.avatar}
        />
        <div className={classes.profileContentUser}>
          <div
            onMouseLeave={() => setIsAddImage(false)}
            onMouseOver={() => setIsAddImage(true)}
            onClick={() => {
              setIsAddImage(false);
              setIsEditorImage(true);
            }}
            data-testid="open_editorAvatar"
          >
            <Avatar
              className={classes.profileAvatar}
              alt="user profile"
              src={user?.avatar}
            />
            <div
              className={
                isAddImage ? classes.visibilityAddImage : classes.hiddenAddImage
              }
            >
              <Icon icon={cross} alt="icon" />
            </div>
          </div>
          <div className={classes.profileUser}>
            <h1 data-testid="txt_user_name">{user?.name}</h1>
            <p data-testid="txt_description">
              {t(
                "pages.profile.You can complete your profile, so the community can get to know you!"
              )}
            </p>
          </div>
          {!isMd && (
            <>
              <div className={classes.profileButton}>
                <Button
                  type="cuaternary"
                  text="pages.profile.My personal information"
                  iconRight={<Icon icon={ArrowRight} alt="ico_arrow" />}
                  onClick={() => {
                    formikFormChangePassword.resetForm();
                    setIsChangePassword(false);
                    setIsFormUser(true);
                  }}
                  id="btn_personal_information"
                />
                {!user?.isRSS && (
                  <Button
                    type="cuaternary"
                    text="pages.profile.Change my password"
                    iconRight={<Icon icon={ArrowRight} alt="ico_arrow" />}
                    onClick={() => {
                      formikFormUser.resetForm();
                      setIsChangePassword(true);
                      setIsFormUser(false);
                    }}
                    id="btn_change_password"
                  />
                )}
              </div>
              {buttonsAccount()}
            </>
          )}
        </div>

        {!isMd ? (
          <div className={classes.profileForm}>
            {isFormUser && formUser()}
            {isChangePassword && formChangePassword()}
          </div>
        ) : (
          <div>
            <div>
              <Accordion
                onChange={() => {
                  if (isFormUser) setIsFormUser(false);
                  else setIsFormUser(true);

                  setIsChangePassword(false);
                }}
                className={`${classes.contentAccordion} ${classes.accordionFormUser}`}
                expanded={isFormUser}
                data-testid={`acc_form_user`}
              >
                <AccordionSummary
                  expandIcon={<Icon icon={ArrowBottom} />}
                  aria-label="Expand"
                  aria-controls="additional-actions1-content"
                  className={classes.contentSumary}
                  data-testid="acc_sumamary_form_user"
                >
                  <h1 data-testid="txt_personal_information">
                    {t("pages.profile.My personal information")}
                  </h1>
                </AccordionSummary>
                <AccordionDetails
                  data-testid="acc_details_form_user"
                  className={classes.contentDetails}
                >
                  {formUser()}
                </AccordionDetails>
              </Accordion>
            </div>
            {!user?.isRSS && (
              <div>
                <Accordion
                  onChange={() => {
                    if (isChangePassword) setIsChangePassword(false);
                    else setIsChangePassword(true);

                    setIsFormUser(false);
                  }}
                  className={`${classes.contentAccordion}`}
                  expanded={isChangePassword}
                  data-testid={`acc_form_password`}
                >
                  <AccordionSummary
                    expandIcon={<Icon icon={ArrowBottom} />}
                    aria-label="Expand"
                    aria-controls="additional-actions1-content"
                    className={classes.contentSumary}
                    data-testid="acc_sumamary_form_password"
                  >
                    <h1 data-testid="txt_change_password">
                      {t("pages.profile.Change my password")}
                    </h1>
                  </AccordionSummary>
                  <AccordionDetails
                    data-testid="acc_details_form_password"
                    className={classes.contentDetails}
                  >
                    {formChangePassword()}
                  </AccordionDetails>
                </Accordion>
              </div>
            )}
          </div>
        )}
        {isMd && buttonsAccount()}
      </div>
    </div>
  );
};

export default ProfileView;
