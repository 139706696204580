import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles({
  recoverPasswordContent: () => {
    return {
      width: "792px",
      outline: "none",
      height: "auto",
    };
  },
  recoverPasswordModal: (props) => {
    let style = {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      overflowY: "auto",
    };
    if (props.mediaQueryMd) {
      style = {
        ...style,
        height: "100%",
      };
    }
    return style;
  },
  recoverPassword: (props) => {
    let style = {
      background: "#FFFFFF",
      padding: "24px 24px 48px 24px",
      borderRadius: "12px",
    };
    if (props.mediaQueryMd) {
      style = {
        ...style,

        borderRadius: "none",
        padding: "8px 20px 72px 20px",
      };
    }
    return style;
  },
  closeIconRecoverPassword: (props) => {
    let style = {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      "& >img": {
        cursor: "pointer",
      },
    };
    if (props.mediaQueryMd) {
      style = {
        ...style,
        padding: "12px 0px",
      };
    }
    return style;
  },
  wrapperRecoverPassword: (props) => {
    let style = {
      margin: "24px 144px 0px 144px",
    };
    if (props.mediaQueryMd) {
      style = {
        ...style,
        margin: "0px",
      };
    }
    return style;
  },
  textRecoverPasswordContent: () => {
    return {
      margin: "0px 0px 24px 0px",
      "&>p": {
        fontFamily: "Nunito Sans",
        fontWeight: "normal",
        fontSize: "16px",
        lineHeight: "24px",
        textAlign: "center",
        color: "#5D688E",
        whiteSpace: "pre-wrap",
      },
    };
  },
  contentButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "24px 0px 0px 0px",
  },
});
