import React from "react";
import { useStyles } from "./styles";
import FormCommunity from "components/Community/Form";
import ListPrayerCommunity from "components/Community/PrayerList";
import Grid from "@material-ui/core/Grid";

export function CommunityComponentStyled(props) {
  const {
    isMd,
    css,
    isTabCommunity,
    posts,
    handleMorePost,
    languages,
    handlePostCommunity,
    isSuccessPost,
    handlePostComments,
    handleReportPost,
    handleMoreComments,
    handleEditComments,
    handleReportComment,
    handleFilter,
    handleFavorite,
    handleDislike,
    onDeletePrayer,
  } = props;
  const classes = useStyles({ mediaQueryMd: isMd, isTabCommunity });

  return (
    <div
      className={`${classes.communityContent} ${css}`}
      data-testid="community_section"
    >
      <Grid container className={classes.community}>
        <Grid item xs={12} md={6} className="communityColLeft">
            <FormCommunity
              isMd={isMd}
              isTabCommunity={isTabCommunity}
              handlePostCommunity={handlePostCommunity}
              isSuccessPost={isSuccessPost}
            />
        </Grid>
        <Grid item xs={12} md={6} >
          <ListPrayerCommunity
            isTabCommunity={isTabCommunity}
            isMd={isMd}
            css={css}
            posts={posts}
            handleMorePost={handleMorePost}
            languages={languages}
            handleFilter={handleFilter}
            isSuccessPost={isSuccessPost}
            handlePostComments={handlePostComments}
            handleReportPost={handleReportPost}
            handleMoreComments={handleMoreComments}
            handleEditComments={handleEditComments}
            handleReportComment={handleReportComment}
            onDeletePrayer={onDeletePrayer}
            handleFavorite={handleFavorite}
            handleDislike={handleDislike}
          />
        </Grid>
      </Grid>
    </div>
  );
}
