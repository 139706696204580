import React, { useRef, useEffect } from "react";
import { useStyles } from "./styles";
import Button from "components/Button";
import Icon from "components/Icon";
import PlayIcon from "assets/icons/Play.svg";
import PauseIcon from "assets/icons/Pause.svg";
import VolumeIcon from "assets/icons/Volume.svg";
import MutedIcon from "assets/icons/Mute.svg";
import StopIcon from "assets/icons/Stop.svg";
import IconPause from "assets/icons/PauseWhite.svg";
import IconPlay from "assets/icons/PlayWhite.svg";
import Slider from "@material-ui/core/Slider";

export function audioPlayerFunctions() {
  function handleProgress(e, value, dur, audio) {
    let compute = (value * dur) / 100;
    audio.current.currentTime = compute;
    return compute;
  }
  function fmtMSS(s) {
    return (s - (s %= 60)) / 60 + (9 < s ? ":" : ":0") + ~~s;
  }
  function toggleMuted(audio, muted) {
    audio.current.muted = !muted;
    return !muted;
  }
  function handleVolume(q, audio) {
    audio.current.volume = q;
    return q;
  }

  return {
    handleProgress,
    fmtMSS,
    toggleMuted,
    handleVolume,
  };
}

export function AudioPlayerStyled(props) {
  const {
    src,
    playing = false,
    toggleAudio,
    isCard,
    title,
    subtitle,
    isMd,
    isOpen,
  } = props;

  const [dur, setDur] = React.useState(0);
  const [currentTime, setCurrentTime] = React.useState(0);
  const [statevolum, setStateVolum] = React.useState(1);
  const [muted, setMuted] = React.useState(false);
  const audio = useRef();
  const classes = useStyles({ isOpen: isOpen, mediaQueryMd: isMd });

  useEffect(() => {
    setCurrentTime(audio?.current?.currentTime);
  }, [audio?.current?.currentTime]);

  useEffect(() => {
    var playPromise = audio?.current?.play();

    if (playPromise !== undefined) {
      playPromise
        .then(() => {
          playing ? audio.current.play() : audio.current.pause();
        })
        .catch(() => {});
    }
  }, [playing]);

  useEffect(() => {
    audio?.current?.addEventListener("ended", () => toggleAudio(audio, "stop"));
    return () => {
      audio?.current?.removeEventListener("ended", () =>
        toggleAudio(audio, "stop")
      );
    };
  }, [audio?.current]);

  const contentAudioPlayer = (css) => {
    return (
      <div
        data-testid={`content_audio_player`}
        className={`${classes.contentControls} ${css}`}
      >
        <audio
          onTimeUpdate={(e) => setCurrentTime(e.target.currentTime)}
          onCanPlay={(e) => setDur(e.target.duration)}
          ref={audio}
          src={src}
          data-testid={`audio`}
        />
        <div className={classes.musicControl}>
          <Button
            id={`btn_play_and_pause`}
            onClick={() => toggleAudio(audio, null)}
            css={`
              ${classes.buttonsControls} ${classes.buttonPlayAndPause}
            `}
            type="cuaternary"
            icon={
              playing ? (
                <Icon icon={PauseIcon} alt="pause" />
              ) : (
                <Icon icon={PlayIcon} alt="play" />
              )
            }
          />

          {isCard
            ? currentTime !== 0 && (
                <Button
                  onClick={() => {
                    setCurrentTime(0);
                    toggleAudio(audio, "stop");
                  }}
                  css={`
                    ${classes.buttonsControls} ${classes.buttonStop}
                  `}
                  type="cuaternary"
                  icon={<Icon icon={StopIcon} alt="stop" />}
                  data-testid={`btn_stop`}
                />
              )
            : playing && (
                <Button
                  onClick={() => {
                    setCurrentTime(0);
                    toggleAudio(audio, "stop");
                  }}
                  css={`
                    ${classes.buttonsControls} ${classes.buttonStop}
                  `}
                  type="cuaternary"
                  icon={<Icon icon={StopIcon} alt="stop" />}
                  data-testid={`btn_stop`}
                />
              )}
        </div>
        <div className={classes.progressContent}>
          <Slider
            id="sld"
            data-testid="sld"
            onChange={(e, value) =>
              setCurrentTime(
                audioPlayerFunctions().handleProgress(e, value, dur, audio)
              )
            }
            value={dur ? (currentTime * 100) / dur : 0}
          />
          <p>{audioPlayerFunctions().fmtMSS(dur)}</p>
        </div>
        {!isMd && (
          <div className={classes.volumeContent}>
            <Button
              onClick={() =>
                setMuted(audioPlayerFunctions().toggleMuted(audio, muted))
              }
              css={classes.buttonsControls}
              type="cuaternary"
              icon={
                muted ? (
                  <Icon icon={MutedIcon} alt="muted" />
                ) : (
                  <Icon icon={VolumeIcon} alt="volume" />
                )
              }
              data-testid={`btn_muted`}
            />
            {!muted ? (
              <Slider
                id="sld-muted"
                data-testid="sld-muted"
                onChange={(e, value) =>
                  setStateVolum(
                    audioPlayerFunctions().handleVolume(value / 100, audio)
                  )
                }
                value={Math.round(statevolum * 100)}
              />
            ) : null}
          </div>
        )}
      </div>
    );
  };
  return (
    <>
      {isCard ? (
        <>
          <div
            data-testid={`btn_singlePrayer_multimedia_card`}
            className={classes.contentCard}
          >
            <div className={classes.contentAudioCard}>
              <div>
                <Button
                  data-testid={`btn_singlePrayer_multimedia`}
                  onClick={() => toggleAudio(audio, null)}
                  css={classes.buttonMultimedia}
                  icon={
                    playing ? (
                      <Icon icon={IconPause} alt={"ico_pause"} />
                    ) : (
                      <Icon icon={IconPlay} alt={"ico_play"} />
                    )
                  }
                />
              </div>
              <div>
                <p data-testid={`txt_audio_player_subtitle`}>{subtitle}</p>
                <h1 data-testid={`txt_audio_player_title`}>{title}</h1>
              </div>
            </div>
          </div>
          {contentAudioPlayer(classes.audioPlayerCard)}
        </>
      ) : (
        contentAudioPlayer(null)
      )}
    </>
  );
}
