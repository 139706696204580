import React from "react";
import { useStyles } from "./styles";
import { useTranslation } from "react-i18next";
import Button from "components/Button";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Grid, Hidden } from "@material-ui/core";
import moment from "moment";

const BlogArticleView = (props) => {
  const { t } = useTranslation();
  const {
    mediaQueryMd,
    prevArticle,
    nextArticle,
    handlerChangeArticle,
    handlerSeeAll,
    article = {},
  } = props;

  const classes = useStyles({
    mediaQueryMd,
    isImage: article.multimedia?.[0]?.url,
  });
  const sectionDateAndAuthor = () => {
    return (
      <>
        <div>
          <h6 data-testid={`txt_article_published`}>
            {t("pages.blogArticle.published")}
          </h6>
          <span data-testid={`txt_article_date`}>
            {moment(article.publish_date).format("LLLL")}
          </span>
        </div>
        {article.author && (
          <div>
            <h6 data-testid={`txt_article_author`}>
              {t("pages.blogArticle.author")}
            </h6>
            <span data-testid={`txt_article_author_people`}>
              {article.author}
            </span>
          </div>
        )}
      </>
    );
  };
  return (
    <Grid
      container
      spacing={2}
      alignItems="center"
      direction="column"
      className={classes.gralBlog}
      id="blog_article_view"
      data-testid="blog_article_view"
    >
      <Grid item container direction="column" spacing={2} xs={12} md={10}>
        <Grid container direction="column" alignItems="flex-start" spacing={2}>
          <Grid item>
            <Button
              iconLeft={<ArrowBackIosIcon />}
              type="cuaternary"
              text="pages.blogArticle.See All Articles"
              css={classes.button}
              onClick={handlerSeeAll}
              id="btn_see_all_articles"
            />
          </Grid>
        </Grid>

        <Grid item>
          <Grid container direction="row" justify="center">
            <Grid item md={3}>
              <div></div>
            </Grid>

            <Grid item xs={12} sm={12} md={9}>
              <h1 data-testid={`txt_article_title`}>{article.title}</h1>
            </Grid>
          </Grid>
        </Grid>

        <Hidden mdUp>
          <Grid item className={classes.authorSection}>
            {sectionDateAndAuthor()}
          </Grid>
        </Hidden>

        <div className={classes.imgBlog}>
          {article.multimedia?.[0]?.url && (
            <img
              data-testid={`txt_article_img`}
              src={article.multimedia?.[0]?.url}
            />
          )}
        </div>

        <Grid item>
          <Grid container direction="row" justify="center">
            <Hidden smDown>
              <Grid item md={3} className={classes.authorSection}>
                {sectionDateAndAuthor()}
              </Grid>
            </Hidden>
            <Grid item xs={12} sm={12} md={9}>
              {article.blockquote && (
                <p
                  data-testid={`txt_article_blockquote`}
                  className={classes.blockquote}
                >
                  &#34;{article.blockquote}&#34;
                </p>
              )}
              <div
                data-testid={`txt_article_body`}
                dangerouslySetInnerHTML={{ __html: article.body }}
              ></div>
              <p
                data-testid={`txt_article_footArticle`}
                className={classes.footArticle}
              >
                <strong>{article.author}</strong>
                <br />
                {article.job_title}
              </p>

              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="flex-start"
              >
                <Grid item>
                  {prevArticle && (
                    <div
                      title={
                        `${t("pages.blogArticle.Prev")}: ` + prevArticle.title
                      }
                    >
                      <Button
                        iconLeft={<ArrowBackIcon />}
                        type="tertiary"
                        text={
                          mediaQueryMd
                            ? "pages.blogArticle.Prev"
                            : "pages.blogArticle.Previous Articles"
                        }
                        css={classes.button}
                        onClick={() => handlerChangeArticle(prevArticle.id)}
                        id="btn_prev_articles"
                      />
                    </div>
                  )}
                </Grid>
                <Grid item>
                  {nextArticle && (
                    <div
                      title={
                        `${t("pages.blogArticle.Next")}: ` + nextArticle.title
                      }
                    >
                      <Button
                        iconRight={<ArrowForwardIcon />}
                        type="tertiary"
                        text={
                          mediaQueryMd
                            ? "pages.blogArticle.Next"
                            : "pages.blogArticle.Next Article"
                        }
                        color="secondary"
                        css={classes.button}
                        disableRipple={true}
                        onClick={() => handlerChangeArticle(nextArticle.id)}
                        id="btn_next_articles"
                      />
                    </div>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BlogArticleView;
