/* eslint-disable */
import {
  postLogin,
  postSignUp,
  signInFacebook,
  signInGoogle,
  signUpFacebook,
  signUpGoogle,
  recoverPasswordSendEmail,
  postRecoverPassword,
  putUser,
} from './services';
import { toHome } from 'helpers';
import { last, remove } from 'lodash';

const USER_KEY = 'user';
const ACCESSTOKEN_KEY = 'access_token';
const EXP_KEY = 'exp';
const REFRESH_TOKEN = 'refresh';
const AMEN_KEY = 'amen';
const HASH_RECOVER_PASS = 'link_recover_pass'; 
const REPORTED_KEY = 'reported'; 

export function setSession(user, access_token, expiration, refreshToken) {
  if (user.avatar.length > 0) {
    user.avatar = last(user.avatar)?.url;
  } else user.avatar = null;
  if (user.person.gender) {
    user.person.id_gender = user.person.gender.id;
  }

  localStorage.setItem(USER_KEY, JSON.stringify(user));
  localStorage.setItem(ACCESSTOKEN_KEY, JSON.stringify(access_token));
  localStorage.setItem(EXP_KEY, JSON.stringify(expiration));
  localStorage.setItem(REFRESH_TOKEN, JSON.stringify(refreshToken));
}

export function setUserSession(user, dispatch) {
  localStorage.setItem(USER_KEY, JSON.stringify(user));
  dispatch({ type: '@auth/user', payload: user });
}

export function setRefreshToken(access_token, expiration, refreshToken) {
  localStorage.setItem(ACCESSTOKEN_KEY, JSON.stringify(access_token));
  localStorage.setItem(EXP_KEY, JSON.stringify(expiration));
  localStorage.setItem(REFRESH_TOKEN, JSON.stringify(refreshToken));
}

export function getUser() {
  return JSON.parse(localStorage.getItem(USER_KEY));
}

export function getAccessToken() {
  try{
    const token = JSON.parse(localStorage.getItem(ACCESSTOKEN_KEY))
    if(token === 'undefined') throw new Error('undefined')
    return token || null;
  } catch(error) {
    localStorage.removeItem(ACCESSTOKEN_KEY);
  }
}

export function getExpirationTime() {
  const expiration = JSON.parse(localStorage.getItem(EXP_KEY));
  return parseInt(expiration) * 1000;
}

export function getRefreshToken() {
  try{
    const token = JSON.parse(localStorage.getItem(REFRESH_TOKEN))
    if(token === 'undefined') throw new Error('undefined')
    return token || null;
  } catch(err) {
    clearSession(toHome);
  }
}

export function updateReducer(dispatch) {
  const user = localStorage.getItem(USER_KEY);
  const token = localStorage.getItem(ACCESSTOKEN_KEY);
  const exp = localStorage.getItem(EXP_KEY);

  dispatch({ type: '@auth/user', payload: JSON.parse(user) });
  dispatch({ type: '@auth/token', payload: token });
  dispatch({ type: '@auth/exp', payload: exp });
  dispatch({ type: '@modal/login', payload: { open: false } });
}

export function clearSession(dispatch) {
  localStorage.removeItem(USER_KEY);
  localStorage.removeItem(ACCESSTOKEN_KEY);
  localStorage.removeItem(EXP_KEY);
  localStorage.removeItem(REFRESH_TOKEN);
  dispatch({ type: '@auth/user', payload: null });
  dispatch({ type: '@auth/token', payload: null });
  dispatch({ type: '@auth/exp', payload: null });
}

export function logout(dispatch) {
  clearSession(dispatch);
}

export function singIn({ email, password, dispatch }) {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await postLogin({
        email: email,
        password: password,
      });
      res.user.multimedia = last(res.user.avatar||[]);
      res.user.isRSS = false;
      setSession(res.user, res.access_token, res.expiration, res.refresh_token);
      updateReducer(dispatch);
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
}

export function singUp(email, password, first_name, last_name, id_country) {
  const body = {
    user: {
      email,
      password,
    },
    first_name,
    last_name,
    id_country,
  };

  return postSignUp(body);
}

export function signUpRSS({ token, isFacebook = false }) {
  if (isFacebook) return signUpFacebook({ access_token: token });
  else return signUpGoogle({ access_token: token });
}

export async function sendEmailRecoverPassword(email) {
  const result = await recoverPasswordSendEmail(email);
  localStorage.setItem(HASH_RECOVER_PASS, result?.hash);
}
export async function recoverPassword(password, hash) {
  await postRecoverPassword(password, hash);
}
export function isExpireLink(hash) {
  const localHash = localStorage.getItem(HASH_RECOVER_PASS);
  return !localHash || String(localHash) !== String(hash);
}

export function signInRSS({ token, isFacebook = false, dispatch }) {
  return new Promise(async (resolve, reject) => {
    try {
      let res;
      if (isFacebook) res = await signInFacebook({ access_token: token });
      else res = await signInGoogle({ access_token: token });
      res.user.isRSS = true;
      setSession(res.user, res.access_token, res.expiration, res.refresh_token);
      updateReducer(dispatch);
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
}

export async function updateProfile(updatedUser, dispatch) {
  await putUser(updatedUser.person, updatedUser.id);
  setUserSession(updatedUser, dispatch);
}

export function retrieveLSAmen() {
  const amenLS = localStorage.getItem('amen');

  if (!!amenLS && amenLS !== 'undefined') {
    const parsed = JSON.parse(amenLS);
    return parsed;
  } else return [];
}

export function updateLSAmen(arrayAmen) {
  localStorage.setItem(AMEN_KEY, JSON.stringify(arrayAmen));
}

export function addLSAmen(id, code) {
  const arrayAmen = retrieveLSAmen();
  arrayAmen.push({ owner: id, ownerType: code });
  updateLSAmen(arrayAmen);
}

export function removeLSAmen(id, code) {
  const arrayAmen = retrieveLSAmen();
  remove(arrayAmen, { owner: id, ownerType: code });
  updateLSAmen(arrayAmen);
}

export function findAmen(id, code) {
  const arrayAmen = retrieveLSAmen();
  return arrayAmen.find((e) => e.owner === id && e.ownerType === code);
}

export function retrieveReported() {
  const reported = localStorage.getItem(REPORTED_KEY);

  if (!!reported && reported !== 'undefined') {
    return JSON.parse(reported);
  } else return [];
}

export function updateReported(arrayReported) {
  localStorage.setItem(REPORTED_KEY, JSON.stringify(arrayReported));
}

export function addReported(id, code) {
  const arrayReported = retrieveReported();
  arrayReported.push({ owner: id, ownerType: code });
  updateReported(arrayReported);
}

export function findReported(id, code) {
  const arrReported = retrieveReported();
  return arrReported.find((e) => e.owner === id && e.ownerType === code);
}
