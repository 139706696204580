import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles({
  root: (props) => {
    let style = {
      paddingTop: "5em",
    };
    if (props.mediaQueryMd) {
      style.paddingTop = "3em";
    }
    return style;
  },
});
