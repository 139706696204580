import makeStyles from "@material-ui/core/styles/makeStyles";
export const useStyles = makeStyles({
  summaryRoot: (props) => {
    let style = {
      width: "100%,",
      backgroundRepet: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "bottom right",
    };
    if (props?.backgroundImage?.color)
      style.backgroundColor = props?.backgroundImage?.color;
    else style.backgroundImage = `url(${props?.backgroundImage?.url})`;

    if (!props.expanded) style.backgroundPosition = 'right 93%';
    return style;
  },
  contentAccordion: (props) => {
    let style = {
      "&.MuiAccordion-root:before": {
        display: "none",
      },
      boxShadow: "none",
      "&.MuiAccordion-root.Mui-expanded": {
        marginBottom: "24px",
      },
      "&.MuiAccordion-rounded": {
        borderRadius: "12px !important",
      },
      "&>.MuiAccordionSummary-root": {
        flexDirection: "row-reverse",
        height: "100%",
        margin: 0,
        borderRadius: "12px",
        padding: "0px 51px 0px 49px",
        alignItems: "flex-start",
      },
    };
    if (props.expanded) {
      style["&>.MuiAccordionSummary-root"].borderRadius = "12px 12px 0px 0px";
    }
    if (props.mediaQuerySm) {
      style = {
        ...style,
        "&>.MuiAccordionSummary-root": {
          ...style["&>.MuiAccordionSummary-root"],
          alignItems: "flex-start",
          padding: "0px 24px 0px 24px",
        },
      };
    }

    return style;
  },
  contentSumary: (props) => {
    let style = {
      "&>.MuiAccordionSummary-content": {
        margin: 0,
      },
      "&>.MuiIconButton-edgeEnd": {
        margin: "80px 36px 0px 0px",
        padding: 0,
      },
      "&>img": {
        width: "17px",
        height: "20px",
      },
    };
    if (props.mediaQuerySm) {
      style["&>.MuiIconButton-edgeEnd"].margin = "31px 28px 0px 0px";
    }
    return style;
  },
  contentDetails: (props) => {
    let style = {
      display: "flex",
      flexDirection: "column",
      background: " #FFFFFF",
      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.15)",
      borderBottomRightRadius: "12px",
      borderBottomLeftRadius: "12px",

      "&.MuiAccordionDetails-root": {
        margin: 0,
      },
      "& > section": {
        padding: "56px 41px 67px 89px ",
      },
    };
    if (props.mediaQuerySm) {
      style["& > section"].padding = "0px 8px ";
    }
    return style;
  },
  ArrowContent: {
    width: "17px",
    height: "20px",
  },
});
