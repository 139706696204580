import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles(() => ({
  fotterContent: (props) => {
    let style = {
      background: "#FFFFFF",
      boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.15)",
      padding: "3.25em 3.6875em",
      flexBasis: "0",
      textAlign: "inherit",

      "& h2": {
        fontFamily: "Nunito Sans",
        fontSize: "1em",
        color: "#444C67",
      },
      "& hr": {
        border: "1px solid #444C67",
        opacity: 0.2,
        margin: "2.85em 0 1.5em 0",
      },
      "& a": {
        textDecoration: "none",
      },
      "& p": {
        fontFamily: "Nunito Sans",
        margin: 0,
        fontSize: "0.875em",
        color: "#444C67",
      },
      "& span": {
        fontSize: "0.75em",
        color: "#444C67",
      },
    };
    if (props.mediaQuerySm) {
      style.flexBasis = "100%";
      style.padding = "2.5em 1.25em";
      style.textAlign = "center";
    }
    return style;
  },
  thirdGrid: () => {
    return {
      marginTop: "3.125em",
      "& div:first-child": {
        display: "flex",
        flexDirection: "row",
        aligItems: "center",
        "& h2": {
          margin: "0 1.875em 0 0",
        },
        "& nav": {
          marginRight: "3.5em",
        },
      },
      "& div:last-child": {
        display: "flex",
        aligItems: "center",
        justifyContent: "flex-end",
      },
    };
  },
  secondGrid: {
    "& h2": {
      marginTop: 0,
    },
    "& div:nth-child(3)": {
      display: "flex",
      aligItems: "center",
      justifyContent: "center",

      "& p": {
        fontFamily: "Nunito Sans",
        fontSize: "0.875em",
        color: "#444C67",
        textAlign: "start",
        margin: "0 46px",
      },
    },
  },
  butonIcon: {
    padding: "0 10px",
    minWidth: "0px !important",
  },
  contentSumary: {
    flexBasis: "100%",
  },
  contentDetailsColumn: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  contentSumaryRow: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    flexDirection: "row",
  },
  download: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "& div:nth-child(3)": {
      margin: "1.5em 0 0 0",
    },
    "& div:last-child": {
      margin: "1.5em 0 0 0",
    },
  },
  initiativeContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
  },
  select: {
    "& button": {
      padding: "0.75em 0px",
      "& span": {
        fontSize: "14px !important",
      },
    },
  },
  contentPwpn: {
    display: "flex",
    justifyContent: "space-around",
  },
  imgPointer: {
    cursor: "pointer",
  },
}));
