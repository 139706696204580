import React from "react";
import { useStyles } from "./styles";
import { useTranslation } from "react-i18next";
import Icon from "components/Icon";
import ArrowLeftBlack from "assets/icons/Chevron_left.svg";
import ArrowRightBlack from "assets/icons/Chevron right.svg";
import Carousel, { consts } from "react-elastic-carousel";

export function PostsStyled(props) {
  const { isMd, posts } = props;
  const { t } = useTranslation();
  const classes = useStyles({
    mediaQueryMd: isMd,
  });

  function myArrow({ type, onClick, isEdge }) {
    const pointer = type === consts.PREV ? ArrowLeftBlack : ArrowRightBlack;
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Icon
          onClick={onClick}
          icon={pointer}
          css={!isEdge ? classes.button : classes.buttonDisabled}
          id={type === consts.PREV ? "ico_back_post" : "ico_next_post"}
        />
      </div>
    );
  }

  return (
    <div data-testid={"posts_content"} className={classes.postsContent}>
      <div className={classes.titleContent}>
        <h1 data-testid={"txt_posts_title"}>
          {t("components.posts.Our prayers on Social media")}
        </h1>
      </div>
      <div className={classes.postsSection}>
        {
          <>
            <Carousel
              renderArrow={myArrow}
              className={classes.carruselContent}
              disableArrowsOnEnd={true}
              itemPadding={[0, 20, 0, 0]}
              breakPoints={[
                {
                  width: 600,
                  itemsToShow: 2,
                  itemsToScroll: 1,
                  pagination: false,
                },
                {
                  width: 960,
                  itemsToShow: 4,
                  itemsToScroll: 4,
                  pagination: false,
                },
                {
                  width: 1294,
                  itemsToShow: 6,
                  itemsToScroll: 6,
                  pagination: false,
                },
                { width: 1750, itemsToShow: 6, pagination: false },
              ]}
            >
              {posts.map(({ link, post_link }, index) => {
                return (
                  <div
                    data-testid="crd_post"
                    key={index}
                    className={classes.post}
                    onClick={() => window.open(post_link)}
                  >
                    <img src={link} alt="post" />
                  </div>
                );
              })}
            </Carousel>
          </>
        }
      </div>
    </div>
  );
}
