import {
  getPrayingTips,
  getIntention,
  getBlogsHome,
  getPrayerSchoolHome
} from "services/services";
import {
  backgrounds,
  parsePrayer,
  parseSchoolsPrayer,
  setAmen,
  unsetAmen
} from "../../helpers";
import moment from "moment";
import { findAmen } from "services/authService";

export function updateDatesArray(initialDate, newDate) {
  let filter = initialDate.filter((date) => date === newDate);
  if (filter.length === 0) {
    let date = initialDate;
    date.push(newDate);
    date.sort();
    return date;
  } else {
    return initialDate;
  }
}
export function handleCardIntentionVideo(setIsPopeVideo) {
  setIsPopeVideo(true);
}
export function handleCardIntentionPrayer(history) {
  history.push("/monthly");
}
export function handleCardIntentionAttitudes(history) {
  history.push("/monthly");
}
export function navigateSchoolPrayer(history) {
  history.push("/school-prayer");
}
export function navigateBlog(history) {
  history.push("/blogs");
}
export async function fetchPrayingTips(setPrayingTips) {
  try {
    let response = await getPrayingTips(moment().format("YYYY-MM-DD"));
    setPrayingTips(response?.description);
  } catch (error) {
    console.log(error);
  }
}
export async function handleClickPrayCard(
  pray,
  prayers,
  setSinglePrayer,
  setIsModalSinglePrayer
) {
  let offeringPrayer = prayers.find((prayer) => prayer.typeMode === "offering");

  if (
    pray.textHover === "components.prayCard.Monthly Prayer" &&
    offeringPrayer
  ) {
    pray.offering = offeringPrayer;
  }
  setSinglePrayer(pray);
  setIsModalSinglePrayer(true);
}
export function moveCarrusel(subtract, add, setSubtract, setAdd) {
  if (subtract !== 0) {
    setSubtract(subtract - 1);
    setAdd(0);
  } else {
    setAdd(add + 1);
  }
}
export async function fetchIntention(
  date,
  mediaQuerySm,
  setInitialDate,
  setIntention,
  setBackgroundImage,
  setPrayers,
  setDateIntention,
  setPositionIntention,
  initialDate,
  countBack,
  countNext,
  handlerGoTo500
) {
  try {
    const response = await getIntention({
      date: date,
      prayersCode: "pop,mor,mon,off,aft,eve"
    });
    if (response?.prayers_date)
      response.prayers_date = moment(response?.prayers_date).format(
        "YYYY-MM-DD"
      );
    if (!initialDate.length && response?.prayers_date) {
      let value = updateDatesArray(initialDate, response?.prayers_date);

      setInitialDate(value);
    }
    setIntention({
      title: response?.title,
      description: response?.description,
      video: response?.video_url
    });
    if (mediaQuerySm)
      setBackgroundImage(backgrounds(response?.multimedia, "IMG_INT_HME_MBL"));
    else
      setBackgroundImage(backgrounds(response?.multimedia, "IMG_INT_HME_WEB"));
    if (response?.hasPrevPrayers && countBack < 2) {
      const value = updateDatesArray(
        initialDate,
        moment(response?.prayers_date).add(-1, "days").format("YYYY-MM-DD")
      );

      setInitialDate(value);
    }

    if (response?.hasNextPrayers && countNext < 2) {
      const value = updateDatesArray(
        initialDate,
        moment(response?.prayers_date)
          .add(+1, "days")
          .format("YYYY-MM-DD")
      );

      setInitialDate(value);
    }
    const prayerList = response?.prayers.map((prayer) => parsePrayer(prayer));
    setPrayers(prayerList);
    setDateIntention({
      day: moment(response?.prayers_date).format("dddd"),
      dayNumber: moment(response?.prayers_date).date(),
      month: moment(response?.prayers_date).format("MMMM").toUpperCase(),
      year: moment(response?.prayers_date).format("YYYY"),
      video: ""
    });
    setPositionIntention(
      initialDate.findIndex((element) => element === response?.prayers_date)
    );
  } catch (error) {
    //TODO navigate to error 500 page
    if (handlerGoTo500) handlerGoTo500();
    console.log(error);
  }
}
export async function fetchBlogsHome(setBlogs) {
  try {
    const response = await getBlogsHome();
    setBlogs(response);
  } catch (error) {
    console.log(error);
  }
}
export async function fetchPrayerSchoolHome(date, setSchoolPrayers) {
  try {
    const response = await getPrayerSchoolHome({ date });
    if (response?.length > 0) {
      let parseSchoolsPrayers = response.map((SchoolPrayer) =>
        parseSchoolsPrayer(SchoolPrayer)
      );
      setSchoolPrayers(parseSchoolsPrayers);
    }
  } catch (error) {
    console.log(error);
  }
}

const amenCheck = (prayer) => {
  return !!findAmen(prayer?.id, prayer.typeForAmen);
};

export async function setFavorite(
  owner,
  ownerType,
  singlePrayer,
  setSinglePrayer
) {
  await setAmen(owner, ownerType);
  setSinglePrayer({ ...singlePrayer, isFavorite: amenCheck(singlePrayer) });
}

export async function handleDislike(
  owner,
  ownerType,
  singlePrayer,
  setSinglePrayer
) {
  await unsetAmen(owner, ownerType);
  setSinglePrayer({ ...singlePrayer, isFavorite: amenCheck(singlePrayer) });
}

export function setHandler500Page(t, history) {
  return function () {
    history.replace("/error", {
      title: t("pages.home.internal server error"),
      message: t(
        "pages.home.Pray with Pope for mankind’s challenges and Church’s mission. Please come back later and we invite you to know more about Pope’s Worldwide Prayer Network proposals"
      ),
      buttonBack: false
    });
  };
}

export const unifyPrayers = (prayers) => {
  let unifiedPrayers = [...prayers];

  let monthlyPrayer = unifiedPrayers.find(
    (prayer) => prayer.typeMode === "monthly"
  );
  let offeringPrayer = unifiedPrayers.find(
    (prayer) => prayer.typeMode === "offering"
  );

  if (monthlyPrayer && offeringPrayer) {
    monthlyPrayer.offering = offeringPrayer;

    unifiedPrayers = unifiedPrayers.filter(
      (prayer) => prayer.typeMode !== "offering"
    );
  }

  return unifiedPrayers;
};
