/* eslint-disable */
import makeStyles from "@material-ui/core/styles/makeStyles";
import BackgroundBlog from "assets/img/Blog_Home.svg";
import ImageSectionIntention from "assets/img/section_intention.svg";
import ImageSectionSchoolPrayer from "assets/img/Escuela_de_Oracion_Home.svg";
import ImageSectionIntentionSm from "assets/img/section_intention_m.svg";

export const useStyles = makeStyles({
  homeContent: {
    overflowX: "hidden",
  },
  sectionCard: (props) => {
    let style = {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      backgroundSize: "cover",
      backgroundPositionX: '0',
      backgroundPositionY: '1px',
      height: "100%",
      width: "100%,",
      paddingBottom: "12em",
    };
    if (props?.backgroundImage?.color)
      style.backgroundColor = props?.backgroundImage?.color;
    else if (!props?.backgroundImage?.url)
      style.backgroundColor = "#b6b6b6";
    else
      style.backgroundImage = `url(${props?.backgroundImage?.url})`;


    if (props.mediaQuerySm) {
      style.backgroundRepeat = 'no-repeat';
      style.backgroundSize = 'cover';
      style.backgroundPositionX = '0%';
      style.backgroundPositionY = 'bottom';
      style.paddingBottom = "0";
      if (props.mediaQueryXs) {
        style.backgroundPositionY = '-9rem';
      }
    }

    return style;
  },
  buttonAppstore: () => {
    let style = {
      margin: "0px 16px 0px 0px !important",
    };

    return style;
  },
  dowload: (props) => {
    let style = {
      margin: "3.3125em 0 0 0",
      display: "flex",
      alignItems: "center",
      flexDirection: "row",
      justifyContent: "flex-end",
      textAlign: "none",
      "& > p": {
        color: "#FFFFFF",
        fontFamily: "Nunito Sans",
        margin: "0 1em 0 0",
        textAlign: "end",
        fontWeight: 600,
        fontSize: "18px",
      },
    };
    if (props.mediaQuerySm) {
      style.margin = "133px 0px 0px 0px";
      style.flexDirection = "column";
      style.textAlign = "center";
      style.width = "100%";
      style.padding = "2.5em";
      style["& > p"].margin = "0 0 1em 0";
      style["& > p"].textAlign = "center";
      style["& > p"].fontWeight = "normal";
      style["& > p"].fontSize = "16px";

      style.background = 'inherit';
      style.backgroundPosition = 'top left';
      style.backgroundSize = '300%';
      style.backgroundImage = `url(${props?.backgroundImage?.url})`;
    }

    return style;
  },
  wrapperIntention: (props) => {
    let style = {
      margin: "0 224px",
    };

    if (props.mediaQuerySm) {
      style.width = "90%";
      style.maxWidth = "62em";
      style.margin = "0";
    }

    return style;
  },
  ContentPrayingTip: {
    width: "100%",
    background: "linear-gradient(270.28deg, #444C67 0.83%, #5D688E 100%)",
  },
  WrapperPrayingTip: {
    margin: "0 1.50em 0 1.50em",
  },
  textContent: (props) => {
    let style = {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      "& li": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      "& h1": {
        textAlign: "end",
        fontFamily: "Libre Baskerville",
        fontSize: "1.5em",
        color: "#FFFFFF",
        lineHeight: "2.25em",
        margin: "0.8em 2em 0.8em 0px",
      },
      "& p": {
        fontFamily: "Nunito Sans",
        fontSize: "1em",
        lineHeight: "1.5em",
        color: "#FFFFFF",
        margin: "1em 0",
      },
    };

    if (props.mediaQuerySm) {
      style.textAlign = "center";
      style["& h1"] = {
        ...style["& h1"],
        textAlign: "center",
        margin: "40px 0 16px 0",
        fontSize: "22px",
      };
      style["& p"].margin = "0 0 4.625em 0";
    }
    return style;
  },
  wrapperPoperCard: (props) => {
    let style = {
      margin: "0 12em",
      display: "flex",
      justifyContent: "center",
    };
    if (props.mediaQuerySm) {
      style.margin = "0 1.25em";
    }
    return style;
  },
  contentIntention: (props) => {
    let style = {
      margin: "16em 0 3.6875em 0",
    };
    if (props.mediaQuerySm) {
      style.margin = "88px 0px 24px 0px";
    }

    return style;
  },
  dateIntention: (props) => {
    let style = {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      borderRight: "1px solid rgba(255, 255, 255, 0.3)",
      "& div": {
        width: "40%",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "center",
        flexDirection: "column",
        margin: 0,
        "& p": {
          fontFamily: "Nunito Sans",
          color: " #FFFFFF",
          margin: 0,
        },
        "& p:first-child": {
          fontSize: "0.875em",
          textTransform: "capitalize",
        },
        "& p:nth-child(2)": {
          fontSize: "5.5em",
          fontFamily: "Libre Baskerville",
        },
        "& p:last-child": {
          fontSize: "0.875em",
          textTransform: "uppercase",
        },
      },
    };
    if (props.mediaQuerySm) {
      style.flexDirection = "row";
      style.borderRight = "none";
      style["& div"] = {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "row",
        textAlign: "center",
        margin: 0,
        "& p": {
          fontFamily: "Nunito Sans",
          fontSize: "0.875em",
          margin: "0 0 0 0.625em",
          color: " #FFFFFF",
        },
        "& p:first-child": {
          textTransform: "uppercase",
        },
        "& p:nth-child(2)": {
          fontSize: "0.875em",
          fontFamily: "Libre Baskerville",
          color: " #FFFFFF",
        },
      };
    }
    return style;
  },
  descriptionIntention: (props) => {
    let style = {
      "& div": {
        margin: "0 0  0 2em",
        textAlign: "start",
        "& p": {
          fontFamily: "Nunito Sans",
          color: " #FFFFFF",
        },
        "& p:first-child": {
          fontSize: "44px",
          margin: "0 0 0.625em 0",
          fontFamily: "Libre Baskerville",
        },
        "& p:last-child": {
          fontSize: "0.875em",
          lineHeight: "1.5em",
          textTransform: "uppercase",
          margin: 0,
        },
        "& hr": {},
      },
    };
    if (props.mediaQuerySm) {
      style["& div"].textAlign = "center";
      style["& div"].margin = "0 0 2.0625em 0";
      style["& div"]["& hr"] = {
        width: "2.5em",
        border: "1px solid rgba(255, 255, 255, 0.3)",
      };
      style["& div"]["& p:first-child"] = {
        ...style["& div"]["& p:first-child"],
        fontSize: "28px",
        margin: "0px 0px 16px 0px",
      };
      style["& div"]["& p"].fontSize = "1.75em";
    }
    return style;
  },
  sectionIntention: (props) => {
    let style = {
      height: "759px",
      backgroundImage: `url(${ImageSectionIntention})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center bottom",
      width: "100%",
      "& >div": {
        "& >div:last-child": {
          marginBottom: "159px",
        },
      },
    };
    if (props.mediaQuerySm) {
      style.height = "776px";
      style["& >div"] = {
        "& >div:last-child": {
          marginBottom: "3em",
        },
      };
      style.backgroundImage = `url(${ImageSectionIntentionSm})`;
      style.backgroundSize = "auto";
      style.backgroundPosition = "left bottom";
    }
    return style;
  },
  intention: (props) => {
    let style = {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "center",
      textAlign: "center",
      margin: "10em 218px 4.5em 218px",
      "& h1": {
        fontFamily: "Libre Baskerville",
        fontSize: "44px",
        color: "#444C67",
        margin: "0 0 16px 0",
      },
      "& p": {
        fontFamily: "Nunito Sans",
        fontSize: "0.875em",
        letterSpacing: "0.1em",
        textTransform: "uppercase",
        color: " #5D688E",
        margin: 0,
      },
      "& hr": {
        border: "1px solid #5D688E",
        width: "2.5em",
        margin: "16px 0px",
      },
    };
    if (props.mediaQuerySm) {
      style.margin = "4em 20px 3em 20px";
      style["& h1"] = {
        ...style["& h1"],
        margin: "0 0 0 0",
        fontSize: "28px",
      };
    }
    return style;
  },
  sectionCardIntention: (props) => {
    let style = {
      marginBottom: "0.5em",
      marginTop: "4em",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    };
    if (props.mediaQuerySm) {
      style.marginBottom = "0";
      style.marginTop = "2em";
    }
    return style;
  },
  buttonCarrusel: {
    "&>.MuiButton-containedPrimary": {
      backgroundColor: "transparent !important",
    },
  },
  sectionBlog: (props) => {
    let style = {
      height: "100%",
      backgroundImage: `url(${BackgroundBlog})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      width: "100%",
      backgroundPosition: "top center",
    };
    if (props.mediaQuerySm) {
      style.backgroundImage = "none";
    }
    return style;
  },
  wrapperTitleBlog: (props) => {
    let style = {
      maxWidth: 1200,
      padding: "160px 216px 48px 216px",
      margin: "auto",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      "&>h1": {
        fontFamily: "Libre Baskerville",
        fontWeight: "normal",
        fontSize: "32px",
        color: "#444C67",
        textAlign: "start",
        margin: 0,
      },
    };
    if (props.mediaQuerySm) {
      style = {
        padding: "64px 20px 24px 20px",
        flexDirection: "column",
        justifyContent: "center",
        textAlign: "center",
        "&>h1": {
          ...style["&>h1"],
          fontSize: "24px",
          textAlign: "center",          
        },
        "& hr": {
          width: "40px",
          margin: "16px 0px",          
        },
      };
    }
    return style;
  },
  wrapperBlogs: (props) => {
    let style = {
      padding: "0px 0px 160px 0px",
    };
    if (props.mediaQuerySm) {
      style = {
        padding: "0px 0px 64px 0px",
      };
    }
    return style;
  },
  cardBlog: () => {
    let style = {
      width: "100% !important",
    };

    return style;
  },
  gridContainerBlog: (props) => {
    let style = {
      maxWidth: 1200,
      margin: "auto",
      "&>div:first-child": {
        margin: "0px  300px  48px 100px",
        float: "left !important",
      },
      "&>div:last-child": {
        margin: "0px 100px  0px  300px",
        float: "right !important",
      },
    };
    if (props.mediaQuerySm) {
      style["&>div:first-child"].margin = "0px 20px 24px 20px";
      style["&>div:last-child"].margin = "0px 20px 0px 20px ";
    }
    return style;
  },
  sectionSchoolPrayer: () => {
    let style = {
      height: "100%",
      backgroundImage: `url(${ImageSectionSchoolPrayer})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    };
    return style;
  },
  wrapperTitleSchoolPrayer: (props) => {
    let style = {
      width: "848px",
      height: "56px",
      padding: "160px 0px 56px 0px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      "&>h1": {
        fontFamily: "Libre Baskerville",
        fontWeight: "normal",
        fontSize: "44px",
        color: "#FFFFFF",
        margin: "0px",
      },
      "& hr": {},
    };
    if (props.mediaQuerySm) {
      style = {
        ...style,
        width: "90%",
        height: "auto",
        padding: "64px 0px 32px 0px",
        flexDirection: "column",
        justifyContent: "center",
        textAlign: "center",
        "& hr": {
          width: "40px",
          margin: "16px 0px",
          border: "1px solid #C1D7E3",
        },
        "&>h1": {
          ...style["&>h1"],
          fontSize: "24px",
        },
      };
    }
    return style;
  },
  buttonSchoolPrayer: () => {
    let style = {
      "&>span": {
        "&>div": {
          display: "flex",
          "&>span": {
            margin: "0px 8px 0px 0px",
          },
        },
      },
    };
    return style;
  },
  carrusel:()=>{
    let style = {
      whiteSpace: "pre-line",
    };
    return style;
  },
  wrapperSchoolPrayer: (props) => {
    let style = {
      width: "848px",
      height: "224px",
      marginBottom: "106px",
      "&>ul": {
        height: "224px",
        "&>li": {
          height: "224px",
          width: "200px",
          margin: "0px 0px 0px 16px",
          "&:first-child": {
            margin: 0,
          },
        },
      },
    };
    if (props.mediaQuerySm) {
      style = {
        ...style,
        width: "90%",
        marginBottom: "40px",
      };
    }

    return style;
  },
  contentDowload: (props) => {
    let style = {
      width: "100%",
      padding: "26px 0px 34px 0px",
      display: "flex",
      backgroundColor: "#5678a3",
      alignItems: "center",
      justifyContent: "center",
      "&>div": {
        flexDirection: "row",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& > p": {
          color: "#FFFFFF",
          fontFamily: "Nunito Sans",
          margin: "0 1em 0 0",
          textAlign: "end",
          fontWeight: 600,
          fontSize: "18px",
        },
      },
    };
    if (props.mediaQuerySm) {
      style = {
        ...style,
        padding: "26px 20px 34px 20px",

        "&>div": {
          ...style["&>div"],
          flexDirection: "column",
          "& > p": {
            ...style["&>div"]["& > p"],
            textAlign: "center",
            margin: "0 0 16px 0",
          },
        },
      };
    }
    return style;
  },
  contentIntentionEmpty: (props) => {
    let style = {
      margin: "6em 0 -12em 0",
      width: "100%",
      padding: "10em",
      backgroundColor: "#EFF2F5",
      backgroundImage: `url(${ImageSectionIntention})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center bottom",
      color: "#444C67",
      fontFamily: "Nunito Sans",
      fontWeight: 600,
      fontSize: "16px",
      lineHeight: "24px",
      "& div": {
        marginLeft: "20%",
        width: "40%",
      },
      "& a": {
        color: "#444C67",
        textDecoration: "none",
        fontWeight: "bold"
      }
    };
    if (props.mediaQuerySm) {
      style.margin = "88px 0px -133px 0px";
    }

    return style;
  },
});
