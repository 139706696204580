import React from "react";
import { useStyles } from "./styles";
import AccordionComponent from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Icon from "components/Icon";

export function AccordionStyled(props) {
  const {
    isSm,
    css,
    isExpanded,
    background,
    sumary,
    details,
    onChange,
    iconArrow,
  } = props;
  const classes = useStyles({
    expanded: isExpanded,
    mediaQuerySm: isSm,
    backgroundImage: background,
  });

  function handleChange() {
    onChange(isExpanded);
  }
  return (
    <AccordionComponent
      onChange={() => handleChange()}
      className={`${classes.contentAccordion} ${css}`}
      expanded={isExpanded}
      data-testid={`acc_component`}
      id="acc_component"
    >
      <AccordionSummary
        expandIcon={<Icon css={classes.ArrowContent} icon={iconArrow} />}
        aria-label="Expand"
        aria-controls="additional-actions1-content"
        className={classes.contentSumary}
        classes={{
          root: classes.summaryRoot,
        }}
        id="acc_sumamary_component"
        data-testid="acc_sumamary_component"
      >
        {sumary}
      </AccordionSummary>
      <AccordionDetails
        id="acc_details_component"
        data-testid="acc_details_component"
        className={classes.contentDetails}
      >
        {details}
      </AccordionDetails>
    </AccordionComponent>
  );
}
