import React from "react";
import moment from "moment";
import { useStyles } from "./styles";
import Grid from "@material-ui/core/Grid";
import Button from "components/Button";

export function BlogCardStyled(props) {
  const {
    title,
    id,
    description,
    img,
    isMd,
    isReverse,
    handleReadArticle,
    date,
    css,
    lineDescription = 2,
    isHome = false,
  } = props;
  const classes = useStyles({
    mediaQueryMd: isMd,
    reverse: isReverse,
    lineDescription: lineDescription,
  });

  return (
    <Grid
      data-testid={`crd_blog_container`}
      className={`${classes.contentBlogCard} ${css}`}
      container
    >
      <Grid
        className={classes.contentImage}
        item
        xs={12}
        sm={12}
        md={isHome ? 5 : 6 }
      >
        <div>
          {img && (
            <img
              data-testid="crd_image_blog"
              src={img}
              id="crd_image_blog"
              alt="crd_image_blog"
            />
          )}
        </div>
      </Grid>
      <Grid
        className={classes.contentTextBlog}
        item
        xs={12}
        sm={12}
        md={isHome ? 7 : 6 }
      >
        <div id="txt_content_blog" className={classes.contentText}>
          <p data-testid="crd_date_blog">{moment(date).format("LLL")}</p>
          <h1 data-testid="crd_title_blog">{title}</h1>
          <p
            data-testid="crd_description_blog"
            className={classes.descriptionBlog}
          >
            {description}
          </p>
          <Button
            id={`btn_read_article`}
            onClick={() => handleReadArticle(id)}
            type="tertiary"
            text="components.blogCard.Read more"
          />
        </div>
      </Grid>
    </Grid>
  );
}
