import React, { useRef } from "react";
import { useStyles } from "./styles";
import { useTranslation } from "react-i18next";
import Carrusel from "components/Carrusel";
import Icon from "components/Icon";
import Button from "components/Button";
import PrayWeek from "components/PrayWeek";
import SinglePrayer from "components/ModalPrayer";
import ArrowLeft from "assets/icons/Carrusel_Arrow_left.svg";
import ArrowRight from "assets/icons/Carrusel_Arrow_right.svg";
import ArrowLeftBlack from "assets/icons/Chevron_left.svg";
import ArrowRightBlack from "assets/icons/Chevron right.svg";

const DailyView = (props) => {
  const { t } = useTranslation();
  const carruselRef = useRef();
  const {
    dateIntention,
    backgroundImage,
    prayers,
    isMd,
    isSm,
    handleMultimedia,
    handleMultimediaWeek,
    isPlay,
    isPlayWeek,
    positionPrayer,
    handleNext,
    handleBack,
    prayersWeek,
    onScrollWeek,
    isNextWeek,
    isBackWeek,
    handleClickPrayCard,
    handleNextWeek,
    handleBackWeek,
    positionWeek,
    handleCloseSinglePrayer,
    isModalSinglePrayer,
    handleFavorite,
    handleDislike,
    allPrayers,
    singlePrayer,
    handleChangePrayer
  } = props;

  const classes = useStyles({
    mediaQueryMd: isMd,
    backgroundImage: backgroundImage
  });
  const intentionDate = (index, day, dayNumber, month, css) => {
    return (
      <div id={`content_date_${index}`} key={index} className={`${css}`}>
        <p data-testid={`content_day_${index}`}>{day}</p>
        <p data-testid={`content_dayNumber_${index}`}>{dayNumber}</p>
        <p data-testid={`content_months_${index}`}>{month.toUpperCase()}</p>
      </div>
    );
  };

  return (
    <div data-testid={`daily_view`} className={classes.dailyContent}>
      <section className={`${classes.sectionCard}`}>
        <div className={classes.wrapperIntention}>
          <div id="text_content_title_daily" className={classes.sectionTitle}>
            <h1 data-testid="text_content_title_daily">
              {t(`pages.daily.Pray every day`)}
            </h1>
            {!isMd && (
              <h6
                className={classes.subtitle}
                data-testid="text_content_chanllenges_daily"
              >
                {t(
                  `pages.daily.We suggest a daily prayer schedule that involves three different times throughout the day to help make ourselves more available to the mission of the Risen Christ.`
                )}
              </h6>
            )}
          </div>
          <div className={classes.contentPrayers}>
            <div className={classes.contentButtonNavigation}>
              <div className={classes.buttons}>
                {positionPrayer !== 0 && (
                  <Button
                    onClick={() => handleBack(positionPrayer)}
                    icon={
                      <Icon id="ico_back" icon={ArrowLeft} alt="arrow right" />
                    }
                    type="transparent"
                    css={`
                      ${classes.buttonsNavigationsPrev}
                    `}
                    data-testid="btn_back"
                  />
                )}
              </div>
              {intentionDate(
                0,
                dateIntention?.day,
                dateIntention?.dayNumber,
                dateIntention?.month,
                classes.dateContent
              )}
              {isMd && (
                <div className={classes.buttons}>
                  {positionPrayer !== 2 && (
                    <Button
                      onClick={() => handleNext(positionPrayer)}
                      icon={
                        <Icon
                          id="ico_next"
                          icon={ArrowRight}
                          alt="arrow right"
                        />
                      }
                      type="transparent"
                      css={`
                        ${classes.buttonsNavigationsNext}
                      `}
                      data-testid="btn_next"
                    />
                  )}
                </div>
              )}
            </div>
            <div>
              <Carrusel
                id="carrusel_content"
                navButtonsProps={{
                  style: {
                    backgroundColor: "transparent",
                    borderRadius: 0
                  }
                }}
                cycleNavigation={true}
                autoPlay={false}
                navButtonsAlwaysInvisible={true}
                animation="slide"
                index={positionPrayer}
                indicators={false}
                swipe={false}
              >
                {prayers.map((prayer, index) => (
                  <SinglePrayer
                    prayerDate={dateIntention?.date}
                    isDaily
                    key={index}
                    description={
                      !prayer?.isPrayer
                        ? t(prayer?.description)
                        : prayer?.description
                    }
                    title={prayer?.title}
                    icon={prayer?.icon}
                    textHover={prayer?.textHover}
                    audio={prayer?.audio}
                    handleMultimedia={handleMultimedia}
                    isPlay={isPlay}
                    isSmall={isSm}
                    open={true}
                    isPrayer={prayer?.isPrayer}
                    css={classes.contentSinglePlayer}
                    handleFavorite={() => {
                      handleFavorite(prayer?.id, prayer?.typeForAmen, index);
                    }}
                    handleDislike={() => {
                      handleDislike(prayer?.id, prayer?.typeForAmen, index);
                    }}
                    owner={prayer?.id}
                    ownerType={prayer.typeForAmen}
                    ownerGroup={prayer?.groupData}
                    typeMode={prayer?.typeMode}
                    prayers={allPrayers}
                    currentPrayerId={prayer?.id}
                    handleChangePrayer={handleChangePrayer}
                  />
                ))}
              </Carrusel>
            </div>

            <div
              className={`${classes.contentButtonNavigation} ${classes.buttons}`}
            >
              {positionPrayer !== 2 && !isMd && (
                <Button
                  onClick={() => handleNext(positionPrayer)}
                  icon={
                    <Icon id="ico_next" icon={ArrowRight} alt="arrow right" />
                  }
                  type="transparent"
                  css={`
                    ${classes.buttonsNavigationsPrev}
                  `}
                  data-testid="btn_next"
                />
              )}
            </div>
          </div>
        </div>
      </section>

      <section className={classes.backgroundWeek}>
        <div id="txt_content_title_week" className={classes.sectionTitleWeek}>
          <h1 data-testid="txt_content_title_week">
            {t(`pages.daily.This week`)}
          </h1>
        </div>

        <div className={classes.sectionWeek}>
          {!isMd ? (
            <>
              <div
                className={`${classes.buttonsWeek} ${classes.buttonsBackWeek}`}
              >
                {isBackWeek && (
                  <Icon
                    onClick={() => {
                      carruselRef.current.scrollLeft -=
                        carruselRef.current.clientWidth;
                    }}
                    icon={ArrowLeftBlack}
                    alt="arrow left"
                    css={classes.button}
                    id="ico_back_week"
                  />
                )}
              </div>

              <div
                onScroll={onScrollWeek}
                ref={carruselRef}
                className={classes.sectionPrayerWeek}
              >
                {prayersWeek.map((prayer, index) => {
                  return (
                    <div className={classes.prayWeek} key={index}>
                      <PrayWeek
                        intentionDate={prayer?.date}
                        day={prayer?.day}
                        dayNumber={prayer?.dayNumber}
                        month={prayer?.month}
                        prayers={prayer?.prayers}
                        isMd={isMd}
                        isSm={isSm}
                        handleClickPrayCard={handleClickPrayCard}
                      />
                    </div>
                  );
                })}
              </div>

              <div
                className={`${classes.buttonsWeek} ${classes.buttonsNextWeek}`}
              >
                {isNextWeek && (
                  <Icon
                    onClick={() => {
                      carruselRef.current.scrollLeft +=
                        carruselRef.current.clientWidth;
                    }}
                    icon={ArrowRightBlack}
                    alt="arrow right"
                    css={classes.button}
                    id="ico_next_week"
                  />
                )}
              </div>
            </>
          ) : (
            <div>
              <Carrusel
                id="carrusel_content_week"
                navButtonsProps={{
                  style: {
                    backgroundColor: "transparent",
                    borderRadius: 0
                  }
                }}
                navButtonsWrapperProps={{
                  style: {
                    top: "-4px"
                  }
                }}
                cycleNavigation={false}
                autoPlay={false}
                animation="slide"
                indicators={false}
                swipe={false}
                next={handleNextWeek}
                prev={handleBackWeek}
                index={positionWeek}
                NextIcon={
                  <Icon
                    id="ico_next_week"
                    icon={ArrowRightBlack}
                    alt="arrow right"
                  />
                }
                PrevIcon={
                  <Icon
                    id="ico_back_week"
                    icon={ArrowLeftBlack}
                    alt="arrow left"
                  />
                }
              >
                {prayersWeek.map((prayer, index) => {
                  return intentionDate(
                    index,
                    prayer?.day,
                    prayer?.dayNumber,
                    prayer?.month,
                    classes.dateContentWeek
                  );
                })}
              </Carrusel>
              <div className={classes.prayWeek}>
                <PrayWeek
                  handleClickPrayCard={handleClickPrayCard}
                  prayers={prayersWeek[0]?.prayers}
                  isMd={isMd}
                  isSm={isSm}
                />
              </div>
            </div>
          )}

          <SinglePrayer
            prayerDate={singlePrayer?.intentionDate}
            description={
              !singlePrayer?.isPrayer
                ? t(singlePrayer?.description)
                : singlePrayer?.description
            }
            title={singlePrayer?.title}
            offering={singlePrayer?.offering}
            icon={singlePrayer?.icon}
            textHover={singlePrayer?.textHover}
            audio={singlePrayer?.audio}
            open={isModalSinglePrayer}
            handleClose={handleCloseSinglePrayer}
            handleMultimedia={handleMultimediaWeek}
            isPlay={isPlayWeek}
            isSmall={isMd}
            isModal
            handleFavorite={() =>
              handleFavorite(singlePrayer?.id, singlePrayer?.typeForAmen, null)
            }
            handleDislike={() =>
              handleDislike(singlePrayer?.id, singlePrayer?.typeForAmen, null)
            }
            owner={singlePrayer?.id}
            ownerType={singlePrayer?.typeForAmen}
            ownerGroup={singlePrayer?.groupData}
            typeMode={singlePrayer?.typeMode}
            prayers={allPrayers}
            currentPrayerId={singlePrayer?.id}
            handleChangePrayer={handleChangePrayer}
          />
        </div>
      </section>
    </div>
  );
};

export default DailyView;
