import React from "react";
import {useStyles} from 'pages/Monthly/styles';
import {useTranslation} from "react-i18next";
import Button from "../../components/Button";

const WhatIsCTP = () => {
  const {t} = useTranslation();
  const classes = useStyles();

  return (
    <>
      <div data-testid={`monthly_view`} className={classes.intentionMonthlyContent}>
        <section className={classes.titleMonthlysContent}>
          <div id="txt_title_what_is_ctp" className={classes.wrappertTitleMonthlys}>
            <h1 data-testid={`txt_title`}>{t("whatCTP.title")}</h1>
            <p>{t("whatCTP.p1")}</p><br/>
            <p>{t("whatCTP.p2")}</p><br/>
            <p>{t("whatCTP.p3")}</p><br/>
            <p>{t("whatCTP.p4")}</p><br/>
            <p>{t("whatCTP.p5")}</p><br/>
            <p>{t("whatCTP.p6")}</p><br/>
            <p>{t("whatCTP.p7")}</p>
          </div>
          <div id="txt_title_what_is_ctp" className={classes.wrappertTitleMonthlys}>
            <h1 data-testid={`txt_title`}>{t("whatRMOP.title")}</h1>
            <p>{t("whatRMOP.p1")}</p><br/>
            <p>{t("whatRMOP.p2")}</p><br/>
            <h6>{t("whatRMOP.subtitle1")}</h6>
            <p>{t("whatRMOP.p3")}</p><br/>
            <h6>{t("whatRMOP.subtitle2")}</h6>
            <p>{t("whatRMOP.p4")}</p><br/>
            <p>{t("whatRMOP.p5")}</p><br/>
            <p>{t("whatRMOP.p6")}</p><br/>
            <p>{t("whatRMOP.p7")}
              <Button
                type="cuaternary"
                onClick={() => window.open(t("whatRMOP.p8"), "_blank")}
                text={"whatRMOP.p8"}
              />
            </p>
          </div>
        </section>
      </div>
    </>
  )
}
export default WhatIsCTP;
