import React from 'react';
import { useStyles } from './styles';
import { useTranslation } from 'react-i18next';
import Button from 'components/Button';
import Accordion from 'components/Accordion';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ArrowIcon from 'assets/icons/chevron-down-dark.svg';
import AudioPlayer from 'components/AudioPlayer';
import SocialSection from '../SocialSection';
import AmenButton from 'components/AmenButton';
import { ROSARY_PAGE } from "../../navigation/CONSTANTS";

export function AccordionRosaryStyled(props) {
  const {
    title,
    description,
    finalPrayer,
    index,
    isExpanded,
    isVideo,
    isImage,
    isSm,
    isLg,
    onChange,
    handlevideo,
    audios,
    day,
    toggleAudio,
    css,
    handleDislike,
    handleFavorite,
    owner,
    ownerType,
    rosaryIndex
  } = props;
  const { t } = useTranslation();
  const classes = useStyles({
    mediaQuerySm: isSm,
    mediaQueryLg: isLg,
  });
  function generateRosaryLink(ind) {
    if(rosaryIndex && ind >= 0) {
      const dayNumber = ind === 7 ? 0 : ind;
      return window.location.origin + `${ROSARY_PAGE}/${rosaryIndex}/${dayNumber}`;
    } else {
      return window.location.href;
    }
  }

  const sumaryRosary = () => {
    return (
      <div
        id={`acc__sumamary_component_content_${index}`}
        data-testid={`acc__sumamary_component_rosary_content_${index}`}
        style={{ height: '100%' }}
      >
        <p
          data-testid={`txt_acc__sumamary_component_rosary_day`}
          className={classes.contentDay}
        >
          {day}
        </p>

        <h1
          className={classes.titleRosary}
          data-testid={`txt_acc__sumamary_component_rosary_title`}
        >
          {title}
        </h1>

        {isExpanded ? (
          <>
            {description && (
              <div className={classes.description}>
                <div
                  id={`ico_description_rosary_${index}`}
                  data-testid={`ico_description_rosary_${index}`}
                  className={classes.contentDescription}
                >
                  <p
                    data-testid={`txt_description_rosary_${index}`}
                    dangerouslySetInnerHTML={{ __html: description }}
                  />
                </div>
              </div>
            )}
            <FormControlLabel
              aria-label="Acknowledge"
              onClick={(event) => event.stopPropagation()}
              onFocus={(event) => event.stopPropagation()}
              classes={{ root: classes.formControlLabel }}
              control={
                <div>
                  {isVideo && (
                    <Button
                      css={classes.buttonVideo}
                      type="primary"
                      text="pages.schoolPrayer.Watch the Video"
                      onClick={() => handlevideo()}
                      data-testid={`btn_summary_video_rosary`}
                    />
                  )}
                </div>
              }
            />
            {isImage && (
              <div className={classes.contentImageRosary}>
                <img src={isImage} />
              </div>
            )}
          </>
        ) : null}
      </div>
    );
  };
  const detailsRosary = () => {
    return (
      <div className={classes.contentDetails}>
        {audios?.map((mysterie, index) => {
          return (
            <AudioPlayer
              isMd={isSm}
              isCard={true}
              toggleAudio={(current, type) =>
                toggleAudio(current, type, mysterie)
              }
              src={mysterie?.audio?.src}
              playing={mysterie?.audio?.isPlay}
              key={index}
              title={`${mysterie?.order} ${t('pages.eRosary.Mystery')}. ${
                mysterie.title
              }`}
              subtitle={mysterie.subtitle}
              isOpen={mysterie?.isOpen}
            />
          );
        })}
        <div className={classes.contentFinalPrayer}>
          {finalPrayer && (
            <>
              <h1 data-testid={`txt_final_prayer_title_${index}`}>
                {finalPrayer.title || t('pages.eRosary.Final Prayer')}
              </h1>
              <p
                data-testid={`txt_final_prayer_description_${index}`}
                dangerouslySetInnerHTML={{ __html: finalPrayer.description }}
              />
            </>
          )}
          <div className={classes.buttonFinalPrayer}>
            <div>
              <AmenButton
                index={index}
                owner={owner}
                ownerType={ownerType}
                handleAmen={handleFavorite}
                handleUnAmen={handleDislike}
                testid={`btn_accordionRosary_favorite`}
              />
            </div>
            <div>
              <SocialSection
                title={t('pages.eRosary.Final Prayer')}
                description={description}
                url={generateRosaryLink(index + 1)}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Accordion
      css={css}
      onChange={onChange}
      background={{ color: '#E7ECF0' }}
      sumary={sumaryRosary()}
      details={detailsRosary()}
      iconArrow={ArrowIcon}
      isSm={isSm}
      isExpanded={isExpanded}
    />
  );
}
