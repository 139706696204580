import React from "react";
import { Modal } from "@material-ui/core";
import { useStyles } from "./styles";
import { Formik, Form } from "formik";
import { useTranslation } from "react-i18next";
import Button from "components/Button";
import Input from "components/Input";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import IconClose from "assets/icons/Close.svg";

import Icon from "components/Icon";
import * as Yup from "yup";

export function NewPasswordStyled(props) {
  const { isMd, handleClose, open, handleNewPassword } = props;
  const { t } = useTranslation();
  const classes = useStyles({ mediaQueryMd: isMd });
  const newPasswordScheme = Yup.object().shape({
    password: Yup.string()
      .required(t("components.signUp.Password required"))
      .matches(
        /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[#?!@$%^&*-]?)([a-zA-Z0-9#?!@$%^&*-]{8,50})$/,
        t("components.signUp.Incorrect format")
      )
      .max(50, t("components.signUp.The field must not exceed 50 characters")),
    passwordConfirmation: Yup.string()
      .oneOf(
        [Yup.ref("password"), null],
        t("components.signUp.Passwords must match")
      )
      .required(t("components.signUp.Passwords must match")),
  });
  const initialValueNewPassword = {
    password: "",
    passwordConfirmation: "",
  };
  return (
    <>
      <Modal
        open={open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        className={classes.newPasswordModal}
        data-testid="newPassword_content"
      >
        <Fade in={open}>
          <div className={classes.newPasswordContent}>
            <div className={classes.newPassword}>
              <div className={classes.closeIconContent}>
                <Icon
                  onClick={() => handleClose()}
                  icon={IconClose}
                  alt="close"
                  id="ico_newPassword_close_modal"
                />
              </div>
              <div className={classes.wrapperNewPassword}>
                <div className={classes.titleContentNewPassword}>
                  <h1 data-testid="txt_title_newPassword">
                    {t(`components.newPassword.Change Password`)}
                  </h1>
                </div>
                <Formik
                  validationSchema={newPasswordScheme}
                  initialValues={initialValueNewPassword}
                >
                  {({
                    dirty,
                    isValid,
                    errors,
                    values,
                    handleChange,
                    handleBlur,
                    touched,
                  }) => {
                    return (
                      <Form>
                        <div className={classes.contentInputs}>
                          <div className={classes.contentInputsForm}>
                            <div className={`${classes.inputLeft}`}>
                              <Input
                                type="password"
                                messageInfo={t(
                                  "components.signUp.password validation text"
                                )}
                                isSucess={!errors.password && touched.password}
                                isError={errors.password && touched.password}
                                value={values.password}
                                label={t(`components.signUp.Password`)}
                                isRequired
                                css={classes.inputs}
                                id="txt_password"
                                idButtonPassword="password"
                                name="password"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                message={errors.password}
                              />
                            </div>
                            <div className={`${classes.inputRight}`}>
                              <Input
                                type="password"
                                isSucess={
                                  !errors.passwordConfirmation &&
                                  touched.passwordConfirmation
                                }
                                isError={
                                  errors.passwordConfirmation &&
                                  touched.passwordConfirmation
                                }
                                value={values.passwordConfirmation}
                                label={t(`components.signUp.Repeat Password`)}
                                isRequired
                                id="txt_repeat_password"
                                idButtonPassword="repeat_password"
                                name="passwordConfirmation"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                message={errors.passwordConfirmation}
                              />
                            </div>
                          </div>

                          <div className={classes.buttonNewPassword}>
                            <Button
                              id="btn_newPassword"
                              text="pages.profile.Update"
                              type="primary"
                              onClick={() => handleNewPassword(values)}
                              disabled={!(isValid && dirty)}
                            />
                          </div>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
}
