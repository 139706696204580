import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles({
  address: {
    marginTop: 5,
    marginBottom: 5,
    paddingTop: 10,
    paddingBottom: 10,
    textAlign: 'center',
  }
});
