import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles({
  rootBtnAmen: {
    padding: "0.75em 1em",
    "& div": {
      display: "flex",
      "& img:first-child": {
        marginRight: "0.60375em",
      },
    },
  },
});
