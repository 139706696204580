import { updateProfile, setUserSession } from "services/authService";
import {
  updatePassword,
  putUserImaege,
  deleteUser,
  genders,
} from "services/services";
import ConfirmationCheck from "../../assets/icons/confirmationCheck.svg";
import Icon from "../../components/Icon";
import React from "react";

export async function handleModifyPassword(value, dispatch) {
  let newPassword = {
    old_password: value.currentPassword,
    new_password: value.newpassword,
  };

  try {
    await updatePassword(newPassword);
    dispatch({
      type: "@modal/error",
      payload: {
        open: true,
        message: { messageError: "Ok", icon: <Icon icon={ConfirmationCheck} alt="ico_arrow" />  },
      },
    });
  } catch (error) {
    dispatch({
      type: "@modal/error",
      payload: {
        open: true,
        message: { name: error?.name, messageError: error?.message ,code: error?.code},
      },
    });
  }
}

export async function handleEditUser(person, dispatch, user) {
  const updatedUser = {
    ...user,
    person: {
      first_name: person.name,
      last_name: person.surname,
      id_country: person?.country?.id?.toString(),
      borned_date: person?.dateOfBirth,
      id_gender: person?.gender?.id,
    },
  };
  try {
    await updateProfile(updatedUser, dispatch);
    dispatch({
      type: "@modal/error",
      payload: {
        open: true,
        message: { messageError: "Ok", icon: <Icon icon={ConfirmationCheck} alt="ico_arrow" />  },
      },
    });
  } catch (error) {
    dispatch({
      type: "@modal/error",
      payload: {
        open: true,
        message: { name: error?.name, messageError: error?.message ,code: error?.code},
      },
    });
  }
}

export async function handleEditorAvatar(image, dispatch, user) {
  if (image?.props?.image) {
    let owner = [
      {
        name: image?.props?.image.name,
        type: "IMG",
        owners: [{ owner: user.id, ownerType: "USR" }],
        replace: user.multimedia?.id,
      },
    ];
    let formData = new FormData();

    try {
      formData.append("", image?.props?.image);
      formData.append("metadata", JSON.stringify(owner));

      await putUserImaege(formData);
      setUserSession({
        ...user,
        avatar: image.getImageScaledToCanvas().toDataURL(),
      }, dispatch);
    } catch (error) {
      dispatch({
        type: "@modal/error",
        payload: {
          open: true,
          message: { name: error?.name, messageError: error?.message ,code: error?.code},
        },
      });
    }
  }
}

export async function handleCloseAccountUser(dispatch, user) {
  try {
    await deleteUser(user.id);
  } catch (error) {
    dispatch({
      type: "@modal/error",
      payload: {
        open: true,
        message: { name: error?.name, messageError: error?.message ,code: error?.code},
      },
    });
  }
}

export async function getGenders(setGenders, t) {
  let response = await genders();
  if (response)
    response = response.map((gender) => {
      return { ...gender, value: t(`components.signUp.${gender.description}`) };
    });
  setGenders(response);
}
